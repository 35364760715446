import { Component } from '@angular/core';
import { ProductMenuStaticSectionOverflowCalculatorViewModel } from './product-menu-static-section-overflow-calculator-view-model';
import { ProductMenuSectionOverflowCalculatorComponent } from '../product-menu-section-overflow-calculator/product-menu-section-overflow-calculator.component';

@Component({
  selector: 'app-product-menu-static-section-overflow-calculator',
  // shared template
  templateUrl: '../product-menu-overflow-calculator.component.html',
  styleUrls: ['../product-menu-overflow-calculator.component.scss'],
  providers: [ProductMenuStaticSectionOverflowCalculatorViewModel]
})
export class ProductMenuStaticSectionOverflowCalculatorComponent extends ProductMenuSectionOverflowCalculatorComponent {

  constructor(
    public viewModel: ProductMenuStaticSectionOverflowCalculatorViewModel
  ) {
    super(viewModel);
  }

}
