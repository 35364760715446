import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { SpotlightMenu } from '../../../models/menu/spotlight-menu';

@Pipe({
  name: 'castToSpotlightMenu'
})
export class CastToSpotlightMenuPipe implements PipeTransform {

  transform(value: Menu): SpotlightMenu {
    if (value instanceof SpotlightMenu) {
      return value as SpotlightMenu;
    }
    return null;
  }

}
