/* tslint:disable:member-ordering */
import { Variant } from '../../../product/dto/variant';
import type { MarketingMenu } from '../../marketing-menu';
import type { CompanyConfiguration } from '../../../company/dto/company-configuration';
import { TypeUtils } from '../../../../utils/type-utils';
import { UnitOfMeasure } from '../../../enum/dto/unit-of-measure.enum';
import type { ProductType } from '../../../enum/dto/product-type.enum';
import type { VariantType } from '../../../enum/dto/variant-type.enum';
import { CannabisUnitOfMeasure } from '../../../enum/dto/cannabis-unit-of-measure.enum';
import type { LocationConfiguration } from '../../../company/dto/location-configuration';
import type { LocationPriceStream } from '../../../enum/shared/location-price-stream';
import { PriceUtils } from '../../../../utils/price-utils';
import { LabelComponentInterface } from '../../../../modules/labels/components/label-component-interface';
import type { Menu } from '../../menu';
import type { SectionWithProducts } from '../../section/section-with-products';
import type { DeprecatedMarketingMenu } from '../../deprecated-marketing-menu';

export class FeaturedProductMenuData implements LabelComponentInterface {

  menu: DeprecatedMarketingMenu;
  locationConfiguration: LocationConfiguration;
  companyConfiguration: CompanyConfiguration;
  variant: Variant;
  productType: ProductType;
  variantType: VariantType;
  featuredProductTitle: string;
  originalPriceText: string;
  salePriceText: string;
  name: string;
  classification: string;
  sizeText: string;
  producer: string;
  thcText: string;
  cbdText: string;
  lowTHC: boolean;
  lowCBD: boolean;
  description: string;

  constructor(
    locationId: number,
    menu: DeprecatedMarketingMenu,
    variant: Variant,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration
  ) {
    this.setData(locationId, menu, variant, companyConfig, locationConfig);
  }

  /**
   * This is an old way of setting data before I fully understood RxJS.  I'm leaving it here for now
   * because it will take me a while to refactor.
   */
  private setData(
    locationId: number,
    menu: DeprecatedMarketingMenu,
    variant: Variant,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration
  ) {
    this.setTitle(menu);
    this.menu = menu;
    this.locationConfiguration = locationConfig;
    this.companyConfiguration = companyConfig;
    this.variant = variant;
    this.productType = variant.productType;
    this.variantType = variant.variantType;
    const priceFormat = locationConfig?.priceFormat;
    this.originalPriceText = this.getVariantRegularPrice(locationId, menu, variant, priceFormat);
    this.salePriceText = FeaturedProductMenuData.getVariantDiscountedPrice(locationId, menu, variant, priceFormat);
    this.name = FeaturedProductMenuData.getFeatureProductName(variant);
    this.classification = FeaturedProductMenuData.setValueOrPlaceholder(variant.classification.toLowerCase());
    this.sizeText = FeaturedProductMenuData.setValueOrPlaceholder(this.getSize(variant));
    this.producer = FeaturedProductMenuData.setValueOrPlaceholder(variant.brand);
    const companyUsesRange = companyConfig.cannabinoidDisplayType === TypeUtils.CannabinoidDisplayType.Range;
    const variantUsesRange = variant.useCannabinoidRange;
    if (companyUsesRange || variantUsesRange) {
      this.thcText = FeaturedProductMenuData.setValueOrPlaceholder(
        variant.getFormattedCannabinoidRange('THC')
      );
      this.cbdText = FeaturedProductMenuData.setValueOrPlaceholder(
        variant.getFormattedCannabinoidRange('CBD')
      );
    } else {
      this.thcText = FeaturedProductMenuData.setValueOrPlaceholder(this.getFormattedTHC(variant));
      this.cbdText = FeaturedProductMenuData.setValueOrPlaceholder(this.getFormattedCBD(variant));
    }
    this.description = this.getDescription(menu, variant);
  }

  static setValueOrPlaceholder(val: string): string {
    return !!val ? val : '-';
  }

  private setTitle(menu: DeprecatedMarketingMenu) {
    if (menu?.hydratedVariantFeature) {
      this.featuredProductTitle = menu?.hydratedVariantFeature?.title;
    }
  }

  // noinspection JSMethodCanBeStatic
  private getVariantRegularPrice(
    locationId: number,
    menu: MarketingMenu,
    variant: Variant,
    priceFormat: LocationPriceStream
  ): string {
    const originalPrice = variant?.getPriceWithoutDiscounts(menu?.theme, locationId, menu?.companyId, priceFormat);
    return PriceUtils.formatPrice(originalPrice);
  }

  static getVariantDiscountedPrice(
    locationId: number,
    menu: DeprecatedMarketingMenu,
    variant: Variant,
    priceFormat: LocationPriceStream
  ): string {
    // first check for priceOverride, which takes highest priority
    const priceOverride = menu?.hydratedVariantFeature?.priceOverrideMap?.get(variant?.id);
    if (priceOverride > 0) return PriceUtils.formatPrice(priceOverride);
    const discountedPrice = variant?.getDiscountedPriceOrNull(menu?.theme, locationId, menu?.companyId, priceFormat);
    if (discountedPrice > 0) return PriceUtils.formatPrice(discountedPrice);
    return '';
  }

  static getFeatureProductName(variant: Variant): string {
    return variant?.getVariantTitle();
  }

  getSize(variant: Variant): string {
    let sizeText;
    if (variant?.packagedQuantity > 1 && variant?.unitSize > 0 && variant?.unitOfMeasure !== UnitOfMeasure.NA) {
      // multi unit pack
      const packagedQuantityText = String('(' + variant.packagedQuantity + 'x) ');
      sizeText = String(packagedQuantityText + variant.unitSize + variant.unitOfMeasure);
    } else {
      // single unit pack
      if (variant?.unitSize > 0 && variant?.unitOfMeasure !== UnitOfMeasure.NA) {
        sizeText = String(variant.unitSize + variant.unitOfMeasure);
      }
    }
    return sizeText;
  }

  getFormattedTHC(variant: Variant): string {
    if (variant?.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
      return '--';
    }
    const value = variant.getNumericCannabinoidOrTerpene('THC');
    if (value < 1) {
      this.lowTHC = true;
      return String('<1.0');
    } else {
      return `${parseFloat(value.toFixed(2))}`;
    }
  }

  getFormattedCBD(variant: Variant): string {
    if (variant?.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
      return '--';
    }
    const value = variant.getNumericCannabinoidOrTerpene('CBD');
    if (value < 1) {
      this.lowCBD = true;
      return String('<1.0');
    } else {
      return `${parseFloat(value.toFixed(2))}`;
    }
  }

  getDescription(menu: DeprecatedMarketingMenu, variant: Variant): string {
    // get description override if applicable
    if (menu?.menuOptions?.hideDescription) return null;
    const descriptionOverride = menu?.hydratedVariantFeature?.descriptionOverrideMap?.get(variant?.id);
    if (descriptionOverride) {
      return descriptionOverride;
    } else if (menu?.menuOptions?.showFullDescription) {
      return variant?.description;
    } else {
      return variant?.shortDescription || variant?.description;
    }
  }

  /* ************************** Label Component Interface ************************** */

  getMenuForLabelComponent(): Menu {
    return this.menu;
  }

  getSectionForLabelComponent(): SectionWithProducts {
    return null;
  }

  getVariantsForLabelComponent(): Variant[] {
    return !!this.variant ? [this.variant] : [];
  }

  getLocationConfigForLabelComponent(): LocationConfiguration {
    return this.locationConfiguration;
  }

  getCompanyConfigForLabelComponent(): CompanyConfiguration {
    return this.companyConfiguration;
  }

  getShutOffLabelForLabelComponent(): boolean {
    return false;
  }

  getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return null;
  }

  /* ****************************************************************************** */

}
