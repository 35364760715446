import { BaseMenuViewModel } from '../menu/base-menu-view-model';
import { Injectable, QueryList } from '@angular/core';
import { asapScheduler, BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { ScrollDirection } from '../../../../../models/enum/shared/scroll-direction.enum';
import { debounceTime, distinctUntilChanged, filter, map, observeOn, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { PageFragment } from '../../../../../models/shared/page-fragment';
import { Menu } from '../../../../../models/menu/menu';
import { MenuType } from '../../../../../models/enum/dto/menu-type.enum';
import { DisplayDomainModel } from '../../../../../domain/display-domain-model';
import { Orientation } from '../../../../../models/enum/dto/orientation.enum';
import { DisplayMenuCoupling } from '../../../../../couplings/display-menu-coupling.service';
import { OrientationService } from '../../../../../services/orientation.service';
import { HoldOffMenuRotation } from '../../../../../models/shared/hold-off-menu-rotation';
import { InterfaceUtils } from '../../../../../utils/interface-utils';
import { SectionsCanOverflow } from '../../../../../models/protocols/sections-can-overflow';
import { OverflowState } from '../../../../../models/enum/shared/overflow-transition-state.enum';
import { exists } from '../../../../../functions/exists';
import { IsMenuReadyService } from '../../../../services/is-menu-ready.service';
import { MenuSectionInflatorComponent } from '../menu/inflators/menu-section-inflator-component';

@Injectable()
export class MenuWithScrollableSectionsViewModel extends BaseMenuViewModel {

  constructor(
    public dm: DisplayDomainModel,
    public displayMenuCoupling: DisplayMenuCoupling,
    public isMenuReadyService: IsMenuReadyService,
    orientationService: OrientationService
  ) {
    super(orientationService);
  }

  public readonly showSplashScreen$ = this.isMenuReadyService.showSplashScreen$;
  public readonly nMenusRotating$ = this.displayMenuCoupling.menuToRotateCount;

  private readonly _sectionsContainerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly sectionsContainerHeight$ = this._sectionsContainerHeight.pipe(distinctUntilChanged());
  connectToSectionsContainerHeight = (h: number) => this._sectionsContainerHeight.next(h);

  private readonly _sectionsContainerWidth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly sectionsContainerWidth$ = this._sectionsContainerWidth.pipe(distinctUntilChanged());
  connectToSectionsContainerWidth = (w: number) => this._sectionsContainerWidth.next(w);

  private readonly _sectionsContainerContentScrollHeight: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly sectionsContainerContentScrollHeight$ = this._sectionsContainerContentScrollHeight.pipe(
    distinctUntilChanged()
  );
  connectToSectionsContainerContentScrollHeight = (h: number) => this._sectionsContainerContentScrollHeight.next(h);

  private readonly _sectionsContainerContentScrollWidth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly sectionsContainerContentScrollWidth$ = this._sectionsContainerContentScrollWidth.pipe(
    distinctUntilChanged()
  );
  connectToSectionsContainerContentScrollWidth = (w: number) => this._sectionsContainerContentScrollWidth.next(w);

  public scrollingInterface$ = this.distinctNotNullMenu$.pipe(
    map(menu => menu as any),
    map(data => (InterfaceUtils.isSectionsCanOverflowInterface(data) ? data as SectionsCanOverflow : null)),
  );

  public isMenuLevelOverflow$ = this.scrollingInterface$.pipe(
    map(scrollingInterface => scrollingInterface?.isMenuLevelOverflow()),
    distinctUntilChanged(),
  );

  public isSectionLevelOverflow$ = this.scrollingInterface$.pipe(
    map(scrollingInterface => scrollingInterface?.isSectionLevelOverflow()),
    distinctUntilChanged()
  );

  public readonly isInHorizontalScrollingOverflowState$ = this.scrollingInterface$.pipe(
    map(scrollingInterface => scrollingInterface?.isInHorizontalScrollingOverflowState()),
    distinctUntilChanged()
  );

  public readonly isInVerticalScrollingOverflowState$ = this.scrollingInterface$.pipe(
    map(scrollingInterface => scrollingInterface?.isInVerticalScrollingOverflowState()),
    distinctUntilChanged()
  );

  public readonly steadyScrolling$ = this.scrollingInterface$.pipe(
    map(scrollingInterface => {
      return scrollingInterface?.isInVerticalScrollingOverflowState()
          || scrollingInterface?.isInHorizontalScrollingOverflowState();
    }),
    distinctUntilChanged()
  );

  connectToSteadyScrollFireMenuRotation = () => this.displayMenuCoupling.connectToNoSteadyScrollContentMenuRotation();
  connectToSteadyScrollFinishedRotateMenu = () => this.displayMenuCoupling.connectToSteadyScrollFinishedRotateMenu();

  private readonly _isScrolling = new BehaviorSubject<boolean>(false);
  public readonly isScrolling$ = this._isScrolling.pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToIsScrolling = (isScrolling: boolean) => this._isScrolling.next(isScrolling);

  private _scrollAnimationClass = new BehaviorSubject<string|null>(null);
  public scrollAnimationClass$ = combineLatest([
    this.reset$,
    this._scrollAnimationClass
  ]).pipe(
    map(([reset, scrollAnimationClass]) => reset ? null : scrollAnimationClass),
    distinctUntilChanged()
  );
  public connectToScrollAnimationClass = (x: string) => this._scrollAnimationClass.next(x);

  public activeOverflowState$ = this.distinctNotNullMenu$.pipe(map(m => m?.overflowState));
  public activeOrientation$ = this.distinctNotNullMenu$.pipe(
    map(menu => menu?.displaySize?.orientation ?? Orientation.NA),
    distinctUntilChanged()
  );

  // Global should this menu transition flag
  protected toggleTransitions$ = this.dm.classificationOfMenu.pipe(map(it => it === MenuType.DisplayMenu));
  protected holdRotation$ = combineLatest([
    this.dm.menuToDisplay.pipe(map(menuToDisplay => menuToDisplay?.menu)),
    this.distinctNotNullMenu$,
    this.isScrolling$.notNull()
  ]);

  private _sectionsContainerContentAnimationStart = new Subject<void>();
  public sectionsContainerContentAnimationStarted$ = this._sectionsContainerContentAnimationStart as Observable<void>;
  public connectToSectionsContainerContentAnimationStart = () => {
    this.connectToIsScrolling(true);
    this._sectionsContainerContentAnimationStart.next();
  };

  private _sectionsContainerContentAnimationEnd = new Subject<void>();
  public sectionsContainerContentAnimationEnded$ = this._sectionsContainerContentAnimationEnd as Observable<void>;
  public connectToSectionsContainerContentAnimationEnd = () => {
    this.connectToIsScrolling(false);
    this._sectionsContainerContentAnimationEnd.next();
  };

  private readonly waitForOverflow$ = this.distinctNotNullMenu$.pipe(
    switchMap(menu => this.displayMenuCoupling.waitForThisMenusOverflow$(menu?.id)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Horizontal scroll overflow
  protected horizontalScrollerDirectionSub: Subscription;
  protected horizontalScrollerSub: Subscription;

  protected _horizontalScrollDirection = new BehaviorSubject<ScrollDirection|null>(null);
  public horizontalScrollDirection$ = this._horizontalScrollDirection.pipe(distinctUntilChanged());
  connectToHorizontalScrollDirection = (scrollDir: ScrollDirection) => this._horizontalScrollDirection.next(scrollDir);

  protected horizontalScrolling$ = combineLatest([
    this.reset$.notNull(),
    this.waitForOverflow$,
    this.toggleTransitions$,
    this.scrollingInterface$,
    this.distinctNotNullMenu$,
  ]).pipe(debounceTime(100));

  // Vertical scroll overflow
  protected verticalScrollerDirectionSub: Subscription;
  protected verticalScrollerSub: Subscription;

  protected _verticalScrollDirection = new BehaviorSubject<ScrollDirection|null>(null);
  public verticalScrollDirection$ = this._verticalScrollDirection.pipe(distinctUntilChanged());
  connectToVerticalScrollDirection = (scrollDir: ScrollDirection) => this._verticalScrollDirection.next(scrollDir);

  protected verticalScrolling$ = combineLatest([
    this.reset$.notNull(),
    this.waitForOverflow$,
    this.toggleTransitions$,
    this.scrollingInterface$,
    this.distinctNotNullMenu$,
  ]).pipe(debounceTime(100));

  // Horizontal and Vertical Paging overflow
  protected pagingTransitionSub: Subscription;
  protected _pages = new BehaviorSubject<PageFragment[]>(null);
  public pages$ = this._pages.asObservable();
  public _pagePosition = new BehaviorSubject<PageFragment>(null);
  public pagePosition$ = this._pagePosition as Observable<PageFragment>;
  protected pagingTimer$ = timer(0, (Menu.defaultRotationInterval * 1000));
  protected timePerPage: number = 0;
  protected pagingTransition$ = combineLatest([
    this.reset$.notNull(),
    this.toggleTransitions$,
    this.scrollingInterface$,
    this.distinctNotNullMenu$,
    this.pages$.pipe(filter(pages => pages?.length > 0), take(1))
  ]).pipe(debounceTime(1));

  bind() {
    this.bindToReset();
    this.bindToHandleOverflowStates();
    this.bindHoldRotation();
  }

  protected bindToReset() {
    const s = this.reset$.subscribe(r => {
      if (r) {
        // Reset scrolling
        this._isScrolling.next(false);
        // Horizontal inputs
        this.connectToHorizontalScrollDirection(null);
        // Vertical inputs
        this.connectToVerticalScrollDirection(null);
      }
    });
    this.pushSub(s);
  }

  protected bindHoldRotation() {
    const holdSub = this.holdRotation$.subscribe(([visibleMenu, menu, isScrolling]) => {
      if (exists(menu) && visibleMenu?.id === menu?.id) {
        if (isScrolling) {
          this.displayMenuCoupling.holdDisplayRotation.next(new HoldOffMenuRotation(menu.id));
        } else {
          this.displayMenuCoupling.holdDisplayRotation.next(null);
        }
      }
    });
    this.pushSub(holdSub);
  }

  protected bindToHandleOverflowStates() {
    this.bindToHorizontalScrollerMechanism();
    this.bindToVerticalScrollerMechanism();
    this.bindToPagingMechanism();
  }

  protected bindToHorizontalScrollerMechanism() {
    const horzSub = this.horizontalScrolling$.subscribe(([reset, wait, toggled, scrollingInterface, menu]) => {
      const isInHorizontalScrollingMode = toggled && scrollingInterface?.isInHorizontalScrollingOverflowState();
      if (!reset && !wait && isInHorizontalScrollingMode && (menu?.rotationInterval !== 0)) {
        this.bindToHorizontalScrolling();
      } else {
        this.killHorizontalScrolling();
      }
    });
    this.pushSub(horzSub);
  }

  protected bindToVerticalScrollerMechanism() {
    const vertSub = this.verticalScrolling$.subscribe(([reset, wait, toggled, scrollingInterface, menu]) => {
      const isInVerticalScrollingMode = toggled && scrollingInterface?.isInVerticalScrollingOverflowState();
      if (!reset && !wait && isInVerticalScrollingMode && (menu?.rotationInterval !== 0)) {
        this.bindToVerticalScrolling();
      } else {
        this.killVerticalScrolling();
      }
    });
    this.pushSub(vertSub);
  }

  protected bindToPagingMechanism() {
    const pagSub = this.pagingTransition$.subscribe(([reset, toggled, scrollingInterface, menu, pages]) => {
      const pagingOn = toggled && scrollingInterface?.isInPagingOverflowState();
      const nonZeroInterval = menu?.rotationInterval > 0;
      const multiPage = pages?.length > 1;
      if (!reset && pagingOn && nonZeroInterval && multiPage) {
        this.timePerPage = (menu?.originalRotationInterval) * 1000;
        if (pages.length > 1) {
          // If landscape, subtract 1 from page length so final page doesnt play for 2x the duration
          const pageLength = menu.displaySize.orientation === Orientation.Landscape
            ? pages.length - 1
            : pages.length;
          const updatedDisplayTime = (menu?.originalRotationInterval * pageLength);
          menu?.updateRotationIntervalInSeconds(updatedDisplayTime);
          this.dm.setMenuToDisplay(menu);
        }
        this.pagingTimer$ = timer(0, this.timePerPage);
        this.bindToPagingTransition();
      } else {
        this.killPagingTransition();
      }
    });
    this.pushSub(pagSub);
  }

  protected bindToHorizontalScrolling() {
    this.killHorizontalScrolling();
    this.bindToHorizontalScrollDirection();
    this.bindToHorizontalScrollClass();
  }

  private bindToHorizontalScrollDirection(): void {
    this.horizontalScrollerDirectionSub = combineLatest([
      this.reset$.notNull().pipe(distinctUntilChanged()),
      this.waitForOverflow$,
      this.activeOverflowState$.notNull().pipe(distinctUntilChanged())
    ]).pipe(
      observeOn(asapScheduler),
      switchMap(([reset, wait, overflowState]) => {
        if (!reset && !wait && (overflowState === OverflowState.STEADY_SCROLL)) {
          return this.sectionsContainerContentAnimationEnded$.pipe(
            startWith<null, null>(null),
            withLatestFrom(this.horizontalScrollDirection$, this.displayMenuCoupling.menuToRotateCount.notNull()),
            tap(([_, horizontalScrollDirection, nMenus]) => {
              if (horizontalScrollDirection === ScrollDirection.FORWARD) {
                // only perform reverse scroll when there is a single menu in the rotation
                if (nMenus === 1) {
                  this.connectToHorizontalScrollDirection(ScrollDirection.REVERSE);
                } else {
                  // tell rotation mechanism that scrolling animation finished
                  this.connectToSteadyScrollFinishedRotateMenu();
                }
              } else {
                // always perform forward scroll
                this.connectToHorizontalScrollDirection(ScrollDirection.FORWARD);
              }
            }),
            observeOn(asapScheduler)
          );
        } else {
          this.connectToHorizontalScrollDirection(null);
          return of(null);
        }
      })
    ).subscribe();
  }

  private bindToHorizontalScrollClass(): void {
    this.horizontalScrollerSub = combineLatest([
      this.isInHorizontalScrollingOverflowState$,
      this.horizontalScrollDirection$,
      this.waitForOverflow$
    ]).pipe(
      filter(([isInHorizontalScrollingOverflowState]) => isInHorizontalScrollingOverflowState),
      map(([_, direction, wait]) => [direction, wait] as [ScrollDirection, boolean])
    ).subscribe(([direction, wait]) => {
      if (!wait && direction === ScrollDirection.FORWARD) {
        this.connectToScrollAnimationClass('scroll-right-over-specific-time-period');
      } else if (!wait && direction === ScrollDirection.REVERSE) {
        this.connectToScrollAnimationClass('scroll-left-over-specific-time-period');
      } else {
        this.connectToScrollAnimationClass(null);
      }
    });
  }

  protected bindToVerticalScrolling() {
    this.killVerticalScrolling();
    this.bindToVerticalScrollDirection();
    this.bindToVerticalScrollClass();
  }

  private bindToVerticalScrollDirection(): void {
    this.verticalScrollerDirectionSub = combineLatest([
      this.reset$.notNull().pipe(distinctUntilChanged()),
      this.waitForOverflow$,
      this.activeOverflowState$.notNull().pipe(distinctUntilChanged())
    ]).pipe(
      observeOn(asapScheduler),
      switchMap(([reset, wait, overflowState]) => {
        if (!reset && !wait && (overflowState === OverflowState.STEADY_SCROLL)) {
          return this.sectionsContainerContentAnimationEnded$.pipe(
            startWith<null, null>(null),
            withLatestFrom(this.verticalScrollDirection$, this.displayMenuCoupling.menuToRotateCount.notNull()),
            tap(([_, verticalScrollDirection, nMenus]) => {
              if (verticalScrollDirection === ScrollDirection.FORWARD) {
                // only perform reverse scroll when there is a single menu in the rotation
                if (nMenus === 1) {
                  this.connectToVerticalScrollDirection(ScrollDirection.REVERSE);
                } else {
                  // tell rotation mechanism that scrolling animation finished
                  this.connectToSteadyScrollFinishedRotateMenu();
                }
              } else {
                // always perform forward scroll
                this.connectToVerticalScrollDirection(ScrollDirection.FORWARD);
              }
            }),
            observeOn(asapScheduler),
          );
        } else {
          this.connectToVerticalScrollDirection(null);
          return of(null);
        }
      })
    ).subscribe();
  }

  private bindToVerticalScrollClass(): void {
    this.verticalScrollerSub = combineLatest([
      this.isInVerticalScrollingOverflowState$,
      this.verticalScrollDirection$,
      this.waitForOverflow$
    ]).pipe(
      filter(([isInVerticalScrollingOverflowState]) => isInVerticalScrollingOverflowState),
      map(([_, direction, wait]) => [direction, wait] as [ScrollDirection, boolean])
    ).subscribe(([direction, wait]) => {
      if (!wait && direction === ScrollDirection.FORWARD) {
        this.connectToScrollAnimationClass('scroll-down-over-specific-time-period');
      } else if (!wait && direction === ScrollDirection.REVERSE) {
        this.connectToScrollAnimationClass('scroll-up-over-specific-time-period');
      } else {
        this.connectToScrollAnimationClass(null);
      }
    });
  }

  protected bindToPagingTransition() {
    this.killPagingTransition(true);
    if (this.timePerPage > 0) {
      this.pagingTransitionSub = combineLatest([
        this.reset$,
        this.activeOrientation$,
      ]).pipe(
        filter(([reset, activeOrientation]) => {
          if (!reset && exists(activeOrientation)) {
            return true;
          } else {
            this.killPagingTransition(true);
            return false;
          }
        }),
        switchMap(() => this.pagingTimer$.pipe(withLatestFrom(this.activeOrientation$))),
      ).subscribe(([_, activeOrientation]) => {
        const pages = this._pages.getValue();
        const currentPage = this._pagePosition.getValue();
        if (!currentPage) {
          this._pagePosition.next(pages.firstOrNull());
        } else {
          this.displayMenuCoupling.menuIdsToRotate.once(ids => {
            const nIds = ids?.length;
            const currIndex = pages?.findIndex(page => page?.position === currentPage?.position);
            // subtract 1 from the length, so it doesn't sit on final page for 2 iterations
            // since the final column is already scrolled into view
            const pageLength = activeOrientation === Orientation.Landscape
              ? pages.length - 1
              : pages.length;
            const nextIndex = (currIndex + 1) % pageLength;
            const nextPage = pages[nextIndex];
            if (nIds > 1 && nextIndex === 0) {
              // do nothing, wait for the next menu in rotation
            } else {
              this._pagePosition.next(nextPage);
            }
          });
        }
      });
    }
  }

  protected killHorizontalScrolling() {
    this.horizontalScrollerDirectionSub?.unsubscribe();
    this.horizontalScrollerSub?.unsubscribe();
    this.connectToHorizontalScrollDirection(null);
  }

  protected killVerticalScrolling() {
    this.verticalScrollerDirectionSub?.unsubscribe();
    this.verticalScrollerSub?.unsubscribe();
    this.connectToVerticalScrollDirection(null);
  }

  protected killPagingTransition(keepLastKnownPage: boolean = false) {
    if (!keepLastKnownPage) this._pagePosition.next(null);
    this.pagingTransitionSub?.unsubscribe();
  }

  public parseSectionInflatorForPages(scrollableItems: QueryList<MenuSectionInflatorComponent>) {
    if (!!scrollableItems) {
      const unwrapped = scrollableItems.map(it => it);
      const pages = unwrapped.map((it, index) => new PageFragment(it?.section?.firstOnPage, index, it));
      const topOfPages = pages.filter(it => it?.scrollableItemComponent?.section?.firstOnPage);
      const existingPages = this._pages.getValue();
      this._pages.next(topOfPages);
      if (existingPages) {
        existingPages.map(p => p.scrollableItemComponent = null);
      }
    }
  }

  destroy() {
    super.destroy();
    this.killHorizontalScrolling();
    this.killVerticalScrolling();
    this.killPagingTransition();
  }

}
