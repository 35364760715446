import { Card } from './card';
import { DisplayableProductCard } from '../../../modules/display/components/menus/marketing-menu/building-blocks/menu-cards/displayable-product-card';
import { Asset } from '../../image/dto/asset';
import { Variant } from '../../product/dto/variant';
import { Menu } from '../menu';
import { CompanyConfiguration } from '../../company/dto/company-configuration';
import { VariantBadge } from '../../product/dto/variant-badge';
import { CannabinoidDisplayType } from '../../enum/shared/cannabinoid-display-type.enum';
import { ProductType } from '../../enum/dto/product-type.enum';
import type { SectionWithProducts } from '../section/section-with-products';
import { CannabisUnitOfMeasure } from '../../enum/dto/cannabis-unit-of-measure.enum';
import { LocationConfiguration } from '../../company/dto/location-configuration';
import { DeprecatedMarketingMenu } from '../deprecated-marketing-menu';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { SectionUtils } from '../../../utils/section-utils';
import { BadgeUtils } from '../../../utils/badge-utils';

export class ProductCard extends Card implements DisplayableProductCard {

  menu: DeprecatedMarketingMenu;
  companyConfig: CompanyConfiguration;
  locationConfig: LocationConfiguration;
  variant: Variant;
  asset: Asset;

  // Displayable product card interface

  getAsset(): Asset {
    return this.asset;
  }

  getBadges(): VariantBadge[] {
    const productSections = this.menu?.sections?.filter(section => {
      return SectionUtils.isSectionWithProducts(section);
    }) as SectionWithProducts[];
    const sectionWithVariant = productSections
      ?.filter(section => section?.variants?.map(variant => variant.id)?.includes(this.variant?.id))
      ?.firstOrNull();
    return BadgeUtils.getBadges(sectionWithVariant?.layoutType, 1, sectionWithVariant?.variantBadgeMap, [this.variant]);
  }

  getCardColor(): string {
    return this.menu?.hydratedVariantFeature?.getVariantColor(this.variant?.id);
  }

  getCardTextColor(): string {
    return this.menu?.menuOptions?.bodyTextColor;
  }

  getThcString(): string {
    if (this.variant.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
      return 'THC --';
    } else {
      return 'THC ' + this.variant.getCannabinoid(this.menu, this.companyConfig, 'THC');
    }
  }

  getCbdString(): string {
    if (this.variant.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
      return 'CBD --';
    } else {
      return 'CBD ' + this.variant.getCannabinoid(this.menu, this.companyConfig, 'CBD');
    }
  }

  getOriginalPrice(): string {
    if (!!this.menu && !!this.variant) {
      if (this.variant?.locationPricing?.length > 0 || this.variant?.locationPromotion?.isActive()) {
        const priceStream = this.locationConfig?.priceFormat;
        const tId = this.menu?.theme;
        const lId = this.menu?.locationId;
        const cId = this.menu?.companyId;
        const hidePriceDecimal = this.menu?.menuOptions?.hidePriceDecimal;
        const priceTuple = this.variant?.getFormattedPrice(tId, cId, lId, priceStream, true, hidePriceDecimal);
        return priceTuple?.[0];
      }
    }
    return null;
  }

  getProductDesc(): string {
    // get description override if applicable
    if (this.menu?.menuOptions?.hideDescription) return null;
    const descriptionOverride = this.menu?.hydratedVariantFeature?.descriptionOverrideMap?.get(this.variant?.id);
    if (descriptionOverride) {
      return descriptionOverride;
    } else if (this.menu?.menuOptions?.showFullDescription) {
      return this.variant?.description;
    } else {
      return this.variant?.shortDescription || this.variant?.description;
    }
  }

  getIsThcAndCbdProduct(): boolean {
    return this.getProductType() !== ProductType.Accessories;
  }

  getProductType(): ProductType {
    return this.variant.productType;
  }

  getProductBrand(): string {
    return this.variant.brand;
  }

  getProductName(): string {
    return this.variant.getVariantTitle();
  }

  getSalePrice(): string {
    const tId = this.menu?.theme;
    const lId = this.menu?.locationId;
    const cId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.menu?.menuOptions?.hideSale;
    const hidePriceDec = this.menu?.menuOptions?.hidePriceDecimal;
    if (this.variant?.hasDiscount(tId, lId, cId, priceStream) && !hideSale) {
      const getPrice = this.variant?.getFormattedPrice?.bind(this.variant);
      const [price, _, isDiscounted] = getPrice(tId, cId, lId, priceStream, false, hidePriceDec) || [null, null, false];
      if (isDiscounted) return price;
    }
    return null;
  }

  getSizeString(): string {
    if (this.variant.packagedQuantity > 1) {
      return `${this.variant.getFormattedUnitSize()} (x${this.variant.packagedQuantity})`;
    } else {
      return this.variant.getFormattedUnitSize();
    }
  }

  getIsCannabinoidRange(): boolean {
    const companyUsesCannabinoidRange = this.companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Range;
    return companyUsesCannabinoidRange || this.variant.useCannabinoidRange;
  }

  getHidePrice(): boolean {
    const regEx = /^\$?0*\.?0*$/;
    const originalZero = regEx.exec(this.getOriginalPrice())?.length > 0;
    const saleZero = regEx.exec(this.getSalePrice())?.length > 0;
    return originalZero || saleZero;
  }

  getCardStartAtValue(): number {
    const v = parseInt(this.menu?.metadata?.cardStartAt, 10);
    return isNaN(v) ? 1 : v;
  }

  getMenuForLabelComponent(): Menu {
    return this.menu;
  }

  getSectionForLabelComponent(): SectionWithProducts {
    return null;
  }

  getVariantsForLabelComponent(): Variant[] {
    return [this.variant];
  }

  getLocationConfigForLabelComponent(): LocationConfiguration {
    return this.locationConfig;
  }

  getCompanyConfigForLabelComponent(): CompanyConfiguration {
    return this.companyConfig;
  }

  getShutOffLabelForLabelComponent(): boolean {
    return false;
  }

  getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return null;
  }

}
