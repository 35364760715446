<div
  #cannabinoidColumn
  *ngIf="
    colVm.columnType === cannabinoidColumnType && colVm.columnType !== Types.SectionColumnCannabinoidType.THCAndCBD
  "
  [class.product-low-amount]="productStylingVm | isLowAmountCannabinoid: cannabinoidColumnType | async"
  [ngStyle]="
    productStylingVm
      | lowAmountStyling: colVm : (productStylingVm | isLowAmountCannabinoid: cannabinoidColumnType)
      | async
  "
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  class="product-column-general product-cannabinoid">
  <!-- product-${cannabinoid} class gets added in the component -->
  <ng-container *ngIf="useNgContent"><ng-content></ng-content></ng-container>
  <ng-container *ngIf="!useNgContent">{{
    rowVm | sectionRowViewModelBasicMethod: 'getCannabinoid' : cannabinoidColumnType
  }}</ng-container>
</div>

<div
  *ngIf="colVm.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'"
  [style.white-space]="'pre-wrap'"
  class="product-column-general product-cannabinoid product-thc-and-cbd">
  {{ rowVm | sectionRowViewModelBasicMethod: 'getThcAndCbd' }}
</div>
