import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './modules/display/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'print-card',
    loadChildren: () => import('./modules/print-cards/print-cards.module').then(m => m.PrintCardsModule),
  },
  {
    path: 'print-label',
    loadChildren: () => import('./modules/print-cards/print-cards.module').then(m => m.PrintCardsModule),
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
