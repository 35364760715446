import { Component, OnDestroy, OnInit } from '@angular/core';
import P5 from 'p5';
import { Vehicle } from './vehicle';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  public p5js: P5;

  ngOnInit() {
    this.makeCanvas();
  }

  clean(): void {
    this.p5js.remove();
  }

  ngOnDestroy(): void {
    this.clean();
  }

  private makeCanvas() {
    this.p5js = new P5(this.sketch);
  }

  private sketch(p: any) {

    let formattedTime = '00:00:00';
    let prevSec = -1;
    const maxChangeForce = 0;

    const instructions = [];
    const insText = 'Error 404';

    let font;
    const vehicles = [];
    let i;

    p.preload = () => {
      font = p.loadFont('assets/fonts/Mont/Mont-Heavy.otf');
    };

    p.windowResized = () => {
      const element = document.getElementById('sketch-holder');
      p.resizeCanvas(element.clientWidth, element.clientHeight);
    };

    // Creates and adds the canvas element
    function addCanvas(canvasWidth: number, canvasHeight: number) {
      const canvas = p.createCanvas(canvasWidth, canvasHeight);
      canvas.parent('sketch-holder');
      return canvas;
    }

    // Initial setup
    p.setup = () => {
      const element = document.getElementById('sketch-holder');
      addCanvas(element.clientWidth, element.clientHeight);
      p.background(255);

      const bounds = font.textBounds(formattedTime, 0, 0, 80);
      const posX = p.width / 2 - bounds.w / 2;
      const posY = p.height / 1.5 + (bounds.h * 0.75);
      const points = font.textToPoints(formattedTime, posX, posY, 80, { sampleFactor: 0.3 });

      let pt;
      for (i = 0; i < points.length; i++) {
        pt = points[i];
        const vehicle = new Vehicle(p, pt.x, pt.y, 3);
        vehicles.push(vehicle);
      }

      const boundsIns = font.textBounds(insText, 0, 0, 80);
      let posxIns = p.width / 2 - boundsIns.w / 2;
      const posyIns = p.height / 2.5 + boundsIns.h / 2;
      const insAr = p.split(insText, ' ');

      for (i = 0; i < insAr.length; i++) {
        const bounds2 = font.textBounds(insAr[i], 0, 0, 80);
        const posx2 = posxIns;
        const posy2 = posyIns;

        posxIns += bounds2.w + 10;

        const points2 = font.textToPoints(insAr[i], posx2, posy2, 80, { sampleFactor: 0.3 });

        for (let j = 0; j < points2.length; j++) {
          pt = points2[j];
          const v = new Vehicle(p, pt.x, pt.y, 3);
          instructions.push(v);
        }
      }
    };

    p.draw = () => {
      p.background(255);
      calcTime();
      for (i = 0; i < instructions.length; i++) {
        const v = instructions[i];
        v.behaviors();
        v.update();
        v.show();
      }

      for (i = 0; i < vehicles.length; i++) {
        const v = vehicles[i];
        v.behaviors();
        v.update();
        v.show();
      }
    };

    const calcTime = () => {
      let hours = p.hour();
      let minutes = p.minute();
      let seconds = p.second();

      if (seconds !== prevSec) {
        prevSec = seconds;
        hours = p.nf(hours, 2, 0);
        minutes = p.nf(minutes, 2, 0);
        seconds = p.nf(seconds, 2, 0);
        updateText(hours + ':' + minutes + ':' + seconds);
      }
    };

    const updateText = (newText) => {

      formattedTime = newText;
      const bounds = font.textBounds(formattedTime, 0, 0, 80);
      const posx = p.width / 2 - bounds.w / 2;
      const posy = p.height / 2 + bounds.h / 2;

      const points = font.textToPoints(formattedTime, posx, posy, 80, { sampleFactor: 0.3 });

      if (points.length < vehicles.length) {
        const toSplice = vehicles.length - points.length;
        vehicles.splice(points.length - 1, toSplice);

        for (i = 0; i < points.length; i++) {
          vehicles[i].target.x = points[i].x;
          vehicles[i].target.y = points[i].y;

          const force = p.constructor.Vector.random2D();
          force.mult(p.random(maxChangeForce));
          vehicles[i].applyForce(force);
        }
      } else if (points.length > vehicles.length) {

        for (i = vehicles.length; i < points.length; i++) {
          const v = vehicles[i - 1].clone();

          vehicles.push(v);
        }

        for (i = 0; i < points.length; i++) {
          vehicles[i].target.x = points[i].x;
          vehicles[i].target.y = points[i].y;

          const force = p.constructor.Vector.random2D();
          force.mult(p.random(maxChangeForce));
          vehicles[i].applyForce(force);
        }

      } else {
        for (i = 0; i < points.length; i++) {
          vehicles[i].target.x = points[i].x;
          vehicles[i].target.y = points[i].y;

          const force2 = p.constructor.Vector.random2D();
          force2.mult(p.random(maxChangeForce));
          vehicles[i].applyForce(force2);
        }
      }
    };

  }

}
