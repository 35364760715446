import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { MenuToDisplay } from '../../../models/menu/menu-to-display';

@Pipe({
  name: 'getMenuToDisplay'
})
export class GetMenuToDisplayPipe implements PipeTransform {

  transform(value: MenuToDisplay): Menu {
    if (value instanceof MenuToDisplay) {
      return value.menu;
    }
    return null;
  }

}
