import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appSpotlightFooterInflatorContainer]'
})
export class SpotlightFooterInflatorContainerDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
