import { ProductSectionViewModel } from '../product-section-view-model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ArrayUtils } from '../../../../../../../../../utils/array-utils';
import { SectionRowViewModel } from '../section-row-view-models/SectionRowViewModel';
import { combineLatest } from 'rxjs';

@Injectable()
export class SplitProductSectionViewModel extends ProductSectionViewModel {

  public orderCorrectedSectionRowViewModels$ = combineLatest([
    this.calculationMode$,
    this.sectionRowViewModels$
  ]).pipe(
    map(([calculationMode, sectionRowViewModels]) => {
      if (calculationMode) {
        return sectionRowViewModels;
      } else {
        const half = Math.ceil(sectionRowViewModels?.length / 2);
        const firstHalf = sectionRowViewModels?.slice(0, half) ?? [];
        const secondHalf = sectionRowViewModels?.slice(half, sectionRowViewModels?.length) ?? [];
        return ArrayUtils.interlace(firstHalf, secondHalf) as SectionRowViewModel[];
      }
    })
  );

}
