<div
  *ngIf="cards?.length > 0; else placeHolder"
  class="card-grid-container"
  [class.has-footer]="viewModel.hasFooter$ | async"
  [class.portrait-1-to-2]="viewModel.portrait1to2$ | async"
  [class.portrait-3-to-4]="viewModel.portrait3to4$ | async"
  [class.portrait-5-to-6]="viewModel.portrait5to6$ | async"
  [class.portrait-7-to-9]="viewModel.portrait7to9$ | async"
  [class.portrait-10-to-12]="viewModel.portrait10to12$ | async"
  [class.landscape-1-to-3]="viewModel.landscape1to3$ | async"
  [class.landscape-4]="viewModel.landscape4$ | async"
  [class.landscape-5-to-8]="viewModel.landscape5to8$ | async"
  [class.landscape-9-to-12]="viewModel.landscape9to12$ | async">
  <ng-container *ngFor="let card of cards; index as i">
    <app-full-image-card
      *ngIf="card | isFullImageCard"
      [card]="card | castToProductCard"
      [index]="i"
      [cardCount]="viewModel.cardCount$ | async">
    </app-full-image-card>
    <app-half-image-card
      *ngIf="card | isHalfImageCard"
      [card]="card | castToProductCard"
      [index]="i"
      [cardCount]="viewModel.cardCount$ | async">
    </app-half-image-card>
    <app-embedded-image-card
      *ngIf="card | isEmbeddedImageCard"
      [card]="card | castToProductCard"
      [index]="i"
      [cardCount]="viewModel.cardCount$ | async">
    </app-embedded-image-card>
  </ng-container>
</div>

<ng-template #placeHolder>
  <div class="placeholder">All items are out of stock currently. Please check back later.</div>
</ng-template>
