<div
  #sectionContainer
  *ngIf="!section?.empty"
  class="section-container {{ menu?.getSectionWrapperClass(index) }}"
  [style.background-color]="viewModel?.cardColor$ | async">
  <div
    class="card-image-header-container"
    [style.align-items]="'center'"
    [style.justify-content]="getImageSectionAlignment()">
    <app-card-section-number
      *ngIf="viewModel?.showCardNumber$ | async"
      class="card-image-header-number"
      [index]="index"
      [section]="section"
      [menu]="menu">
    </app-card-section-number>
    <img
      appImage
      [asset]="section?.image"
      [reset]="reset"
      [style.width]="getImageSectionWidth()"
      [style.height.%]="100"
      [style.object-fit]="section?.metadata?.objectFit"
      alt="section image"
      src="" />
  </div>
  <div [style.color]="viewModel.sectionHeaderTextColor$ | async" class="card-subtitle">
    {{ section?.subTitle }}
  </div>
  <div
    [style.color]="viewModel.sectionHeaderTextColor$ | async"
    class="card-title"
    [ngClass]="section?.isHidingPrices() ? 'hide-price-font-size' : ''">
    {{ section?.title }}
  </div>
  <div #productsContainer class="products-container" [style.color]="viewModel.sectionBodyTextColor$ | async">
    <app-product-menu-item-inflator
      *ngFor="
        let rowViewModel of viewModel.sectionRowViewModels$ | async;
        index as itemIndex;
        first as first;
        last as last
      "
      [calculationMode]="calculationMode"
      [reset]="reset"
      [odd]="itemIndex % 2 === 0"
      [locationId]="locationId"
      [locationConfig]="locationConfig"
      [menu]="menu"
      [sectionRowViewModels]="viewModel.sectionRowViewModels$ | async"
      [rowViewModel]="rowViewModel"
      [hidden]="rowViewModel.hideOverflowItem() || false"
      [sectionIndex]="index"
      [itemIndex]="itemIndex"
      [first]="first"
      [last]="last">
    </app-product-menu-item-inflator>
  </div>
</div>
