import { Component, Input } from '@angular/core';
import { RenderContextComponent } from '../../../../../../models/base/render-context-component';
import { PrintMenu } from '../../../../../../models/menu/print-menu';

@Component({
  selector: 'app-print-menu-sections-container',
  templateUrl: './print-menu-sections-container.component.html',
  styleUrls: ['./print-menu-sections-container.component.scss']
})
export class PrintMenuSectionsContainerComponent extends RenderContextComponent {

  @Input() public menu: PrintMenu = null;
  @Input() public calculating: boolean = false;

  setupViews() {
  }

  setupBindings() {
  }

}
