@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/marketing-url-playlist-menu/marketing-url-playlist-menu.component.scss */
.marketing-url-playlist-theme {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: black;
}
/*# sourceMappingURL=marketing-url-playlist-menu.component.css.map */
