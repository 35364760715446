import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { asapScheduler, BehaviorSubject, combineLatest } from 'rxjs';
import { Menu } from '../../../../../models/menu/menu';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { debounceTime, distinctUntilChanged, map, observeOn, shareReplay } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../utils/distinct.utils';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import { exists } from '../../../../../functions/exists';

@Injectable()
export class MenuFlipperViewModel extends BaseViewModel {

  public menuToDisplay = new BehaviorSubject<Menu>(null);
  public menus = new BehaviorSubject<Menu[]>(null);
  public hidePrices = new BehaviorSubject<boolean>(null);
  public locationId = new BehaviorSubject<number>(null);
  public companyConfig = new BehaviorSubject<CompanyConfiguration>(null);
  public locationConfig = new BehaviorSubject<LocationConfiguration>(null);
  public loading = new BehaviorSubject<boolean>(null);

  private menuAndNextMenu$ = combineLatest([
    this.menuToDisplay.pipe(distinctUntilChanged(DistinctUtils.distinctMenu)),
    this.menus.pipe(distinctUntilChanged(DistinctUtils.distinctMenus)),
  ]).pipe(
    debounceTime(10),
    map(([menuToDisplay, rotatingMenus]) => {
      const position = rotatingMenus?.findIndex(m => m.id === menuToDisplay?.id) ?? 0;
      const nextMenu = rotatingMenus?.length > 0
        ? rotatingMenus[(position + 1) % (rotatingMenus?.length ?? 1)]
        : null;
      // Compare menuToDisplay with corresponding item in menus and check uniqueId equality
      const updatedMenuToDisplay = rotatingMenus?.find(m => m.id === menuToDisplay?.id);
      if (exists(updatedMenuToDisplay) && updatedMenuToDisplay?.uniqueIdentifier !== menuToDisplay?.uniqueIdentifier) {
        menuToDisplay = updatedMenuToDisplay;
      }
      return [menuToDisplay, nextMenu];
    }),
    observeOn(asapScheduler),
  );

  private menuFlip = new BehaviorSubject<Menu>(null);
  private menuFlop = new BehaviorSubject<Menu>(null);

  public flipIsActive$ = combineLatest([
    this.menuToDisplay.pipe(distinctUntilChanged(DistinctUtils.distinctMenuById)),
    this.menuFlip.pipe(distinctUntilChanged(DistinctUtils.distinctMenuById)),
  ]).pipe(
    observeOn(asapScheduler),
    map(([currentMenu, flipMenu]) => (currentMenu?.id === flipMenu?.id)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public menu0$ = this.menuFlip.pipe(
    distinctUntilChanged(DistinctUtils.distinctMenu),
    shareReplay({ bufferSize: 1, refCount: true }),
    // Delay so that it doesn't flash wrong menu when changing z-index
    debounceTime(250),
    observeOn(asapScheduler),
  );

  public menu0Reset$ = combineLatest([
    this.loading,
    this.menuToDisplay,
    this.menu0$,
  ]).pipe(
    debounceTime(1),
    map(([loading, currentMenu, m0]) => loading || currentMenu?.id !== m0?.id),
    observeOn(asapScheduler),
  );

  public menu1$ = this.menuFlop.pipe(
    distinctUntilChanged(DistinctUtils.distinctMenu),
    shareReplay({ bufferSize: 1, refCount: true }),
    // Delay so that it doesn't flash wrong menu when changing z-index
    debounceTime(250),
    observeOn(asapScheduler),
  );

  public menu1Reset$ = combineLatest([
    this.loading,
    this.menuToDisplay,
    this.menu1$,
  ]).pipe(
    debounceTime(1),
    map(([loading, currentMenu, m1]) => loading || currentMenu?.id !== m1?.id),
    observeOn(asapScheduler),
  );

  private menu0ZIndex = 1;
  private menu1ZIndex = this.menu0ZIndex + 1;
  private distinctIndices = ([a, b]: [number, number], [c, d]: [number, number]) => a === c && b === d;
  public readonly menuZIndices$ = this.flipIsActive$.pipe(
    map(flipIsActive => {
      if (flipIsActive) {
        this.menu0ZIndex = this.menu1ZIndex + 1;
      } else {
        this.menu1ZIndex = this.menu0ZIndex + 1;
      }
      return [this.menu0ZIndex, this.menu1ZIndex] as [number, number];
    }),
    distinctUntilChanged((a, b) => this.distinctIndices(a, b)),
    shareReplay({ bufferSize: 1, refCount: true }),
    observeOn(asapScheduler),
  );

  private flipper$ = combineLatest([
    this.menuAndNextMenu$,
    this.menuFlip.pipe(distinctUntilChanged(DistinctUtils.distinctMenu)),
    this.menuFlop.pipe(distinctUntilChanged(DistinctUtils.distinctMenu))
  ]).pipe(
    debounceTime(1),
    observeOn(asapScheduler),
  );

  /**
   * Flip-flops between menus. Aka, cycles through menus one after the other.
   * Circular sequence (loops back to first menu after last menu).
   */
  private readonly startFlipper = this.flipper$.subscribeWhileAlive({
    owner: this,
    next: ([[menuToDisplay, nextMenu], flip, flop]) => {
      const hasNextDifferentMenu = menuToDisplay?.id !== nextMenu?.id;
      const currentAndNextExist = exists(menuToDisplay) && exists(nextMenu);
      const nextAndNoCurrent = !menuToDisplay && exists(nextMenu);
      const flipIsCurrentMenuSoSetFlop = (menuToDisplay?.id === flip?.id);
      const flopIsCurrentMenuSoSetFlip = (menuToDisplay?.id === flop?.id);
      // Flipper Logic
      if (flipIsCurrentMenuSoSetFlop && hasNextDifferentMenu && currentAndNextExist) {
        this.menuFlop.next(nextMenu);
      } else if (flopIsCurrentMenuSoSetFlip && hasNextDifferentMenu && currentAndNextExist) {
        this.menuFlip.next(nextMenu);
      } else if (nextAndNoCurrent) {
        this.menuFlip.next(nextMenu);
        this.menuFlop.next(null);
      } else if (exists(menuToDisplay)) {
        this.menuFlip.next(menuToDisplay);
        this.menuFlop.next(null);
      }
    }
  });

}
