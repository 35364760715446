@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/building-blocks/menu-cards/half-image-card/half-image-card.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.half-image-card-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.half-image-card-container .top-row {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
}
.half-image-card-container .half-image {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.half-image-card-container .product-info {
  position: relative;
  z-index: 11;
  bottom: 0;
  width: 100%;
}
.half-image-card-container .product-label {
  font-family: poppins, sans-serif;
  font-weight: 600;
  align-self: flex-end;
  margin-left: 0;
}
.half-image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  padding: 5.17%;
  background:
    linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.24),
      rgba(0, 0, 0, 0.24));
}
.half-image-badge-asset {
  display: block;
  margin-left: 0.5rem;
}
.card-half-image-asset {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  overflow: hidden;
}
.card-fallback-icon {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.top-half-of-card .card-price {
  color: #ffffff;
}
.top-half-of-card .card-price.black-shadow {
  text-shadow: 1px 1px 2px #222222;
}
.top-half-of-card .card-price.white-shadow {
  text-shadow: 1px 1px 2px white;
}
.bottom-half-of-card {
  color: #222222;
}
.bottom-half-of-card .marketing-card-product-thc {
  margin: auto 0;
  font-size: 0.875rem;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
.bottom-half-of-card .marketing-card-product-cbd {
  margin: auto 0;
  font-size: 0.875rem;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
.bottom-half-of-card .marketing-card-product-size {
  max-width: 33%;
  margin: auto 0;
  font-size: 0.875rem;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
.bottom-half-of-card .thc-cbd-size {
  line-height: 1;
  margin-top: 3%;
}
.bottom-half-of-card .marketing-card-product-name {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-family: poppins, sans-serif;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
}
.bottom-half-of-card .marketing-card-product-desc {
  font-family: poppins, sans-serif;
  font-weight: 400;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 2%;
  line-height: 1.25;
}
/*# sourceMappingURL=half-image-card.component.css.map */
