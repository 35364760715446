import { SectionsCanOverflow } from '../models/protocols/sections-can-overflow';

// @dynamic
export class InterfaceUtils {

  static isSectionsCanOverflowInterface(obj: any): obj is SectionsCanOverflow {
    return (obj as SectionsCanOverflow)?.getOverflowState !== undefined
        && (obj as SectionsCanOverflow)?.hasOverflowState !== undefined
        && (obj as SectionsCanOverflow)?.isMenuLevelOverflow !== undefined
        && (obj as SectionsCanOverflow)?.isInHorizontalScrollingOverflowState !== undefined
        && (obj as SectionsCanOverflow)?.isInVerticalScrollingOverflowState !== undefined
        && (obj as SectionsCanOverflow)?.isInPagingOverflowState !== undefined
        && (obj as SectionsCanOverflow)?.isInHorizontalPagingOverflowState !== undefined
        && (obj as SectionsCanOverflow)?.isInVerticalPagingOverflowState !== undefined
        && (obj as SectionsCanOverflow)?.isInOverflowModeThatNeedsFlexWrap !== undefined
        && (obj as SectionsCanOverflow)?.isSectionLevelOverflow !== undefined;
  }

}
