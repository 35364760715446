import { Component, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MarketingMenu } from '../../../../../../../models/menu/marketing-menu';
import { Card } from '../../../../../../../models/menu/product-menu-cards/card';
import { InflatorComponent } from '../../../../../../inflator/inflator.component';

@Component({
  selector: 'app-marketing-menu-footer-inflator',
  templateUrl: './marketing-menu-footer-inflator.component.html'
})
export class MarketingMenuFooterInflatorComponent extends InflatorComponent implements OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() menu: MarketingMenu;
  @Input() cards: Card[] = [];
  @Input() reset: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) {
      this.connectToComponentType(this.menu?.getFooterType());
    } else {
      super.ngOnChanges(changes);
    }
  }

}
