<div
  *ngIf="!section?.empty"
  appTypeturaContext
  class="product-deal-card-container"
  [style.background-color]="viewModel?.backgroundColor$ | async">
  <ng-container *ngFor="let sectionRowViewModel of viewModel.featureCategorySectionRowViewModels$ | async">
    <div
      *ngIf="(viewModel.currentCarouselProductRowViewModel$ | async) === sectionRowViewModel"
      [class.force-bold-style]="viewModel.forcedRowBoldStyling$ | async"
      [class.force-italic-style]="viewModel.forcedRowItalicStyling$ | async"
      #carouselContainer
      class="carousel-container landscape-container"
      [@featuredCategoryCardSectionAnimations]="viewModel.transitionType$ | async">
      <div #leftSideOfCard class="landscape-card">
        <div>
          <div class="product-deal-header landscape-header" [style.color]="viewModel.sectionHeaderTextColor$ | async">
            <div class="product-deal-container">
              <app-card-section-number
                *ngIf="viewModel.showCardNumber$ | async"
                class="card-image-header-number"
                [index]="index"
                [section]="section"
                [menu]="menu">
              </app-card-section-number>
              <img
                appImage
                *ngIf="viewModel?.cardHeaderImage$ | async"
                [asset]="viewModel?.cardHeaderImage$ | async"
                [reset]="reset"
                [style.object-fit]="section?.metadata?.objectFit"
                class="product-deal-primary-image"
                alt="section image"
                src="" />
              <div class="product-header-info" [style.color]="sectionRowViewModel?.style?.fontColor">
                <div *ngIf="viewModel?.showDiscountTitle$ | async" class="discount-display">
                  ${{ viewModel?.currentDiscount$ | async }} OFF
                </div>
                <div class="product-brand" [class.brand-without-discount]="!(viewModel?.showDiscountTitle$ | async)">
                  {{ viewModel?.subtitle$ | async }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="product-deal-body-container">
          <div class="flex-container landscape">
            <div class="product-deal-asset-container">
              <img
                *ngIf="!(sectionRowViewModel | getVariantAssetForCard | async)"
                class="product-deal-variant-asset"
                src="../../../../../../../../../../assets/placeholder/no-image.svg"
                alt="" />
              <img
                appImage
                *ngIf="sectionRowViewModel | getVariantAssetForCard | async as variantAsset"
                [asset]="variantAsset"
                [reset]="reset"
                [style.object-fit]="section?.metadata?.objectFit"
                class="product-deal-variant-asset"
                alt="product asset"
                src="" />
            </div>
            <div
              #productDealInfoContainer
              class="product-deal-info-container landscape"
              [style.color]="viewModel.sectionBodyTextColor$ | async">
              <div
                [style.width.px]="viewModel.productNameWidth$ | async"
                [style.color]="sectionRowViewModel?.style?.fontColor"
                class="product-name landscape">
                {{ viewModel?.productName$ | async }}
              </div>
              <div class="product-deal-label-and-badge">
                <app-label-inflator
                  class="product-label-container"
                  [columnLabel]="true"
                  [labelComponentInterface]="sectionRowViewModel">
                </app-label-inflator>
                <div>
                  <template [ngTemplateOutlet]="productBadges"></template>
                </div>
              </div>

              <ng-template #productBadges>
                <div
                  *ngFor="let b of sectionRowViewModel | sectionRowViewModelBasicMethod: 'getAllVariantBadges'"
                  class="product-badge">
                  <img
                    appImage
                    [style.height]="'100%'"
                    [style.width]="'100%'"
                    [asset]="b.image"
                    [reset]="reset"
                    alt="badge"
                    src="" />
                </div>
              </ng-template>

              <div [style.color]="sectionRowViewModel?.style?.fontColor">
                <div class="product-info">
                  THC {{ sectionRowViewModel | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'THC' }}
                </div>
                <div class="product-info">
                  CBD {{ sectionRowViewModel | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'CBD' }}
                </div>
                <div class="product-info">{{ sectionRowViewModel | sectionRowViewModelBasicMethod: 'getSize' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="product-deal-footer landscape-footer">
        <div>
          <img
            appImage
            *ngIf="viewModel?.cardSecondaryImage$ | async"
            [asset]="viewModel?.cardSecondaryImage$ | async"
            [reset]="reset"
            [style.object-fit]="section?.metadata?.objectFit"
            class="footer-image"
            alt="section image"
            src="" />
        </div>
        <div
          *ngIf="section?.metadata?.hidePrices === 'false'"
          [style.color]="viewModel?.priceColor$ | async"
          class="product-prices-container">
          <div [style.color]="sectionRowViewModel?.style?.fontColor" class="product-deal-price">
            ${{ viewModel?.discountedPriceNullCoalesceOriginalPrice$ | async }}
          </div>
          <div
            *ngIf="!!(viewModel?.discountedPrice$ | async)"
            [style.color]="sectionRowViewModel?.style?.fontColor"
            class="product-regular-price">
            REG ${{ viewModel?.originalPrice$ | async }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
