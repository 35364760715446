import { Injectable, SecurityContext } from '@angular/core';
import { BaseMenuViewModel } from '../../menu/base-menu-view-model';
import { OrientationService } from '../../../../../../services/orientation.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MarketingUrlPlaylistMenu } from '../../../../../../models/menu/marketing/marketing-url-playlist-menu';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class MarketingUrlPlaylistMenuViewModel extends BaseMenuViewModel {

  constructor(
    protected sanitizer: DomSanitizer,
    orientationService: OrientationService
  ) {
    super(orientationService);
  }

  public _menu: BehaviorSubject<MarketingUrlPlaylistMenu>;

  private readonly _loaded = new BehaviorSubject<boolean>(false);
  public readonly loaded$ = this._loaded as Observable<boolean>;
  connectToLoaded = (loaded: boolean) => this._loaded.next(loaded);

  public readonly url$ = this._menu.pipe(
    map(menu => {
      const url = this.sanitizer.sanitize(SecurityContext.URL, menu?.linkedURL);
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }),
    distinctUntilChanged()
  );

}
