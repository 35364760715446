import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../models/menu/section/section';

@Pipe({
  name: 'sectionsOnPage'
})
export class SectionsOnPagePipe implements PipeTransform {

  transform(sections: Section[], pageIndex: number): Section[] {
    return sections?.filter(s => s?.pageIndex === pageIndex) || [];
  }

}
