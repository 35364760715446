import { ChangeDetectionStrategy, Component, ElementRef, forwardRef } from '@angular/core';
import { ProductSectionComponent } from '../../product-section/product-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportNewProductsSectionViewModel } from './report-new-products-section-view-model.service';

@Component({
  selector: 'app-report-new-products-section',
  templateUrl: './report-new-products-section.component.html',
  styleUrls: ['./report-new-products-section.component.scss'],
  providers: [
    ReportNewProductsSectionViewModel,
    { provide: ProductSectionComponent, useExisting: forwardRef(() => ReportNewProductsSectionComponent) }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportNewProductsSectionComponent extends ProductSectionComponent {

  constructor(
    public viewModel: ReportNewProductsSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

}
