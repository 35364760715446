<div class="marketing-loop-theme">
  <app-asset
    class="loop-asset"
    [reset]="vm.reset$ | async"
    [asset]="vm.asset$ | async"
    [scaleFit]="vm.scaleFit$ | async"
    [size]="Types.ImageSize.Original"
    [loopVideo]="vm.loopVideo$ | async"
    [playAudio]="vm.playAudio$ | async"
    (currentVideoTime)="vm.connectToCurrentVideoTime($event)"
    (duration)="vm.connectToVideoDuration($event)"
    (videoStartedToLoad)="vm.connectToVideoEnded(false)"
    (videoEnded)="vm.connectToVideoEnded($event)">
    Background Asset
  </app-asset>
</div>
<!-- BuzzTV devices will freeze on a single frame during video playback without this -->
<div class="stop-video-from-sleeping">{{ vm.currentVideoTime$ | async }}</div>
