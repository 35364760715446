import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Section } from '../../../../../../../../models/menu/section/section';
import { ColorUtils } from '../../../../../../../../utils/color-utils';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Menu } from '../../../../../../../../models/menu/menu';

@Component({
  selector: 'app-card-section-number',
  templateUrl: './card-section-number.component.html',
  styleUrls: ['./card-section-number.component.scss']
})
export class CardSectionNumberComponent extends RenderContextComponent implements OnChanges {

  @Input() index: number;
  @Input() section: Section;
  @Input() menu: Menu;
  private _section = new BehaviorSubject<Section | null>(null);
  public section$ = this._section.asObservable();
  public automaticNumberTextColor$ = this.section$.pipe(
    map(section => {
      const bgColor = section?.metadata?.productsHeaderBackgroundColor
        || ColorUtils.DEFAULT_CARD_SECTION_NUMBER_BACKGROUND_COLOR;
      return ColorUtils.isDarkColor(bgColor) ? ColorUtils.COLOR_WHITE : ColorUtils.COLOR_BLACK;
    })
  );

  private _menu = new BehaviorSubject<Menu | null>(null);
  public menu$ = this._menu.asObservable();

  private _index = new BehaviorSubject<number | null>(null);
  public index$ = this._index.asObservable();

  public cardNumber$ = combineLatest([
    this.menu$.pipe(map(m => m?.metadata?.cardStartAt)),
    this.index$,
  ]).pipe(
    map(([startAt, index]) => {
      const startAtAsInt = parseInt(startAt, 10);
      if (isNaN(startAtAsInt) || startAtAsInt < 1) {
        return index + 1;
      } else {
        return startAtAsInt + index;
      }
    })
  );

  setupViews() {
    this._section.next(this.section);
    this._menu.next(this.menu);
    this._index.next(this.index);
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section) this._section.next(this.section);
    if (changes.menu) this._menu.next(this.menu);
    if (changes.index) this._index.next(this.index);
  }

}
