import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrientationError } from '../../../../../models/enum/dto/orientation-error.enum';

@Injectable()
export class OrientationErrorViewModel extends BaseViewModel {

  private readonly _isDisplay = new BehaviorSubject<boolean>(false);
  public readonly isDisplay$ = this._isDisplay as Observable<boolean>;
  connectToIsDisplay = (isDisplay: boolean) => this._isDisplay.next(isDisplay);

  private readonly _orientationError = new BehaviorSubject<OrientationError>(null);
  public readonly orientationError$ = this._orientationError as Observable<OrientationError>;
  connectToOrientationError = (orientationError: OrientationError) => this._orientationError.next(orientationError);

  public readonly errorDesc1$ = combineLatest([
    this.isDisplay$,
    this.orientationError$
  ]).pipe(
    map(([isDisplay, orientationError]) => {
      if (isDisplay) {
        const orientation = orientationError === OrientationError.DisplayInLandscapeModeButScreenIsPortrait
          ? 'portrait'
          : 'landscape';
        const opposite = orientationError === OrientationError.DisplayInLandscapeModeButScreenIsPortrait
          ? 'landscape'
          : 'portrait';
        return `This physical display is set to ${orientation}, `
          + `but your budsense display is set to ${opposite}.`;
      }
      const keyWord = orientationError === OrientationError.DisplayInLandscapeModeButScreenIsPortrait
        ? 'landscape'
        : 'portrait';
      return `This menu is currently set to be displayed in ${keyWord} mode.`;
    })
  );

  public readonly errorDesc2$ = combineLatest([
    this.isDisplay$,
    this.orientationError$
  ]).pipe(
    map(([isDisplay, orientationError]) => {
      if (isDisplay) {
        const orientation = orientationError === OrientationError.DisplayInLandscapeModeButScreenIsPortrait
          ? 'portrait'
          : 'landscape';
        const opposite = orientationError === OrientationError.DisplayInLandscapeModeButScreenIsPortrait
          ? 'landscape'
          : 'portrait';
        return 'Open the budsense dashboard and go to the display tab. '
          + `Change this displays orientation from ${opposite} to ${orientation}.`;
      }
      const keyWord = orientationError === OrientationError.DisplayInLandscapeModeButScreenIsPortrait
        ? 'landscape'
        : 'portrait';
      return `Resize your window to a ${keyWord} orientation to view menu.`;
    })
  );

}
