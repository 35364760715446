<div
  class="h100p"
  [ngClass]="[viewModel.themeClass$ | async, viewModel.menuWrapperClass$ | async]"
  [style.opacity]="viewModel.menuOpacity$ | async">
  <div
    class="product-menu-container"
    [class.screen-is-portrait]="viewModel.isPortrait$ | async"
    [class.no-header]="!(viewModel.showMenuHeader$ | async)"
    [class.no-footer]="!(viewModel.showMenuFooter$ | async)"
    [class.header-and-footer-height]="viewModel.hasHeaderAndFooter$ | async"
    [class.header-xor-footer-height]="viewModel.hasFooterXorHeader$ | async"
    [class.no-header-and-footer-height]="viewModel.hasNoHeaderAndFooter$ | async">
    <app-product-menu-header-inflator [hidden]="!menu?.getShowHeader()" [reset]="reset" [menu]="menu">
      Header
    </app-product-menu-header-inflator>

    <div
      #sectionsContainer
      appCardGridManager
      [limitedSections]="viewModel.limitedSections$ | async"
      class="sections-container"
      id="sections-container-{{ menu?.id }}"
      [style.margin-top]="viewModel?.sectionMarginTopRem$ | async"
      [style.margin-bottom]="viewModel?.sectionMarginBottomRem$ | async"
      [style.background-color]="
        menu?.getOpacityLocation() === Types.OpacityLocation.SECTION_CONTAINER
          ? menu?.getBackgroundColorWithOpacity()
          : menu?.getSectionsBackgroundColor()
      "
      [ngClass]="[menu?.getSectionsWrapperClass(), menu?.getMenuScrollClass()]">
      <app-product-menu-section-inflator
        *ngFor="let s of viewModel.limitedSections$ | async; index as i; last as last"
        class="card-container"
        [signalPagination]="viewModel.signalPagination$ | async"
        [reset]="reset"
        [locationId]="locationId"
        [menu]="menu"
        [index]="i"
        [last]="last"
        [section]="s">
        Cards
      </app-product-menu-section-inflator>
    </div>

    <app-product-menu-footer-inflator [hidden]="!menu?.getShowFooter()" [reset]="reset" [menu]="menu">
      Footer
    </app-product-menu-footer-inflator>
  </div>
</div>
