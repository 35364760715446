<div class="error-container">
  <div class="flex-grow"></div>
  <div class="error-margin flex-grow">
    <div class="error-title">Oh no.</div>
    <div class="error-desc flex-grow">
      <p>{{ viewModel.emptyMarketingMenuHasNoContentText$ | async }}</p>
      <p>{{ viewModel.howToFixText$ | async }}</p>
    </div>
    <img
      *ngIf="!(viewModel.isUrlPlaylistMenu$ | async)"
      class="empty-marketing-switch flex-grow"
      src="/assets/images/ToggleSwitch.jpg"
      alt="" />
  </div>
  <div class="error-logo-container flex-grow">
    <img class="error-logo" src="/assets/logo/dark/logo-stroke.svg" alt="" />
  </div>
</div>
