<div
  class="h100p"
  [ngClass]="[menu?.getThemeClass()]"
  [ngStyle]="{
    opacity: menu?.getOpacityEnabled()
      ? menu?.getOpacityLocation() === Types.OpacityLocation.ENTIRE_MENU
        ? menu?.menuOptions?.backgroundOpacity || '1'
        : '1'
      : '1'
  }">
  <div
    [hidden]="!(vm.hasVariants$ | async)"
    class="marketing-combo-menu-wrapper"
    fxLayout="column"
    fxLayoutAlign="center stretch"
    [ngClass]="{ 'screen-is-portrait': isPortrait$ | async }">
    <app-marketing-menu-header-inflator
      [class.fxFlex]="vm.hasFooter$ | async"
      [style.display]="(vm.hasHeader$ | async) ? 'block' : 'none'"
      [reset]="vm.reset$ | async"
      [cards]="vm.cards$ | async"
      [menu]="vm.comboMenu$ | async">
      Header
    </app-marketing-menu-header-inflator>

    <app-card-grid class="card-grid" [cards]="vm.cards$ | async" [menu]="vm.comboMenu$ | async"> </app-card-grid>

    <app-marketing-menu-footer-inflator
      [class.fxFlex]="true"
      [style.display]="(vm.hasFooter$ | async) ? 'block' : 'none'"
      [reset]="vm.reset$ | async"
      [cards]="vm.cards$ | async"
      [menu]="vm.comboMenu$ | async">
      Footer
    </app-marketing-menu-footer-inflator>
  </div>
</div>
