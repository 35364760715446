import { Pipe, PipeTransform } from '@angular/core';
import { ProductSectionWithTransitionsViewModel } from '../product-menu/building-blocks/menu-section/product-section/product-section-with-transitions/product-section-with-transitions-view-model';
import { Observable } from 'rxjs';
import { OverflowState } from '../../../../../models/enum/shared/overflow-transition-state.enum';

@Pipe({
  name: 'getSectionAnimationState'
})
export class GetSectionAnimationStatePipe implements PipeTransform {

  transform(
    viewModel: ProductSectionWithTransitionsViewModel,
    index: number,
    length: number
  ): Observable<OverflowState> {
    return viewModel.getAnimationState$(index, length);
  }

}
