import { Component, EnvironmentInjector, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, Type } from '@angular/core';
import { ProductMenu } from '../../../../../../../models/menu/product-menu';
import { ProductMenuHeaderInterface } from './product-menu-header-interface';
import { InflatorComponent } from '../../../../../../inflator/inflator.component';
import { ProductMenuHeaderComponent } from '../../building-blocks/menu-header/product-menu-header.component';

@Component({
  selector: 'app-product-menu-header-inflator',
  templateUrl: './product-menu-header-inflator.component.html',
})
export class ProductMenuHeaderInflatorComponent extends InflatorComponent
  implements ProductMenuHeaderInterface, OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() menu: ProductMenu;
  @Input() reset: boolean;
  @Output() headerHeightInPx = new EventEmitter<number>(true);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.menu?.previousValue?.id !== changes?.menu?.currentValue?.id) {
      this.connectToComponentType(this.getInflationType());
    }
    super.ngOnChanges(changes);
  }

  getInflationType(): Type<ProductMenuHeaderComponent> {
    return this.menu?.getHeaderType();
  }

}
