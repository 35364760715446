import { Pipe, PipeTransform } from '@angular/core';
import { SectionWithProducts } from '../../../models/menu/section/section-with-products';

@Pipe({
  name: 'getSectionBottomBorderHeightInPx'
})
export class GetSectionBottomBorderHeightInPxPipe implements PipeTransform {

  transform(section: SectionWithProducts): number {
    return section?.getBottomBorderHeightInPx();
  }

}
