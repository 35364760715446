import { Component, ElementRef, EnvironmentInjector, forwardRef, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { SpotlightMenu } from '../../../../../../../models/menu/spotlight-menu';
import { Section } from '../../../../../../../models/menu/section/section';
import { SpotlightProductSectionComponent } from '../../building-blocks/menu-section/spotlight-product-section/spotlight-product-section.component';
import { SectionType } from '../../../../../../../models/enum/dto/section-type.enum';
import { TitleSectionComponent } from '../../../product-menu/building-blocks/menu-section/title-section/title-section.component';
import { AssetSectionComponent } from '../../../product-menu/building-blocks/menu-section/image-section/asset-section.component';
import { PageBreakSectionComponent } from '../../../product-menu/building-blocks/menu-section/page-break-section/page-break-section.component';
import { SpotlightSectionComponent } from '../../building-blocks/menu-section/spotlight-section/spotlight-section.component';
import { EmptySectionComponent } from '../../../product-menu/building-blocks/menu-section/empty-section/empty-section.component';
import { MenuSectionInflatorComponent } from '../../../menu/inflators/menu-section-inflator-component';
import { ProductSectionWithTransitionsComponent } from '../../../product-menu/building-blocks/menu-section/product-section/product-section-with-transitions/product-section-with-transitions.component';
import { exists } from '../../../../../../../functions/exists';

@Component({
  selector: 'app-spotlight-section-inflator',
  templateUrl: './spotlight-section-inflator.component.html',
  providers: [
    { provide: MenuSectionInflatorComponent, useExisting: forwardRef(() => SpotlightSectionInflatorComponent) }
  ]
})
export class SpotlightSectionInflatorComponent extends MenuSectionInflatorComponent implements OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector,
    element: ElementRef
  ) {
    super(injector, environmentInjector, element);
  }

  @Input() locationId: number;
  @Input() menu: SpotlightMenu;
  @Input() section: Section;
  @Input() index: number;
  @Input() last: boolean = false;
  @Input() reset: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (exists(changes.menu) || exists(changes.section)) {
      this.connectToComponentType(this.getSectionType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  getSectionType(): Type<SpotlightSectionComponent> {
    switch (this.section?.sectionType) {
      case SectionType.Spotlight: {
        return SpotlightProductSectionComponent;
      }
      case SectionType.Product: {
        return ProductSectionWithTransitionsComponent;
      }
      case SectionType.Title: {
        return TitleSectionComponent;
      }
      case SectionType.Asset: {
        return AssetSectionComponent;
      }
      case SectionType.PageBreak: {
        return PageBreakSectionComponent;
      }
    }
    return EmptySectionComponent;
  }

  public getMenuSectionInflatorId(): string {
    return this.element?.nativeElement?.id;
  }

  public getChildSectionComponent(): SpotlightSectionComponent {
    return this.compRef.instance as SpotlightSectionComponent;
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
