import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrientationError } from '../../../../../models/enum/dto/orientation-error.enum';
import { BaseComponent } from '../../../../../models/base/base-component';
import { OrientationErrorViewModel } from './orientation-error-view-model';

@Component({
  selector: 'app-orientation-error',
  templateUrl: './orientation-error.component.html',
  styleUrls: ['./orientation-error.component.scss'],
  providers: [OrientationErrorViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrientationErrorComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges {

  constructor(public viewModel: OrientationErrorViewModel) {
    super();
  }

  @Input() isDisplay: boolean;
  @Input() orientationError: OrientationError;

  setupViews() {
    this.viewModel.connectToIsDisplay(this.isDisplay);
    this.viewModel.connectToOrientationError(this.orientationError);
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisplay) this.viewModel.connectToIsDisplay(this.isDisplay);
    if (changes.orientationError) this.viewModel.connectToOrientationError(this.orientationError);
  }

}
