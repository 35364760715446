<div class="h100p" [ngClass]="vm.menuClasses$ | async" [style.opacity]="vm.menuOpacity$ | async">
  <div
    class="spotlight-menu-container"
    [class.screen-is-portrait]="vm.isPortrait$ | async"
    [class.no-footer]="!(vm.hasFooter$ | async)"
    [class.no-header]="!(vm.hasHeader$ | async)"
    [class.header-and-footer-height]="vm.hasHeaderAndFooter$ | async"
    [class.header-xor-footer-height]="vm.hasHeaderXorFooter$ | async"
    [class.no-header-and-footer-height]="vm.hasNoHeaderAndFooter$ | async">
    <app-spotlight-header-inflator
      [hidden]="!(vm.hasHeader$ | async)"
      [companyConfig]="vm.companyConfig$ | async"
      [locationConfig]="vm.locationConfig$ | async"
      [locationId]="vm.locationId$ | async"
      [reset]="vm.reset$ | async"
      [menu]="vm.spotlightMenu$ | async">
      Header
    </app-spotlight-header-inflator>
    <div
      #sectionsContainer
      class="sections-container"
      [id]="vm.sectionsContainerId$ | async"
      [style.margin-top]="vm.sectionMarginTopRem$ | async"
      [style.margin-bottom]="vm.sectionMarginBottomRem$ | async"
      [style.background-color]="vm.sectionsBackgroundColor$ | async"
      [ngClass]="[vm.sectionsWrapperClass$ | async, vm.sectionsScrollClass$ | async]">
      <div
        #sectionsContainerContent
        class="content-inside-sections-container"
        [style.flex-wrap]="vm.sectionsFlexWrapStyle$ | async">
        <app-spotlight-menu-section-overflow-calculator
          [menu]="menu"
          [companyConfig]="vm.companyConfig$ | async"
          [locationConfig]="vm.locationConfig$ | async"
          [locationId]="locationId"
          [reset]="reset"
          [sectionsContainerHeight]="sectionsContainerHeight$ | async"
          (overflowedSections)="vm.connectToOverflowedSections($event)">
        </app-spotlight-menu-section-overflow-calculator>
        <app-spotlight-section-inflator
          *ngFor="let s of vm.overflowedSections$ | async; index as i; last as last"
          [reset]="vm.reset$ | async"
          [style.width.%]="vm.sectionWidth$ | async"
          [locationId]="vm.locationId$ | async"
          [locationConfig]="vm.locationConfig$ | async"
          [menu]="vm.spotlightMenu$ | async"
          [index]="i"
          [last]="last"
          [section]="s"
          [ngStyle]="{ 'max-height': vm.sectionMaxHeight$ | async }">
          Section
        </app-spotlight-section-inflator>
      </div>
    </div>
    <app-spotlight-footer-inflator
      [hidden]="!(vm.hasFooter$ | async)"
      [companyConfig]="vm.companyConfig$ | async"
      [locationConfig]="vm.locationConfig$ | async"
      [reset]="vm.reset$ | async"
      [menu]="vm.spotlightMenu$ | async">
      Footer
    </app-spotlight-footer-inflator>
  </div>
</div>
