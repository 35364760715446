import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MenuFlipperViewModel } from './menu-flipper-view-model';
import { Menu } from '../../../../../models/menu/menu';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { Size } from '../../../../../models/shared/size';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-menu-flipper',
  templateUrl: './menu-flipper.component.html',
  styleUrls: ['./menu-flipper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MenuFlipperViewModel],
})
export class MenuFlipperComponent extends BaseComponent implements OnChanges {

  constructor(
    public vm: MenuFlipperViewModel
  ) {
    super();
  }

  @Input() menuToDisplay: Menu;
  @Input() menus: Menu[];
  @Input() hidePrices: boolean;
  @Input() locationId: number;
  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() displaySize: Size;
  @Input() loading: boolean;

  setupViews() {
    this.vm.menuToDisplay.next(this.menuToDisplay);
    this.vm.menus.next(this.menus);
    this.vm.hidePrices.next(this.hidePrices);
    this.vm.locationId.next(this.locationId);
    this.vm.companyConfig.next(this.companyConfig);
    this.vm.locationConfig.next(this.locationConfig);
    this.vm.loading.next(this.loading);
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuToDisplay) this.vm.menuToDisplay.next(this.menuToDisplay);
    if (changes.menus) this.vm.menus.next(this.menus);
    if (changes.hidePrices) this.vm.hidePrices.next(this.hidePrices);
    if (changes.locationId) this.vm.locationId.next(this.locationId);
    if (changes.companyConfig) this.vm.companyConfig.next(this.companyConfig);
    if (changes.locationConfig) this.vm.locationConfig.next(this.locationConfig);
    if (changes.loading) this.vm.loading.next(this.loading);
  }

}
