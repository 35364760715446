import { Component, ElementRef, forwardRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MenuSectionComponent } from '../../menu-section.component';
import { ProductMenu } from '../../../../../../../../../models/menu/product-menu';
import { ProductMenuSectionHeaderInflatorComponent } from '../../../../inflators/product-menu-section-header-inflator/product-menu-section-header-inflator.component';
import { MenuItemInflatorComponent } from '../../../../../menu/inflators/menu-item-inflator-component';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuItemComponent } from '../../../menu-item/menu-item.component';
import { SativaHybridIndicaSplitProductSectionViewModel } from './sativa-hybrid-indica-split-product-section-view-model';
import { ProductSection } from '../../../../../../../../../models/menu/section/product-section';
import { exists } from '../../../../../../../../../functions/exists';

@Component({
  selector: 'app-sativa-hybrid-indica-split-product-section',
  templateUrl: './sativa-hybrid-indica-split-product-section.component.html',
  providers: [
    SativaHybridIndicaSplitProductSectionViewModel,
    { provide: MenuSectionComponent, useExisting: forwardRef(() => SativaHybridIndicaSplitProductSectionComponent) }
  ]
})
export class SativaHybridIndicaSplitProductSectionComponent extends MenuSectionComponent implements OnChanges {

  constructor(
    public vm: SativaHybridIndicaSplitProductSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(sanitizer, element);
  }

  @Input() override menu: ProductMenu;
  @Input() override section: ProductSection;
  @ViewChild('sectionHeader') sectionHeader: ProductMenuSectionHeaderInflatorComponent;
  @ViewChildren('sativaItem') sativaItems: QueryList<MenuItemInflatorComponent>;
  @ViewChildren('hybridItem') hybridItems: QueryList<MenuItemInflatorComponent>;
  @ViewChildren('indicaItem') indicaItems: QueryList<MenuItemInflatorComponent>;
  @ViewChildren(MenuItemInflatorComponent) sectionItem: QueryList<MenuItemInflatorComponent>;

  /**
   * This height is used to calculate how many empty cells can fit below a section. If the last item
   * in the section is used by the algorithm, then it will be out by one pixel, because the bottom border
   * for the last item is on the parent container, and not the item. Hence, why the + 1 is added to the
   * last item.
   *
   * return height of item, plus one pixel for bottom border on parent container if last item, else
   * return height of item.
   *
   * See https://github.com/mobilefirstdev/budsense-client-display/pull/229 for more info.
   */
  private static getItemHeight(component: MenuItemComponent, length: number, index: number): number {
    return (index + 1) === length
      ? component?.getItemHeight() + 1
      : component?.getItemHeight();
  }

  setupViews() {
    super.setupViews();
    this.initViewModel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (exists(changes.menu) || exists(changes.section) || exists(changes.calculationMode)) {
      this.initViewModel();
    }
  }

  private initViewModel() {
    this.vm.init(this.menu, this.section, this.calculationMode);
  }

  public getHeaderHeight(): number {
    return this.sectionHeader?.getHeaderHeight();
  }

  public getExpandedHeaderHeight(): number {
    return this.sectionHeader?.getExpandedHeaderHeight();
  }

  public getCollapsedHeaderHeight(): number {
    return this.sectionHeader?.getCollapsedHeaderHeight();
  }

  public getSativaItemComponents(): MenuItemComponent[] {
    return this.sativaItems?.toArray()?.map(inflator => inflator?.getMenuItemComponent()) || [];
  }

  /**
   * Need to account for bottom border on last item (1px)
   */
  public getSativaItemComponentsWithHeights(): [MenuItemComponent, number][] {
    const items = this?.sativaItems?.toArray();
    const length = items?.length || 0;
    return items?.map((inflator, i) => {
      const component = inflator?.getMenuItemComponent();
      const height = SativaHybridIndicaSplitProductSectionComponent.getItemHeight(component, length, i);
      return [component, height];
    }) || [];
  }

  public getHybridItemComponents(): MenuItemComponent[] {
    return this.hybridItems?.toArray()?.map(inflator => inflator?.getMenuItemComponent()) || [];
  }

  /**
   * Need to account for bottom border on last item
   */
  public getHybridItemComponentsWithHeights(): [MenuItemComponent, number][] {
    const items = this?.hybridItems?.toArray();
    const length = items?.length || 0;
    return items?.map((inflator, i) => {
      const component = inflator?.getMenuItemComponent();
      const height = SativaHybridIndicaSplitProductSectionComponent.getItemHeight(component, length, i);
      return [component, height];
    }) || [];
  }

  public getIndicaItemComponents(): MenuItemComponent[] {
    return this.indicaItems?.toArray()?.map(inflator => inflator?.getMenuItemComponent()) || [];
  }

  /**
   * Need to account for bottom border on last item
   */
  public getIndicaItemComponentsWithHeights(): [MenuItemComponent, number][] {
    const items = this?.indicaItems?.toArray();
    const length = items?.length || 0;
    return items?.map((inflator, i) => {
      const component = inflator?.getMenuItemComponent();
      const height = SativaHybridIndicaSplitProductSectionComponent.getItemHeight(component, length, i);
      return [component, height];
    }) || [];
  }

  public getMenuItemComponents(): MenuItemComponent[] {
    return this.sectionItem?.toArray()?.map(inflator => inflator?.getMenuItemComponent());
  }

  public getMenuItemComponentsWithHeights(): [MenuItemComponent, number][] {
    return this?.sectionItem?.toArray()?.map(inflator => {
      const component = inflator?.getMenuItemComponent();
      const height = component?.getItemHeight();
      return [component, height];
    });
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
