/* src/app/modules/display/components/menus/print-menu/print-menu-section-overflow-calculator/print-menu-section-overflow-calculator.component.scss */
.hide-print-header {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
}
.hide-empty-section-calculator {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
}
.menu-section-overflow-calculator {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
}
.hide-print-footer {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
}
/*# sourceMappingURL=print-menu-section-overflow-calculator.component.css.map */
