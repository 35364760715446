import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RenderPrintMenuLiveViewViewModel } from './render-print-menu-live-view-view-model';

@Component({
  selector: 'app-render-print-menu-live-view',
  templateUrl: './render-print-menu-live-view.component.html',
  styleUrls: ['./render-print-menu-live-view.component.scss'],
  providers: [RenderPrintMenuLiveViewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderPrintMenuLiveViewComponent {

  constructor(
    public viewModel: RenderPrintMenuLiveViewViewModel
  ) {
  }

}
