import { MenuSectionOverflowCalculatorViewModel } from '../../menu/menu-section-overflow-calculator/menu-section-overflow-calculator-view-model';
import { IsMenuReadyService } from '../../../../../services/is-menu-ready.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { PrintMenu } from '../../../../../../models/menu/print-menu';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../utils/distinct.utils';
import { PrintHeaderLayoutType } from '../../../../../../models/enum/shared/print-header-layout-type.enum';
import { PrintFooterLayoutType } from '../../../../../../models/enum/shared/print-footer-layout-type.enum';
import { SectionType } from '../../../../../../models/enum/dto/section-type.enum';
import { Section } from '../../../../../../models/menu/section/section';
import { MenuSectionComponent } from '../../product-menu/building-blocks/menu-section/menu-section.component';
import { PageBreakSectionComponent } from '../../product-menu/building-blocks/menu-section/page-break-section/page-break-section.component';
import { ProductSection } from '../../../../../../models/menu/section/product-section';
import { SativaHybridIndicaSplitProductSectionComponent } from '../../product-menu/building-blocks/menu-section/product-section/sativa-hybrid-indica-split-product-section/sativa-hybrid-indica-split-product-section.component';
import { SectionDimensions } from '../../product-menu/building-blocks/menu-section/section-dimensions';
import { PageBreakSection } from '../../../../../../models/menu/section/page-break-section';
import { EmptySection } from '../../../../../../models/menu/section/empty-section';
import { ProductSectionComponent } from '../../product-menu/building-blocks/menu-section/product-section/product-section.component';
import { WrappingSwimLaneOverflow } from '../../../../../../models/enum/shared/swim-lane-overflow.enum';
import { WrappingSwimlaneSection } from '../../../../../../models/menu/section/wrapping-swimlane-section';
import { DisplayMenuCoupling } from '../../../../../../couplings/display-menu-coupling.service';
import { exists } from '../../../../../../functions/exists';

@Injectable()
export class PrintMenuSectionOverflowCalculatorViewModel extends MenuSectionOverflowCalculatorViewModel {

  constructor(
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected isMenuReadyService: IsMenuReadyService
  ) {
    super(displayMenuCoupling, isMenuReadyService);
  }

  protected override taskSpreadInMilliSeconds = 0;
  // Keep track of the current product section
  protected currentProductComponent: MenuSectionComponent;

  // Print headers and footers
  protected headerHeight = 0;
  protected emptyHeaderReplacementHeight = 0;
  protected emptySectionAdditionalHeight = 0;
  protected footerHeight = 0;
  protected emptyFooterReplacementHeight = 0;
  protected headerLayoutType: PrintHeaderLayoutType;
  protected footerLayoutType: PrintFooterLayoutType;

  protected _menu = new BehaviorSubject<PrintMenu>(null);
  public menu$ = defer(() => this._menu).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  protected menu: PrintMenu;

  public wrappingSwimlanesEnabled$ = this.menu$.pipe(map(menu => menu?.wrappingOverflowSwimlanesEnabled()));
  public nSwimLanesToFake$ = this.menu$.pipe(
    map(menu => (menu?.wrappingOverflowSwimlanesEnabled() ? (menu?.getNWrappingOverflowSwimlanes() - 1) : 0))
  );
  public sectionWidthPercentage$ = this.menu$.pipe(
    map(menu => {
      if (menu?.wrappingSwimLaneOverflow() !== WrappingSwimLaneOverflow.Off) {
        return 100;
      } else {
        return menu?.getSectionWidthPercentage();
      }
    })
  );
  public shouldOverflowHorizontallyElseVertically$ = this.menu$.pipe(
    map(menu => menu?.getShouldOverflowHorizontallyElseVertically())
  );
  public headerAsTitleSection$ = this.menu$?.pipe(
    map(menu => menu?.getShowHeaderAsTitleSection()),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public showHeaderAsTitleSection$ = this.headerAsTitleSection$?.pipe(map(titleSection => exists(titleSection)));
  public headerAsTitleSectionIndex$ = this.menu$.pipe(
    map(m => {
      if (exists(m?.getShowHeaderAsTitleSection())) {
        return m?.sections?.findIndex(s => !(s instanceof PageBreakSection)) ?? 0;
      } else {
        return -1;
      }
    })
  );

  public emptySection$ = this.menu$.pipe(
    map(menu => {
      const SectionConstructor = menu?.getEmptySectionType();
      return new SectionConstructor();
    })
  );

  protected _headerHeight = new BehaviorSubject<number>(0);
  public headerHeight$ = this._headerHeight as Observable<number>;

  protected _emptyHeaderReplacementHeight = new BehaviorSubject(0);
  public emptyHeaderReplacementHeight$ = this._emptyHeaderReplacementHeight as Observable<number>;

  public headerStatus$ = this.menu$.pipe(
    map(m => (m?.getShowHeader() ? m?.getPrintHeaderLayoutType() : PrintHeaderLayoutType.None))
  ) as Observable<PrintHeaderLayoutType>;
  public footerStatus$ = this.menu$.pipe(
    map(m => (m?.getShowFooter() ? m?.getPrintFooterLayoutType() : PrintFooterLayoutType.None))
  ) as Observable<PrintFooterLayoutType>;

  protected _pageHeightInPx = new BehaviorSubject<number>(0);
  public pageHeightInPx$ = this._pageHeightInPx as Observable<number>;
  public pageHeightMinusMargins$ = combineLatest([
    this.menu$,
    this.pageHeightInPx$,
  ]).pipe(
    map(([menu, pageHeightInPx]) => {
      return (pageHeightInPx ?? 0) - (menu?.getPageTopMarginInPx() ?? 0) - (menu?.getPageBottomMarginInPx() ?? 0);
    })
  );

  protected _footerHeight = new BehaviorSubject<number>(0);
  public footerHeight$ = this._footerHeight as Observable<number>;

  protected _emptyFooterReplacementHeight = new BehaviorSubject(0);
  public emptyFooterReplacementHeight$ = this._emptyFooterReplacementHeight as Observable<number>;

  // Group heights into one stream - [headerHeightPx, pageHeightPx, footerHeightPx]
  // only fires if the page height is greater than 0
  public headerPageFooterHeights$ = combineLatest([
    this.headerHeight$,
    this.pageHeightMinusMargins$.pipe(filter(canvasHeightInPx => canvasHeightInPx > 0)),
    this.footerHeight$
  ]).pipe(
    map(([header, page, footer]) => [header, page, footer])
  );

  public emptyReplacementHeights$ = combineLatest([
    this.emptyHeaderReplacementHeight$,
    this.emptyFooterReplacementHeight$
  ]).pipe(
    map(([headerReplacement, footerReplacement]) => [headerReplacement, footerReplacement])
  );

  // Group header and footer status into one stream - [headerStatus, footerStatus]
  public headerFooterStatus$ = combineLatest([
    this.headerStatus$,
    this.footerStatus$
  ]).pipe(
    map(([headerStatus, footerStatus]) => [headerStatus, footerStatus])
  ) as Observable<[PrintHeaderLayoutType, PrintFooterLayoutType]>;

  // Calculations
  public overflowedSections$ = combineLatest([
    combineLatest([
      this.companyConfig$,
      this.locationConfig$
    ]),
    combineLatest([
      defer(() => this._menu).notNull(),
      this.screenshotMode$
    ]),
    this.headerFooterStatus$,
    this.headerPageFooterHeights$,
    this.emptyReplacementHeights$,
    combineLatest([
      this.sectionHeights$,
      this.emptySectionAdditionalHeight$
    ])
  ]).pipe(
    tap(_ => this.isMenuReadyService.overflow(true)),
    debounceTime(100),
    map(([
      [companyConfig, locationConfig],
      [menu, screenshotMode],
      [headerStatus, footerStatus],
      [headerHeight, pageHeight, footerHeight],
      [headerReplacementHeight, footerReplacementHeight],
      [sectionHeights, emptySectionAdditionalHeight]
    ]) => {
      this.menu = menu;
      this.companyConfig = companyConfig;
      this.locationConfig = locationConfig;
      this.canvasHeight = pageHeight;
      this.headerHeight = headerHeight;
      this.emptyHeaderReplacementHeight = headerReplacementHeight;
      this.emptySectionAdditionalHeight = emptySectionAdditionalHeight;
      this.footerHeight = footerHeight;
      this.emptyFooterReplacementHeight = footerReplacementHeight;
      this.headerLayoutType = headerStatus;
      this.footerLayoutType = footerStatus;
      this.runningPageHeight = 0;
      this.pageIndex = 0;
      this.overflowSections = [];
      this.screenshotMode = screenshotMode;
      return this.createOverflowSections(menu, pageHeight, sectionHeights);
    }),
    tap(_ => this.isMenuReadyService.overflow(false)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // connectors
  public connectToHeaderHeight = (headerHeight: number) => this._headerHeight.next(headerHeight);
  public connectToEmptyHeaderReplacementHeight = (height: number) => this._emptyHeaderReplacementHeight.next(height);
  public connectToPageHeightInPx = (pageHeight: number) => this._pageHeightInPx.next(pageHeight);
  public connectToFooterHeight = (footerHeight: number) => this._footerHeight.next(footerHeight);
  public connectToEmptyFooterReplacementHeight = (height: number) => this._emptyFooterReplacementHeight.next(height);

  /* Entrypoint into [1] */

  protected override createOverflowSections(
    menu: PrintMenu,
    pageHeight: number,
    sectionHeights: [MenuSectionComponent, SectionDimensions][]
  ): Section[] {
    this.resetOverflowSectionData(sectionHeights);
    this.calculatePageHeight();
    this.currentProductComponent = null;
    for (const [sectionComponent, sectionHeight] of sectionHeights) {
      this.calculateSectionPlacementAtPageCursorPosition(sectionComponent, sectionHeight);
    }
    const remainingSpace = this.pageHeight - this.runningPageHeight;
    const shouldFillRemainingSpace = remainingSpace > 0 && remainingSpace < this.pageHeight;
    if (shouldFillRemainingSpace) {
      this.fillRemainingSpace();
    }
    if (this.menu?.wrappingSwimLaneOverflow() !== WrappingSwimLaneOverflow.Off) {
      this.createSwimLaneSections();
    }
    this.handleLastSection();
    return this.overflowSections?.map(([section]) => section);
  }

  /** This allows for footers to be pushed to the bottom of the page. */
  protected override fillRemainingSpace(): void {
    const remainingSpace = this.pageHeight - this.runningPageHeight - this.emptySectionAdditionalHeight;
    if (remainingSpace > 0) {
      if (this.menu?.pushFooterToBottomOfPage()) {
        const emptySections = this.getEmptySections(this.pageIndex, remainingSpace);
        emptySections?.forEach(([height, emptySection]) => {
          const dimensions = new SectionDimensions(0, 0, height);
          this.overflowSections.push([emptySection, dimensions]);
        });
      }
    }
  }

  protected override calculatePageHeight(): void {
    let pageHeight = this.canvasHeight;
    const firstPageColumnIndices = this.menu?.getNWrappingOverflowSwimlanes();
    const onFirstPage = this.pageIndex < firstPageColumnIndices;
    if (onFirstPage) {
      const headerOnFirstPage = this.headerLayoutType !== PrintHeaderLayoutType.None && this.menu.getShowHeader();
      const footerOnFirstPage = this.footerLayoutType !== PrintFooterLayoutType.None && this.menu.getShowFooter();
      if (headerOnFirstPage) pageHeight -= this.headerHeight;
      if (!headerOnFirstPage) pageHeight -= this.emptyHeaderReplacementHeight;
      if (footerOnFirstPage) pageHeight -= this.footerHeight;
      if (!footerOnFirstPage) pageHeight -= this.emptyFooterReplacementHeight;
    } else {
      const headerOnPage = this.headerLayoutType === PrintHeaderLayoutType.AllPages && this.menu.getShowHeader();
      const footerOnPage = this.footerLayoutType !== PrintFooterLayoutType.None && this.menu.getShowFooter();
      if (headerOnPage) pageHeight -= this.headerHeight;
      if (!headerOnPage) pageHeight -= this.emptyHeaderReplacementHeight;
      if (footerOnPage) pageHeight -= this.footerHeight;
      if (!footerOnPage) pageHeight -= this.emptyFooterReplacementHeight;
    }
    this.pageHeight = pageHeight;
  }

  protected override calculateSectionPlacementAtPageCursorPosition(
    sectionComponent: MenuSectionComponent,
    sectionHeight: SectionDimensions
  ): void {
    if (this.stopSectionLayoutForScreenshotMode()) {
      return;
    }
    const productSection = sectionComponent instanceof ProductSectionComponent;
    const sativaHybridIndicaSection = sectionComponent instanceof SativaHybridIndicaSplitProductSectionComponent;
    if (productSection || sativaHybridIndicaSection) this.currentProductComponent = sectionComponent;
    if (this.shouldForceOntoNewPage(sectionComponent?.section)) {
      this.moveCursorToNewPage();
    }
    // Accounts for expanded and collapsed section headers
    let fitsOnPageHeight = sectionHeight;
    if (sectionComponent instanceof SativaHybridIndicaSplitProductSectionComponent) {
      if (!this.shouldExpandSectionHeader()) {
        const expandedHeaderHeight = sectionComponent.getExpandedHeaderHeight();
        const collapsedHeaderHeight = sectionComponent.getCollapsedHeaderHeight();
        const actualHeight = sectionHeight?.getHeightWithoutMargins()
          - expandedHeaderHeight + collapsedHeaderHeight;
        fitsOnPageHeight = sectionHeight.copyWith(actualHeight);
      }
    }
    if (sectionComponent instanceof PageBreakSectionComponent) {
      if (this.menu?.getPrintFooterLayoutType() !== PrintFooterLayoutType.AllPagesFixed) {
        const remainingSpace = this.pageHeight - this.runningPageHeight;
        const [lastAddedSection, ] = this.overflowSections?.last() || [null, null];
        const lastAddedSectionIsPageBreak = lastAddedSection instanceof PageBreakSection;
        const lastAddedSectionIsEmptySection = lastAddedSection instanceof EmptySection;
        if (!lastAddedSection || lastAddedSectionIsPageBreak || lastAddedSectionIsEmptySection) {
          const emptySections = this.getEmptySections(this.pageIndex, remainingSpace);
          const [, first] = emptySections?.firstOrNull() || [null, null];
          if (exists(first)) first.firstOnPage = true;
          const [, last] = emptySections?.last() || [null, null];
          if (exists(last)) last.lastOnPage = true;
          emptySections?.forEach(([height, emptySection]) => {
            if (!lastAddedSection) emptySection.pageIndex = 0;
            const dimensions = new SectionDimensions(0, 0, height);
            this.overflowSections.push([emptySection, dimensions]);
          });
        } else {
          const pageBreakSection = new PageBreakSection(this.pageIndex, remainingSpace);
          const dimensions = new SectionDimensions(0, 0, 0);
          this.overflowSections.push([pageBreakSection, dimensions]);
        }
      }
      this.moveCursorToNewPage();
    } else if (sectionHeight?.getHeightWithoutMargins() <= 0) {
      return;
    } else if (this.sectionFitsOntoPageAtCurrentPosition(sectionComponent, fitsOnPageHeight)) {
      this.sectionFitsOnPage(sectionComponent?.section, fitsOnPageHeight);
    } else {
      this.sectionDidNotFitAtCurrentPosition(sectionComponent, sectionHeight);
    }
  }

  protected override stopSectionLayoutForScreenshotMode(): boolean {
    if (this.screenshotMode && this.menu?.wrappingOverflowSwimlanesEnabled()) {
      const nLanesLaidOut = this.pageIndex + 1;
      return nLanesLaidOut > this.menu?.getNWrappingOverflowSwimlanes();
    } else {
      return super.stopSectionLayoutForScreenshotMode();
    }
  }

  shouldForceOntoNewPage(section: Section): boolean {
    if (this.runningPageHeight !== 0) {
      const [lastOverflowedSection, ] = this.overflowSections?.last() || [null, null];
      const productSection = section instanceof ProductSection;
      const dontForce = !lastOverflowedSection && productSection;
      if (dontForce) {
        return false;
      }
      // Only applied to print menus, and never applies to very first section
      const forceSection = this.menu.menuOptions?.sectionPageBreak;
      const forceTitleSection = this.menu.menuOptions?.titleSectionPageBreak;
      const isTitleSection = section?.sectionType === SectionType.Title;
      if (forceSection || forceTitleSection) {
        // check previous section and return false if it's a title section
        if (lastOverflowedSection?.sectionType === SectionType.Title) {
          return false;
        }
      }
      return forceSection || (forceTitleSection && isTitleSection);
    }
    return false;
  }

  protected createSwimLaneSections(): void {
    const nSwimlanes = this.menu.getNWrappingOverflowSwimlanes();
    const [lastSection, ] = this.overflowSections?.last() || [null, null];
    const nColumns = lastSection?.pageIndex + 1;
    const nSwimlaneSections = Math.ceil(nColumns / nSwimlanes) || 0;
    this.overflowSections = Array.from(Array(nSwimlaneSections)).map((_, i) => {
      const swimLaneIndexOffset = i * nSwimlanes;
      const swimlaneContent = Array.from(Array(nSwimlanes)).map((val, nthLane) => {
        const swimlaneIndex = swimLaneIndexOffset + nthLane;
        return this.overflowSections
          ?.filter(([section, ]) => section?.pageIndex === swimlaneIndex)
          ?.map(([section, ]) => section) || [];
      });
      return [
        new WrappingSwimlaneSection(nSwimlanes, swimlaneContent, i),
        null
      ] as [WrappingSwimlaneSection, SectionDimensions];
    });
  }

  /**
   * Print menus use .sections-container { display: block },
   * which means we have to account for collapsing margins between sections.
   * See "Mastering Margin Collapsing" for more info:
   * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
   */
  override getSectionCombinedMargins(sectionDimensions: SectionDimensions): number {
    let margins: number;
    if (this.accountForCollapsingMarginIfNoHeaderAndAtTopOfPage()) {
      margins = sectionDimensions?.bottomMargin;
    } else if (this.runningPageHeight !== 0 && exists(this.overflowSections?.last())) {
      const [, aboveMeDimensions] = this.overflowSections?.last() || [null, null];
      margins = sectionDimensions?.getMarginsAccountingForCollapsedMargin(aboveMeDimensions);
    } else {
      margins = sectionDimensions?.getTotalMarginHeight();
    }
    return margins ?? 0;
  }

}
