<div
  #headerContainer
  class="header-container"
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <img
    appImage
    *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'"
    class="company-logo block"
    [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
    [style.margin]="menu | productMenuBasicMethod : 'getHeaderLogoMargin'"
    [asset]="menu?.companyLogo"
    [reset]="reset"
    alt="company logo"
    src="" />
</div>
