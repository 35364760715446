import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'getPaddingForDisplayContainerFromMenu'
})
export class GetPaddingForDisplayContainerFromMenuPipe implements PipeTransform {

  transform(menu: Menu): string {
    return menu?.getDisplayPadding();
  }

}
