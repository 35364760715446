<div class="menu-section-overflow-calculator" #sectionOverflowContainer>
  <app-spotlight-section-inflator
    *ngFor="let s of menu.sections; index as i; last as last"
    [reset]="reset"
    [style.width.%]="menu?.getSectionWidthPercentage()"
    [locationId]="locationId"
    [menu]="menu"
    [index]="i"
    [last]="last"
    [section]="s"
    [ngStyle]="{ 'max-height': menu?.getShouldOverflowHorizontallyElseVertically() ? '100%' : null }">
    Section
  </app-spotlight-section-inflator>
</div>
