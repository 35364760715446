import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../models/menu/section/section';
import { EmptySection } from '../../../../../models/menu/section/empty-section';

@Pipe({
  name: 'isEmptySection'
})
export class IsEmptySectionPipe implements PipeTransform {

  transform(value: Section): boolean {
    return value instanceof EmptySection;
  }

}
