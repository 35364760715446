import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../../domain/company-domain-model';
import { LabelDomainModel } from '../../../../../domain/label-domain-model';
import { LocationDomainModel } from '../../../../../domain/location-domain-model';
import { VariantAssetService } from '../../../../services/variant-asset-service';
import { InjectorService } from '../../../../services/injector.service';
import { Label } from '../../../../../models/menu/labels/label';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { Menu } from '../../../../../models/menu/menu';
import { exists } from '../../../../../functions/exists';
import { DistinctUtils } from '../../../../../utils/distinct.utils';
import { PrintMenu } from '../../../../../models/menu/print-menu';
import { document } from 'ngx-bootstrap/utils';

@Injectable()
export class RenderPrintMenuLiveViewViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private variantAssetService: VariantAssetService,
    private injectorService: InjectorService
  ) {
    super();
  }

  public readonly companyConfig$ = this.companyDomainModel.companyConfig$;
  connectToCompanyConfig = (companyConfigData: any|null) => {
    const companyConfig = this.injectorService?.Deserialize?.instanceOf(CompanyConfiguration, companyConfigData);
    this.companyDomainModel.companyConfigSentFromDashboardApp(companyConfig);
  };

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  connectToLocationConfig = (locationConfigData: any|null) => {
    const locationConfig = this.injectorService?.Deserialize?.instanceOf(LocationConfiguration, locationConfigData);
    this.locationDomainModel.locationConfigSentFromDashboardApp(locationConfig);
  };

  connectToLabels = (labelsData: any|null) => {
    const labels = this.injectorService?.Deserialize?.arrayOf(Label, labelsData);
    this.labelDomainModel.labelDataSentFromDashboardApp(labels);
  };

  public readonly locationId$ = this.locationConfig$.pipe(map(config => config?.locationId));

  private readonly _printMenu = new BehaviorSubject<PrintMenu|null>(null);
  public readonly printMenu$ = combineLatest([
    this._printMenu,
    this.companyConfig$,
    this.locationConfig$
  ]).pipe(
    map(([menu, companyConfig, locationConfig]) => {
      menu?.setImplicitProperties(companyConfig, locationConfig);
      document.documentElement.className = menu?.getMenuClass();
      return menu;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToPrintMenu = (printMenuData: any|null) => {
    const menu = this.injectorService?.Deserialize?.instanceOf(Menu, printMenuData);
    const printMenu = menu instanceof PrintMenu ? menu : null;
    this._printMenu.next(printMenu);
  };

  private readonly _nPages = new BehaviorSubject<number>(0);
  public readonly nPages$ = this._nPages.pipe(distinctUntilChanged());
  connectToNPages = (nPages: number) => this._nPages.next(nPages);

  private readonly _pageIndex = new BehaviorSubject<number>(0);
  public readonly pageIndex$ = this._pageIndex.pipe(distinctUntilChanged());
  connectToPageNumber = (pageIndex: number) => this._pageIndex.next(pageIndex);

  public readonly pageContentAreaHeightInPx$ = this.printMenu$.pipe(
    map(menu => menu?.pageContentAreaHeightInPx()),
    distinctUntilChanged()
  );

  public readonly pageContentAreaWidthInPx$ = this.printMenu$.pipe(
    map(menu => menu?.pageContentAreaWidthInPx()),
    distinctUntilChanged()
  );

  private validOrigin(event: MessageEvent): boolean {
    const origin = event?.origin?.toLowerCase();
    return origin?.includes('localhost') || origin?.includes('mybudsense.com');
  }

  /* *************************** Local Threads of Execution *************************** */

  private listenToMenuDataMessage = fromEvent(window, 'message').pipe(
    filter((event: MessageEvent) => {
      const hasData = event?.data?.type === 'menu';
      return this.validOrigin(event) && hasData;
    }),
    map((event: MessageEvent) => event?.data)
  ).subscribeWhileAlive({
    owner: this,
    next: (data: any) => {
      this.connectToCompanyConfig(data?.companyConfig);
      this.connectToLocationConfig(data?.locationConfig);
      this.connectToLabels(data?.labels);
      this.connectToPrintMenu(data?.printMenu);
    }
  });

  private listenToPageSelectionMessage = fromEvent(window, 'message').pipe(
    filter((event: MessageEvent) => {
      const hasData = event?.data?.type === 'page';
      return this.validOrigin(event) && hasData;
    }),
    map((event: MessageEvent) => event?.data)
  ).subscribeWhileAlive({
    owner: this,
    next: (data: any) => {
      if (Number.isFinite(data?.pageIndex)) this.connectToPageNumber(data?.pageIndex);
    }
  });

  private sendPageInfoToDashboard = combineLatest([
    this.pageContentAreaHeightInPx$.notNull(),
    this.pageContentAreaWidthInPx$.notNull(),
    this.nPages$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([menuHeight, menuWidth, nPages]) => {
      const msg = {
        type: 'page',
        menuHeight,
        menuWidth,
        nPages
      };
      window?.parent?.postMessage(msg, '*');
    }
  });

  private connectWithVariantAssetService = this.printMenu$.pipe(
    filter(exists),
    distinctUntilChanged(DistinctUtils.distinctMenuById)
  ).subscribeWhileAlive({
    owner: this,
    next: (printMenu) => {
      this.variantAssetService.fetchVariantAssetsForMenusAssignedToDisplay([printMenu]);
    }
  });

}
