import { Component, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { MarketingMenu } from '../../../../../../../models/menu/marketing-menu';
import { CompanyConfiguration } from '../../../../../../../models/company/dto/company-configuration';
import { MarketingLoopMenu } from '../../../../../../../models/menu/marketing/marketing-loop-menu';
import { MarketingLoopComponent } from '../../marketing-loop/marketing-loop.component';
import { MarketingFeaturedProductMenuComponent } from '../../marketing-featured-product-menu/marketing-featured-product-menu.component';
import { MarketingFeaturedProductMenu } from '../../../../../../../models/menu/marketing/FeaturedProduct/marketing-featured-product-menu';
import { EmptyMarketingMenuComponent } from '../../empty-marketing-menu/empty-marketing-menu.component';
import { MarketingComboMenu } from '../../../../../../../models/menu/marketing/marketing-combo-menu';
import { MarketingComboMenuComponent } from '../../marketing-combo-menu/marketing-combo-menu.component';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import { MarketingSmartPlaylistMenu } from '../../../../../../../models/menu/marketing/marketing-smart-playlist-menu';
import { MarketingSmartPlaylistMenuComponent } from '../../marketing-product-linked-looping-menu/marketing-smart-playlist-menu.component';
import { InflatorComponent } from '../../../../../../inflator/inflator.component';
import { MarketingMenuInterface } from './marketing-menu.interface';
import { MarketingUrlPlaylistMenu } from '../../../../../../../models/menu/marketing/marketing-url-playlist-menu';
import { MarketingUrlPlaylistMenuComponent } from '../../marketing-url-playlist-menu/marketing-url-playlist-menu.component';

@Component({
  selector: 'app-marketing-menu-inflator',
  templateUrl: './marketing-menu-inflator.component.html',
})
export class MarketingMenuInflatorComponent extends InflatorComponent implements MarketingMenuInterface, OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() menu: MarketingMenu;
  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() reset: boolean;
  @Input() backOfMenuFlipper: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) {
      this.connectToComponentType(this.getMenuType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  protected getMenuType(): Type<MarketingMenuInterface> {
    let type: Type<MarketingMenuInterface>;
    if (this.menu.isEmpty()) {
      type = EmptyMarketingMenuComponent;
    } else if (this.menu instanceof MarketingSmartPlaylistMenu) {
      type = MarketingSmartPlaylistMenuComponent;
    } else if (this.menu instanceof MarketingLoopMenu) {
      type = MarketingLoopComponent;
    } else if (this.menu instanceof MarketingFeaturedProductMenu) {
      type = MarketingFeaturedProductMenuComponent;
    } else if (this.menu instanceof MarketingComboMenu) {
      type = MarketingComboMenuComponent;
    } else if (this.menu instanceof MarketingUrlPlaylistMenu) {
      type = MarketingUrlPlaylistMenuComponent;
    }
    return type;
  }

}
