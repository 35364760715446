import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { ProductSectionComponent } from '../../../../product-menu/building-blocks/menu-section/product-section/product-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SpotlightProductSectionViewModel } from './spotlight-product-section-view-model';
import { SpotlightMenu } from '../../../../../../../../models/menu/spotlight-menu';
import { SpotlightSection } from '../../../../../../../../models/menu/section/spotlight-section';

@Component({
  selector: 'app-spotlight-product-section',
  templateUrl: './spotlight-product-section.component.html',
  providers: [
    SpotlightProductSectionViewModel,
    { provide: ProductSectionComponent, useExisting: forwardRef(() => SpotlightProductSectionComponent) }
  ],
})
export class SpotlightProductSectionComponent extends ProductSectionComponent {

  constructor(
    public viewModel: SpotlightProductSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

  @Input() menu: SpotlightMenu;
  @Input() section: SpotlightSection;

}
