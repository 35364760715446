import { Component, ElementRef, Input, OnChanges, OnDestroy, SecurityContext, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { debounceTime, map } from 'rxjs/operators';
import { ResizeObserver } from '@juggle/resize-observer';
import { AssetSize } from '../../../../../../../models/enum/dto/asset-size.enum';
import { CachePolicy } from '../../../../../../../models/enum/shared/cachable-image-policy.enum';
import { DateUtils } from '../../../../../../../utils/date-utils';
import { HtmlUtils } from '../../../../../../../utils/html-utils';
import { ScrollableItemComponent } from '../../../scrollable-sections/scrollable-item.component';
import { SectionDimensions } from './section-dimensions';
import type { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import type { ProductMenu } from '../../../../../../../models/menu/product-menu';
import type { Section } from '../../../../../../../models/menu/section/section';
import { exists } from '../../../../../../../functions/exists';

@Component({ selector: 'app-menu-section', template: '' })
export abstract class MenuSectionComponent extends ScrollableItemComponent implements OnChanges, OnDestroy {

  protected constructor(
    public sanitizer: DomSanitizer,
    public element: ElementRef
  ) {
    super(element);
  }

  @Input() calculationMode: boolean = false;
  @Input() signalPagination: any;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: ProductMenu;
  @Input() section: Section;
  @Input() index: number;
  @Input() last: boolean = false;
  @Input() reset: boolean;
  @Input() backOfMenuFlipper: boolean;
  public asset: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);

  @ViewChild('sectionContainer') sectionContainer: ElementRef;
  private ro: ResizeObserver;
  private sectionDimensionsObserver: Subscription;
  private _sectionAndHeight = new BehaviorSubject<[MenuSectionComponent, SectionDimensions]>([null, null]);
  public sectionAndHeight$ = this._sectionAndHeight.pipe(debounceTime(10));

  protected static getSectionContainerHeightWithoutMargins(nativeElement: any): number {
    return HtmlUtils.getElementHeightWithoutMargins(nativeElement);
  }

  trackBySectionUniqueIdentifier = (index: number, section: Section): string => {
    return section?.getUniqueIdentifier();
  };

  getComponentAndHeight(): Observable<[MenuSectionComponent, SectionDimensions]> {
    return this.sectionAndHeight$;
  }

  setupViews() {
  }

  setupBindings() {
    const observable$ = this?.section?.image?.sizePriorityUrl$;
    if (observable$) {
      this.fetchAsset();
      const s = this.asset.bind(
        observable$.pipe(
          map(url => {
            if (url && url !== '' && this.section.image.isImage()) {
              const imgUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
              return `url(${imgUrl})`;
            } else {
              return null;
            }
          })
        )
      );
      this.pushSub(s);
    }
    this.observeSectionContainer();
  }

  observeSectionContainer() {
    this.ro = new ResizeObserver(() => {
      // Wait for resize observer to fire, because this means that the section container
      // is inflated in the dom. Therefore, getComputedStyle will return a hydrated object.
      this.sectionDimensionsObserver?.unsubscribe();
      this.sectionDimensionsObserver = this.getSectionDimensions().subscribe(dimensions => {
        this._sectionAndHeight.next([this, dimensions]);
      });
    });
    if (exists(this.sectionContainer)) this.ro.observe(this.sectionContainer.nativeElement);
  }

  protected getSectionDimensions(): Observable<SectionDimensions> {
    const dimensions = new SectionDimensions(
      HtmlUtils.getElementTopMargin(this.sectionContainer?.nativeElement),
      HtmlUtils.getElementBottomMargin(this.sectionContainer?.nativeElement),
      MenuSectionComponent.getSectionContainerHeightWithoutMargins(this.sectionContainer?.nativeElement)
    );
    return of(dimensions);
  }

  private fetchAsset() {
    const asset = this.asset;
    const size = AssetSize.Original;
    const cacheForNSeconds = (DateUtils.unixOneHour() * 12);
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (exists(asset) && exists(size) && validCacheTime) {
      const policy = CachePolicy.Service;
      this?.section?.image?.getAsset(policy, size, cacheForNSeconds);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.ro?.disconnect();
    this.sectionDimensionsObserver?.unsubscribe();
  }

}
