import { animate, state, style, transition, trigger } from '@angular/animations';
import { OverflowState } from '../../../../../../../models/enum/shared/overflow-transition-state.enum';

const pagingSpeed = '333ms';
const transitionSpeed = '333ms';
export const featuredCategoryCardSectionAnimations = trigger(
  'featuredCategoryCardSectionAnimations',
  [
    state(OverflowState.SCROLL, style({})),
    state(OverflowState.PAGING, style({})),
    transition(
      `void => ${OverflowState.SCROLL}`,
      [
        style({ transform: 'translateX(100%)' }),
        animate(pagingSpeed, style({ transform: 'translateX(0)' }))
      ]
    ),
    transition(
      `${OverflowState.SCROLL} => void`,
      [
        style({ transform: 'translateX(0)' }),
        animate(pagingSpeed, style({ transform: 'translateX(-100%)' }))
      ]
    ),
    transition(
      `void => ${OverflowState.PAGING}`,
      [
        style({ opacity: 0 }),
        animate(transitionSpeed, style({ opacity: 1 }))
      ]
    ),
    transition(
      `${OverflowState.PAGING} => void`,
      [
        style({ opacity: 1 }),
        animate(transitionSpeed, style({ opacity: 0 }))
      ]
    )
  ]
);
