<div
  #sectionContainer
  class="empty-container sativa-hybrid-indica-section-container"
  [style.display]="'flex'"
  [style.height.px]="section?.heightInPx">
  <!-- Sativa Product Items -->
  <div class="sativa-products"></div>
  <!-- Hybrid Product Items -->
  <div class="hybrid-products"></div>
  <!-- Indica Product Items -->
  <div class="indica-products"></div>
</div>
