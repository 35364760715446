<div
  *ngIf="viewModel.printMenu$ | async | isPrintMenu as printMenu"
  [style.margin-top]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageTopMargin'"
  [style.margin-right]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageRightMargin'"
  [style.margin-bottom]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageBottomMargin'"
  [style.margin-left]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageLeftMargin'">
  <div [style.position]="'relative'">
    <app-background-asset
      class="menu-background-asset"
      [top]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageTopMargin'"
      [right]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageRightMargin'"
      [bottom]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageBottomMargin'"
      [left]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageLeftMargin'"
      [defaultImageUrl]="printMenu | productMenuBasicMethod : 'getDefaultBackgroundImageUrl'"
      [defaultVideoUrl]="printMenu | productMenuBasicMethod : 'getDefaultBackgroundVideoUrl'"
      [menu]="printMenu"
      [scaleFit]="false"
      [loaded]="true">
    </app-background-asset>
    <div
      class="fixed-print-wrapper"
      [ngClass]="(printMenu | castToProductMenu)?.getThemeClass()"
      [style.margin-top]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageTopMargin'"
      [style.margin-bottom]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageBottomMargin'"
      [style.margin-left]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageLeftMargin'"
      [style.margin-right]="viewModel.printMenu$ | async | productMenuBasicMethod : 'getPageRightMargin'"></div>
    <app-print-menu-inflator
      [menu]="printMenu"
      [locationId]="viewModel.locationId$ | async"
      [companyConfig]="viewModel.companyConfig$ | async"
      [locationConfig]="viewModel.locationConfig$ | async"
      [pageIndex]="viewModel.pageIndex$ | async"
      [reset]="false"
      (nPages)="viewModel.connectToNPages($event)">
    </app-print-menu-inflator>
  </div>
</div>
