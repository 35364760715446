import { Component, ElementRef, Input } from '@angular/core';
import { UniquelyIdentifiable } from '../../../../../models/protocols/uniquely-identifiable';
import type { Section } from '../../../../../models/menu/section/section';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';

@Component({ selector: 'app-scrollable-item', template: '' })
export abstract class ScrollableItemComponent extends RenderContextComponent implements UniquelyIdentifiable {

  @Input() section: Section;

  constructor(
    public element: ElementRef
  ) {
    super();
  }

  abstract getUniqueIdentifier(...opts: any): string;

}
