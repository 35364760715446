import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MarketingMenuComponent } from '../marketing-menu.component';
import { OrientationService } from '../../../../../../services/orientation.service';
import { MarketingComboViewModel } from './marketing-combo-view-model';
import { MarketingComboMenu } from '../../../../../../models/menu/marketing/marketing-combo-menu';
import { SyncSectionTransitionsService } from '../../services/sync-section-transitions.service';
import { RenderContextService } from '../../../../../services/render-context.service';

@Component({
  selector: 'app-marketing-combo-menu',
  templateUrl: './marketing-combo-menu.component.html',
  styleUrls: ['./marketing-combo-menu.component.scss'],
  providers: [MarketingComboViewModel, SyncSectionTransitionsService, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingComboMenuComponent extends MarketingMenuComponent {

  public isPortrait$ = this.orientationService.isPortrait$;

  @Input() public menu: MarketingComboMenu = null;

  constructor(
    public vm: MarketingComboViewModel,
    private orientationService: OrientationService
  ) {
    super(vm);
  }

}
