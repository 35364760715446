/* src/app/modules/display/components/menus/menu/menu.component.scss */
.menu-default-white-background {
  position: fixed;
  background-color: white;
  z-index: -2;
  height: 100%;
  width: 100%;
}
.rotate90 .menu-default-white-background,
.rotate270 .menu-default-white-background {
  height: 100vw;
  width: 100vh;
}
/*# sourceMappingURL=menu.component.css.map */
