import './utils/array.extensions';
import './utils/map.extensions';
import './utils/number.extensions';
import './utils/behavior-subject.extensions';
import './utils/replay-subject.extensions';
import './utils/observable.extensions';
import './utils/string.extensions';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { DisplayRoutingModule } from './modules/display/display-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { DisplayModule } from './modules/display/display.module';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ApplicationSourceInterceptor } from './services/interceptors/application-source-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrintCardsModule } from './modules/print-cards/print-cards.module';

@NgModule({
  // Components
  declarations: [
    AppComponent
  ],
  // What modules do I need to function
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DisplayModule,
    DisplayRoutingModule,
    AppRoutingModule,
    PrintCardsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 30000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 5,
      toastClass: 'bs-toast ngx-toastr'
    })
  ],
  // What do I as a module provide for injection
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationSourceInterceptor,
      multi: true
    }
  ],
  /* The component that angular should load, when this Angular Module loads.
     The component must be part of this module.
   */
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
