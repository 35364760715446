import { SativaHybridIndicaSplitProductSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/sativa-hybrid-indica-split-product-section/sativa-hybrid-indica-split-product-section.component';
import { Section } from '../models/menu/section/section';
import { SectionDimensions } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/section-dimensions';
import { ProductSection } from '../models/menu/section/product-section';
import { ProductMenu } from '../models/menu/product-menu';
import { DoubleDutchEmptySection } from '../models/menu/section/double-dutch-empty-section';
import { PlantlifeNonSmokableEmptySection } from '../models/menu/section/plantlife-non-smokable-empty-section';

// @dynamic
export class EmptySectionUtils {

  public static handleSativaHybridIndicaSplitProductEmptySection = (
    menu: ProductMenu,
    firstOnPage: boolean,
    pageIndex: number,
    remainingSpaceInPx: number,
    leaveNPixelsAtBottom: number,
    component: SativaHybridIndicaSplitProductSectionComponent
  ): [number, Section][] => {
    // Fill empty space with visible items without content inside them
    const [itemComp, itemHeight] = (component?.getSativaItemComponentsWithHeights())?.firstOrNull()
      || (component?.getHybridItemComponentsWithHeights())?.firstOrNull()
      || (component?.getIndicaItemComponentsWithHeights())?.firstOrNull()
      || [null, 0];
    const product = itemComp?.rowViewModel?.product;
    const [leftOverSpace, [height, section]] = component?.section
      ?.getCollapsedCopyWithMaxDuplicationOfVariant(product, itemHeight, remainingSpaceInPx, firstOnPage, pageIndex)
      || [0, [0, null]];
    const leftOverSpaceHeight = leftOverSpace - leaveNPixelsAtBottom;
    const EmptySection = menu?.getEmptySectionType();
    const leftOverSpaceSection = new EmptySection(pageIndex, leftOverSpaceHeight, firstOnPage);
    const emptyGrid = [height, section] as [number, Section];
    const empty = [leftOverSpaceHeight, leftOverSpaceSection] as [number, Section];
    return [emptyGrid, empty];
  };

  public static handleDoubleDutchEmptySection = (
    menu: ProductMenu,
    firstOnPage: boolean,
    pageIndex: number,
    remainingSpaceInPx: number,
    leaveNPixelsAtBottom: number,
    overflowSections: [Section, SectionDimensions][]
  ): [number, Section][] => {
    const heightInPixels = remainingSpaceInPx - leaveNPixelsAtBottom;
    const EmptySection = menu?.getEmptySectionType();
    const emptySection = new EmptySection(pageIndex, heightInPixels, firstOnPage);
    const productSections = overflowSections
      ?.flatten<[Section, SectionDimensions]>()
      ?.filter(items => items instanceof ProductSection) as ProductSection[];
    if (emptySection instanceof DoubleDutchEmptySection) {
      if (productSections?.length === 1) {
        const productSection = productSections?.firstOrNull();
        emptySection.borderColor = productSection?.metadata?.productsHeaderBackgroundColor;
        emptySection.backgroundColor = productSection?.metadata?.productsContainerBackgroundColor;
      } else {
        const lastSection = productSections.last();
        emptySection.borderColor = lastSection?.metadata?.productsHeaderBackgroundColor;
        emptySection.backgroundColor = lastSection?.metadata?.productsContainerBackgroundColor;
      }
    }
    return [[heightInPixels, emptySection]];
  };

  public static handlePlantlifeNonSmokableEmptySection = (
    menu: ProductMenu,
    firstOnPage: boolean,
    pageIndex: number,
    remainingSpaceInPx: number,
    leaveNPixelsAtBottom: number,
    overflowSections: [Section, SectionDimensions][]
  ): [number, Section][] => {
    const heightInPixels = remainingSpaceInPx - leaveNPixelsAtBottom;
    const EmptySection = menu?.getEmptySectionType();
    const emptySection = new EmptySection(pageIndex, heightInPixels, firstOnPage);
    const productSections = overflowSections
      ?.flatten<[Section, SectionDimensions]>()
      ?.filter(items => items instanceof ProductSection) as ProductSection[];
    if (emptySection instanceof PlantlifeNonSmokableEmptySection) {
      if (productSections?.length === 1) {
        const productSection = productSections?.firstOrNull();
        emptySection.backgroundColor = productSection?.metadata?.productsContainerBackgroundColor;
      } else {
        const lastSection = productSections.last();
        emptySection.backgroundColor = lastSection?.metadata?.productsContainerBackgroundColor;
      }
    }
    return [[heightInPixels, emptySection]];
  };

  public static handlePlantlifeEmptySection = (
    menu: ProductMenu,
    firstOnPage: boolean,
    pageIndex: number,
    remainingSpaceInPx: number,
    leaveNPixelsAtBottom: number
  ): [number, Section][] => {
    const heightInPixels = remainingSpaceInPx - leaveNPixelsAtBottom;
    const EmptySection = menu?.getEmptySectionType();
    const emptySection = new EmptySection(pageIndex, heightInPixels, firstOnPage);
    return [[heightInPixels, emptySection]];
  };

}
