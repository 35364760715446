import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ProductSectionViewModel } from '../product-section-view-model';
import { StrainType } from '../../../../../../../../../models/enum/dto/strain-classification.enum';
import { SativaHybridIndicaCols } from './sativa-hybrid-indica-cols';
import { SativaHybridIndicaSectionRowViewModel } from './sativa-hybrid-indica-section-row-view-model';
import type { SectionWithProducts } from '../../../../../../../../../models/menu/section/section-with-products';
import { SectionRowViewModel } from '../section-row-view-models/SectionRowViewModel';
import { CollapsedSativaHybridIndicaSectionViewModel } from './collapsed-sativa-hybrid-indica-section-view-model';
import { SectionColumnConfigDataValue } from '../../../../../../../../../models/menu/section/section-column-config';
import { SortSectionRowViewModelUtils } from '../../../../../../../../../utils/sort-section-row-view-model-utils';

@Injectable()
export class SativaHybridIndicaSplitProductSectionViewModel extends ProductSectionViewModel {

  public splitSectionRowViewModels$ = combineLatest([
    this.sectionRowViewModels$,
    this.calculationMode$
  ]).pipe(
    map(([vms, calculationMode]) => {
      const castedVMS = vms as SativaHybridIndicaSectionRowViewModel[];
      return this.getColumnData(castedVMS, calculationMode);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly calculateColumns$ = combineLatest([
    this.section$,
    this.splitSectionRowViewModels$,
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public sativaColRowViewModels$ = this.calculateColumns$.pipe(
    map(([section, rows]) => this.sortRowVMs(section, rows?.sativa)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public hybridColRowViewModels$ = this.calculateColumns$.pipe(
    map(([section, rows]) => this.sortRowVMs(section, rows?.hybrid)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public indicaColRowViewModels$ = this.calculateColumns$.pipe(
    map(([section, rows]) => this.sortRowVMs(section, rows?.indica)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public longestListLength$ = combineLatest([
    this.sativaColRowViewModels$,
    this.hybridColRowViewModels$,
    this.indicaColRowViewModels$,
  ]).pipe(
    map(data => this.getLongestListLength(data)),
  );

  public sativaSectionIsLongestIndex$ = combineLatest([
    this.sativaColRowViewModels$,
    this.longestListLength$
  ]).pipe(
    map(data => this.isLongestLength(data))
  );

  public hybridSectionIsLongestIndex$ = combineLatest([
    this.hybridColRowViewModels$,
    this.longestListLength$
  ]).pipe(
    map(data => this.isLongestLength(data))
  );

  public indicaSectionIsLongestIndex$ = combineLatest([
    this.indicaColRowViewModels$,
    this.longestListLength$
  ]).pipe(
    map(data => this.isLongestLength(data))
  );

  sativas = (vm: SativaHybridIndicaSectionRowViewModel) => {
    return vm?.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeWord) === StrainType.Sativa;
  };

  hybrids = (vm: SativaHybridIndicaSectionRowViewModel) => {
    return vm?.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeWord) === StrainType.Hybrid;
  };

  indicas = (vm: SativaHybridIndicaSectionRowViewModel) => {
    return vm?.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeWord) === StrainType.Indica;
  };

  adjustedSativas = (vm: SativaHybridIndicaSectionRowViewModel) => {
    const isSativa = vm?.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeWord) === StrainType.Sativa;
    const moveToCenterColumn = vm?.rowVariants?.some(v => v?.moveToCenterColumn && !vm.section?.collapseContent);
    return isSativa && !moveToCenterColumn;
  };

  adjustedHybrids = (vm: SativaHybridIndicaSectionRowViewModel) => {
    const isHyrid = vm?.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeWord) === StrainType.Hybrid;
    const moveToCenterColumn = vm?.rowVariants?.some(v => v?.moveToCenterColumn && !vm.section?.collapseContent);
    return isHyrid || moveToCenterColumn;
  };

  adjustedIndicas = (vm: SativaHybridIndicaSectionRowViewModel) => {
    const isIndica = vm.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeWord) === StrainType.Indica;
    const moveToCenterColumn = vm?.rowVariants?.some(v => v?.moveToCenterColumn && !vm.section?.collapseContent);
    return isIndica && !moveToCenterColumn;
  };

  isLongestLength([viewModels, longestIndex]: [SativaHybridIndicaSectionRowViewModel[], number]): boolean {
    return (viewModels?.length ?? 0) === longestIndex;
  }

  getColumnData(vms: SativaHybridIndicaSectionRowViewModel[], calculationMode: boolean): SativaHybridIndicaCols {
    const threshold = vms?.firstOrNull()?.section?.getAutoBalanceThreshold() ?? 0;
    if (calculationMode && threshold > 0) {
      return this.calculatePlantlifeSpecialRowWrapping(vms, threshold);
    }
    return this.getBaseSativaHybridIndicaRows(vms, calculationMode);
  }

  getBaseSativaHybridIndicaRows(
    vms: SativaHybridIndicaSectionRowViewModel[],
    calculationMode: boolean
  ): SativaHybridIndicaCols {
    const sativa = vms?.filter(calculationMode ? this.sativas : this.adjustedSativas) ?? [];
    let hybrid = vms?.filter(calculationMode ? this.hybrids : this.adjustedHybrids) ?? [];
    const indica = vms?.filter(calculationMode ? this.indicas : this.adjustedIndicas) ?? [];
    // Fill Remaining Space In Section With Empty Rows With Visible Gridlines
    const collapse = (vm: SativaHybridIndicaSectionRowViewModel) => {
      vm.collapseContent = true;
      return vm;
    };
    const scopedSativaLength = sativa?.flatMap(v => v?.getScopedVisibleVariantsElseRowVariants())?.length ?? 0;
    const scopedHybridLength = hybrid?.flatMap(v => v?.getScopedVisibleVariantsElseRowVariants())?.length ?? 0;
    const scopedIndicaLength = indica?.flatMap(v => v?.getScopedVisibleVariantsElseRowVariants())?.length ?? 0;
    const largest = Math.max(scopedSativaLength, scopedHybridLength, scopedIndicaLength);
    const sativaExtraLength = largest - scopedSativaLength;
    const hybridExtraLength = largest - scopedHybridLength;
    const indicaExtraLength = largest - scopedIndicaLength;
    const firstVisibleRowViewModel = sativa?.firstOrNull() || hybrid?.firstOrNull() || indica?.firstOrNull();
    const getExtras = (length: number, classification: StrainType) => {
      return Array(length)
        .fill(CollapsedSativaHybridIndicaSectionViewModel.shallowCopy(firstVisibleRowViewModel, classification))
        .map(collapse) as SativaHybridIndicaSectionRowViewModel[];
    };
    // Add sativa extra cells
    const sativaExtras = getExtras(sativaExtraLength, StrainType.Sativa);
    if (sativaExtraLength > 0) sativa.push(...sativaExtras);
    // Add hybrid extra cells - Separate items so the spacers are in between
    const hybridExtras = getExtras(hybridExtraLength, StrainType.Hybrid);
    const hybridItems = hybrid.filter(h => h.rowStrainType() === StrainType.Hybrid);
    const nonHybridItems = hybrid.filter(h => h.rowStrainType() !== StrainType.Hybrid);
    if (hybridExtraLength > 0) hybrid = [...hybridItems, ...hybridExtras, ...nonHybridItems];
    // Add indica extra cells
    const indicaExtras = getExtras(indicaExtraLength, StrainType.Indica);
    if (indicaExtraLength > 0) indica.push(...indicaExtras);
    return new SativaHybridIndicaCols(sativa, hybrid, indica);
  }

  /**
   * @param vms to parse through to create columns of data.
   * @param threshold must be greater than 0 if using this function.
   * The delta value within is distance between the largest column and second-largest column.
   * @returns Sativa Hybrid Indica Column Data.
   */
  calculatePlantlifeSpecialRowWrapping(
    vms: SativaHybridIndicaSectionRowViewModel[],
    threshold: number
  ): SativaHybridIndicaCols {
    const sativa = vms?.filter(this.sativas) ?? [];
    const nSativa = sativa.length;
    let hybrid = vms?.filter(this.hybrids) ?? [];
    const nHybrid = hybrid.length;
    const indica = vms?.filter(this.indicas) ?? [];
    const nIndica = indica.length;
    const largest: number = Math.max(nSativa, nHybrid, nIndica);
    const leftSize: number = nSativa;
    const centerSize: number = nHybrid;
    const rightSize: number = nIndica;
    const largestIsLeft: boolean = leftSize === largest;
    const largestIsCenter: boolean = largest === centerSize;
    const thereIsAnEmptyColumn = nSativa === 0 || nHybrid === 0 && nIndica === 0;
    let delta: number;
    let minSliceAmount = 0;
    let centerSecondLargest = false;
    /**
     * Do nothing in these cases
     * a.                 b.                 c.
     * **** **** ****     **** **** ****     **** **** ****
     * **** **** ****     **** **** ****     **** **** ****
     *      ****          **** ****               **** ****
     *      ****          **** ****               **** ****
     *      ****               ****               ****
     *      ****               ****               ****
     */
    if (largestIsCenter || thereIsAnEmptyColumn) {
      delta = 0;
      // eslint-disable-next-line brace-style
    }
    /**
     * d.                 e.                 f.
     * **** **** ****     **** **** ****     **** **** ****
     * **** **** ****     **** **** ****     **** **** ****
     * ****         ┬     **** ****          ****      ****
     * ****         Δ     ****   ┬           ****        ┬
     * ****         │     ****   Δ           ****        Δ
     * ****         ┴     ****   ┴           ****        ┴
     */
    else if (largestIsLeft) {
      const centerLarger = centerSize - rightSize >= 0;
      if (centerLarger) {
        centerSecondLargest = true;
        delta = Math.abs(leftSize - centerSize); // d || e
      } else {
        minSliceAmount = rightSize;
        delta = Math.abs(leftSize - rightSize);  // f
      }
      // eslint-disable-next-line brace-style
    }
    /**
     *   g.                  h.                  i.
     *   **** **** ****      **** **** ****      **** **** ****
     *   **** **** ****      **** **** ****      **** **** ****
     *   ┬         ****           **** ****      ****      ****
     *   Δ         ****            ┬   ****        ┬       ****
     *   │         ****            Δ   ****        Δ       ****
     *   ┴         ****            ┴   ****        ┴       ****
     */
    else { // largest is Right
      const centerLarger = centerSize - leftSize >= 0;
      if (centerLarger) {
        centerSecondLargest = true;
        delta = Math.abs(rightSize - centerSize); // g || h
      } else {
        minSliceAmount = leftSize;
        delta = Math.abs(rightSize - leftSize);  // i
      }
    }

    /**
     * If delta is greater than threshold, then we need to wrap the data.
     * Move half the size of delta to middle column.
     * If the outside columns are the largest, ensure splice position is not less than the size
     * of the second-largest column.
     * Use explicit bounds if the center is the second-largest column
     */
    const deltaCheck = centerSecondLargest ? delta > threshold : delta >= threshold;
    if (deltaCheck) {
      const centerIndex = centerSize;
      if (largestIsLeft) { // move left column into center
        const nItemsBetweenCenterAndLargestColumn = leftSize - centerSize;
        const keepNItems = Math.ceil(nItemsBetweenCenterAndLargestColumn / 2);
        const startSpliceAt = Math.max((centerIndex + keepNItems), minSliceAmount);
        const splicedItems = sativa.splice(startSpliceAt,);
        splicedItems?.forEach(item => item.rowVariants?.forEach(v => v.moveToCenterColumn = true));
        hybrid = [...hybrid, ...splicedItems];
      } else { // move right column into center
        const nItemsBetweenCenterAndLargestColumn = rightSize - centerSize;
        const keepNItems = Math.ceil(nItemsBetweenCenterAndLargestColumn / 2);
        const startSpliceAt = Math.max((centerIndex + keepNItems), minSliceAmount);
        const splicedItems = indica.splice(startSpliceAt,);
        splicedItems?.forEach(item => item.rowVariants?.forEach(v => v.moveToCenterColumn = true));
        hybrid = [...hybrid, ...splicedItems];
      }
    }
    return new SativaHybridIndicaCols(sativa, hybrid, indica);
  }

  getLongestListLength(
    [sativa, hybrid, indica]: [
      SativaHybridIndicaSectionRowViewModel[],
      SativaHybridIndicaSectionRowViewModel[],
      SativaHybridIndicaSectionRowViewModel[]]
  ): number {
    const sativaIndex = (sativa?.length ?? 0);
    const hybridIndex = (hybrid?.length ?? 0);
    const indicaIndex = (indica?.length ?? 0);
    return Math.max(sativaIndex, hybridIndex, indicaIndex);
  }

  protected sortRowVMs(
    s: SectionWithProducts,
    rowVMs: SectionRowViewModel[]
  ): SectionRowViewModel[] {
    // Sorting
    return rowVMs.sort((a, b) => SortSectionRowViewModelUtils.plantlifeSectionRowSortCompare(s, a, b));
  }

}
