import { Injectable } from '@angular/core';
import { DisplayDomainModel } from '../../../../../domain/display-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ProductMenu } from '../../../../../models/menu/product-menu';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Section } from '../../../../../models/menu/section/section';
import { DisplayMenuCoupling } from '../../../../../couplings/display-menu-coupling.service';
import { OrientationService } from '../../../../../services/orientation.service';
import { MenuWithScrollableSectionsViewModel } from '../scrollable-sections/menu-with-scrollable-sections-view-model';
import { ScalingService } from '../../../../../services/scaling.service';
import { OpacityLocation } from '../../../../../models/shared/opacity-location.enum';
import { IsMenuReadyService } from '../../../../services/is-menu-ready.service';
import { SectionUtils } from '../../../../../utils/section-utils';
import { exists } from '../../../../../functions/exists';

@Injectable()
export class ProductMenuViewModel extends MenuWithScrollableSectionsViewModel {

  constructor(
    dm: DisplayDomainModel,
    displayMenuCoupling: DisplayMenuCoupling,
    isMenuReadyService: IsMenuReadyService,
    orientationService: OrientationService,
    public scalingService: ScalingService,
  ) {
    super(dm, displayMenuCoupling, isMenuReadyService, orientationService);
  }

  public _menu = new BehaviorSubject<ProductMenu>(null);
  public menu$ = this._menu as Observable<ProductMenu>;

  public sections$ = this.distinctNotNullMenu$.pipe(map(menu => {
    return menu?.sections.filter(s => {
      if (SectionUtils.isSectionWithProducts(s)) {
        const enabledVariants = s.variants?.filter(v => s.enabledVariantIds?.contains(v.id));
        return s.showZeroStockItems || enabledVariants?.some(v => v.inStock());
      }
    });
  }));

  private _overflowedSections = new BehaviorSubject<Section[]>([]);
  public overflowedSections$ = this._overflowedSections.asObservable();
  public connectToOverflowedSections = (overflowed: Section[]) => {
    this._overflowedSections.next(overflowed);
  };

  public readonly hasHeader$ = this.menu$.pipe(
    map(m => m?.getShowHeader()),
    distinctUntilChanged()
  );

  public readonly hasFooter$ = this.menu$.pipe(
    map(m => m?.getShowFooter()),
    distinctUntilChanged()
  );

  public readonly hasHeaderAndFooter$ = this.menu$.pipe(
    map(m => m?.getShowHeader() && m?.getShowFooter()),
    distinctUntilChanged()
  );

  public readonly hasNoHeaderAndFooter$ = this.menu$.pipe(
    map(m => !m?.getShowHeader() && !m?.getShowFooter()),
    distinctUntilChanged()
  );

  public readonly hasHeaderXorFooter$ = this.menu$.pipe(
    map(m => (m?.getShowHeader() && !m?.getShowFooter()) || (!m?.getShowHeader() && m?.getShowFooter())),
    distinctUntilChanged()
  );

  public readonly themeClass$ = this.menu$.pipe(
    map(m => m?.getThemeClass()),
    distinctUntilChanged()
  );

  public readonly fontStylesForPreload$ = this.menu$.pipe(
    map(m => m?.getFontStyleSheets())
  );

  public readonly menuWrapperClass$ = this.menu$.pipe(
    map(m => m?.getMenuWrapperClass()),
    distinctUntilChanged()
  );

  public readonly menuClasses$ = combineLatest([
    this.themeClass$,
    this.menuWrapperClass$
  ]).pipe(
    map(([theme, wrapper]) => [theme, wrapper])
  );

  public readonly menuOpacity$ = this.menu$.pipe(
    map(m => {
      if (m?.getOpacityEnabled()) {
        return (m?.getOpacityLocation() === OpacityLocation.ENTIRE_MENU)
          ? (m?.menuOptions?.backgroundOpacity || '1')
          : '1';
      }
      return '1';
    }),
    distinctUntilChanged()
  );

  public readonly sectionsContainerId$ = this.menu$.pipe(
    map(m => `sections-container-${m?.id}`),
    distinctUntilChanged()
  );

  public readonly sectionsTopMargin$ = this.menu$.pipe(
    map(m => m?.menuOptions?.sectionMarginTop),
    distinctUntilChanged()
  );

  public readonly sectionsBottomMargin$ = this.menu$.pipe(
    map(m => m?.menuOptions?.sectionMarginBottom),
    distinctUntilChanged()
  );

  public readonly sectionsWrapperClass$ = this.menu$.pipe(
    map(m => m?.getSectionsWrapperClass()),
    distinctUntilChanged()
  );

  public readonly sectionsScrollClass$ = this.menu$.pipe(
    map(m => m?.getMenuScrollClass()),
    distinctUntilChanged()
  );

  public readonly sectionWidthPercentage$ = this.menu$.pipe(
    map(m => m?.getSectionWidthPercentage()),
    distinctUntilChanged()
  );

  public readonly getSectionsContainerAlignContent$ = this.menu$.pipe(
    map(m => m?.getSectionsContainerAlignContent()),
    distinctUntilChanged()
  );

  public readonly getShowHeaderAsTitleSection$ = this.menu$.pipe(
    map(m => m?.getShowHeaderAsTitleSection()),
    distinctUntilChanged()
  );

  public readonly sectionsBackgroundColor$ = this.menu$.pipe(
    map(m => {
      if (m?.getOpacityLocation() === OpacityLocation.SECTION_CONTAINER) {
        return m?.getBackgroundColorWithOpacity();
      }
      return m?.getSectionsBackgroundColor();
    }),
    distinctUntilChanged()
  );

  public readonly sectionMaxHeight$ = this.menu$.pipe(
    map(m => (m?.getShouldOverflowHorizontallyElseVertically() ? '100%' : null)),
    distinctUntilChanged()
  );

  public readonly needsOverflowCalculatorInAbsolutePosition$ = this.menu$.pipe(
    map(m => m?.needsOverflowCalculatorInAbsolutePosition() ? 'relative' : null),
    distinctUntilChanged()
  );

  public readonly sectionsContainerContentVerticalHeight$ = this.menu$.pipe(
    map(m => m?.getShouldSectionsContainerFlexWrap() ? null : 'fit-content'),
    distinctUntilChanged()
  );

  public readonly sectionsContainerContentHorizontalWidth$ = this.menu$.pipe(
    map(m => m?.getShouldSectionsContainerFlexWrap() ? 'fit-content' : null),
    distinctUntilChanged()
  );

  public readonly getShouldSectionsContainerFlexWrap$ = this.menu$.pipe(
    map(m => m?.getShouldSectionsContainerFlexWrap() ? 'wrap' : null),
    distinctUntilChanged()
  );

  public readonly getSectionsContainerFlexDirection$ = this.menu$.pipe(
    map(m => m?.getSectionsContainerFlexDirection()),
    distinctUntilChanged()
  );

  /**
   * Top Margin (accounting for base rem value)
   */
  public sectionMarginTopRem$ = combineLatest([
    this.scalingService.fontSizeInPixels$,
    this.sectionsTopMargin$,
  ]).pipe(
    map(([fontSizePx, marginTopInPx]) => {
      if (exists(marginTopInPx) && marginTopInPx > 0) {
        return `${marginTopInPx / fontSizePx}rem`;
      }
      return null;
    }),
    distinctUntilChanged()
  );

  /**
   * Bottom Margin (accounting for base rem value)
   */
  public sectionMarginBottomRem$ = combineLatest([
    this.scalingService.fontSizeInPixels$,
    this.sectionsBottomMargin$
  ]).pipe(
    map(([fontSizePx, marginBottomInPx]) => {
      if (exists(marginBottomInPx) && marginBottomInPx > 0) {
        return `${marginBottomInPx / fontSizePx}rem`;
      }
      return null;
    }),
    distinctUntilChanged()
  );

  public readonly productSectionInflatorRenderStrategy$ = this.backOfMenuFlipper$.pipe(
    map(backOfMenuFlipper => backOfMenuFlipper ? 'normal' : 'immediate'),
  );

  trackBySectionId = (index: number, section: Section) => section?.id;

}
