import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { combineLatest, iif, of, Subscription, timer } from 'rxjs';
import { MenuWithScrollableSectionsViewModel } from './menu-with-scrollable-sections-view-model';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { OverflowState } from '../../../../../models/enum/shared/overflow-transition-state.enum';
import { BaseMenuComponent } from '../menu/base-menu.component';
import { exists } from '../../../../../functions/exists';
import { SCROLL_DELAY_SECONDS } from '../../display/display-view-model';
import { Menu } from '../../../../../models/menu/menu';
import { MenuSectionInflatorComponent } from '../menu/inflators/menu-section-inflator-component';

@Component({ selector: 'app-menu-with-scrollable-sections', template: '' })
export abstract class MenuWithScrollableSectionsComponent extends BaseMenuComponent {

  public constructor(
    public viewModel: MenuWithScrollableSectionsViewModel
  ) {
    super(viewModel);
  }

  @ViewChild('sectionsContainer') public sectionsContainer: ElementRef<HTMLDivElement>;
  @ViewChild('sectionsContainerContent') public sectionsContainerContent: ElementRef<HTMLDivElement>;
  @ViewChildren(MenuSectionInflatorComponent) scrollableItems: QueryList<MenuSectionInflatorComponent>;

  private readonly NUMBER_OF_STEADY_SCROLL_INTERVALS = 11;
  private readonly SLOWEST_SCROLL_SPEED_PX_PER_SECOND = 10;
  private readonly FASTEST_SCROLL_SPEED_PIXELS_PER_SECOND = 200;
  private readonly SLOWEST_STATIC_STEADY_SCROLL_DURATION_IN_SECONDS = 80;
  private readonly FASTEST_STATIC_STEADY_SCROLL_DURATION_IN_SECONDS = 20;

  public sectionsContainerResizeObserver: ResizeObserver;
  private noScrollRunwaySub: Subscription;

  private readonly runSteadyScrollPipe$ = combineLatest([
    this.viewModel.reset$.pipe(distinctUntilChanged()),
    this.viewModel.steadyScrolling$,
  ]).pipe(
    map(([reset, steadyScrolling]) => !reset && steadyScrolling),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  setupBindings() {
    super.setupBindings();
    this.observeSectionsContainer();
    this.observeSectionsContainerContentContainer();
    this.listenToReset();
    this.listenForInitialPages();
    this.listenForSectionChanges();
    // wait for slash screen before starting overflow mechanisms
    this.viewModel.showSplashScreen$.pipe(
      filter(show => !show),
      take(1)
    ).subscribe(() => {
      this.bindToPaging();
      this.setSteadyScrollCssVariables();
      this.setVerticalScrollTime();
      this.setHorizontalScrollTime();
      this.setSteadyScrollDelay();
    });
  }

  protected observeSectionsContainer() {
    this.sectionsContainerResizeObserver = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        this.viewModel.connectToSectionsContainerHeight(cr?.height);
        this.viewModel.connectToSectionsContainerWidth(cr?.width);
      }
    });
    const element = this.sectionsContainer?.nativeElement;
    if (exists(element)) {
      this.viewModel.connectToSectionsContainerHeight(element?.offsetHeight);
      this.viewModel.connectToSectionsContainerWidth(element?.offsetWidth);
      this.sectionsContainerResizeObserver.observe(element);
    }
  }

  private observeSectionsContainerContentContainer(): void {
    const container = this.sectionsContainerContent?.nativeElement;
    if (exists(container)) {
      container.onanimationstart = () => this.viewModel.connectToSectionsContainerContentAnimationStart();
      container.onanimationend = (ev: AnimationEvent) => {
        this.viewModel.nMenusRotating$.once(nMenusRotating => {
          const classList = this.sectionsContainerContent?.nativeElement?.classList;
          const animationEnded = () => {
            if (!this.reset) this.viewModel.connectToSectionsContainerContentAnimationEnd();
          };
          switch (true) {
            case ev?.animationName === 'scroll-down-over-specific-time-period': {
              if (nMenusRotating === 1) {
                animationEnded();
              } else {
                classList?.add('delay-end-of-scroll-down');
              }
              break;
            }
            case ev?.animationName === 'scroll-right-over-specific-time-period': {
              if (nMenusRotating === 1) {
                animationEnded();
              } else {
                classList?.add('delay-end-of-scroll-right');
              }
              break;
            }
            case ev?.animationName === 'scroll-up-over-specific-time-period':
            case ev?.animationName === 'scroll-left-over-specific-time-period': {
              animationEnded();
              break;
            }
            case ev?.animationName === 'delay-end-of-scroll-down': {
              animationEnded();
              break;
            }
            case ev?.animationName === 'delay-end-of-scroll-right': {
              animationEnded();
              break;
            }
          }
        });
      };
      this.sectionsContainerPollScrollDistance();
    }
  }

  private sectionsContainerPollScrollDistance(): void {
    this.runSteadyScrollPipe$.pipe(
      switchMap(run => iif(() => run, timer(0, 2000), of(null)))
    ).subscribeWhileAlive({
      owner: this,
      next: () => {
        const element = this.sectionsContainerContent?.nativeElement;
        this.viewModel.connectToSectionsContainerContentScrollHeight(element?.scrollHeight);
        this.viewModel.connectToSectionsContainerContentScrollWidth(element?.scrollWidth);
      }
    });
  }

  private setSteadyScrollCssVariables(): void {
    combineLatest([
      this.runSteadyScrollPipe$,
      this.viewModel.sectionsContainerHeight$,
      this.viewModel.sectionsContainerWidth$,
      this.viewModel.sectionsContainerContentScrollHeight$,
      this.viewModel.sectionsContainerContentScrollWidth$,
    ]).pipe(
      debounceTime(100)
    ).subscribeWhileAlive({
      owner: this,
      next: ([
        runSteadyScroll,
        sectionContainerHeight,
        sectionContainerWidth,
        sectionsContainerContentScrollHeight,
        sectionsContainerContentScrollWidth
      ]) => {
        if (!runSteadyScroll) return;
        if (sectionContainerHeight > 0 && sectionsContainerContentScrollHeight > 0) {
          let contentScroll = sectionsContainerContentScrollHeight - sectionContainerHeight;
          contentScroll = contentScroll > 0 ? contentScroll : 0;
          document.documentElement.style.setProperty('--sections-container-scroll-height', `${contentScroll}`);
        }
        if (sectionContainerWidth > 0 && sectionsContainerContentScrollWidth > 0) {
          let contentScroll = sectionsContainerContentScrollWidth - sectionContainerWidth;
          contentScroll = contentScroll > 0 ? contentScroll : 0;
          document.documentElement.style.setProperty('--sections-container-scroll-width', `${contentScroll}`);
        }
      }
    });
  }

  private setVerticalScrollTime(): void {
    this.listenForRegularVerticalScrollDuration();
    this.listenForVerticalPixelsPerSecondScrollDuration();
  }

  private listenForRegularVerticalScrollDuration(): void {
    combineLatest([
      this.runSteadyScrollPipe$,
      this.viewModel.distinctNotNullMenu$,
    ]).subscribeWhileAlive({
      owner: this,
      next: ([runSteadyScroll, menu]) => {
        if (!runSteadyScroll) return;
        if (menu?.rotationInterval > 0) {
          const scrollInterval = `${menu?.rotationInterval - (SCROLL_DELAY_SECONDS * 2)}s`;
          document.documentElement.style.setProperty('--down-scroll-time', scrollInterval);
          document.documentElement.style.setProperty('--up-scroll-time', scrollInterval);
        }
      }
    });
  }

  private listenForVerticalPixelsPerSecondScrollDuration(): void {
    combineLatest([
      this.runSteadyScrollPipe$,
      this.viewModel.isInVerticalScrollingOverflowState$,
      this.viewModel.distinctNotNullMenu$,
      this.viewModel.sectionsContainerHeight$,
      this.viewModel.sectionsContainerContentScrollHeight$,
    ]).subscribeWhileAlive({
      owner: this,
      next: ([runSteadyScroll, vScrolling, menu, sectionContainerHeight, sectionsContainerContentScrollHeight]) => {
        if (!runSteadyScroll || !vScrolling) return;
        const runwayLength = sectionsContainerContentScrollHeight - sectionContainerHeight;
        const scrollSliderUsed = menu?.rotationInterval < 0;
        if (scrollSliderUsed && runwayLength > 0) {
          this.noScrollRunwaySub?.unsubscribe();
          const pxPerSecond = this.getPixelsPerSecondFromScrollInterval(menu?.rotationInterval);
          const durationInSeconds = this.getDurationFromPxPerSecond(runwayLength, pxPerSecond);
          document.documentElement.style.setProperty('--down-scroll-time', `${durationInSeconds}s`);
          document.documentElement.style.setProperty('--up-scroll-time', `${durationInSeconds}s`);
        } else if (scrollSliderUsed && runwayLength <= 0) {
          this.noScrollRunwaySoForceMenuChangeAfterNSeconds(menu);
        }
      }
    });
  }

  /**
   * Current scroll intervals are saved between -11 and -1.
   * -11 is the slowest scroll speed and -1 is the fastest.
   * This is a linear interpolation between the slowestPxPerSecond and fastestPxPerSecond.
   * The result is the number of pixels per second.
   */
  private getPixelsPerSecondFromScrollInterval(
    negativeScrollInterval: number,
    nIntervals: number = this.NUMBER_OF_STEADY_SCROLL_INTERVALS,
    slowestPxPerSecond: number = this.SLOWEST_SCROLL_SPEED_PX_PER_SECOND,
    fastestPxPerSecond: number = this.FASTEST_SCROLL_SPEED_PIXELS_PER_SECOND
  ): number {
    const normalizedInterval = negativeScrollInterval + nIntervals;
    const interval = (fastestPxPerSecond - slowestPxPerSecond) / (nIntervals - 1);
    return slowestPxPerSecond + (normalizedInterval * interval);
  }

  /**
   * let d = distance, let v = velocity, let t = time.
   * d = v * t, solve for t, t = d / v
   */
  private getDurationFromPxPerSecond(runwayLengthInPx: number, pxPerSecond: number): number {
    return runwayLengthInPx / pxPerSecond;
  }

  private setHorizontalScrollTime(): void {
    this.listenForRegularHorizontalScrollDuration();
    this.listenForHorizontalPixelsPerSecondScrollDuration();
  }

  private listenForRegularHorizontalScrollDuration(): void {
    combineLatest([
      this.runSteadyScrollPipe$,
      this.viewModel.distinctNotNullMenu$,
    ]).subscribeWhileAlive({
      owner: this,
      next: ([runSteadyScroll, menu]) => {
        if (!runSteadyScroll) return;
        if (menu?.rotationInterval > 0) {
          const scrollInterval = `${menu?.rotationInterval - (SCROLL_DELAY_SECONDS * 2)}s`;
          document.documentElement.style.setProperty('--left-scroll-time', scrollInterval);
          document.documentElement.style.setProperty('--right-scroll-time', scrollInterval);
        }
      }
    });
  }

  private listenForHorizontalPixelsPerSecondScrollDuration(): void {
    combineLatest([
      this.runSteadyScrollPipe$,
      this.viewModel.isInHorizontalScrollingOverflowState$,
      this.viewModel.distinctNotNullMenu$,
      this.viewModel.sectionsContainerWidth$,
      this.viewModel.sectionsContainerContentScrollWidth$,
    ]).subscribeWhileAlive({
      owner: this,
      next: ([runSteadyScroll, hScrolling, menu, sectionContainerWidth, sectionsContainerContentScrollWidth]) => {
        if (!runSteadyScroll || !hScrolling) return;
        const runwayLength = sectionsContainerContentScrollWidth - sectionContainerWidth;
        const scrollSliderUsed = menu?.rotationInterval < 0;
        if (scrollSliderUsed && runwayLength > 0) {
          this.noScrollRunwaySub?.unsubscribe();
          const pxPerSecond = this.getPixelsPerSecondFromScrollInterval(menu?.rotationInterval);
          const durationInSeconds = this.getDurationFromPxPerSecond(runwayLength, pxPerSecond);
          document.documentElement.style.setProperty('--left-scroll-time', `${durationInSeconds}s`);
          document.documentElement.style.setProperty('--right-scroll-time', `${durationInSeconds}s`);
        } else if (scrollSliderUsed && runwayLength <= 0) {
          this.noScrollRunwaySoForceMenuChangeAfterNSeconds(menu);
        }
      }
    });
  }

  private noScrollRunwaySoForceMenuChangeAfterNSeconds(menu: Menu): void {
    this.noScrollRunwaySub?.unsubscribe();
    const duration = this.getDisplayDurationInMilliSecondsForNoScrollRunway(menu);
    this.noScrollRunwaySub = this.viewModel.reset$.pipe(
      filter(reset => !reset),
      switchMap(() => timer(duration)),
    ).once(() => {
      this.viewModel.connectToSteadyScrollFireMenuRotation();
    });
  }

  /**
   * Current scroll intervals are saved between -NUMBER_OF_STEADY_SCROLL_INTERVALS and -1.
   * -NUMBER_OF_STEADY_SCROLL_INTERVALS is the slowest scroll speed and -1 is the fastest.
   * This is a linear interpolation between the slowest and fastest steady scroll durations.
   */
  private getDisplayDurationInMilliSecondsForNoScrollRunway(
    menu: Menu,
    nIntervals: number = this.NUMBER_OF_STEADY_SCROLL_INTERVALS,
    slowestStaticSteadyScrollDuration: number = this.SLOWEST_STATIC_STEADY_SCROLL_DURATION_IN_SECONDS,
    fastestStaticSteadyScrollDuration: number = this.FASTEST_STATIC_STEADY_SCROLL_DURATION_IN_SECONDS
  ): number {
    const normalizedInterval = menu?.rotationInterval + nIntervals;
    const interval = (slowestStaticSteadyScrollDuration - fastestStaticSteadyScrollDuration) / (nIntervals - 1);
    return (slowestStaticSteadyScrollDuration - (normalizedInterval * interval)) * 1000;
  }

  private setSteadyScrollDelay(): void {
    combineLatest([
      this.runSteadyScrollPipe$,
      this.viewModel.distinctNotNullMenu$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([runSteadyScroll, menu]) => {
        if (!runSteadyScroll) return;
        const scrollDelay = `${SCROLL_DELAY_SECONDS}s`;
        document.documentElement.style.setProperty('--steady-scroll-delay-animation', scrollDelay);
      }
    });
  }

  protected parseSectionInflatorForPages(
    scrollableItems: QueryList<MenuSectionInflatorComponent> = this.scrollableItems
  ) {
    this.viewModel.parseSectionInflatorForPages(scrollableItems);
  }

  protected listenToReset() {
    this.viewModel.reset$.subscribeWhileAlive({
      owner: this,
      next: reset => {
        if (reset) {
          this.sectionsContainerContent?.nativeElement?.classList?.remove('delay-end-of-scroll-down');
          this.sectionsContainerContent?.nativeElement?.classList?.remove('delay-end-of-scroll-right');
        }
      }
    });
  }

  protected listenForInitialPages() {
    const s = combineLatest([
      this.viewModel.reset$,
      this.viewModel.scrollingInterface$
    ]).pipe(debounceTime(1000)).subscribe(([reset, scrollingInterface]) => {
      if (!reset && scrollingInterface?.isInPagingOverflowState()) {
        if (scrollingInterface?.isInPagingOverflowState()) {
          this.parseSectionInflatorForPages();
        }
      }
    });
    this.pushSub(s);
  }

  protected listenForSectionChanges() {
    this.scrollableItems?.changes?.pipe(
      debounceTime(1000),
      takeUntil(this.onDestroy)
    )?.subscribe((scrollableItems) => {
      this.parseSectionInflatorForPages(scrollableItems);
    });
  }

  protected bindToPaging() {
    const s = combineLatest([
      this.viewModel.reset$,
      this.viewModel.pagePosition$.notNull(),
      this.viewModel.scrollingInterface$,
      this.viewModel.activeOverflowState$.notNull(),
    ]).subscribe(([reset, page, scrollingInterface, overflowState]) => {
      if (overflowState === OverflowState.PAGING || overflowState === OverflowState.SCROLL) {
        if (!reset && !!page.scrollableItemComponent) {
          const smooth = scrollingInterface?.isInScrollPageOverflowState();
          if (smooth) {
            page.scrollableItemComponent
              .element
              .nativeElement
              .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
          } else {
            page.scrollableItemComponent
              .element
              .nativeElement
              .scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
          }
        }
      }
    });
    this.pushSub(s);
    this.viewModel.reset$.subscribeWhileAlive({
      owner: this,
      next: reset => {
        if (reset) this.sectionsContainer?.nativeElement?.scrollTo(0, 0);
      }
    });
  }

  destroy() {
    super.destroy();
    this.sectionsContainerResizeObserver?.disconnect();
    this.noScrollRunwaySub?.unsubscribe();
  }

}
