<div
  #sectionContainer
  class="empty-container plantlife-non-smokable-empty-container"
  [style.display]="'flex'"
  [style.height.px]="section?.heightInPx"
  [style.background-color]="section?.backgroundColor"
  [style.border-color]="section?.borderColor">
  <div class="lane-one"></div>
  <div class="lane-two"></div>
</div>
