import { Component, ElementRef } from '@angular/core';
import { MenuSectionComponent } from '../menu-section.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-page-break-section',
  templateUrl: './page-break-section.component.html'
})
export class PageBreakSectionComponent extends MenuSectionComponent {

  constructor(
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(sanitizer, element);
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
