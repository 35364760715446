import * as i0 from '@angular/core';
import { inject, IterableDiffers, ChangeDetectorRef, NgZone, Injector, ViewContainerRef, ErrorHandler, isSignal, Directive, Input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { coerceObservableWith, coerceDistinctWith } from '@rx-angular/cdk/coercing';
import { RxStrategyProvider } from '@rx-angular/cdk/render-strategies';
import { RxDefaultListViewContext, createListTemplateManager } from '@rx-angular/cdk/template';
import { isObservable, ReplaySubject, Subscription } from 'rxjs';
import { switchAll, shareReplay } from 'rxjs/operators';

class RxForViewContext extends RxDefaultListViewContext {
    rxForOf;
    constructor(item, rxForOf, customProps) {
        super(item, customProps);
        this.rxForOf = rxForOf;
    }
}

/**
 * @Directive RxFor
 *
 * @description
 *
 * The most common way to render lists in angular is by using the `*ngFor` structural directive. `*ngFor` is able
 * to take an arbitrary list of data and repeat a defined template per item of the list. However, it can
 * only do it synchronously.
 *
 * Compared to the `NgFor`, `RxFor` treats each child template as single renderable unit.
 * The change detection of the child templates get prioritized, scheduled and executed by
 * leveraging `RenderStrategies` under the hood.
 * This technique enables non-blocking rendering of lists and can be referred to as `concurrent mode`.
 *
 * Read more about this in the [strategies
 * section](https://www.rx-angular.io/docs/template/api/rx-for-directive#rxfor-with-concurrent-strategies).
 *
 * Furthermore, `RxFor` provides hooks to react to rendered items in form of a `renderCallback: Subject`.
 *
 * Together with the `RxRenderStrategies`, this makes the rendering behavior extremely versatile
 * and transparent for the developer.
 * Each instance of `RxFor` can be configured to render with different settings.
 *
 * Read more in the [official docs](https://www.rx-angular.io/docs/template/api/rx-for-directive)
 *
 * @docsCategory RxFor
 * @docsPage RxFor
 * @publicApi
 */
class RxFor {
    templateRef;
    /** @internal */
    iterableDiffers = inject(IterableDiffers);
    /** @internal */
    cdRef = inject(ChangeDetectorRef);
    /** @internal */
    ngZone = inject(NgZone);
    /** @internal */
    injector = inject(Injector);
    /** @internal */
    viewContainerRef = inject(ViewContainerRef);
    /** @internal */
    strategyProvider = inject(RxStrategyProvider);
    /** @internal */
    errorHandler = inject(ErrorHandler);
    /** @internal */
    staticValue;
    /** @internal */
    renderStatic = false;
    /**
     * @description
     * The iterable input
     *
     * @example
     * <ng-container *rxFor="heroes$; let hero">
     *   <app-hero [hero]="hero"></app-hero>
     * </ng-container>
     *
     * @param { Observable<(U & NgIterable<T>) | undefined | null>
     *       | Signal<(U & NgIterable<T>) | undefined | null>
     *       | (U & NgIterable<T>)
     *       | null
     *       | undefined } potentialSignalOrObservable
     */
    set rxForOf(potentialSignalOrObservable) {
        if (isSignal(potentialSignalOrObservable)) {
            this.staticValue = undefined;
            this.renderStatic = false;
            this.observables$.next(toObservable(potentialSignalOrObservable, { injector: this.injector }));
        }
        else if (!isObservable(potentialSignalOrObservable)) {
            this.staticValue = potentialSignalOrObservable;
            this.renderStatic = true;
        }
        else {
            this.staticValue = undefined;
            this.renderStatic = false;
            this.observables$.next(potentialSignalOrObservable);
        }
    }
    /**
     * @internal
     * A reference to the template that is created for each item in the iterable.
     * @see [template reference variable](guide/template-reference-variables)
     * (inspired by @angular/common `ng_for_of.ts`)
     */
    _template;
    set rxForTemplate(value) {
        this._template = value;
    }
    /**
     * @description
     *
     * You can change the used `RenderStrategy` by using the `strategy` input of the `*rxFor`. It accepts
     * an `Observable<RxStrategyNames>` or [`RxStrategyNames`](https://github.com/rx-angular/rx-angular/blob/b0630f69017cc1871d093e976006066d5f2005b9/libs/cdk/render-strategies/src/lib/model.ts#L52).
     *
     * The default value for strategy is
     * [`normal`](https://www.rx-angular.io/docs/template/cdk/render-strategies/strategies/concurrent-strategies).
     *
     * Read more about this in the
     * [official docs](https://www.rx-angular.io/docs/template/api/rx-for-directive#use-render-strategies-strategy).
     *
     * @example
     *
     * \@Component({
     *   selector: 'app-root',
     *   template: `
     *     <ng-container *rxFor="let hero of heroes$; strategy: strategy">
     *       <app-hero [hero]="hero"></app-hero>
     *     </ng-container>
     *
     *     <ng-container *rxFor="let hero of heroes$; strategy: strategy$">
     *       <app-hero [hero]="hero"></app-hero>
     *     </ng-container>
     *   `
     * })
     * export class AppComponent {
     *   strategy = 'low';
     *   strategy$ = of('immediate');
     * }
     *
     * @param {string | Observable<string> | undefined} strategyName
     * @see {@link strategies}
     */
    set rxForStrategy(strategyName) {
        this.strategyInput$.next(strategyName);
    }
    /**
     * @description
     *
     * When local rendering strategies are used, we need to treat view and content queries in a
     * special way.
     * To make `*rxFor` in such situations, a certain mechanism is implemented to
     * execute change detection on the parent (`parent`).
     *
     * This is required if your components state is dependent on its view or content children:
     *
     * - `@ViewChild`
     * - `@ViewChildren`
     * - `@ContentChild`
     * - `@ContentChildren`
     *
     * Read more about this in the
     * [official docs](https://www.rx-angular.io/docs/template/api/rx-for-directive#local-strategies-and-view-content-queries-parent).
     *
     * @example
     * \@Component({
     *   selector: 'app-root',
     *   template: `
     *    <app-list-component>
     *      <app-list-item
     *        *rxFor="
     *          let item of items$;
     *          trackBy: trackItem;
     *          parent: true;
     *        "
     *      >
     *        <div>{{ item.name }}</div>
     *      </app-list-item>
     *    </app-list-component>
     *   `
     * })
     * export class AppComponent {
     *   items$ = itemService.getItems();
     * }
     *
     * @param {boolean} renderParent
     *
     * @deprecated this flag will be dropped soon, as it is no longer required when using signal based view & content queries
     */
    renderParent = this.strategyProvider.config.parent;
    /**
     * @description
     *
     * A flag to control whether *rxFor templates are created within `NgZone` or not.
     * The default value is `true, `*rxFor` will create it's `EmbeddedViews` inside `NgZone`.
     *
     * Event listeners normally trigger zone. Especially high frequently events cause performance issues.
     *
     * Read more about this in the
     * [official docs](https://www.rx-angular.io/docs/template/api/rx-for-directive#working-with-event-listeners-patchzone).
     *
     * @example
     * \@Component({
     *   selector: 'app-root',
     *   template: `
     *    <app-list-component>
     *      <app-list-item
     *        *rxFor="
     *          let item of items$;
     *          trackBy: trackItem;
     *          patchZone: false;
     *        "
     *      >
     *        <div>{{ item.name }}</div>
     *      </app-list-item>
     *    </app-list-component>
     *   `
     * })
     * export class AppComponent {
     *   items$ = itemService.getItems();
     * }
     *
     * @param {boolean} patchZone
     */
    patchZone = this.strategyProvider.config.patchZone;
    /**
     * @description
     * A function or key that defines how to track changes for items in the iterable.
     *
     * When items are added, moved, or removed in the iterable,
     * the directive must re-render the appropriate DOM nodes.
     * To minimize churn in the DOM, only nodes that have changed
     * are re-rendered.
     *
     * By default, rxFor assumes that the object instance identifies the node in the iterable (equality check `===`).
     * When a function or key is supplied, rxFor uses the result to identify the item node.
     *
     * @example
     * \@Component({
     *   selector: 'app-root',
     *   template: `
     *    <app-list-component>
     *      <app-list-item
     *        *rxFor="
     *          let item of items$;
     *          trackBy: 'id';
     *        "
     *      >
     *        <div>{{ item.name }}</div>
     *      </app-list-item>
     *    </app-list-component>
     *   `
     * })
     * export class AppComponent {
     *   items$ = itemService.getItems();
     * }
     *
     * // OR
     *
     * \@Component({
     *   selector: 'app-root',
     *   template: `
     *    <app-list-component>
     *      <app-list-item
     *        *rxFor="
     *          let item of items$;
     *          trackBy: trackItem;
     *        "
     *      >
     *        <div>{{ item.name }}</div>
     *      </app-list-item>
     *    </app-list-component>
     *   `
     * })
     * export class AppComponent {
     *   items$ = itemService.getItems();
     *   trackItem = (idx, item) => item.id;
     * }
     *
     * @param trackByFnOrKey
     */
    set trackBy(trackByFnOrKey) {
        if ((typeof ngDevMode === 'undefined' || ngDevMode) &&
            trackByFnOrKey != null &&
            typeof trackByFnOrKey !== 'string' &&
            typeof trackByFnOrKey !== 'function') {
            console.warn(`trackBy must be a function, but received ${JSON.stringify(trackByFnOrKey)}.`);
        }
        if (trackByFnOrKey == null) {
            this._trackBy = null;
        }
        else {
            this._trackBy =
                typeof trackByFnOrKey !== 'function'
                    ? (i, a) => a[trackByFnOrKey]
                    : trackByFnOrKey;
        }
    }
    /**
     * @description
     * A `Subject` which emits whenever *rxFor finished rendering a set changes to the view.
     * This enables developers to perform actions when a list has finished rendering.
     * The `renderCallback` is useful in situations where you rely on specific DOM properties like the `height` a
     * table after all items got rendered.
     * It is also possible to use the renderCallback in order to determine if a view should be visible or not. This
     * way developers can hide a list as long as it has not finished rendering.
     *
     * The result of the `renderCallback` will contain the currently rendered set of items in the iterable.
     *
     * @example
     * \Component({
     *   selector: 'app-root',
     *   template: `
     *   <app-list-component>
     *     <app-list-item
     *       *rxFor="
     *         let item of items$;
     *         trackBy: trackItem;
     *         renderCallback: itemsRendered;
     *       ">
     *       <div>{{ item.name }}</div>
     *     </app-list-item>
     *   </app-list-component>
     * `
     * })
     * export class AppComponent {
     *   items$: Observable<Item[]> = itemService.getItems();
     *   trackItem = (idx, item) => item.id;
     *   // this emits whenever rxFor finished rendering changes
     *   itemsRendered = new Subject<Item[]>();
     *
     *   constructor(elementRef: ElementRef<HTMLElement>) {
     *     itemsRendered.subscribe(() => {
     *       // items are rendered, we can now scroll
     *       elementRef.scrollTo({bottom: 0});
     *     })
     *   }
     * }
     *
     * @param {Subject<U>} renderCallback
     */
    set renderCallback(renderCallback) {
        this._renderCallback = renderCallback;
    }
    get template() {
        return this._template || this.templateRef;
    }
    /** @internal */
    strategyInput$ = new ReplaySubject(1);
    /** @internal */
    observables$ = new ReplaySubject(1);
    /** @internal */
    _renderCallback;
    /** @internal */
    values$ = this.observables$.pipe(coerceObservableWith(), switchAll(), shareReplay({ refCount: true, bufferSize: 1 }));
    /** @internal */
    values = null;
    /** @internal */
    strategy$ = this.strategyInput$.pipe(coerceDistinctWith());
    /** @internal */
    listManager;
    /** @internal */
    _subscription = new Subscription();
    /** @internal */
    _trackBy;
    /** @internal */
    _distinctBy = (a, b) => a === b;
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
    /** @internal */
    ngOnInit() {
        this._subscription.add(this.values$.subscribe((v) => (this.values = v)));
        this.listManager = createListTemplateManager({
            iterableDiffers: this.iterableDiffers,
            renderSettings: {
                cdRef: this.cdRef,
                strategies: this.strategyProvider.strategies, // TODO: move strategyProvider
                defaultStrategyName: this.strategyProvider.primaryStrategy,
                parent: !!this.renderParent,
                patchZone: this.patchZone ? this.ngZone : false,
                errorHandler: this.errorHandler,
            },
            templateSettings: {
                viewContainerRef: this.viewContainerRef,
                templateRef: this.template,
                createViewContext: this.createViewContext.bind(this),
                updateViewContext: this.updateViewContext.bind(this),
            },
            trackBy: this._trackBy,
        });
        this.listManager.nextStrategy(this.strategy$);
        this._subscription.add(this.listManager
            .render(this.values$)
            .subscribe((v) => this._renderCallback?.next(v)));
    }
    /** @internal */
    createViewContext(item, computedContext) {
        return new RxForViewContext(item, this.values, computedContext);
    }
    /** @internal */
    updateViewContext(item, view, computedContext) {
        view.context.updateContext(computedContext);
        view.context.rxForOf = this.values;
        view.context.$implicit = item;
    }
    /** @internal */
    ngDoCheck() {
        if (this.renderStatic) {
            this.observables$.next(this.staticValue);
        }
    }
    /** @internal */
    ngOnDestroy() {
        this._subscription.unsubscribe();
        this.viewContainerRef.clear();
    }
    /** @internal */
    static ngTemplateContextGuard(dir, ctx) {
        return true;
    }
    /** @nocollapse */ static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.3.2", ngImport: i0, type: RxFor, deps: [{ token: i0.TemplateRef }], target: i0.ɵɵFactoryTarget.Directive });
    /** @nocollapse */ static ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "17.3.2", type: RxFor, isStandalone: true, selector: "[rxFor][rxForOf]", inputs: { rxForOf: "rxForOf", rxForTemplate: "rxForTemplate", rxForStrategy: "rxForStrategy", renderParent: ["rxForParent", "renderParent"], patchZone: ["rxForPatchZone", "patchZone"], trackBy: ["rxForTrackBy", "trackBy"], renderCallback: ["rxForRenderCallback", "renderCallback"] }, ngImport: i0 });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.3.2", ngImport: i0, type: RxFor, decorators: [{
            type: Directive,
            args: [{
                    selector: '[rxFor][rxForOf]',
                    standalone: true,
                }]
        }], ctorParameters: () => [{ type: i0.TemplateRef }], propDecorators: { rxForOf: [{
                type: Input
            }], rxForTemplate: [{
                type: Input
            }], rxForStrategy: [{
                type: Input
            }], renderParent: [{
                type: Input,
                args: ['rxForParent']
            }], patchZone: [{
                type: Input,
                args: ['rxForPatchZone']
            }], trackBy: [{
                type: Input,
                args: ['rxForTrackBy']
            }], renderCallback: [{
                type: Input,
                args: ['rxForRenderCallback']
            }] } });

/**
 * Generated bundle index. Do not edit.
 */

export { RxFor, RxForViewContext };

