/* src/app/modules/display/components/display/empty-display/empty-display.component.scss */
.move-to-top-of-z-stack {
  z-index: 999997;
}
:host .error-title.smaller {
  font-size: 5rem;
}
:host .error-desc.smaller {
  font-size: 2rem;
}
:host .error-logo.smaller {
  height: 10rem;
}
/*# sourceMappingURL=empty-display.component.css.map */
