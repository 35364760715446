<div
  #sectionContainer
  *ngIf="!section.empty"
  class="section-container sativa-hybrid-indica-section-container"
  [ngClass]="menu | productMenuBasicMethod : 'getSectionWrapperClass' : index"
  [ngStyle]="{
    'background-image':
      (menu | productMenuBasicMethod : 'getMakeHeaderSectionImageFillEntireSection') ? (asset | async) : null
  }"
  [style.background-color]="menu | productMenuBasicMethod : 'getProductSectionBackgroundColor' : section"
  [style.background-size]="'cover'"
  [style.color]="menu | productMenuBasicMethod : 'getSectionBodyTextColor' : section"
  [style.max-height]="(menu | productMenuBasicMethod : 'getShouldOverflowHorizontallyElseVertically') ? '100%' : null">
  <app-product-menu-section-header-inflator
    #sectionHeader
    *ngIf="
      ((menu | productMenuBasicMethod : 'getOnlyShowFirstSectionHeader') ? index === 0 : true) &&
      !section?.collapseContent
    "
    [calculationMode]="calculationMode"
    [reset]="reset"
    [menu]="menu"
    [sectionRowViewModels]="vm.sectionRowViewModels$ | async"
    [section]="section"
    [index]="index">
  </app-product-menu-section-header-inflator>
  <div
    #productsContainer
    class="products-container"
    [style.background-color]="section?.metadata?.productsContainerBackgroundColor">
    <!-- Sativa Product Items -->
    <div
      class="sativa-products"
      [class.last]="(vm.sativaSectionIsLongestIndex$ | async) || section?.lastOnPage || section?.lastSection">
      <app-product-menu-item-inflator
        *ngFor="
          let secVm of vm.sativaColRowViewModels$ | async;
          trackBy: vm.trackByUniqueRowViewModelTransitionId;
          index as itemIndex;
          first as first;
          last as last
        "
        #sativaItem
        [calculationMode]="calculationMode"
        [reset]="reset"
        [odd]="itemIndex % 2 === 0"
        [locationId]="locationId"
        [locationConfig]="locationConfig"
        [menu]="menu"
        [sectionRowViewModels]="vm.sativaColRowViewModels$ | async"
        [rowViewModel]="secVm"
        [hidden]="(secVm | sectionRowViewModelBasicMethod : 'hideOverflowItem') || false"
        [sectionIndex]="index"
        [itemIndex]="itemIndex"
        [first]="first"
        [last]="(vm.longestListLength$ | async) === itemIndex + 1">
      </app-product-menu-item-inflator>
    </div>
    <!-- Hybrid Product Items -->
    <div
      class="hybrid-products"
      [class.last]="(vm.hybridSectionIsLongestIndex$ | async) || section?.lastOnPage || section?.lastSection">
      <app-product-menu-item-inflator
        *ngFor="
          let secVm of vm.hybridColRowViewModels$ | async;
          trackBy: vm.trackByUniqueRowViewModelTransitionId;
          index as itemIndex;
          first as first;
          last as last
        "
        #hybridItem
        [calculationMode]="calculationMode"
        [reset]="reset"
        [odd]="itemIndex % 2 === 0"
        [locationId]="locationId"
        [locationConfig]="locationConfig"
        [menu]="menu"
        [sectionRowViewModels]="vm.hybridColRowViewModels$ | async"
        [rowViewModel]="secVm"
        [hidden]="(secVm | sectionRowViewModelBasicMethod : 'hideOverflowItem') || false"
        [sectionIndex]="index"
        [itemIndex]="itemIndex"
        [first]="first"
        [last]="(vm.longestListLength$ | async) === itemIndex + 1">
      </app-product-menu-item-inflator>
    </div>
    <!-- Indica Product Items -->
    <div
      [class.last]="(vm.indicaSectionIsLongestIndex$ | async) || section?.lastOnPage || section?.lastSection"
      class="indica-products">
      <app-product-menu-item-inflator
        *ngFor="
          let secVm of vm.indicaColRowViewModels$ | async;
          trackBy: vm.trackByUniqueRowViewModelTransitionId;
          index as itemIndex;
          first as first;
          last as last
        "
        #indicaItem
        [calculationMode]="calculationMode"
        [reset]="reset"
        [odd]="itemIndex % 2 === 0"
        [locationId]="locationId"
        [locationConfig]="locationConfig"
        [menu]="menu"
        [sectionRowViewModels]="vm.indicaColRowViewModels$ | async"
        [rowViewModel]="secVm"
        [hidden]="(secVm | sectionRowViewModelBasicMethod : 'hideOverflowItem') || false"
        [sectionIndex]="index"
        [itemIndex]="itemIndex"
        [first]="first"
        [last]="(vm.longestListLength$ | async) === itemIndex + 1">
      </app-product-menu-item-inflator>
    </div>
  </div>
</div>
