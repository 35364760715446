<div *ngIf="vm.noVariants$ | async" class="empty-state">
  <img [src]="'assets/logo/dark/logo-stroke.svg'" alt="" class="empty-state-logo" />
  <div class="empty-state-text">All featured products on this menu are out of stock.</div>
</div>
<div
  *ngIf="vm.hasVariants$ | async"
  class="marketing-featured-product-wrapper"
  [class.screen-is-portrait]="isPortrait$ | async"
  [class.strip-white-background]="(vm.distinctNotNullMenu$ | async)?.hasBackgroundAsset()">
  <div class="row menu-title" [ngStyle]="{ display: (vm.showFeatureTitle$ | async) ? 'flex' : 'none' }">
    <!--     Featured Product menu title       -->
    <div class="featured-product-title">
      {{ (vm.featuredDisplayData$ | async)?.featuredProductTitle }}
    </div>
  </div>
  <div
    *ngFor="let fv of (vm.featuredVariants$ | async).variants"
    [hidden]="(vm.distinctFeaturedVariant$ | async)?.id !== fv.id">
    <div class="padding-container" [class.reverse-flex]="oddPosition$ | async">
      <div
        class="row featured-product-info-container"
        [class.right-align]="evenPosition$ | async"
        [class.left-align]="oddPosition$ | async">
        <!--     Company Logo                -->
        <img
          appImage
          *ngIf="menu?.showCompanyLogo()"
          class="company-logo"
          [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
          [asset]="menu?.companyLogo"
          [reset]="reset"
          alt="company logo"
          src="" />
        <!--     Featured Product name       -->
        <div class="featured-product-name" appScaleDownText>
          {{ (vm.featuredDisplayData$ | async)?.name }}
        </div>
        <!--     Featured Product information       -->
        <div *ngIf="vm.showFeaturedProductInfo$ | async" class="featured-product-info">
          <div *ngIf="vm.showSize$ | async" class="featured-product-info-child">
            <div class="featured-product-size">
              {{ (vm.featuredDisplayData$ | async)?.sizeText }}
            </div>
          </div>
          <div class="featured-product-info-child">
            <div
              *ngIf="vm.showClassification$ | async"
              class="featured-product-strain-type"
              [ngClass]="(vm.featuredDisplayData$ | async)?.classification">
              {{ (vm.featuredDisplayData$ | async)?.classification | titlecase }}
            </div>
          </div>
        </div>
        <!-- The label component must compute what the label is, before we can know if there is a label or not -->
        <app-label-inflator
          #isThereALabel
          hidden
          [labelComponentInterface]="vm.featuredDisplayData$ | async"></app-label-inflator>
        <!--     Featured Product potency       -->
        <div *ngIf="vm.showThcCbdProducerLabelRow$ | async" class="featured-product-potency">
          <div *ngIf="vm.showLabel$ | async" class="featured-product-label">
            <div class="label-label">Label</div>
            <app-label-inflator
              [style.display]="'block'"
              [style.zoom]="menu?.getMarketingLabelZoomLevel(isThereALabel.currentLabelStyle$ | async)"
              [labelComponentInterface]="vm.featuredDisplayData$ | async">
            </app-label-inflator>
          </div>
          <div *ngIf="vm.showCBD$ | async" class="featured-product-thc-cbd">
            <span class="thc-cbd-label"> CBD {{ vm.distinctFeaturedVariantCUOM$ | async }} </span>
            <span
              class="thc-cbd-value"
              appScaleDownText
              [scaleDownLinkedKey]="'THC-CBD-SIZE' + fv.uniqueIdentifier"
              [class.low-cbd]="(vm.featuredDisplayData$ | async)?.lowCBD">
              {{ (vm.featuredDisplayData$ | async)?.cbdText }}
            </span>
          </div>
          <div *ngIf="vm.showTHC$ | async" class="featured-product-thc-cbd">
            <span class="thc-cbd-label"> THC {{ vm.distinctFeaturedVariantCUOM$ | async }} </span>
            <span
              class="thc-cbd-value"
              appScaleDownText
              [scaleDownLinkedKey]="'THC-CBD-SIZE' + fv.uniqueIdentifier"
              [class.low-thc]="(vm.featuredDisplayData$ | async)?.lowTHC">
              {{ (vm.featuredDisplayData$ | async)?.thcText }}
            </span>
          </div>
          <div *ngIf="vm.showProducer$ | async" class="featured-product-producer">
            <span class="producer-label"> Producer </span>
            <span class="producer-value" appScaleDownText>
              {{ (vm.featuredDisplayData$ | async)?.producer }}
            </span>
          </div>
        </div>
        <!--     Featured Product description       -->
        <ng-container *ngIf="vm.showDesc$ | async">
          <app-featured-product-description [description]="(vm?.featuredDisplayData$ | async)?.description">
          </app-featured-product-description>
        </ng-container>
      </div>
      <div
        class="row featured-product-image-container"
        [class.right-align]="oddPosition$ | async"
        [class.left-align]="evenPosition$ | async">
        <!--     Featured Product image/video section       -->
        <span
          class="featured-product-media-wrapper"
          [ngStyle]="{ height: (vm.distinctNotNullMenu$ | async).isLandscape() ? '50vh' : '30vh' }">
          <app-asset
            *ngIf="(vm.distinctFeaturedVariant$ | async)?.id === fv.id"
            class="featured-product-media"
            [reset]="vm.reset$ | async"
            [asset]="vm.asset$ | async"
            [scaleFit]="vm.scaleFit$ | async"
            [loopVideo]="vm.loopVideo$ | async"
            [size]="Types.ImageSize.Original"
            [playAudio]="(vm.playAudio$ | async) && (vm.distinctFeaturedVariant$ | async)?.id === fv.id"
            (duration)="vm.videoDuration.next($event)"
            (videoStartedToLoad)="vm.connectToVideoEnded(false)"
            (videoEnded)="vm.connectToVideoEnded(true)">
          </app-asset>
        </span>
        <!--     Featured Product menu price section       -->
        <div
          *ngIf="
            (vm.featuredDisplayData$ | async)?.salePriceText === '' ||
            (vm.featuredDisplayData$ | async)?.salePriceText === undefined
          "
          class="featured-product-price-container">
          <span class="single-price">
            {{ (vm.featuredDisplayData$ | async)?.originalPriceText }}
          </span>
        </div>
        <div
          *ngIf="
            (vm.featuredDisplayData$ | async)?.salePriceText !== '' &&
            (vm.featuredDisplayData$ | async)?.salePriceText !== undefined
          "
          class="featured-product-price-container">
          <span class="original-price">
            {{ (vm.featuredDisplayData$ | async)?.originalPriceText }}
          </span>
          <span class="sale-price">
            {{ (vm.featuredDisplayData$ | async)?.salePriceText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
