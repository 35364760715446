import { Injectable, QueryList } from '@angular/core';
import { DisplayDomainModel } from '../../../../../../domain/display-domain-model';
import { DisplayMenuCoupling } from '../../../../../../couplings/display-menu-coupling.service';
import { OrientationService } from '../../../../../../services/orientation.service';
import { map, take } from 'rxjs/operators';
import { combineLatest, timer } from 'rxjs';
import { ProductMenuViewModel } from '../../product-menu/product-menu-view-model';
import { PageFragment } from '../../../../../../models/shared/page-fragment';
import { ScalingService } from '../../../../../../services/scaling.service';
import { IsMenuReadyService } from '../../../../../services/is-menu-ready.service';
import { MenuSectionInflatorComponent } from '../../menu/inflators/menu-section-inflator-component';

@Injectable()
export class MarketingFeaturedCategoryMenuViewModel extends ProductMenuViewModel {

  constructor(
    dm: DisplayDomainModel,
    displayMenuCoupling: DisplayMenuCoupling,
    isMenuReadyService: IsMenuReadyService,
    orientationService: OrientationService,
    scalingService: ScalingService,
  ) {
    super(dm, displayMenuCoupling, isMenuReadyService, orientationService, scalingService);
  }

  public readonly MIN_SECTIONS_ON_SCREEN = 2;
  public readonly MAX_SECTIONS_ON_SCREEN = 4;

  public nSectionsOnScreen$ = this.distinctNotNullMenu$.pipe(
    map(menu => parseInt(menu?.metadata?.cardCount ?? '2', 10)),
    map(n => (n < this.MIN_SECTIONS_ON_SCREEN ? this.MIN_SECTIONS_ON_SCREEN : n)),
    map(n => (n > this.MAX_SECTIONS_ON_SCREEN ? this.MAX_SECTIONS_ON_SCREEN : n)),
  );
  public nSectionsPipe$ = combineLatest([this.isPortrait$, this.nSectionsOnScreen$]);
  public landscape2SectionsOnScreen$ = this.nSectionsPipe$.pipe(map(([portrait, n]) => !portrait && n === 2));
  public landscape3SectionsOnScreen$ = this.nSectionsPipe$.pipe(map(([portrait, n]) => !portrait && n === 3));
  public landscape4SectionsOnScreen$ = this.nSectionsPipe$.pipe(map(([portrait, n]) => !portrait && n === 4));
  public portrait2SectionsOnScreen$ = this.nSectionsPipe$.pipe(map(([portrait, n]) => portrait && n === 2));
  public portrait3to4SectionsOnScreen$ = this.nSectionsPipe$.pipe(
    map(([portrait, n]) => portrait && (n === 3 || n === 4))
  );

  /**
   * Portrait cards on screen: 1 column
   *
   * [  0  ] |- p(1) -|
   * [  1  ] |- p(1) -| |- p(2) -|
   * [  2  ]            |- p(2) -| |- p(3) -|
   * [  3  ]                       |- p(3) -| |- p(4) -|
   * [  4  ]                                  |- p(4) -|
   *
   * sits on each p(x) for one time slot and follows this order:
   * p1 → p2 → p3 → p4 ↓
   * ↑ ←-------------- ←
   */
  private static getMenuPagesForPortrait2(items: MenuSectionInflatorComponent[] | null): PageFragment[] {
    const pages = [];
    for (let i = 1; i < (items?.length ?? 0); i++) {
      const page = new PageFragment(true, i, items[i]);
      pages.push(page);
    }
    return pages;
  }

  /**
   * Portrait cards on screen: 2 columns
   *
   * [  0  ]  [  1  ] |- p(1) -|
   * [  2  ]  [  3  ] |- p(1) -| |- p(2) -|
   * [  4  ]  [  5  ]            |- p(2) -| |- p(3) -|
   * [  6  ]  [  7  ]                       |- p(3) -| |- p(4) -|
   * [  8  ]  [  9  ]                                  |- p(4) -|
   *
   * sits on each p(x) for one time slot and follows this order:
   * p1 → p2 → p3 → p4 ↓
   * ↑ ←-------------- ←
   */
  private static getMenuPagesForPortrait4(items: MenuSectionInflatorComponent[] | null): PageFragment[] {
    const pages = [];
    for (let i = 2; i < (items?.length ?? 0); i += 2) {
      const page = new PageFragment(true, i, items[i]);
      pages.push(page);
    }
    return pages;
  }

  /**
   * Landscape cards on screen: 1 row, 2 columns
   *
   * |---- p(1) ---|
   *         |---- p(2) ---|
   *                 |---- p(3) ---|
   *                         |---- p(4) ---|
   *                                 |---- p(5) ---|
   *                                         |---- p(6) ---|
   * [  0  ] [  1  ] [  2  ] [  3  ] [  4  ] [  5  ] [  6  ] <-- cards
   *
   * sits on each p(x) for one time slot and follows this order:
   * p1 → p2 → p3 → p4 → p5 → p6 ↓
   * ↑ ←------------------------ ←
   */
  private static getMenuPagesForLandscape2(items: MenuSectionInflatorComponent[] | null): PageFragment[] {
    const pages = [];
    const nItems = (items?.length ?? 0);
    const loopCount = nItems - 1;
    for (let i = 0; i <= loopCount; i++) {
      const page = new PageFragment(true, i, items[i]);
      pages.push(page);
    }
    return pages;
  }

  /**
   * Landscape cards on screen: 1 row, 3 columns
   *
   * |-------- p(1) -------|
   *         |-------- p(2) -------|
   *                 |-------- p(3) -------|
   *                         |-------- p(4) -------|
   *                                 |-------- p(5) -------|
   * [  0  ] [  1  ] [  2  ] [  3  ] [  4  ] [  5  ] [  6  ] <-- cards
   *
   * sits on each p(x) for one time slot and follows this order:
   * p1 → p2 → p3 → p4 → p5 ↓
   * ↑ ←------------------- ←
   */
  private static getMenuPagesForLandscape3(items: MenuSectionInflatorComponent[] | null): PageFragment[] {
    const pages = [];
    const nItems = (items?.length ?? 0);
    const loopCount = nItems - 2;
    for (let i = 0; i <= loopCount; i++) {
      const page = new PageFragment(true, i, items[i]);
      pages.push(page);
    }
    return pages;
  }

  /**
   * Landscape cards on screen: 1 row, 4 columns
   *
   * |------------ p(1) -----------|
   *         |------------ p(2) -----------|
   *                 |------------ p(3) -----------|
   *                         |------------ p(4) -----------|
   * [  0  ] [  1  ] [  2  ] [  3  ] [  4  ] [  5  ] [  6  ] <-- cards
   *
   * sits on each p(x) for one time slot and follows this order:
   * p1 → p2 → p3 → p4 ↓
   * ↑ ←-------------- ←
   */
  private static getMenuPagesForLandscape4(items: MenuSectionInflatorComponent[] | null): PageFragment[] {
    const pages = [];
    const nItems = (items?.length ?? 0);
    const loopCount = nItems - 3;
    for (let i = 0; i <= loopCount; i++) {
      const page = new PageFragment(true, i, items[i]);
      pages.push(page);
    }
    return pages;
  }

  private static getMenuPages(
    items: MenuSectionInflatorComponent[],
    portrait: boolean,
    nCardsOnScreen: number,
    totalAmountOfCards: number
  ): PageFragment[] {
    let pages = [];
    const hasItems = items?.length > 0;
    const shouldBePaging = hasItems && totalAmountOfCards > nCardsOnScreen;
    if (shouldBePaging && portrait) {
      switch (nCardsOnScreen) {
        case 2:
          pages = MarketingFeaturedCategoryMenuViewModel.getMenuPagesForPortrait2(items);
          break;
        default:
          pages = MarketingFeaturedCategoryMenuViewModel.getMenuPagesForPortrait4(items);
          break;
      }
    }
    if (shouldBePaging && !portrait) {
      switch (nCardsOnScreen) {
        case 2:
          pages = MarketingFeaturedCategoryMenuViewModel.getMenuPagesForLandscape2(items);
          break;
        case 3:
          pages = MarketingFeaturedCategoryMenuViewModel.getMenuPagesForLandscape3(items);
          break;
        default:
          pages = MarketingFeaturedCategoryMenuViewModel.getMenuPagesForLandscape4(items);
          break;
      }
    }
    return pages;
  }

  public override parseSectionInflatorForPages(scrollableItems: QueryList<MenuSectionInflatorComponent>) {
    if (!!scrollableItems) {
      this.nSectionsPipe$.pipe(take(1)).subscribe(([portrait, nCardsOnScreen]) => {
        const unwrapped = scrollableItems?.map(it => it);
        const totalAmountOfCards = unwrapped?.length ?? 0;
        const pages = MarketingFeaturedCategoryMenuViewModel.getMenuPages(
          unwrapped,
          portrait,
          nCardsOnScreen,
          totalAmountOfCards
        );
        const existingPages = this._pages.getValue();
        this._pages.next(pages);
        if (existingPages) {
          existingPages.map(p => p.scrollableItemComponent = null);
        }
      });
    }
  }

  protected override bindToPagingMechanism() {
    const pagSub = this.pagingTransition$.subscribe(([reset, toggled, scrollingInterface, menu, pages]) => {
      const pagingOn = toggled && scrollingInterface?.isInPagingOverflowState();
      const nonZeroInterval = menu?.rotationInterval > 0;
      const multiPage = pages?.length > 1;
      if (!reset && pagingOn && nonZeroInterval && multiPage) {
        this.timePerPage = (menu?.originalRotationInterval) * 1000;
        if (pages.length > 1) {
          const updatedDisplayTime = (menu?.originalRotationInterval) * (pages?.length ?? 1);
          menu?.updateRotationIntervalInSeconds(updatedDisplayTime);
          this.dm.setMenuToDisplay(menu);
        }
        this.pagingTimer$ = timer(0, this.timePerPage);
        this.bindToPagingTransition();
      } else {
        this.killPagingTransition();
      }
    });
    this.pushSub(pagSub);
  }

}
