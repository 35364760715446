import { Injectable } from '@angular/core';
import { OrientationService } from '../../../../../../services/orientation.service';
import { BehaviorSubject, combineLatest, iif, Observable, of, timer } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { MenuWithPaginatedSectionsViewModel } from '../../scrollable-sections/menu-with-paginated-sections-view-model';
import { MarketingFeaturedCategoryStaticGridMenu } from '../../../../../../models/menu/marketing/featured-category/marketing-featured-category-static-grid-menu';
import { ScalingService } from '../../../../../../services/scaling.service';
import { OpacityLocation } from '../../../../../../models/shared/opacity-location.enum';
import { DisplayMenuCoupling } from '../../../../../../couplings/display-menu-coupling.service';
import { OverflowState } from '../../../../../../models/enum/shared/overflow-transition-state.enum';
import { SectionUtils } from '../../../../../../utils/section-utils';
import { exists } from '../../../../../../functions/exists';

@Injectable()
export class MarketingFeaturedCategoryStaticGridMenuViewModel extends MenuWithPaginatedSectionsViewModel {

  constructor(
    protected orientationService: OrientationService,
    protected scalingService: ScalingService,
    protected displayMenuCoupling: DisplayMenuCoupling
  ) {
    super(orientationService);
  }

  private isScreenshotMode$ = this.displayMenuCoupling.screenshotMode;

  public _menu = new BehaviorSubject<MarketingFeaturedCategoryStaticGridMenu>(null);
  public menu$ = this._menu as Observable<MarketingFeaturedCategoryStaticGridMenu>;
  public limitSectionToN$ = this.distinctNotNullMenu$.pipe(map(menu => parseInt(menu?.metadata?.cardCount ?? '0', 10)));
  public limitedSections$ = combineLatest([
    this.distinctNotNullMenu$,
    this.limitSectionToN$
  ]).pipe(
    map(([menu, limitSectionsToN]) => {
      const visibleSections = menu?.sections?.filter(s => {
        if (SectionUtils.isSectionWithProducts(s)) {
          const enabledVariants = s.variants?.filter(v => s.enabledVariantIds?.contains(v.id));
          return s.showZeroStockItems || enabledVariants?.some(v => v.inStock());
        } else {
          return true;
        }
      });
      return (limitSectionsToN > 0) ? visibleSections?.take(limitSectionsToN) : visibleSections;
    })
  );

  public themeClass$ = this.menu$.pipe(map(menu => menu?.getThemeClass()));
  public menuWrapperClass$ = this.menu$.pipe(map(menu => menu?.getMenuWrapperClass()));
  public menuOpacity$ = this.menu$.pipe(
    map(menu => {
      if (menu?.getOpacityEnabled() && (menu?.getOpacityLocation() === OpacityLocation.ENTIRE_MENU)) {
        return menu?.menuOptions?.backgroundOpacity || 1;
      }
      return 1;
    })
  );
  public showMenuHeader$ = this.menu$.pipe(map(menu => menu?.getShowHeader()));
  public showMenuFooter$ = this.menu$.pipe(map(menu => menu?.getShowFooter()));
  public hasHeader$ = this.menu$.pipe(map(menu => menu?.getShowHeader()));
  public hasFooter$ = this.menu$.pipe(map(menu => menu?.getShowFooter()));
  public hasHeaderCombinedWithHasFooter$ = combineLatest([this.hasHeader$, this.hasFooter$]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public hasHeaderAndFooter$ = this.hasHeaderCombinedWithHasFooter$.pipe(
    map(([hasHeader, hasFooter]) => hasHeader && hasFooter)
  );
  public hasFooterXorHeader$ = this.hasHeaderCombinedWithHasFooter$.pipe(
    map(([hasHeader, hasFooter]) => (hasHeader && !hasFooter) || (!hasHeader && hasFooter))
  );
  public hasNoHeaderAndFooter$ = this.hasHeaderCombinedWithHasFooter$.pipe(
    map(([hasHeader, hasFooter]) => !hasHeader && !hasFooter)
  );

  // Top and Bottom Margin (accounting for base rem value)
  public sectionMarginTopRem$ = combineLatest([
    this.scalingService.fontSizeInPixels$,
    this.menu$,
  ]).pipe(
    map(([fontSizePx, menu]) => {
      const marginTopInPx = menu?.menuOptions?.sectionMarginTop;
      if (exists(marginTopInPx) && marginTopInPx > 0) {
        return `${marginTopInPx / fontSizePx}rem`;
      }
      return null;
    })
  );

  public sectionMarginBottomRem$ = combineLatest([
    this.scalingService.fontSizeInPixels$,
    this.menu$
  ]).pipe(
    map(([fontSizePx, menu]) => {
      const marginBottomInPx = menu?.menuOptions?.sectionMarginBottom;
      if (exists(marginBottomInPx) && marginBottomInPx > 0) {
        return `${marginBottomInPx / fontSizePx}rem`;
      }
      return null;
    })
  );

  public overflowState$ = this.menu$.pipe(map(menu => menu?.getOverflowState()));
  public disableOverflow$ = this.overflowState$.pipe(map(overflowState => overflowState === OverflowState.NONE));
  public carouselDuration$ = this.menu$.pipe(map(menu => menu?.getCarouselDurationInSeconds()));
  public paginationCalculator$ = this.carouselDuration$.pipe(
    switchMap(rotationTimeInSeconds => timer(0, rotationTimeInSeconds * 1000))
  );
  public signalPagination$ = combineLatest([
    this.reset$,
    this.isScreenshotMode$,
    this.disableOverflow$
  ]).pipe(
    switchMap(([reset, isScreenshot, disableOverflow]) => {
      const calculateIndex$ = this.displayMenuCoupling.holdLoopingMenuRotation.pipe(
        switchMap(hold => this.paginationCalculator$.pipe(map(i => (hold ? false : i)))),
      );
      return iif(() => reset || isScreenshot || disableOverflow, of(0), calculateIndex$);
    }),
    startWith(false),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

}
