import { MenuSectionOverflowCalculatorViewModel } from '../../../menu/menu-section-overflow-calculator/menu-section-overflow-calculator-view-model';
import { IsMenuReadyService } from '../../../../../../services/is-menu-ready.service';
import { Injectable } from '@angular/core';
import { DisplayMenuCoupling } from '../../../../../../../couplings/display-menu-coupling.service';
import { combineLatest, defer, iif, of } from 'rxjs';
import { CachedOverflowService } from '../../../../../../services/cached-overflow.service';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Section } from '../../../../../../../models/menu/section/section';
import { Menu } from '../../../../../../../models/menu/menu';
import { SectionUtils } from '../../../../../../../utils/section-utils';

@Injectable()
export class ProductMenuSectionOverflowCalculatorViewModel extends MenuSectionOverflowCalculatorViewModel {

  constructor(
    displayMenuCoupling: DisplayMenuCoupling,
    isMenuReadyService: IsMenuReadyService,
    protected cachedOverflowService: CachedOverflowService
  ) {
    super(displayMenuCoupling, isMenuReadyService);
  }

  public saveOverflowedSections() {
    combineLatest([
      defer(() => this._menu),
      this.overflowedSections$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([menu, overflowed]) => this.cachedOverflowService.connectToOverflowedSections(menu?.id, overflowed)
    });
  }

  private readonly menu$ = defer(() => this._menu);

  public readonly renderOverflow$ = this.menu$.pipe(
    switchMap(menu => this.cachedOverflowService.getCachedOverflowedSectionsFor(menu?.id).pipe(take(1))),
    map(overflowed => !overflowed?.length),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cachedOverflowedSections$ = this.menu$.pipe(
    switchMap(m => this.cachedOverflowService.getCachedOverflowedSectionsFor(m?.id).pipe(withLatestFrom(this.menu$))),
    take(1),
    tap(([_, menu]) => this.displayMenuCoupling.connectToWaitForOverflowCalculation(menu?.id, true)),
    switchMap(([cached, menu]) => {
      const cached$ = defer(() => of([cached, menu] as [Section[], Menu]));
      const calculate$ = defer(() => {
        return combineLatest([
          this.menu$,
          this.displayMenuCoupling.menuCanCalculateOverflow(menu?.id)
        ]).pipe(
          tap(([_, [isCurrentMenuMe, cacheSize, __]]) => {
            if (isCurrentMenuMe && !cacheSize) this.taskSpreadInMilliSeconds = 0;
            else this.taskSpreadInMilliSeconds = 5;
          }),
          filter(([_, [__, ___, myTimeToCalculate]]) => myTimeToCalculate),
          take(1),
          switchMap(() => this.overflowedSections$.pipe(withLatestFrom(this.menu$)))
        );
      });
      return iif(() => cached?.length > 0, cached$, calculate$);
    }),
    tap(([_, menu]) => this.displayMenuCoupling.connectToWaitForOverflowCalculation(menu?.id, false)),
    map(([overflowSections]) => overflowSections),
    filter(sections => !(sections?.length === 1 && (SectionUtils.isEmptySection(sections?.firstOrNull())))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
