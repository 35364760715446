import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { SpotlightMenuViewModel } from './spotlight-menu-view-model';
import { SpotlightMenu } from '../../../../../models/menu/spotlight-menu';
import { BehaviorSubject } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import { SyncSectionTransitionsService } from '../services/sync-section-transitions.service';
import { RenderContextService } from '../../../../services/render-context.service';

@Component({
  selector: 'app-spotlight-menu',
  templateUrl: './spotlight-menu.component.html',
  providers: [SpotlightMenuViewModel, SyncSectionTransitionsService, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpotlightMenuComponent extends RenderContextComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    public vm: SpotlightMenuViewModel
  ) {
    super();
  }

  @ViewChild('sectionsContainer') public sectionsContainer: ElementRef;
  public ro: ResizeObserver;

  @Input() public companyConfig: CompanyConfiguration;
  @Input() public locationConfig: LocationConfiguration;
  @Input() public locationId: number;
  @Input() public menu: SpotlightMenu = null;
  @Input() public reset: boolean;
  @Input() public backOfMenuFlipper: boolean;

  // Container Heights
  private sectionsContainerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public sectionsContainerHeight$ = this.sectionsContainerHeight.asObservable();
  public footerContainerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  setupViews() {
    this.connectToInputs();
  }

  private connectToInputs() {
    this.vm.connectToCompanyConfig(this.companyConfig);
    this.vm.connectToLocationConfig(this.locationConfig);
    this.vm.connectToLocationId(this.locationId);
    this.vm.connectToSpotlightMenu(this.menu);
    if (!this.menu?.getShowFooter()) {
      this.footerContainerHeight.next(0);
    }
    this.vm.connectToReset(this.reset);
  }

  setupBindings() {
    this.observeSectionsContainer();
  }

  observeSectionsContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        this.sectionsContainerHeight.next(cr.height);
      }
    });
    // Element for which to observe height and width
    this.ro.observe(this.sectionsContainer.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyConfig) {
      this.vm.connectToCompanyConfig(this.companyConfig);
    }
    if (changes.locationId) {
      this.vm.connectToLocationId(this.locationId);
    }
    if (changes.menu) {
      this.vm.connectToSpotlightMenu(this.menu);
      if (!this.menu?.getShowFooter()) {
        this.footerContainerHeight.next(0);
      }
    }
    if (changes.reset) {
      this.vm.connectToReset(this.reset);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.ro?.disconnect();
  }

}
