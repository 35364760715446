import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnTerpeneType } from '../../../../../models/enum/shared/section-column-terpene-type';
import { SectionColumnType } from '../../../../../models/enum/shared/section-column-type';

@Pipe({
  name: 'isTerpeneColumn',
  standalone: true
})
export class IsTerpeneColumnPipe implements PipeTransform {

  transform(columnType: SectionColumnType): SectionColumnType | null {
    const isTerpene = Object.values(SectionColumnTerpeneType).includes(columnType);
    return isTerpene ? columnType : null;
  }

}
