/* src/app/modules/display/components/menus/marketing-menu/building-blocks/card-grid/card-grid.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.card-grid-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-content: center;
}
.portrait-1-to-2 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 29rem 29rem;
  grid-template-rows: 29rem;
}
.portrait-3-to-4 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 29rem 29rem;
  grid-template-rows: 29rem 29rem;
}
.portrait-5-to-6 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 29rem 29rem;
  grid-template-rows: 29rem 29rem 29rem;
}
.portrait-7-to-9 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 18.75rem 18.75rem 18.75rem;
  grid-template-rows: 18.75rem 18.75rem 18.75rem;
}
.portrait-10-to-12 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 18.75rem 18.75rem 18.75rem;
  grid-template-rows: 18.75rem 18.75rem 18.75rem 18.75rem;
}
.landscape-1-to-3 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 36.25rem 36.25rem 36.25rem;
  grid-template-rows: 36.25rem;
}
.landscape-4 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 26.875rem 26.875rem 26.875rem 26.875rem;
  grid-template-rows: 26.875rem;
}
.landscape-5-to-8 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 23.5rem 23.5rem 23.5rem 23.5rem;
  grid-template-rows: 23.5rem 23.5rem;
}
.landscape-5-to-8.has-footer {
  grid-template-columns: 20.5rem 20.5rem 20.5rem 20.5rem;
  grid-template-rows: 20.5rem 20.5rem;
}
.landscape-9-to-12 {
  display: grid;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  grid-template-columns: 17.375rem 17.375rem 17.375rem 17.375rem 17.375rem 17.375rem;
  grid-template-rows: 17.375rem 17.375rem;
}
.placeholder {
  font-family: poppins, sans-serif;
  font-weight: 700;
  background-color: white;
  font-size: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 4rem;
}
/*# sourceMappingURL=card-grid.component.css.map */
