import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../utils/distinct.utils';
import { SpotlightMenu } from '../../../../../models/menu/spotlight-menu';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { OpacityLocation } from '../../../../../models/shared/opacity-location.enum';
import { OrientationService } from '../../../../../services/orientation.service';
import { Section } from '../../../../../models/menu/section/section';
import { ScalingService } from '../../../../../services/scaling.service';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';

@Injectable()
export class SpotlightMenuViewModel extends BaseViewModel {

  constructor(
    private orientationService: OrientationService,
    private scalingService: ScalingService,
  ) {
    super();
  }

  // interface coming into spotlight menu
  private companyConfig = new BehaviorSubject<CompanyConfiguration>(null);
  public companyConfig$ = this.companyConfig.asObservable();
  private locationConfig = new BehaviorSubject<LocationConfiguration>(null);
  public locationConfig$ = this.locationConfig.asObservable();
  private locationId = new BehaviorSubject<number>(null);
  public locationId$ = this.locationId.asObservable();
  private spotlightMenu = new BehaviorSubject<SpotlightMenu>(null);
  public spotlightMenu$ = this.spotlightMenu.notNull().pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  private resetFromParent = new BehaviorSubject<boolean>(false);
  public reset$ = this.resetFromParent.pipe(distinctUntilChanged());

  // sections
  public sections$ = this.spotlightMenu$.pipe(map(m => m.sections ?? []));

  // Overflowed sections
  private _overflowedSections = new BehaviorSubject<Section[]>([]);
  public overflowedSections$ = this._overflowedSections.asObservable();

  // async menu properties
  public themeClass$ = this.spotlightMenu$.pipe(map(m => m.getThemeClass()));
  public menuWrapperClass$ = this.spotlightMenu$.pipe(map(m => m.getMenuWrapperClass()));
  public menuClasses$ = combineLatest([
    this.themeClass$,
    this.menuWrapperClass$
  ]).pipe(map(([theme, wrapper]) => [theme, wrapper]));
  public menuOpacity$ = this.spotlightMenu$.pipe(
    map(m => {
      if (m.getOpacityEnabled()) {
        return (m.getOpacityLocation() === OpacityLocation.ENTIRE_MENU)
          ? (m?.menuOptions?.backgroundOpacity || '1')
          : '1';
      }
      return '1';
    })
  );
  public sectionsContainerId$ = this.spotlightMenu$.pipe(map(m => `sections-container-${m.id}`));
  public sectionsTopMargin$ = this.spotlightMenu$.pipe(map(m => m?.menuOptions?.sectionMarginTop));
  public sectionsBottomMargin$ = this.spotlightMenu$.pipe(map(m => m?.menuOptions?.sectionMarginBottom));
  public sectionsFlexWrapStyle$ = this.spotlightMenu$.pipe(
    map(m => (m?.getShouldSectionsContainerFlexWrap() ? 'wrap' : null))
  );
  public sectionsBackgroundColor$ = this.spotlightMenu$.pipe(
    map(m => {
      if (m?.getOpacityLocation() === OpacityLocation.SECTION_CONTAINER) {
        return m.getBackgroundColorWithOpacity();
      }
      return m.getSectionsBackgroundColor();
    })
  );
  public sectionsWrapperClass$ = this.spotlightMenu$.pipe(map(m => m.getSectionsWrapperClass()));
  public sectionsScrollClass$ = this.spotlightMenu$.pipe(map(m => m.getMenuScrollClass()));
  public sectionWidth$ = this.spotlightMenu$.pipe(map(m => m?.getSectionWidthPercentage()));
  public sectionMaxHeight$ = this.spotlightMenu$.pipe(
    map(m => (m?.getShouldOverflowHorizontallyElseVertically() ? '100%' : null))
  );

  // isPortrait
  public isPortrait$ = this.orientationService.isPortrait$;

  // has header and footer logic
  public hasHeader$ = this.spotlightMenu$.pipe(map(m => m.getShowHeader()));
  public hasFooter$ = this.spotlightMenu$.pipe(map(m => m.getShowFooter()));
  public hasHeaderAndFooter$ = this.spotlightMenu$.pipe(map(m => m.getShowHeader() && m.getShowFooter()));
  public hasHeaderXorFooter$ = this.spotlightMenu$.pipe(
    map(m => (m.getShowHeader() && !m.getShowFooter()) || (!m.getShowHeader() && m.getShowFooter()))
  );
  public hasNoHeaderAndFooter$ = this.spotlightMenu$.pipe(map(m => !m.getShowHeader() && !m.getShowFooter()));

  // Top and Bottom Margin (accounting for base rem value)
  public sectionMarginTopRem$ = combineLatest([
    this.scalingService.fontSizeInPixels$,
    this.sectionsTopMargin$,
  ]).pipe(map(([fontSizePx, marginTopInPx]) => {
    if (!!marginTopInPx && marginTopInPx > 0) {
      return `${marginTopInPx / fontSizePx}rem`;
    }
    return null;
  }));

  public sectionMarginBottomRem$ = combineLatest([
    this.scalingService.fontSizeInPixels$,
    this.sectionsBottomMargin$
  ]).pipe(map(([fontSizePx, marginBottomInPx]) => {
    if (!!marginBottomInPx && marginBottomInPx > 0) {
      return `${marginBottomInPx / fontSizePx}rem`;
    }
    return null;
  }));

  public connectToCompanyConfig(cc: CompanyConfiguration) {
    this.companyConfig.next(cc);
  }

  public connectToLocationConfig(lc: LocationConfiguration) {
    this.locationConfig.next(lc);
  }

  public connectToLocationId(id: number) {
    this.locationId.next(id);
  }

  public connectToReset(reset: boolean) {
    this.resetFromParent.next(reset);
  }

  public connectToSpotlightMenu(m: SpotlightMenu) {
    this.spotlightMenu.next(m);
  }

  public connectToOverflowedSections(overflowed: Section[]) {
    this._overflowedSections.next(overflowed);
  }

}
