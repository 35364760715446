<div
  class="h100p"
  [ngClass]="[viewModel.themeClass$ | async, viewModel.menuWrapperClass$ | async]"
  [style.opacity]="viewModel.menuOpacity$ | async">
  <div
    class="product-menu-container"
    [class.screen-is-portrait]="viewModel.isPortrait$ | async"
    [class.landscape-2-sections-on-screen]="viewModel.landscape2SectionsOnScreen$ | async"
    [class.landscape-3-sections-on-screen]="viewModel.landscape3SectionsOnScreen$ | async"
    [class.landscape-4-sections-on-screen]="viewModel.landscape4SectionsOnScreen$ | async"
    [class.portrait-2-sections-on-screen]="viewModel.portrait2SectionsOnScreen$ | async"
    [class.portrait-3-4-sections-on-screen]="viewModel.portrait3to4SectionsOnScreen$ | async"
    [class.no-header]="!(viewModel.hasHeader$ | async)"
    [class.no-footer]="!(viewModel.hasFooter$ | async)"
    [class.header-and-footer-height]="viewModel.hasHeaderAndFooter$ | async"
    [class.header-xor-footer-height]="viewModel.hasHeaderXorFooter$ | async"
    [class.no-header-and-footer-height]="viewModel.hasNoHeaderAndFooter$ | async"
    [class.print-product-menu-container]="menu | isPrintMenu">
    <app-product-menu-header-inflator [hidden]="!(viewModel.hasHeader$ | async)" [reset]="reset" [menu]="menu">
      Header
    </app-product-menu-header-inflator>
    <div
      #sectionsContainer
      class="sections-container"
      [id]="viewModel.sectionsContainerId$ | async"
      [style.margin-top]="viewModel.sectionMarginTopRem$ | async"
      [style.margin-bottom]="viewModel.sectionMarginBottomRem$ | async"
      [style.background-color]="viewModel.sectionsBackgroundColor$ | async"
      [ngClass]="[viewModel.sectionsWrapperClass$ | async, viewModel.sectionsScrollClass$ | async]">
      <div
        #sectionsContainerContent
        class="content-inside-sections-container"
        [class]="viewModel.scrollAnimationClass$ | async"
        [style.flex-wrap]="viewModel.getShouldSectionsContainerFlexWrap$ | async">
        <app-product-menu-section-inflator
          *ngFor="let s of viewModel.sections$ | async; index as i; last as last"
          class="card-container"
          [class.odd-and-last]=""
          [reset]="reset"
          [locationId]="locationId"
          [menu]="menu"
          [index]="i"
          [last]="last"
          [section]="s">
          Cards
        </app-product-menu-section-inflator>
      </div>
    </div>
    <app-product-menu-footer-inflator [hidden]="!(viewModel.hasFooter$ | async)" [reset]="reset" [menu]="menu">
      Footer
    </app-product-menu-footer-inflator>
  </div>
</div>
