import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { HtmlUtils } from '../../../../../../../../utils/html-utils';

@Component({
  selector: 'app-featured-product-description',
  templateUrl: './featured-product-description.component.html',
  styleUrls: ['./featured-product-description.component.scss'],
})
export class FeaturedProductDescriptionComponent implements OnChanges {

  @Input() description: string = '';
  @Input() nLines: number = 7;
  @ViewChild('desc') desc: ElementRef<HTMLDivElement>;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.description) await this.clampDescription();
  }

  private async clampDescription() {
    await new Promise(_ => setTimeout(_, 200));
    const descElement = this.desc?.nativeElement;
    if (descElement) {
      const heightInPx = await HtmlUtils.getElementHeightWithoutMarginsAsync(descElement);
      const computedStyle = getComputedStyle(descElement);
      const lineHeight = parseFloat(computedStyle?.lineHeight?.replace('px', ''));
      const maxHeight = lineHeight * this.nLines;
      if (heightInPx > 0) this.clampDescriptionText(descElement, maxHeight);
    }
  }

  private clampDescriptionText(description: Element, maxHeight: number) {
    const exceedsMaxDescriptionHeight = () => parseFloat(getComputedStyle(description).height) > maxHeight;
    if (!exceedsMaxDescriptionHeight()) return;
    const endingString = '…';
    const text = description.textContent.trim() || '';
    description.textContent = '';
    for (let i = 0; i < text.length; i++) {
      description.textContent = text.slice(0, i) + endingString;
      if (exceedsMaxDescriptionHeight()) {
        description.textContent = text.slice(0, i - 1) + endingString;
        break;
      }
    }
  }

}
