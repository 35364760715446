import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getArrayElementAtIndex'
})
export class GetArrayElementAtIndexPipe implements PipeTransform {

  transform(value: any[], index: number): any {
    return value?.[index];
  }

}
