// Daniel Shiffman
// http://codingtra.in
// Steering Text Paths

export class Vehicle {

  public p5;
  public pos;
  public target;
  public vel;
  public acc;
  public r;
  public maxSpeed: number;
  public maxForce: number;

  constructor(p5, x, y, size?) {
    this.p5 = p5;
    this.pos = p5.createVector(p5.random(p5.width), p5.random(p5.height));
    this.target = p5.createVector(x, y);
    this.vel = p5.constructor.Vector.random2D();
    this.acc = p5.createVector();
    if (!!size) {
      this.r = size;
    } else {
      this.r = 8;
    }
    this.maxSpeed = 10;
    this.maxForce = 1;
  }

  behaviors() {
    const arrive = this.arrive(this.target);
    const mouse = this.p5.createVector(this.p5.mouseX, this.p5.mouseY);
    const flee = this.flee(mouse);

    arrive.mult(1);
    flee.mult(5);

    this.applyForce(arrive);
    this.applyForce(flee);
  }

  applyForce(f) {
    this.acc.add(f);
  }

  update() {
    this.pos.add(this.vel);
    this.vel.add(this.acc);
    this.acc.mult(0);
  }

  show() {
    this.p5.stroke(0);
    this.p5.strokeWeight(this.r);
    this.p5.point(this.pos.x, this.pos.y);
  }

  arrive(target) {
    const desired = this.p5.constructor.Vector.sub(target, this.pos);
    const d = desired.mag();
    let speed = this.maxSpeed;
    if (d < 100) {
      speed = this.p5.map(d, 0, 100, 0, this.maxSpeed);
    }
    desired.setMag(speed);
    const steer = this.p5.constructor.Vector.sub(desired, this.vel);
    steer.limit(this.maxForce);
    return steer;
  }

  flee(target) {
    const desired = this.p5.constructor.Vector.sub(target, this.pos);
    const d = desired.mag();
    if (d < 50) {
      desired.setMag(this.maxSpeed);
      desired.mult(-1);
      const steer = this.p5.constructor.Vector.sub(desired, this.vel);
      steer.limit(this.maxForce);
      return steer;
    } else {
      return this.p5.createVector(0, 0);
    }
  }

  clone() {
    const v = new Vehicle(this.p5, this.pos.x, this.pos.y, this.r);
    v.pos.x = this.pos.x;
    v.pos.y = this.pos.y;
    v.vel.x = this.vel.x;
    v.vel.y = this.vel.y;
    v.acc.x = this.acc.x;
    v.acc.y = this.acc.y;
    return v;
  }

}
