import { Component, Input } from '@angular/core';
import { StrainType } from '../../../../../../../../../models/enum/dto/strain-classification.enum';
import { ProductMenu } from '../../../../../../../../../models/menu/product-menu';
import { SectionRowViewModel } from '../../../menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnViewModel } from '../../../menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { RenderContextComponent } from '../../../../../../../../../models/base/render-context-component';
import type { SectionWithProducts } from '../../../../../../../../../models/menu/section/section-with-products';

@Component({
  selector: 'app-plantlife-section-sub-header',
  templateUrl: './plantlife-section-sub-header.component.html'
})
export class PlantlifeSectionSubHeaderComponent extends RenderContextComponent {

  @Input() menu: ProductMenu;
  @Input() section: SectionWithProducts;
  @Input() sectionRowViewModels: SectionRowViewModel[] = [];
  @Input() sectionColumnViewModels: SectionColumnViewModel[] = [];
  @Input() classification: StrainType;

  setupViews() {
  }

  setupBindings() {
  }

}
