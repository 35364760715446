import { Section } from './section';

/**
 *                      |                    |
 *    First Swimlane    |   Second Swimlane  |   Third Swimlane
 *                      |                    |
 * --------------------------------------------------------------
 * |          ↓         →    →    ↓          →    →    ↓        |
 * |          ↓         |         ↓          |         ↓        |
 * |          ↓         |         ↓          |         ↓        |
 * |          ↓         ↑         ↓          ↑         ↓        |
 * |          ↓         |         ↓          |         ↓        |
 * |          ↓         |         ↓          |         ↓        |
 * |          ↓         ↑         ↓          ↑         ↓        |
 * |          ↓         |         ↓          |         ↓        |
 * |          ↓         |         ↓          |         ↓        |
 * |          ↓         |         ↓          |         ↓        |
 * |          →    →    ↑         →     →    ↑         ↓        |
 * --------------------------------------------------------------
 *                      |                    |
 *                      |                    |
 */
export class WrappingSwimlaneSection extends Section {

  constructor(
    public nSwimlanes: number,
    public contentInLanes: Section[][],
    pageIndex: number
  ) {
    super();
    this.pageIndex = pageIndex;
    this.firstOnPage = true;
    this.lastOnPage = true;
  }

}
