import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import { Menu } from '../../../../../models/menu/menu';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { BaseMenuViewModel } from './base-menu-view-model';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import { BaseMenuInterface } from './base-menu.interface';

@Component({ selector: 'app-base-menu', template: '' })
export abstract class BaseMenuComponent extends RenderContextComponent implements BaseMenuInterface, OnInit, OnChanges {

  public constructor(
    public viewModel: BaseMenuViewModel
  ) {
    super();
  }

  @Input() public menu: Menu;
  @Input() public companyConfig: CompanyConfiguration;
  @Input() public locationConfig: LocationConfiguration;
  @Input() public locationId: number;
  @Input() public pageIndex: number;
  @Input() public reset: boolean;
  @Input() public backOfMenuFlipper: boolean;

  ngOnInit(): void {
    this.renderContext.setContextId(this.menu?.id);
    super.ngOnInit();
    this.viewModel.bind();
  }

  setupViews() {
    this.setInitialData();
  }

  setupBindings() {
  }

  protected setInitialData() {
    this.viewModel.connectToLocationId(this.locationId);
    this.viewModel.connectToMenu(this.menu);
    this.viewModel.connectToCompanyConfig(this.companyConfig);
    this.viewModel.connectToLocationConfig(this.locationConfig);
    this.viewModel.connectToPageIndex(this.pageIndex);
    this.viewModel.connectToReset(this.reset);
    this.viewModel.connectToBackOfMenuFlipper(this.backOfMenuFlipper);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.locationId) this.viewModel.connectToLocationId(this.locationId);
    if (changes.menu) {
      this.renderContext.setContextId(this.menu?.id);
      this.viewModel.connectToMenu(this.menu);
    }
    if (changes.companyConfig) this.viewModel.connectToCompanyConfig(this.companyConfig);
    if (changes.locationConfig) this.viewModel.connectToLocationConfig(this.locationConfig);
    if (changes.pageIndex) this.viewModel.connectToPageIndex(this.pageIndex);
    if (changes.reset) this.viewModel.connectToReset(this.reset);
    if (changes.backOfMenuFlipper) this.viewModel.connectToBackOfMenuFlipper(this.backOfMenuFlipper);
  }

}
