import { Pipe, PipeTransform } from '@angular/core';
import { SectionRowViewModel } from '../../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SpotlightSectionRowViewModel } from '../building-blocks/menu-section/spotlight-product-section/spotlight-section-row-view-model';

@Pipe({
  name: 'castToSpotLightSectionRowVMs'
})
export class CastToSpotLightSectionRowVMsPipe implements PipeTransform {

  transform(value: SectionRowViewModel[]): SpotlightSectionRowViewModel[] {
    if (value?.every(vm => vm instanceof SpotlightSectionRowViewModel)) {
      return value as SpotlightSectionRowViewModel[];
    }
    return [];
  }

}
