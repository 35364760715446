import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { Menu } from '../../../../../models/menu/menu';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../utils/distinct.utils';
import { OrientationService } from '../../../../../services/orientation.service';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseMenuViewModel extends BaseViewModel {

  public constructor(
    protected orientationService: OrientationService
  ) {
    super();
  }

  protected _locationId = new BehaviorSubject<number>(null);
  public locationId$ = this._locationId as Observable<number>;
  public connectToLocationId = (locationId: number) => this._locationId.next(locationId);

  protected _companyConfiguration = new BehaviorSubject<CompanyConfiguration>(null);
  public companyConfiguration$ = this._companyConfiguration as Observable<CompanyConfiguration>;
  public connectToCompanyConfig = (cc: CompanyConfiguration) => this._companyConfiguration.next(cc);

  protected _locationConfig = new BehaviorSubject<LocationConfiguration>(null);
  public locationConfiguration$ = this._locationConfig as Observable<LocationConfiguration>;
  public connectToLocationConfig = (locationConfig: LocationConfiguration) => this._locationConfig.next(locationConfig);

  protected _pageIndex = new BehaviorSubject<number>(null);
  public pageIndex$ = this._pageIndex as Observable<number>;
  public connectToPageIndex = (pageIndex: number) => this._pageIndex.next(pageIndex);

  protected _menu = new BehaviorSubject<Menu>(null);
  public distinctNotNullMenu$ = defer(() => this._menu)
    .notNull()
    .pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable));
  public connectToMenu = (menu: Menu) => this._menu.next(menu);

  public _reset = new BehaviorSubject<boolean>(true);
  public reset$ = this._reset.pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public connectToReset = (reset: boolean) => this._reset.next(reset);

  public readonly _backOfMenuFlipper = new BehaviorSubject<boolean>(undefined);
  public backOfMenuFlipper$ = this._backOfMenuFlipper.pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public connectToBackOfMenuFlipper = (backOfMenuFlipper: boolean) => this._backOfMenuFlipper.next(backOfMenuFlipper);

  public isPortrait$ = this.orientationService.isPortrait$;

  public bind() {}

}
