import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeturaContextDirective } from './typetura-context.directive';

@NgModule({
  declarations: [
    TypeturaContextDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TypeturaContextDirective
  ]
})
export class TypeturaModule {
}
