import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../../../../models/menu/section/section';

@Pipe({
  name: 'assetSectionRepetitions'
})
export class AssetSectionRepetitionsPipe implements PipeTransform {

  transform(section: Section): any[] {
    const repeat = section.metadata?.repeat;
    const width = +(section.metadata?.width || '100');
    const reps = repeat === 'true' ? Math.floor(100 / width) : 1;
    return Array(reps).fill(1);
  }

}
