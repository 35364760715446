import { Component, ComponentRef, EnvironmentInjector, Injector, Input, OnChanges, OnInit, SimpleChanges, Type, ViewChild } from '@angular/core';
import { CompanyConfiguration } from '../../../../../../../models/company/dto/company-configuration';
import { SpotlightMenuComponent } from '../../spotlight-menu.component';
import { SpotlightMenu } from '../../../../../../../models/menu/spotlight-menu';
import { SpotlightMenuInflatorContainerDirective } from './spotlight-menu-inflator-container.directive';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import { Subscribable } from '../../../../../../../models/base/subscribable';
import { exists } from '../../../../../../../functions/exists';

@Component({
  selector: 'app-spotlight-menu-inflator',
  templateUrl: './spotlight-menu-inflator.component.html'
})
export class SpotlightMenuInflatorComponent extends Subscribable implements OnInit, OnChanges {

  constructor(
    protected injector: Injector,
    protected environmentInjector: EnvironmentInjector
  ) {
    super();
  }

  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: SpotlightMenu;
  @Input() reset: boolean;
  @Input() backOfMenuFlipper: boolean;
  @ViewChild(SpotlightMenuInflatorContainerDirective, { static: true })
  contentContainer: SpotlightMenuInflatorContainerDirective;

  private compRef: ComponentRef<SpotlightMenuComponent>;

  ngOnInit() {
    this.inflate();
  }

  inflate() {
    if (exists(this.menu)) {
      const type: Type<SpotlightMenuComponent> = SpotlightMenuComponent;
      this.contentContainer.viewContainerRef.clear();
      this.compRef = this
        .contentContainer
        .viewContainerRef
        .createComponent(type, { injector: this.injector, environmentInjector: this.environmentInjector });
      this.compRef.setInput('menu', this.menu);
      this.compRef.setInput('locationId', this.locationId);
      this.compRef.setInput('companyConfig', this.companyConfig);
      this.compRef.setInput('locationConfig', this.locationConfig);
      this.compRef.setInput('reset', this.reset);
      this.compRef.setInput('backOfMenuFlipper', this.backOfMenuFlipper);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) {
      this.inflate();
    } else {
      if (exists(this.compRef)) {
        if (changes.locationId) this.compRef.setInput('locationId', this.locationId);
        if (changes.companyConfig) this.compRef.setInput('companyConfig', this.companyConfig);
        if (changes.locationConfig) this.compRef.setInput('locationConfig', this.locationConfig);
        if (changes.reset) this.compRef.setInput('reset', this.reset);
        if (changes.backOfMenuFlipper) this.compRef.setInput('backOfMenuFlipper', this.backOfMenuFlipper);
        this.compRef.instance.ngOnChanges(changes);
      }
    }
  }

}
