export {};
declare global {
  interface String {
    pluralizer(): Pluralizer;
  }
}

type PluralizerRule = { isPlural?: boolean; listConnection?: any[]; word: string; useApostrophe: boolean };
type Pluralizer = { addRule: (rule: PluralizerRule) => Pluralizer; pluralize: () => string };
String.prototype.pluralizer = function(): Pluralizer {
  const rules: PluralizerRule[] = [];
  let pluralized: string = this;
  const addRule = (rule: PluralizerRule) => {
    rules.push(rule);
    return { addRule, pluralize };
  };
  const pluralize = (): string => {
    for (const rule of rules) {
      if (rule?.isPlural || (rule?.listConnection?.length > 1)) {
        pluralized = pluralized.replace(rule.word, rule.word + (rule.useApostrophe ? '\'' : '') + 's');
      }
    }
    return pluralized;
  };
  return { addRule, pluralize };
};
