<div class="h100p" [ngClass]="vm.menuClasses$ | async" [style.opacity]="vm.menuOpacity$ | async">
  <div
    class="product-menu-container"
    [class.screen-is-portrait]="vm.isPortrait$ | async"
    [class.no-footer]="!(vm.hasFooter$ | async)"
    [class.no-header]="!(vm.hasHeader$ | async)"
    [class.header-and-footer-height]="vm.hasHeaderAndFooter$ | async"
    [class.header-xor-footer-height]="vm.hasHeaderXorFooter$ | async"
    [class.no-header-and-footer-height]="vm.hasNoHeaderAndFooter$ | async">
    <app-product-menu-header-inflator
      [hidden]="!(vm.hasHeader$ | async)"
      [reset]="vm.reset$ | async"
      [menu]="vm.menu$ | async">
      Header
    </app-product-menu-header-inflator>
    <div
      #sectionsContainer
      class="sections-container"
      [id]="vm.sectionsContainerId$ | async"
      [style.margin-top]="vm.sectionMarginTopRem$ | async"
      [style.margin-bottom]="vm.sectionMarginBottomRem$ | async"
      [style.background-color]="vm.sectionsBackgroundColor$ | async"
      [ngClass]="[vm.sectionsWrapperClass$ | async, vm.sectionsScrollClass$ | async]">
      <div
        #sectionsContainerContent
        class="content-inside-sections-container"
        [class]="vm.scrollAnimationClass$ | async"
        [style.height]="vm.sectionsContainerContentVerticalHeight$ | async"
        [style.width]="vm.sectionsContainerContentHorizontalWidth$ | async"
        [style.position]="vm.needsOverflowCalculatorInAbsolutePosition$ | async"
        [style.flex-wrap]="vm.getShouldSectionsContainerFlexWrap$ | async"
        [style.flex-direction]="vm.getSectionsContainerFlexDirection$ | async"
        [style.align-content]="vm.getSectionsContainerAlignContent$ | async">
        <app-product-menu-section-overflow-calculator
          *ngIf="viewModel.isMenuLevelOverflow$ | async"
          [menu]="vm.menu$ | async"
          [companyConfig]="vm.companyConfiguration$ | async"
          [locationConfig]="vm.locationConfiguration$ | async"
          [locationId]="vm.locationId$ | async"
          [reset]="vm.reset$ | async"
          [sectionsContainerHeight]="viewModel.sectionsContainerHeight$ | async"
          (overflowedSections)="vm.connectToOverflowedSections($event)">
        </app-product-menu-section-overflow-calculator>
        <!-- Static overflow is used when the sections are in charge of the overflow mechanism.
             It only calculates the sections that will be displayed on screen and removes the rest -->
        <app-product-menu-static-section-overflow-calculator
          *ngIf="viewModel.isSectionLevelOverflow$ | async"
          [menu]="vm.menu$ | async"
          [companyConfig]="vm.companyConfiguration$ | async"
          [locationConfig]="vm.locationConfiguration$ | async"
          [locationId]="vm.locationId$ | async"
          [reset]="vm.reset$ | async"
          [sectionsContainerHeight]="viewModel.sectionsContainerHeight$ | async"
          (overflowedSections)="vm.connectToOverflowedSections($event)">
        </app-product-menu-static-section-overflow-calculator>
        <!-- vm.overflowedSections$ is an output that comes from
             the overflow calculators. This will output either the recalculated overflowed sections
             or just the base menu.sections -->
        <app-product-menu-section-inflator
          *rxFor="
            let s of vm.overflowedSections$;
            index as i;
            last as last;
            trackBy: vm.trackBySectionId;
            strategy: vm.productSectionInflatorRenderStrategy$
          "
          [reset]="vm.reset$ | async"
          [backOfMenuFlipper]="vm.backOfMenuFlipper$ | async"
          [style.width.%]="vm.sectionWidthPercentage$ | async"
          [locationId]="vm.locationId$ | async"
          [locationConfig]="vm.locationConfiguration$ | async"
          [menu]="vm.menu$ | async"
          [index]="(vm.getShowHeaderAsTitleSection$ | async) ? i + 1 : i"
          [last]="last"
          [section]="s"
          [ngStyle]="{ 'max-height': vm.sectionMaxHeight$ | async }">
          Section
        </app-product-menu-section-inflator>
      </div>
    </div>
    <app-product-menu-footer-inflator
      [hidden]="!(vm.hasFooter$ | async)"
      [locationConfig]="vm.locationConfiguration$ | async"
      [reset]="vm.reset$ | async"
      [menu]="vm.menu$ | async">
      Footer
    </app-product-menu-footer-inflator>
  </div>
</div>
