<!--
  if !(viewModel.backOfMenuFlipper$ | async) doesn't exist, then iFrames play in the background,
  which messes everything up
-->
<iframe
  *ngIf="!(viewModel.backOfMenuFlipper$ | async)"
  #iFrame
  allowfullscreen
  sandbox="allow-scripts allow-orientation-lock	allow-pointer-lock allow-presentation allow-top-navigation allow-same-origin"
  allow="autoplay"
  loading="eager"
  id="marketing-url-playlist-theme"
  [style.height.%]="100"
  [style.width.%]="100"
  [frameBorder]="'0'"
  [src]="viewModel.url$ | async"
  (load)="viewModel.connectToLoaded(true)">
</iframe>
