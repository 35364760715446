<div
  #displayContainer
  class="display-container"
  [style.padding]="vm.menuToDisplay$ | async | getMenuToDisplay | getPaddingForDisplayContainerFromMenu"
  [style.pointer-events]="(ignoreTouch | async) ? 'none' : null">
  <app-splash [isDisplay]="isDisplayElseMenu$ | async"> Splash Screen </app-splash>

  <app-orientation-error
    [hidden]="noOrientationError$ | async"
    [isDisplay]="isDisplayElseMenu$ | async"
    [orientationError]="calcOrientationError$ | async">
    Orientation Error
  </app-orientation-error>

  <app-empty-display
    *ngIf="(vm.nMenusToRotate$ | async) === 0"
    [isCollection]="vm.isCollection$ | async"
    [hasScheduledMenus]="vm.hasScheduledMenus$ | async">
    Empty Display
  </app-empty-display>

  <app-menu-flipper
    [menuToDisplay]="vm.menuToDisplay$ | async | getMenuToDisplay"
    [menus]="vm.menusToRotate$ | async"
    [hidePrices]="vm.hidePrices | async"
    [locationId]="vm.locationId$ | async"
    [companyConfig]="vm.companyConfig$ | async"
    [locationConfig]="vm.locationConfig$ | async"
    [displaySize]="vm.displaySettings | async"
    [loading]="vm.loading$ | async">
    Menu Flipper
  </app-menu-flipper>
</div>
