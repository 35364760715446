import { Injectable } from '@angular/core';
import { IsMenuReadyService } from '../../../../services/is-menu-ready.service';
import { CacheService } from '../../../../services/cache-service';
import { DisplayDomainModel } from '../../../../../domain/display-domain-model';
import { DisplayMenuCoupling } from '../../../../../couplings/display-menu-coupling.service';
import { OrientationService } from '../../../../../services/orientation.service';
import { BehaviorSubject, combineLatest, defer, Observable, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../utils/distinct.utils';
import { RotationState } from '../../../../../models/enum/shared/rotation-state.enum';
import { DisplayOptions } from '../../../../../models/shared/display-options';
import { HoldOffMenuRotation } from '../../../../../models/shared/hold-off-menu-rotation';
import { BaseMenuViewModel } from '../menu/base-menu-view-model';
import { UpgradedMarketingLoopingContentMenu } from '../../../../../models/menu/upgraded-marketing-looping-content-menu';
import { MarketingAsset } from '../../../../../models/image/dto/marketing-asset';
import { exists } from '../../../../../functions/exists';

@Injectable()
export class MarketingLoopingContentMenuViewModel extends BaseMenuViewModel {

  constructor(
    protected isMenuReadyService: IsMenuReadyService,
    protected cache: CacheService,
    protected displayDomainModel: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected orientationService: OrientationService
  ) {
    super(orientationService);
  }

  private intervalSub: Subscription;
  public readonly marketingMenuAssets$ = this.displayDomainModel.marketingMenuAssets$;
  public readonly menuToDisplay$ = this.displayDomainModel.menuToDisplay
    .notNull()
    .pipe(map(it => it?.menu), distinctUntilChanged(DistinctUtils.distinctMenu));

  public override readonly _menu = new BehaviorSubject<UpgradedMarketingLoopingContentMenu>(null);
  public readonly distinctNotNullMenu$: Observable<UpgradedMarketingLoopingContentMenu> = defer(() => this._menu)
    .notNull()
    .pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable));
  public readonly nMenusRotating$ = this.displayMenuCoupling.menuToRotateCount
    .notNull()
    .pipe(distinctUntilChanged());
  public readonly menuRotationInterval$ = this.distinctNotNullMenu$.pipe(
    map(m => m?.rotationInterval),
    distinctUntilChanged()
  );

  public readonly sections$ = this.distinctNotNullMenu$.pipe(
    map(it => it?.sections),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _asset = new BehaviorSubject<MarketingAsset>(null);
  public asset$ = this._asset.pipe(
    filter(it => it !== null && it !== undefined),
    distinctUntilChanged(DistinctUtils.distinctAsset),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToAsset = (asset: MarketingAsset) => this._asset.next(asset);

  private rotationInstructions$ = this.distinctNotNullMenu$.pipe(map(it => it?.options)).notNull();

  /**
   * scaleFit is set at the section level, and not the menu level.
   */
  public scaleFit$ = combineLatest([
    this.asset$,
    this.sections$
  ]).pipe(
    map(([asset, sections]) => {
      const associatedSection = sections?.find(s => s?.id === asset?.sectionId);
      return associatedSection?.metadata?.objectFit?.toLowerCase() === 'contain';
    }),
    distinctUntilChanged()
  );

  private _loopVideo = new BehaviorSubject<boolean>(false);
  public loopVideo$ = this._loopVideo.pipe(distinctUntilChanged());
  connectToLoopVideo = (loopVideo: boolean) => this._loopVideo.next(loopVideo);

  public playAudio$ = this._menu.pipe(map(m => m?.menuOptions?.playAudio));

  private _position = new BehaviorSubject<number>(0);
  public position$ = this._position as Observable<number>;
  connectToPosition = (position: number) => this._position.next(position);

  private _currentVideoTime = new BehaviorSubject<number>(0);
  public currentVideoTime$ = this._currentVideoTime as Observable<number>;
  connectToCurrentVideoTime = (currentVideoTime: number) => this._currentVideoTime.next(currentVideoTime);

  private _videoDuration = new BehaviorSubject<number>(0);
  public videoDuration$ = this._videoDuration.notNull();
  connectToVideoDuration = (videoDuration: number) => this._videoDuration.next(videoDuration);

  public _videoEnded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public videoEnded$ = this._videoEnded as Observable<boolean>;
  connectToVideoEnded = (videoEnded: boolean) => this._videoEnded.next(videoEnded);

  private smartPlaylistAssets$ = combineLatest([
    this._menu,
    this.sections$,
    this.marketingMenuAssets$
  ]).pipe(
    map(([menu, sections, assetMap]) => {
      const assets = assetMap?.get(menu?.getOriginalMenuId());
      return assets?.filter(asset => sections?.find(s => s?.id === asset?.sectionId)?.isVisible(menu)) ?? [];
    })
  );

  public readonly numberOfAssets$ = this.smartPlaylistAssets$.pipe(
    map(assets => assets?.length),
    distinctUntilChanged()
  );

  private isTheCurrentMenuBeingDisplayedThisOne$ = combineLatest([
    this.distinctNotNullMenu$,
    this.menuToDisplay$
  ]).pipe(
    map(([a, b]) => a?.id === b?.id),
    filter(x => x !== null && x !== undefined),
    distinctUntilChanged()
  );

  private _rotationState = new BehaviorSubject<RotationState>(RotationState.START);
  public rotationState$ = this._rotationState.pipe(distinctUntilChanged());
  connectToRotationState = (rotationState: RotationState) => this._rotationState.next(rotationState);

  /**
   * Don't rotate content if display is about to rotate menus.
   */
  private holdOffLoopingMenuRotation$ = this.displayMenuCoupling.holdLoopingMenuRotation.pipe(distinctUntilChanged());

  /* *************************** Local Threads of Execution *************************** */

  private listenToReset = this.reset$
    .pipe(filter(reset => reset))
    .subscribeWhileAlive({
      owner: this,
      next: () => this.connectToRotationState(RotationState.RESET)
    });

  /**
   * Only rotate through the assets if the current menu is active and being displayed to the user,
   * hence why isTheCurrentMenuBeingDisplayedThisOne$ is connected to this circuit.
   */
  private rotationMechanism = combineLatest([
    this.smartPlaylistAssets$,
    this.rotationState$,
    this.isTheCurrentMenuBeingDisplayedThisOne$
  ]).pipe(debounceTime(1), takeUntil(this.onDestroy))
    .subscribe(([media, state, isCurrentMenuActive]) => {
      const hasMedia = exists(media);
      if (hasMedia && isCurrentMenuActive) {
        switch (state) {
          case RotationState.START: {
            this.displayAsset(media, false);
            break;
          }
          case RotationState.ROTATE: {
            this.displayAsset(media, true);
            this.connectToRotationState(RotationState.HOLD);
            break;
          }
          case RotationState.RESET: {
            this.displayAsset(media, false, true);
            break;
          }
          case RotationState.HOLD: {
            break;
          }
        }
      } else if (hasMedia && !isCurrentMenuActive) {
        this.displayAsset(media, false, true);
      }
    });

  private loopVideoMechanism = combineLatest([
    this.nMenusRotating$,
    this.smartPlaylistAssets$,
    this.rotationInstructions$,
    this.asset$,
    this.videoDuration$,
  ]).pipe(debounceTime(1), takeUntil(this.onDestroy))
    .subscribe(([, , options, asset, duration]) => {
      const videoDisplayTime = ((options?.rotationInterval?.get(asset?.sectionId)) || 0);
      const loopContent = !((videoDisplayTime === -1) || (Math.trunc(videoDisplayTime) <= Math.trunc(duration)));
      if (loopContent) this.connectToLoopVideo(true);
    });

  private listenToTimer = combineLatest([
    this.asset$,
    this.rotationInstructions$,
    this.isTheCurrentMenuBeingDisplayedThisOne$,
    this.distinctNotNullMenu$,
  ]).pipe(debounceTime(1), takeUntil(this.onDestroy))
    .subscribe(([asset, options, isBeingDisplayed, menu]) => {
      const loopingContentMenu = (menu instanceof UpgradedMarketingLoopingContentMenu);
      if (exists(menu) && asset && options && isBeingDisplayed && loopingContentMenu) {
        this.setIntervalTimer(menu.id, asset, options);
      } else if (!isBeingDisplayed) {
        this.intervalSub?.unsubscribe();
      }
    });

  /* ********************************************************************************** */

  private displayAsset(media: MarketingAsset[], rotate: boolean = false, reset: boolean = false) {
    const hasMedia = media?.length > 0;
    switch (true) {
      case reset && hasMedia: {
        this.connectToPosition(0);
        this.connectToAsset(media?.firstOrNull());
        this.connectToVideoDuration(0);
        this.connectToLoopVideo(false);
        break;
      }
      case rotate && hasMedia: {
        this.position$.once(position => this.connectToPosition((position + 1) % media?.length));
        break;
      }
    }
    switch (true) {
      case media?.length > 1: {
        this.position$.once(position => this.displayAssetAtPosition(position, media));
        break;
      }
      case media?.length === 1: {
        this.connectToAsset(media?.firstOrNull());
        break;
      }
    }
  }

  private displayAssetAtPosition(position: number, media: MarketingAsset[]) {
    let currentIndex = position;
    let currentVariantAsset = media?.[position];
    if (!currentVariantAsset) {
      currentIndex = 0;
      this.connectToPosition(currentIndex);
      currentVariantAsset = media?.[currentIndex];
    }
    if (currentVariantAsset) {
      this.connectToAsset(currentVariantAsset);
    }
  }

  /**
   * Sets the time interval for current asset.
   * If it's a video:
   * - Loop asset for designated time
   * - If asset hasn't finished current play through, wait, then toggle rotation
   */
  private setIntervalTimer(menuId: string, asset: MarketingAsset, options: DisplayOptions) {
    const displayTime = ((options?.rotationInterval?.get(asset?.sectionId)) || 0) * 1000;
    if (exists(asset) && (displayTime > 0)) {
      if (asset?.isImage()) {
        this.setIntervalForImage(menuId, displayTime);
      } else if (asset?.isVideo()) {
        this.setIntervalForVideo(menuId, displayTime);
      }
    } else {
      this.connectToRotationState(RotationState.HOLD);
    }
  }

  private killInterval() {
    this.intervalSub?.unsubscribe();
  }

  private setIntervalForImage(menuId: string, displayTime: number) {
    this.killInterval();
    this.displayMenuCoupling.holdDisplayRotation.next(new HoldOffMenuRotation(menuId));
    this.intervalSub = combineLatest([
      timer(0, displayTime),
      this.holdOffLoopingMenuRotation$
    ]).subscribe(([timeToRotate, holdOfRotation]) => {
      if (timeToRotate > 0) {
        this.displayMenuCoupling.holdDisplayRotation.next(null);
        if (!holdOfRotation) {
          this.connectToRotationState(RotationState.ROTATE);
        }
        this.killInterval();
      }
    });
  }

  private setIntervalForVideo(menuId: string, displayTime: number) {
    this.killInterval();
    this.displayMenuCoupling.holdDisplayRotation.next(new HoldOffMenuRotation(menuId));
    const assetRanForAllottedDuration$ = timer(0, displayTime);
    this.intervalSub = combineLatest([
      this.menuRotationInterval$,
      this.nMenusRotating$,
      this.numberOfAssets$,
      assetRanForAllottedDuration$,
      this.holdOffLoopingMenuRotation$,
      this.videoEnded$
    ]).subscribe(([rotationInterval, nMenusRotating, nAssets, timerFired, holdOfRotation, videoEnded]) => {
      const singleMenuRotating = nMenusRotating <= 1;
      const singleAssetRotating = nAssets <= 1;
      const singleAssetAndMenuIntervalLongerThanVideo = singleAssetRotating
        && (Math.trunc(timerFired * displayTime) < Math.trunc(rotationInterval * 1000));

      switch (true) {
        case singleMenuRotating && singleAssetRotating: {
          this.connectToLoopVideo(true);
          break;
        }
        case (timerFired > 0) && videoEnded: {
          this.displayMenuCoupling.holdDisplayRotation.next(null);
          if (!holdOfRotation) this.connectToRotationState(RotationState.ROTATE);
          this.killInterval();
          break;
        }
        case timerFired > 0: {
          this.connectToLoopVideo(singleAssetAndMenuIntervalLongerThanVideo);
          break;
        }
      }
    });
  }

}
