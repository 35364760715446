import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ProductMenu } from '../../../../../../models/menu/product-menu';
import { CompanyConfiguration } from '../../../../../../models/company/dto/company-configuration';
import { Section } from '../../../../../../models/menu/section/section';
import { MenuSectionInflatorComponent } from '../inflators/menu-section-inflator-component';
import { takeUntil } from 'rxjs/operators';
import { ResizeObserver } from '@juggle/resize-observer';
import { MenuSectionOverflowCalculatorViewModel } from './menu-section-overflow-calculator-view-model';
import { LocationConfiguration } from '../../../../../../models/company/dto/location-configuration';
import { BaseComponent } from '../../../../../../models/base/base-component';

@Component({ selector: 'app-menu-section-overflow-calculator', template: '' })
export abstract class MenuSectionOverflowCalculatorComponent extends BaseComponent
  implements AfterViewInit, OnChanges, OnDestroy {

  public constructor(
    public viewModel: MenuSectionOverflowCalculatorViewModel
  ) {
    super();
  }

  @Input() public menu: ProductMenu = null;
  @Input() public locationId: number;
  @Input() public companyConfig: CompanyConfiguration;
  @Input() public locationConfig: LocationConfiguration;
  @Input() public reset: boolean;
  @Input() public backOfMenuFlipper: boolean;
  @Input() public sectionsContainerHeight: number;
  @Output() public overflowedSections = new EventEmitter<Section[]>(true);

  @ViewChild('sectionOverflowContainer') sectionOverflowContainer: ElementRef;
  @ViewChildren(MenuSectionInflatorComponent) sections: QueryList<MenuSectionInflatorComponent>;

  protected ro: ResizeObserver;

  setupViews() {
    this.viewModel.connectToMenu(this.menu);
    this.viewModel.connectToBackOfMenuFlipper(this.backOfMenuFlipper);
  }

  setupBindings() {
    this.observeSectionOverflowContainer();
    this.observeSections();
    this.observeCalculatedOverflowedSections();
  }

  observeSections() {
    this.sections.changes.pipe(takeUntil(this.onDestroy)).subscribe(sections => {
      this.viewModel.connectToSections(sections);
    });
  }

  observeSectionOverflowContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        this.viewModel.connectToSectionsOverflowHeight(entry.target.scrollHeight);
      }
    });
    // Element for which to observe height and width
    this.ro.observe(this.sectionOverflowContainer.nativeElement);
  }

  observeCalculatedOverflowedSections() {
    this.viewModel.overflowedSections$.pipe(takeUntil(this.onDestroy)).subscribe(sections => {
      this.overflowedSections.emit(sections);
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.viewModel.connectToSections(this.sections.toArray());
    this.viewModel.connectToSectionsContainerHeight(this.sectionsContainerHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.locationConfig) this.viewModel.connectToLocationConfig(this.locationConfig);
    if (changes.companyConfig) this.viewModel.connectToCompanyConfig(this.companyConfig);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.backOfMenuFlipper) this.viewModel.connectToBackOfMenuFlipper(this.backOfMenuFlipper);
    if (changes.sectionsContainerHeight) {
      this.viewModel.connectToSectionsContainerHeight(this.sectionsContainerHeight);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.ro?.disconnect();
  }

}
