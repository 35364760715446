import { Component, EnvironmentInjector, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, Type } from '@angular/core';
import { CompanyConfiguration } from '../../../../../../../models/company/dto/company-configuration';
import { PrintMenuComponent } from '../../print-menu.component';
import { PrintMenu } from '../../../../../../../models/menu/print-menu';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import { InflatorComponent } from '../../../../../../inflator/inflator.component';
import { PrintMenuInterface } from './print-menu.interface';

@Component({
  selector: 'app-print-menu-inflator',
  templateUrl: './print-menu-inflator.component.html',
})
export class PrintMenuInflatorComponent extends InflatorComponent implements PrintMenuInterface, OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: PrintMenu;
  @Input() pageIndex: number;
  @Input() reset: boolean;
  @Input() backOfMenuFlipper: boolean;
  @Output() nPages = new EventEmitter<number>(true);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) {
      this.connectToComponentType(this.getMenuType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  protected getMenuType(): Type<PrintMenuComponent> {
    return PrintMenuComponent;
  }

}
