import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../models/menu/section/section';
import { PageBreakSection } from '../../../../../models/menu/section/page-break-section';

@Pipe({
  name: 'isPageBreakSection'
})
export class IsPageBreakSectionPipe implements PipeTransform {

  transform(section: Section): boolean {
    return section instanceof PageBreakSection;
  }

}
