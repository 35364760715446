import { Component } from '@angular/core';
import { MarketingMenuComponent } from '../marketing-menu.component';
import { DeprecatedMarketingMenuViewModel } from '../deprecated-marketing-menu-view-model';
import { RenderContextService } from '../../../../../services/render-context.service';

@Component({
  selector: 'app-marketing-loop',
  templateUrl: './marketing-loop.component.html',
  styleUrls: ['./marketing-loop.component.scss'],
  providers: [DeprecatedMarketingMenuViewModel, RenderContextService],
})
export class MarketingLoopComponent extends MarketingMenuComponent {

  constructor(
    public vm: DeprecatedMarketingMenuViewModel
  ) {
    super(vm);
  }

}
