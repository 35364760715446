import { Pipe, PipeTransform } from '@angular/core';
import { SectionRowViewModel } from '../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { Observable } from 'rxjs';
import { ProductSectionWithTransitionsViewModel } from '../product-menu/building-blocks/menu-section/product-section/product-section-with-transitions/product-section-with-transitions-view-model';

@Pipe({
  name: 'isLineItemOdd'
})
export class IsLineItemOddPipe implements PipeTransform {

  transform(
    viewModel: ProductSectionWithTransitionsViewModel,
    rowViewModel: SectionRowViewModel,
    indexOnPage: number
  ): Observable<boolean> {
    return viewModel.isRowViewModelOdd(rowViewModel, indexOnPage);
  }

}
