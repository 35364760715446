<div
  #sectionContainer
  *ngIf="!section.empty"
  class="section-container split-product-section-container"
  [ngClass]="menu | productMenuBasicMethod : 'getSectionWrapperClass' : index"
  [ngStyle]="{
    'background-image':
      (menu | productMenuBasicMethod : 'getMakeHeaderSectionImageFillEntireSection') ? (asset | async) : null
  }"
  [style.background-color]="menu | productMenuBasicMethod : 'getProductSectionBackgroundColor' : section"
  [style.background-size]="'cover'"
  [style.color]="menu | productMenuBasicMethod : 'getSectionBodyTextColor' : section"
  [style.margin-top]="!calculationMode && section?.firstOnPage ? '0' : null"
  [style.max-height]="(menu | productMenuBasicMethod : 'getShouldOverflowHorizontallyElseVertically') ? '100%' : null">
  <div
    *ngIf="(menu | productMenuBasicMethod : 'getOnlyShowFirstSectionHeader') ? index === 0 : true"
    [style.color]="menu | getSectionBorderColor : section"
    class="split-product-section-header">
    <app-product-menu-section-header-inflator
      #sectionHeader
      class="split-product-header-inflator"
      [ngClass]="{ odd: true }"
      [calculationMode]="calculationMode"
      [reset]="reset"
      [menu]="menu"
      [sectionRowViewModels]="viewModel.orderCorrectedSectionRowViewModels$ | async"
      [section]="section"
      [index]="index">
    </app-product-menu-section-header-inflator>
    <app-product-menu-section-header-inflator
      #sectionHeader
      class="split-product-header-inflator"
      [calculationMode]="calculationMode"
      [reset]="reset"
      [menu]="menu"
      [sectionRowViewModels]="viewModel.orderCorrectedSectionRowViewModels$ | async"
      [section]="section"
      [index]="index">
    </app-product-menu-section-header-inflator>
  </div>

  <!--
     [style.border-bottom]="section.getBottomBorder(menu)" conditionally changes the height of a products-container
     outside of the overflow calculator. Conditionally adding heights outside of the overflow calculator is bad for
     business, because then things won't overflow correctly. If you're adding a bottom border, then use
     getBottomBorderHeightInPx()  in tandem with getBottomBorder(menu: Menu), so that a negative margin
     gets added to the products-container. This will make the products-container the same height as it
     was calculated to be in the overflow calculator.
     --->
  <div
    #productsContainer
    class="products-container"
    [style.background-color]="section?.metadata?.productsContainerBackgroundColor"
    [style.border-color]="menu | getSectionBorderColor : section"
    [style.border-radius]="menu | getSectionBorderRadius : section"
    [style.border-bottom]="calculationMode ? null : section.getBottomBorder(menu)"
    [style.margin-bottom.px]="
      calculationMode
        ? null
        : !!(menu | getSectionBottomBorder : section)
        ? -(section | getSectionBottomBorderHeightInPx)
        : null
    ">
    <app-product-menu-item-inflator
      *ngFor="
        let secVm of viewModel.orderCorrectedSectionRowViewModels$ | async;
        trackBy: viewModel.trackByUniqueRowViewModelTransitionId;
        index as itemIndex;
        first as first;
        last as last
      "
      class="split-product-inflator"
      [calculationMode]="calculationMode"
      [reset]="reset"
      [odd]="itemIndex % 2 === 0"
      [locationId]="locationId"
      [locationConfig]="locationConfig"
      [menu]="menu"
      [sectionRowViewModels]="viewModel.orderCorrectedSectionRowViewModels$ | async"
      [rowViewModel]="secVm"
      [hidden]="(secVm | sectionRowViewModelBasicMethod : 'hideOverflowItem') || false"
      [sectionIndex]="index"
      [itemIndex]="itemIndex"
      [first]="first"
      [last]="last">
    </app-product-menu-item-inflator>
  </div>
</div>
