import { DeprecatedMarketingMenuViewModel } from '../deprecated-marketing-menu-view-model';
import { Injectable } from '@angular/core';
import { CacheService } from '../../../../../services/cache-service';
import { DisplayDomainModel } from '../../../../../../domain/display-domain-model';
import { DisplayMenuCoupling } from '../../../../../../couplings/display-menu-coupling.service';
import { debounceTime, map } from 'rxjs/operators';
import { ComboCard } from '../../../../../../models/menu/product-menu-cards/combo-card';
import { combineLatest, defer } from 'rxjs';
import { Card } from '../../../../../../models/menu/product-menu-cards/card';
import { DefaultCardCount } from '../../../../../../models/menu/dto/menu-metadata';
import { IsMenuReadyService } from '../../../../../services/is-menu-ready.service';
import { OrientationService } from '../../../../../../services/orientation.service';

@Injectable()
export class MarketingComboViewModel extends DeprecatedMarketingMenuViewModel {

  constructor(
    protected isMenuReadyService: IsMenuReadyService,
    protected cache: CacheService,
    protected dm: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected orientationService: OrientationService
  ) {
    super(isMenuReadyService, cache, dm, displayMenuCoupling, orientationService);
  }

  public comboMenu$ = defer(() => this._menu);
  public hasHeader$ = defer(() => this._menu).pipe(map(m => m?.getShowHeader()));
  public hasFooter$ = defer(() => this._menu).pipe(map(m => m?.getShowFooter()));

  public cards$ = combineLatest([
    this.distinctNotNullMenu$,
    this.dm.companyConfig$.notNull(),
    this.dm.locationConfig$.notNull(),
    this.orderedAssets$,
  ]).pipe(
    debounceTime(100),
    map(([menu, companyConfig, locationConfig, variantAndAssets]) => {
      const cards: Card[] = [];
      variantAndAssets?.forEach(variantAndAsset => {
        const card = new ComboCard(
          menu,
          companyConfig,
          locationConfig,
          variantAndAsset.variant,
          variantAndAsset.asset
        );
        cards.push(card);
      });
      const cardCount = parseInt(menu.metadata.cardCount, 10) ?? parseInt(DefaultCardCount, 10);
      return cards.take(cardCount);
    })
  );

}
