/* src/app/modules/display/components/menus/marketing-menu/building-blocks/card-sections/header-image-carousel-card-section/header-image-carousel-card-price/header-image-carousel-card-price.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.header-image-carousel-price-container {
  font-family: poppins, sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}
@keyframes original-price {
  0% {
    font-size: 1rem;
  }
  100% {
    font-size: 2.75rem;
  }
}
@keyframes original-price-smaller {
  0% {
    font-size: 0.85rem;
  }
  100% {
    font-size: 2.65rem;
  }
}
@keyframes sale-price {
  0% {
    font-size: 1.25rem;
  }
  100% {
    font-size: 3rem;
  }
}
.header-image-carousel-price-container .header-image-carousel-price {
  font-size: 1.5rem;
  line-height: 1;
  --tt-key: original-price;
}
.header-image-carousel-price-container .header-image-carousel-price.sale {
  text-decoration: line-through;
  --tt-key: original-price-smaller;
}
.header-image-carousel-price-container .header-image-carousel-sale-price {
  line-height: 1;
  color: #d92729;
  --tt-key: sale-price;
}
/*# sourceMappingURL=header-image-carousel-card-price.component.css.map */
