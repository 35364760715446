import { AfterViewInit, Component, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { takeUntil } from 'rxjs/operators';
import { ProductMenuSectionOverflowCalculatorViewModel } from './product-menu-section-overflow-calculator-view-model';
import { MenuSectionOverflowCalculatorComponent } from '../../../menu/menu-section-overflow-calculator/menu-section-overflow-calculator-component';

@Component({
  selector: 'app-product-menu-section-overflow-calculator',
  // shared template
  templateUrl: '../product-menu-overflow-calculator.component.html',
  styleUrls: ['../product-menu-overflow-calculator.component.scss'],
  providers: [ProductMenuSectionOverflowCalculatorViewModel]
})
export class ProductMenuSectionOverflowCalculatorComponent extends MenuSectionOverflowCalculatorComponent
  implements AfterViewInit, OnChanges, OnDestroy {

  constructor(
    public viewModel: ProductMenuSectionOverflowCalculatorViewModel
  ) {
    super(viewModel);
  }

  protected ro: ResizeObserver;

  setupViews() {
    this.viewModel.connectToMenu(this.menu);
  }

  setupBindings() {
    this.observeSectionOverflowContainer();
    this.observeSections();
    this.observeCalculatedOverflowedSections();
  }

  observeSections() {
    this.sections.changes.pipe(takeUntil(this.onDestroy)).subscribe(sections => {
      this.viewModel.connectToSections(sections);
    });
  }

  observeSectionOverflowContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        this.viewModel.connectToSectionsOverflowHeight(entry.target.scrollHeight);
      }
    });
    // Element for which to observe height and width
    this.ro.observe(this.sectionOverflowContainer.nativeElement);
  }

  observeCalculatedOverflowedSections() {
    this.viewModel.saveOverflowedSections();
    this.viewModel.cachedOverflowedSections$.subscribeWhileAlive({
      owner: this,
      next: (sections) => this.overflowedSections.emit(sections)
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.viewModel.connectToSections(this.sections.toArray());
    this.viewModel.connectToSectionsContainerHeight(this.sectionsContainerHeight);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.sectionsContainerHeight) this.viewModel.connectToSectionsContainerHeight(this.sectionsContainerHeight);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.ro?.disconnect();
  }

}
