import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CardGridViewModel } from './card-grid-view-model';
import { Card } from '../../../../../../../models/menu/product-menu-cards/card';
import { MarketingMenu } from '../../../../../../../models/menu/marketing-menu';

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss'],
  providers: [CardGridViewModel]
})
export class CardGridComponent implements OnInit, OnChanges {

  @Input() cards: Card[] = [];
  @Input() menu: MarketingMenu;

  constructor(
    public viewModel: CardGridViewModel
  ) {
  }

  ngOnInit(): void {
    this.viewModel.connectToCards(this.cards);
    this.viewModel.connectToMenu(this.menu);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cards) this.viewModel.connectToCards(this.cards);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
  }

}
