import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../models/menu/menu';

@Pipe({
  name: 'getDefaultBackgroundVideoUrl'
})
export class GetDefaultBackgroundVideoUrlPipe implements PipeTransform {

  transform(menu: Menu): string | null {
    return menu?.getDefaultBackgroundVideoUrl() || null;
  }

}
