import { Pipe, PipeTransform } from '@angular/core';
import { ProductMenu } from '../../../models/menu/product-menu';
import { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'castToProductMenu',
})
export class CastToProductMenuPipe implements PipeTransform {

  transform(value: Menu): ProductMenu {
    if (value instanceof ProductMenu) {
      return value as ProductMenu;
    }
    return null;
  }

}
