import { Pipe, PipeTransform } from '@angular/core';
import { ProductMenu } from '../../../../../../../../models/menu/product-menu';

@Pipe({
  name: 'sectionWrapperClass'
})
export class SectionWrapperClassPipe implements PipeTransform {

  transform(menu: ProductMenu, index: number): string | null | undefined {
    return menu?.getSectionWrapperClass(index);
  }

}
