import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { BehaviorSubject, iif, Observable, of, timer, zip } from 'rxjs';
import { ProductMenu } from '../../../../../models/menu/product-menu';
import { DisplayMenuCoupling } from '../../../../../couplings/display-menu-coupling.service';
import { DisplayDomainModel } from '../../../../../domain/display-domain-model';
import { SectionRowViewModel } from '../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { MenuMetadata } from '../../../../../models/menu/dto/menu-metadata';

// Local type alias
type SyncMap = Map<string, Observable<SectionRowViewModel[][]>>;

// Provided by Product Menu Component
@Injectable()
export class SyncSectionTransitionsService {

  constructor(
    private displayDomainModel: DisplayDomainModel,
    private displayMenuCoupling: DisplayMenuCoupling,
  ) {
  }

  public currentMenu$ = this.displayDomainModel.menuToDisplay.pipe(
    map(menuToDisplay => menuToDisplay?.menu),
    filter(menu => menu instanceof ProductMenu),
  );

  private sectionOverflowDuration$ = this.currentMenu$.pipe(
    map(menu => {
      const userDefinedDuration = menu?.metadata?.sectionOverflowDuration;
      return Number.parseInt(userDefinedDuration || MenuMetadata.defaultSectionOverflowDuration, 10);
    }),
    distinctUntilChanged()
  );

  public sectionOverflowTimer$ = this.displayMenuCoupling.screenshotMode.pipe(
    distinctUntilChanged(),
    switchMap(screenshotMode => {
      const ignoreAllAnimationsWhenInScreenshotMode$ = of(0);
      const sectionDuration$ = this.sectionOverflowDuration$.pipe(switchMap(sec => timer(0, sec * 1000)));
      return iif(() => screenshotMode, ignoreAllAnimationsWhenInScreenshotMode$, sectionDuration$);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _syncedSectionRowViewModels = new BehaviorSubject<SyncMap>(new Map());

  public keyedAndSyncedSectionRowViewModels$ = this._syncedSectionRowViewModels.pipe(
    switchMap(syncMap => {
      return zip(...(syncMap.values() || [])).pipe(
        map((threeDimensionalListOfSectionRowViewModels: SectionRowViewModel[][][]) => {
          const keyValueMapping = new Map<string, SectionRowViewModel[][]>();
          threeDimensionalListOfSectionRowViewModels?.forEach((listOfSectionRowViewModels, index) => {
            const sectionId = [...(syncMap?.keys() || [])]?.[index];
            keyValueMapping.set(sectionId, listOfSectionRowViewModels);
          });
          return keyValueMapping;
        })
      );
    })
  );

  connectToSync = (sectionId: string, sectionRowViewModels$: Observable<SectionRowViewModel[][]>) => {
    const syncMap = this._syncedSectionRowViewModels.getValue();
    if (!syncMap.has(sectionId)) {
      syncMap.set(sectionId, sectionRowViewModels$);
      this._syncedSectionRowViewModels.next(syncMap);
    }
  };

  disconnectFromSync = (sectionId: string) => {
    const syncMap = this._syncedSectionRowViewModels.getValue();
    if (syncMap.has(sectionId)) {
      syncMap.delete(sectionId);
      this._syncedSectionRowViewModels.next(syncMap);
    }
  };

}
