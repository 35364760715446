import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MarketingMenuComponent } from '../marketing-menu.component';
import { MarketingUrlPlaylistMenu } from '../../../../../../models/menu/marketing/marketing-url-playlist-menu';
import { RenderContextService } from '../../../../../services/render-context.service';
import { MarketingUrlPlaylistMenuViewModel } from './marketing-url-playlist-menu-view-model';

@Component({
  selector: 'app-marketing-url-playlist-menu',
  templateUrl: './marketing-url-playlist-menu.component.html',
  styleUrls: ['./marketing-url-playlist-menu.component.scss'],
  providers: [MarketingUrlPlaylistMenuViewModel, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingUrlPlaylistMenuComponent extends MarketingMenuComponent implements AfterViewInit {

  constructor(
    public override viewModel: MarketingUrlPlaylistMenuViewModel
  ) {
    super(viewModel);
  }

  @Input() public override menu: MarketingUrlPlaylistMenu = null;
  @ViewChild('iFrame') iFrame: ElementRef<HTMLIFrameElement>;

}
