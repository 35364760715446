import { Component, ComponentRef, EnvironmentInjector, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, Type, ViewChild } from '@angular/core';
import { ProductMenuFooterComponent } from '../../building-blocks/menu-footer/product-menu-footer.component';
import { ProductMenuFooterInflatorContainerDirective } from './product-menu-footer-inflator-container.directive';
import { ProductMenu } from '../../../../../../../models/menu/product-menu';
import { ProductSection } from '../../../../../../../models/menu/section/product-section';
import { Section } from '../../../../../../../models/menu/section/section';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import { Subscribable } from '../../../../../../../models/base/subscribable';
import { exists } from '../../../../../../../functions/exists';

@Component({
  selector: 'app-product-menu-footer-inflator',
  templateUrl: './product-menu-footer-inflator.component.html',
})
export class ProductMenuFooterInflatorComponent extends Subscribable implements OnInit, OnChanges {

  constructor(
    protected injector: Injector,
    protected environmentInjector: EnvironmentInjector
  ) {
    super();
  }

  @Input() menu: ProductMenu;
  @Input() reset: boolean;
  @Input() lastProductSection: ProductSection;
  @Input() locationConfig: LocationConfiguration;
  @Input() sectionsOnPage: Section[];
  @Output() heightInPx = new EventEmitter<number>(true);
  @ViewChild(ProductMenuFooterInflatorContainerDirective, { static: true })
  contentContainer: ProductMenuFooterInflatorContainerDirective;

  protected compRef: ComponentRef<ProductMenuFooterComponent>;

  ngOnInit() {
    this.inflateFooter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.menu?.previousValue?.id !== changes?.menu?.currentValue?.id) {
      this.inflateFooter();
    }
    if (exists(this.compRef)) {
      if (changes.menu) this.compRef.setInput('menu', this.menu);
      if (changes.reset) this.compRef.setInput('reset', this.reset);
      if (changes.sectionsOnPage) this.compRef.setInput('sectionsOnPage', this.sectionsOnPage);
      if (changes.lastProductSection) this.compRef.setInput('lastProductSection', this.lastProductSection);
      if (changes.locationConfig) this.compRef.setInput('locationConfig', this.locationConfig);
      this.compRef.instance.ngOnChanges(changes);
    }
  }

  protected getFooterType(): Type<ProductMenuFooterComponent> {
    return this.menu.getFooterType();
  }

  protected inflateFooter() {
    this.contentContainer?.viewContainerRef?.clear();
    this.compRef = this
      .contentContainer
      .viewContainerRef
      .createComponent(
        this.getFooterType(),
        { injector: this.injector, environmentInjector: this.environmentInjector }
      );
    this.compRef.setInput('menu', this.menu);
    this.compRef.setInput('reset', this.reset);
    this.compRef.setInput('lastProductSection', this.lastProductSection);
    this.compRef.setInput('locationConfig', this.locationConfig);
    this.compRef.setInput('sectionsOnPage', this.sectionsOnPage);
    this.bind(this.compRef);
  }

  protected bind(componentRef: ComponentRef<ProductMenuFooterComponent>) {
    this.destroy();
    const s = this.heightInPx.bind(componentRef.instance.footerHeightInPx);
    this.pushSub(s);
  }

}
