import { Injectable } from '@angular/core';
import { ProductDealCarouselCardSectionViewModel } from '../product-deal-carousel-card-section-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class ProductDealCarouselCardLandscapeSectionViewModel extends ProductDealCarouselCardSectionViewModel {

  private _carouselContainerWidth = new BehaviorSubject<number>(0);
  public carouselContainerWidth$ = this._carouselContainerWidth.pipe(distinctUntilChanged());
  connectToCarouselContainerWidth = (width: number) => this._carouselContainerWidth.next(width);

  private _leftSideOfCardWidth = new BehaviorSubject<number>(0);
  public leftSideOfCardWidth$ = this._leftSideOfCardWidth.pipe(distinctUntilChanged());
  connectToLeftSideOfCardWidth = (width: number) => this._leftSideOfCardWidth.next(width);

  private _productDealInfoContainerWidth = new BehaviorSubject<number>(0);
  public productDealInfoContainerWidth$ = this._productDealInfoContainerWidth.pipe(distinctUntilChanged());
  connectToProductDealInfoContainerWidth = (width: number) => this._productDealInfoContainerWidth.next(width);

  public productNameWidth$ = combineLatest([
    this.carouselContainerWidth$,
    this.leftSideOfCardWidth$,
    this.productDealInfoContainerWidth$,
  ]).pipe(
    map(([carouselContainerWidth, leftSideOfCardWidth, productDealInfoContainerWidth]) => {
      const remainingSpace = carouselContainerWidth - leftSideOfCardWidth;
      return productDealInfoContainerWidth + remainingSpace;
    })
  );

}
