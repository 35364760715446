import { Component, ElementRef, forwardRef, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ProductDealCarouselCardSectionViewModel } from '../product-deal-carousel-card-section-view-model';
import { FeaturedCategoryCardComponent } from '../../featured-category-card.component';
import { featuredCategoryCardSectionAnimations } from '../../card_animations';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-product-deal-carousel-card-portrait-section',
  templateUrl: './product-deal-carousel-card-portrait-section.component.html',
  styleUrls: ['./product-deal-carousel-card-portrait-section.component.scss'],
  providers: [
    ProductDealCarouselCardSectionViewModel,
    {
      provide: FeaturedCategoryCardComponent,
      useExisting: forwardRef(() => ProductDealCarouselCardPortraitSectionComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [featuredCategoryCardSectionAnimations]
})
export class ProductDealCarouselCardPortraitSectionComponent extends FeaturedCategoryCardComponent
  implements OnChanges {

  constructor(
    public override viewModel: ProductDealCarouselCardSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.signalPagination) this.viewModel?.connectToUpdateCurrentCarouselPosition(this.signalPagination);
    if (changes.reset) this.viewModel?.connectToReset(this.reset);
  }

}
