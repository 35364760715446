import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnCannabinoidType, SectionColumnType } from '../product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Pipe({
  name: 'isCannabinoidColumn',
  standalone: true
})
export class IsCannabinoidColumnPipe implements PipeTransform {

  transform(columnType: SectionColumnType): SectionColumnCannabinoidType | null {
    const isCannabinoid = Object.values(SectionColumnCannabinoidType)
      .includes(columnType as SectionColumnCannabinoidType);
    return isCannabinoid ? columnType as SectionColumnCannabinoidType : null;
  }

}
