import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { DisplayableProductCard } from '../displayable-product-card';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetUtils } from '../../../../../../../../utils/asset-utils';
import { exists } from '../../../../../../../../functions/exists';

@Injectable()
export class BaseProductCardViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _card = new BehaviorSubject<DisplayableProductCard>(null);
  public card$ = this._card as Observable<DisplayableProductCard>;
  connectToCard = (card: DisplayableProductCard) => this._card.next(card);

  private _index = new BehaviorSubject<number>(undefined);
  public index$ = this._index as Observable<number>;
  connectToIndex = (index: number) => this._index.next(index);

  private _cardCount = new BehaviorSubject<number>(undefined);
  public cardCount$ = this._cardCount as Observable<number>;
  connectToCardCount = (cardCount: number) => this._cardCount.next(cardCount);

  public asset$ = this.card$.pipe(
    map(card => card?.getAsset())
  );

  public hasAsset$ = this.asset$.pipe(
    map(asset => exists(asset))
  );

  public assetFallBackIconUrl$ = this.card$.pipe(
    map(card => {
      const productType = card?.getProductType();
      const variantType = card?.getVariantsForLabelComponent()?.firstOrNull()?.variantType;
      return AssetUtils.budSenseProductTypeUrls(productType, variantType);
    })
  );

}
