import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { MarketingMenu } from '../../../models/menu/marketing-menu';

@Pipe({
  name: 'castToMarketingMenu'
})
export class CastToMarketingMenuPipe implements PipeTransform {

  transform(value: Menu): MarketingMenu {
    if (value instanceof MarketingMenu) {
      return value as MarketingMenu;
    }
    return null;
  }

}
