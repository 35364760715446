import { Pipe, PipeTransform } from '@angular/core';
import { StrainType } from '../../../../../models/enum/dto/strain-classification.enum';
import { StrainTypeUtils } from '../../../../../utils/strain-type-utils';

@Pipe({
  name: 'getClassificationSymbol'
})
export class GetClassificationSymbolPipe implements PipeTransform {

  transform(value: StrainType): string {
    return StrainTypeUtils.getSymbol(value);
  }

}
