import { Pipe, PipeTransform } from '@angular/core';
import { ProductSection } from '../../../models/menu/section/product-section';

@Pipe({
  name: 'getLastKnownProductSectionForPage'
})
export class GetLastKnownProductSectionForPage implements PipeTransform {

  transform(productSectionsLastOnPage: ProductSection[], pageIndex: number): ProductSection {
    return productSectionsLastOnPage?.filter(section => section?.pageIndex <= pageIndex)?.last();
  }

}
