import { Type } from '@angular/core';
import { HeaderImageCarouselCardSectionComponent } from '../modules/display/components/menus/marketing-menu/building-blocks/card-sections/header-image-carousel-card-section/header-image-carousel-card-section.component';
import { MenuSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/menu-section.component';
import { MarketingFeaturedCategoryStaticGridMenu } from '../models/menu/marketing/featured-category/marketing-featured-category-static-grid-menu';
import { FeaturedCategorySection } from '../models/menu/section/featured-category/featured-category-section';
import { CardType } from '../modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-type.enum';
import { ProductDealCarouselCardPortraitSectionComponent } from '../modules/display/components/menus/marketing-menu/building-blocks/card-sections/product-deal-carousel-card-section/product-deal-carousel-card-portrait-section/product-deal-carousel-card-portrait-section.component';
import { ProductDealCarouselCardLandscapeSectionComponent } from '../modules/display/components/menus/marketing-menu/building-blocks/card-sections/product-deal-carousel-card-section/product-deal-carousel-card-landscape-section/product-deal-carousel-card-landscape-section.component';
import { MarketingFeaturedCategoryMenu } from '../models/menu/marketing/featured-category/marketing-featured-category-menu';
import { HeaderImageCardSectionComponent } from '../modules/display/components/menus/marketing-menu/building-blocks/card-sections/header-image-card-section/header-image-card-section.component';
import { PlantlifeNonSmokableEmptySection } from '../models/menu/section/plantlife-non-smokable-empty-section';
import { PlantlifeNonSmokableEmptySectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/empty-section/plantlife-non-smokable-empty-section/plantlife-non-smokable-empty-section.component';
import { PlantlifeEmptySection } from '../models/menu/section/plantlife-empty-section';
import { PlantlifeEmptySectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/empty-section/plantlife-empty-section/plantlife-empty-section.component';
import { DoubleDutchEmptySection } from '../models/menu/section/double-dutch-empty-section';
import { DoubleDutchEmptySectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/empty-section/double-dutch-empty-section/double-dutch-empty-section.component';
import { ReportNewProductsSection } from '../models/menu/section/report/report-new-products-section';
import { ReportNewProductsSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/report-section/new-products-section/report-new-products-section.component';
import { ReportRestockedProductsSection } from '../models/menu/section/report/report-restocked-products-section';
import { ProductSectionWithTransitionsComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/product-section-with-transitions/product-section-with-transitions.component';
import { SectionType } from '../models/enum/dto/section-type.enum';
import { PlantlifeNonSmokableMenu } from '../models/menu/print/plantlife-non-smokable-menu';
import { SplitProductSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/split-product-section/split-product-section.component';
import { PlantlifeMenu } from '../models/menu/print/plantlife-menu';
import { SativaHybridIndicaSplitProductSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/sativa-hybrid-indica-split-product-section/sativa-hybrid-indica-split-product-section.component';
import { RuntzMenu } from '../models/menu/product/runtz-menu';
import { RuntzTitleSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/title-section/runtz-title-section/runtz-title-section.component';
import { TitleSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/title-section/title-section.component';
import { AssetSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/image-section/asset-section.component';
import { PageBreakSectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/page-break-section/page-break-section.component';
import { EmptySectionComponent } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/empty-section/empty-section.component';
import type { Menu } from '../models/menu/menu';
import type { Section } from '../models/menu/section/section';

export class SectionInflatorUtils {

  public static getSectionComponentType(menu: Menu, section: Section): Type<MenuSectionComponent> {
    if (menu instanceof MarketingFeaturedCategoryStaticGridMenu) {
      if (section instanceof FeaturedCategorySection) {
        switch (section?.cardType) {
          case CardType.HeaderImageCarousel: {
            return HeaderImageCarouselCardSectionComponent;
          }
          case CardType.ProductDealCarousel: {
            return menu?.isPortrait()
              ? ProductDealCarouselCardPortraitSectionComponent
              : ProductDealCarouselCardLandscapeSectionComponent;
          }
          default:
            return HeaderImageCarouselCardSectionComponent;
        }
      }
    }
    if (menu instanceof MarketingFeaturedCategoryMenu) {
      return HeaderImageCardSectionComponent;
    }
    if (section instanceof PlantlifeNonSmokableEmptySection) {
      return PlantlifeNonSmokableEmptySectionComponent;
    }
    if (section instanceof PlantlifeEmptySection) {
      return PlantlifeEmptySectionComponent;
    }
    if (section instanceof DoubleDutchEmptySection) {
      return DoubleDutchEmptySectionComponent;
    }
    if (section instanceof ReportNewProductsSection) {
      return ReportNewProductsSectionComponent;
    }
    if (section instanceof ReportRestockedProductsSection) {
      return ProductSectionWithTransitionsComponent;
    }
    switch (section?.sectionType) {
      case SectionType.Product:
      case SectionType.PlantlifeProduct: {
        switch (menu.constructor) {
          case PlantlifeNonSmokableMenu:
            return SplitProductSectionComponent;
          case PlantlifeMenu:
            return SativaHybridIndicaSplitProductSectionComponent;
        }
        return ProductSectionWithTransitionsComponent;
      }
      case SectionType.DoubleDutchProduct:
        return SplitProductSectionComponent;
      case SectionType.Title: {
        switch (menu.constructor) {
          case RuntzMenu:
            return RuntzTitleSectionComponent;
        }
        return TitleSectionComponent;
      }
      case SectionType.Asset:
        return AssetSectionComponent;
      case SectionType.PageBreak:
        return PageBreakSectionComponent;
      case SectionType.Empty:
        return EmptySectionComponent;
    }
    return EmptySectionComponent;
  }

}
