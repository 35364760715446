/* src/app/modules/display/components/menus/render-print-menu-live-view/render-print-menu-live-view.component.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
:host ::ng-deep .background-asset-container {
  height: unset !important;
  width: unset !important;
}
:host ::ng-deep .background-asset {
  position: absolute !important;
}
/*# sourceMappingURL=render-print-menu-live-view.component.css.map */
