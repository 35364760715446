import { Injectable } from '@angular/core';
import { BehaviorSubject, defer } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseViewModel } from '../../../../../../../../../models/base/base-view-model';
import type { PrintMenu } from '../../../../../../../../../models/menu/print-menu';
import type { WrappingSwimlaneSection } from '../../../../../../../../../models/menu/section/wrapping-swimlane-section';

@Injectable()
export class WrappingSwimlaneSectionViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _menu = new BehaviorSubject<PrintMenu>(null);
  public menu$ = defer(() => this._menu);

  private _section = new BehaviorSubject<WrappingSwimlaneSection>(null);
  public section$ = defer(() => this._section);
  public nSwimlanes$ = this.section$.pipe(map(s => s.nSwimlanes || 1));

  public connectToMenu = (menu: PrintMenu) => this._menu.next(menu);
  public connectToSection = (section: WrappingSwimlaneSection) => this._section.next(section);

}
