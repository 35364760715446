import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductSectionComponent } from '../../../product-menu/building-blocks/menu-section/product-section/product-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FeaturedCategoryCardViewModel } from './featured-category-card-view-model';

@Component({ selector: 'app-featured-category-card', template: '' })
export abstract class FeaturedCategoryCardComponent extends ProductSectionComponent implements OnChanges {

  protected constructor(
    public override viewModel: FeaturedCategoryCardViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

  @Input() signalPagination: any;

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.signalPagination) this.viewModel?.connectToUpdateCurrentCarouselPosition(this.signalPagination);
    if (changes.reset) this.viewModel?.connectToReset(this.reset);
  }

}
