import { AfterViewInit, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MarketingMenuComponent } from '../marketing-menu.component';
import { DeprecatedMarketingMenuViewModel } from '../deprecated-marketing-menu-view-model';
import { OrientationService } from '../../../../../../services/orientation.service';
import { LabelInflatorComponent } from '../../../../../labels/components/product-menu-label-inflator/label-inflator.component';
import { takeUntil } from 'rxjs/operators';
import { MarketingFeaturedProductMenu } from '../../../../../../models/menu/marketing/FeaturedProduct/marketing-featured-product-menu';
import { RenderContextService } from '../../../../../services/render-context.service';

@Component({
  selector: 'app-marketing-featured-product-menu',
  templateUrl: './marketing-featured-product-menu.component.html',
  styleUrls: ['./marketing-featured-product-menu.component.scss'],
  providers: [DeprecatedMarketingMenuViewModel, RenderContextService],
  encapsulation: ViewEncapsulation.None
})
export class MarketingFeaturedProductMenuComponent extends MarketingMenuComponent implements AfterViewInit {

  constructor(
    public vm: DeprecatedMarketingMenuViewModel,
    private orientationService: OrientationService
  ) {
    super(vm);
  }

  public isPortrait$ = this.orientationService.isPortrait$;
  public evenPosition$ = this.vm.evenPosition$;
  public oddPosition$ = this.vm.oddPosition$;
  @Input() public override menu: MarketingFeaturedProductMenu = null;

  @ViewChild('isThereALabel') isThereALabel: LabelInflatorComponent;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.isThereALabel?.currentLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.vm.connectToLabelText(labelText));
  }

}
