<app-menu
  class="relative-menu"
  [style.z-index]="vm.menuZIndices$ | async | getArrayElementAtIndex : 0"
  [menu]="vm.menu0$ | async"
  [hidePrices]="vm.hidePrices | async"
  [locationId]="vm.locationId | async"
  [companyConfig]="vm.companyConfig | async"
  [locationConfig]="vm.locationConfig | async"
  [loading]="vm.loading | async"
  [reset]="vm.menu0Reset$ | async"
  [backOfMenuFlipper]="!(vm.flipIsActive$ | async)">
</app-menu>
<app-menu
  class="relative-menu"
  [style.z-index]="vm.menuZIndices$ | async | getArrayElementAtIndex : 1"
  [menu]="vm.menu1$ | async"
  [hidePrices]="vm.hidePrices | async"
  [locationId]="vm.locationId | async"
  [companyConfig]="vm.companyConfig | async"
  [locationConfig]="vm.locationConfig | async"
  [loading]="vm.loading | async"
  [reset]="vm.menu1Reset$ | async"
  [backOfMenuFlipper]="vm.flipIsActive$ | async">
</app-menu>
