import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, defer, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { Section } from '../../../../../../models/menu/section/section';
import { ProductMenu } from '../../../../../../models/menu/product-menu';
import { CopyableProductSection } from '../../../../../../models/menu/section/copyable-product-section';
import { Variant } from '../../../../../../models/product/dto/variant';
import { MenuSectionInflatorInterface } from '../../interface/menu-section-inflator-interface';
import { MenuSectionComponent } from '../../product-menu/building-blocks/menu-section/menu-section.component';
import { ProductSectionComponent } from '../../product-menu/building-blocks/menu-section/product-section/product-section.component';
import { MenuItemComponent } from '../../product-menu/building-blocks/menu-item/menu-item.component';
import { DistinctUtils } from '../../../../../../utils/distinct.utils';
import { IsMenuReadyService } from '../../../../../services/is-menu-ready.service';
import { EmptySection } from '../../../../../../models/menu/section/empty-section';
import { Menu } from '../../../../../../models/menu/menu';
import { PageBreakSectionComponent } from '../../product-menu/building-blocks/menu-section/page-break-section/page-break-section.component';
import { PageBreakSection } from '../../../../../../models/menu/section/page-break-section';
import { SativaHybridIndicaSplitProductSectionComponent } from '../../product-menu/building-blocks/menu-section/product-section/sativa-hybrid-indica-split-product-section/sativa-hybrid-indica-split-product-section.component';
import { PrintMenu } from '../../../../../../models/menu/print-menu';
import { SectionDimensions } from '../../product-menu/building-blocks/menu-section/section-dimensions';
import { PrintHeaderLayoutType } from '../../../../../../models/enum/shared/print-header-layout-type.enum';
import { SplitProductSectionComponent } from '../../product-menu/building-blocks/menu-section/product-section/split-product-section/split-product-section.component';
import { ProductSection } from '../../../../../../models/menu/section/product-section';
import { DisplayMenuCoupling } from '../../../../../../couplings/display-menu-coupling.service';
import { Orientation } from '../../../../../../models/enum/dto/orientation.enum';
import { EmptySectionUtils } from '../../../../../../utils/empty-section-utils';
import { DoubleDutchEmptySection } from '../../../../../../models/menu/section/double-dutch-empty-section';
import { PlantlifeEmptySection } from '../../../../../../models/menu/section/plantlife-empty-section';
import { PlantlifeNonSmokableEmptySection } from '../../../../../../models/menu/section/plantlife-non-smokable-empty-section';
import { LocationConfiguration } from '../../../../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../../../../models/company/dto/company-configuration';
import { TitleSection } from '../../../../../../models/menu/section/title-section';
import { exists } from '../../../../../../functions/exists';
import { AssetSection } from '../../../../../../models/menu/section/asset-section';
import { SectionUtils } from '../../../../../../utils/section-utils';

@Injectable()
export abstract class MenuSectionOverflowCalculatorViewModel extends BaseViewModel {

  protected constructor(
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected isMenuReadyService: IsMenuReadyService
  ) {
    super();
  }

  protected taskSpreadInMilliSeconds: number = 5;
  protected screenshotMode$ = this.displayMenuCoupling.screenshotMode;

  protected readonly _backOfMenuFlipper = new BehaviorSubject<boolean>(false);
  public readonly backOfMenuFlipper$ = this._backOfMenuFlipper.pipe(distinctUntilChanged());
  connectToBackOfMenuFlipper = (flipper: boolean) => this._backOfMenuFlipper.next(flipper);

  public readonly productSectionInflatorRenderStrategy$ = this.backOfMenuFlipper$.pipe(
    map(backOfMenuFlipper => backOfMenuFlipper ? 'normal' : 'immediate'),
  );

  protected _companyConfig = new BehaviorSubject<CompanyConfiguration>(null);
  public readonly companyConfig$ = this._companyConfig as Observable<CompanyConfiguration>;

  protected _locationConfig = new BehaviorSubject<LocationConfiguration>(null);
  public readonly locationConfig$ = this._locationConfig as Observable<LocationConfiguration>;

  protected _menu = new BehaviorSubject<ProductMenu>(null);
  protected _sectionsContainerHeight = new BehaviorSubject<number>(0);
  protected sectionsContainerHeight$ = this._sectionsContainerHeight.asObservable();
  protected _sectionsOverflowHeight = new BehaviorSubject<number>(0);
  protected _sections = new BehaviorSubject<MenuSectionComponent[]>([]);
  protected sectionHeights$ = this._sections.pipe(
    debounceTime(250),
    map(sections => sections?.filter(comp => comp?.section instanceof ProductSection ? !comp?.section?.empty : true)),
    switchMap(sections => combineLatest(sections.map(section => section?.getComponentAndHeight()))),
    // wait for all sections to have a dimensions object
    filter(sectionAndHeights => sectionAndHeights?.every(([_, dimensions]) => !!dimensions)),
  );

  protected _emptySectionComponent = new BehaviorSubject<MenuSectionComponent>(null);
  protected emptySectionAdditionalHeight$ = this._emptySectionComponent.pipe(
    switchMap(section => section?.getComponentAndHeight() || of([null, null])),
    map(([, height]) => height?.getTotalHeight() ?? 0),
    startWith(0)
  );

  public overflowedSections$ = combineLatest([
    combineLatest([this.companyConfig$, this.locationConfig$]),
    defer(() => this._menu).notNull(),
    this.sectionsContainerHeight$.pipe(filter(height => height > 0)),
    this.sectionHeights$,
    this.screenshotMode$,
  ]).pipe(
    tap(_ => this.isMenuReadyService.overflow(true)),
    debounceTime(100),
    map(([[companyConfig, locationConfig], menu, pageHeight, sectionHeights, screenshotMode]) => {
      const isPrint = menu instanceof PrintMenu;
      const shouldOverflow = (menu?.getShouldSectionsContainerFlexWrap() && (pageHeight > 0));
      const isPortrait = menu?.displaySize?.orientation === Orientation.Portrait;
      const screenShotAndPortrait = screenshotMode && isPortrait;
      if (shouldOverflow || isPrint || screenShotAndPortrait) {
        // Calculate overflow to only show sections that will be visible on the first page
        this.menu = menu;
        this.companyConfig = companyConfig;
        this.locationConfig = locationConfig;
        this.canvasHeight = pageHeight;
        this.runningPageHeight = 0;
        this.pageIndex = 0;
        this.overflowSections = [];
        this.screenshotMode = screenshotMode;
        return this.createOverflowSections(menu, pageHeight, sectionHeights);
      } else {
        return menu?.sections || [];
      }
    }),
    tap(_ => this.isMenuReadyService.overflow(false)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected menu: ProductMenu;
  protected companyConfig: CompanyConfiguration;
  protected locationConfig: LocationConfiguration;
  // The size of the canvas in which you want to place content on.
  protected canvasHeight: number;
  // Used for calculating overflow sections. Can change on a per-page basis on whether you want to show
  // headers or footers.
  protected pageHeight = 0;
  // When the page overflows the page running height is set to 0 for the next pages calculations,
  // therefore, runningPageHeight is the page cursor.
  protected runningPageHeight = 0;
  protected pageIndex = 0;
  protected overflowSections: [Section, SectionDimensions][] = [];
  // Keep track of last product section
  protected currentProductComponent: MenuSectionComponent;
  // When in screenshot mode, only render the first visible page
  protected screenshotMode = false;

  connectToLocationConfig = (config: LocationConfiguration) => this._locationConfig.next(config);
  connectToCompanyConfig = (config: CompanyConfiguration) => this._companyConfig.next(config);
  connectToMenu = (menu: ProductMenu) => this._menu.next(menu);
  connectToSectionsOverflowHeight = (height: number) => this._sectionsOverflowHeight.next(height);
  connectToSectionsContainerHeight = (height: number) => this._sectionsContainerHeight.next(height);
  connectToSections = (sections: MenuSectionInflatorInterface[]) => {
    const emptySectionKey = 'empty-section-height-calculator';
    const emptySection = sections?.find(inflator => inflator?.getMenuSectionInflatorId() === emptySectionKey);
    const inflators = sections?.filter(inflator => inflator?.getMenuSectionInflatorId() !== emptySectionKey);
    const sectionComponents = inflators?.map(s => s.getChildSectionComponent());
    this._emptySectionComponent.next(emptySection?.getChildSectionComponent());
    this._sections.next(sectionComponents);
  };

  /* [1] ---------------------------- Calculate Overflow Sections ---------------------------- [1] */

  public trackBySectionUniqueIdentifier = (index: number, section: Section): string => {
    return section?.getUniqueIdentifier();
  };

  /* Entrypoint into [1] */
  protected createOverflowSections(
    menu: Menu,
    pageHeight: number,
    sectionHeights: [MenuSectionComponent, SectionDimensions][]
  ): Section[] {
    this.resetOverflowSectionData(sectionHeights);
    this.calculatePageHeight();
    this.currentProductComponent = null;
    if (this.shouldSplitSectionsInHalf(menu)) {
      this.overflowSections = sectionHeights
        ?.map(([sectionComponent, ]) => this.splitSectionInHalf(sectionComponent))
        ?.flatten<Section[]>()
        ?.filterNulls()
        ?.map(section => [section, new SectionDimensions()]);
      return this.overflowSections?.map(([section]) => section);
    }
    for (const [sectionComponent, sectionHeight] of sectionHeights) {
      this.calculateSectionPlacementAtPageCursorPosition(sectionComponent, sectionHeight);
    }
    const remainingSpace = this.pageHeight - this.runningPageHeight;
    const shouldFillRemainingSpace = remainingSpace > 0 && remainingSpace < this.pageHeight;
    if (shouldFillRemainingSpace) this.fillRemainingSpace();
    this.handleLastSection();
    return this.overflowSections?.map(([section]) => section);
  }

  protected shouldSplitSectionsInHalf(menu: Menu): boolean {
    if (menu instanceof ProductMenu) {
      const portrait = menu.isPortrait();
      const scrolling = menu.isInVerticalScrollingOverflowState();
      const moreThanOneColumn = menu.getSectionWidthPercentage() < 100;
      return portrait && scrolling && moreThanOneColumn;
    }
    return false;
  }

  protected splitSectionInHalf(sectionComponent: MenuSectionComponent): Section[] {
    if (sectionComponent instanceof ProductSectionComponent) {
      const originalSection = sectionComponent?.section as CopyableProductSection;
      const itemComponentsAndHeights = sectionComponent?.getMenuItemComponentsWithHeights();
      const nLineItems = itemComponentsAndHeights.length || 0;
      if (nLineItems === 0) {
        return [];
      } else if (nLineItems === 1) {
        return [sectionComponent?.section, new EmptySection()];
      } else if (nLineItems > 1) {
        const half = Math.ceil(itemComponentsAndHeights?.length / 2);
        const sectionRowViewModels = itemComponentsAndHeights
          ?.map(([comp, ]) => comp.rowViewModel);
        const variantPool = sectionRowViewModels
          ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
          ?.flatten<Variant[]>();
        const firstHalfVariants = sectionRowViewModels
          ?.slice(0, half)
          ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
          ?.flatten<Variant[]>();
        const secondHalf = originalSection
          ?.changeVariantPool(variantPool)
          ?.copyWithout(this.menu, this.companyConfig, this.locationConfig, firstHalfVariants);
        const firstHalf = originalSection
          ?.changeVariantPool(variantPool)
          ?.copyWithout(this.menu, this.companyConfig, this.locationConfig, secondHalf?.variants);
        return [firstHalf, secondHalf];
      }
    } else {
      return [sectionComponent?.section, new EmptySection()];
    }
  }

  protected calculateSectionPlacementAtPageCursorPosition(
    sectionComponent: MenuSectionComponent,
    sectionHeight: SectionDimensions
  ): void {
    if (this.stopSectionLayoutForScreenshotMode()) {
      return;
    }
    if (sectionComponent instanceof PageBreakSectionComponent) {
      this.moveCursorToNewPage();
    } else if (this.sectionFitsOntoPageAtCurrentPosition(sectionComponent, sectionHeight)) {
      this.sectionFitsOnPage(sectionComponent?.section, sectionHeight);
    } else {
      this.sectionDidNotFitAtCurrentPosition(sectionComponent, sectionHeight);
    }
  }

  protected stopSectionLayoutForScreenshotMode(): boolean {
    if (this.screenshotMode && this.menu?.getNumberOfColumns() > 1) {
      const nColumnsLaidOut = this.pageIndex + 1;
      return nColumnsLaidOut > this.menu?.getNumberOfColumns();
    }
    return this.screenshotMode && this.pageIndex > 0;
  }

  protected sectionFitsOntoPageAtCurrentPosition(
    sectionComp: MenuSectionComponent,
    sectionHeight: SectionDimensions
  ): boolean {
    let newTotalHeight: number;
    switch (true) {
      case this.accountForCollapsingMarginIfNoHeaderAndAtTopOfPage():
      case this.shouldRemoveTopMarginFromHeightCalculation(sectionComp?.section):
        newTotalHeight = this.runningPageHeight + sectionHeight?.getHeightWithoutTopMargin();
        break;
      case this.runningPageHeight !== 0 && exists(this.overflowSections?.last()):
        const [, aboveMeDimensions] = this.overflowSections?.last() || [null, null];
        newTotalHeight = this.runningPageHeight + sectionHeight?.getHeightWithCollapsedMargin(aboveMeDimensions);
        break;
      default:
        newTotalHeight = this.runningPageHeight + sectionHeight?.getTotalHeight();
        break;
    }
    const fitsOnPage = newTotalHeight <= this.pageHeight;
    const isProductSection = sectionComp instanceof ProductSectionComponent
      || sectionComp instanceof SativaHybridIndicaSplitProductSectionComponent;
    const notProductSectionAndDoesNotFitStartingAtTopOfPage
      = !isProductSection && !fitsOnPage && this.runningPageHeight === 0;
    return fitsOnPage || notProductSectionAndDoesNotFitStartingAtTopOfPage;
  }

  protected sectionFitsOnPage(section: Section, height: SectionDimensions): void {
    this.addSectionToOverflowAndUpdateCursorPosition(section, height);
  }

  protected sectionDidNotFitAtCurrentPosition(
    sectionComponent: MenuSectionComponent,
    sectionHeight: SectionDimensions
  ): void {
    if (sectionComponent instanceof SativaHybridIndicaSplitProductSectionComponent) {
      this.calculateSativaHybridIndicaSplitOverflow(sectionComponent, sectionHeight); // -> goto [2]
    } else if (sectionComponent instanceof SplitProductSectionComponent) {
      this.calculateSplitProductOverflow(sectionComponent, sectionHeight); // -> goto [2]
    } else if (sectionComponent instanceof ProductSectionComponent) {
      this.calculateProductSectionOverflow(sectionComponent, sectionHeight); // -> goto [2]
    } else {
      this.moveCursorToNewPage();
      this.calculateSectionPlacementAtPageCursorPosition(sectionComponent, sectionHeight);
    }
  }

  /* [2] ********************* Update Cursor Position For Product Section ********************* [2] */

  /* Entrypoint into [2] if SativaHybridIndicaSplitProductSectionComponent */
  protected calculateSativaHybridIndicaSplitOverflow(
    sectionComponent: SativaHybridIndicaSplitProductSectionComponent,
    sectionDimensions: SectionDimensions
  ): void {
    const expandedHeaderHeight = sectionComponent.getExpandedHeaderHeight() || 0;
    const collapsedHeaderHeight = sectionComponent.getCollapsedHeaderHeight() || 0;
    const sectionHeightWithoutMargins = this.getSectionHeightWithoutMargins(sectionDimensions);
    // Sativa
    const sativaItemsAndHeights = sectionComponent?.getSativaItemComponentsWithHeights();
    const sativaItemCombinedHeight = sativaItemsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const sativaSectionPaddingBorderExpanded = sectionHeightWithoutMargins
      - (expandedHeaderHeight + sativaItemCombinedHeight);
    const sativaSectionPaddingBorderCollapsed =
      (sectionHeightWithoutMargins - expandedHeaderHeight + collapsedHeaderHeight)
      - (collapsedHeaderHeight + sativaItemCombinedHeight);
    const sativaNonItemSpaceExpanded = expandedHeaderHeight + sativaSectionPaddingBorderExpanded;
    const sativaNonItemSpaceCollapsed = collapsedHeaderHeight + sativaSectionPaddingBorderCollapsed;
    // Hybrid
    const hybridItemsAndHeights = sectionComponent?.getHybridItemComponentsWithHeights();
    const hybridItemCombinedHeight = hybridItemsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const hybridSectionPaddingBorderExpanded = sectionHeightWithoutMargins
      - (expandedHeaderHeight + hybridItemCombinedHeight);
    const hybridSectionPaddingBorderCollapsed =
      (sectionHeightWithoutMargins - expandedHeaderHeight + collapsedHeaderHeight)
      - (collapsedHeaderHeight + hybridItemCombinedHeight);
    const hybridNonItemSpaceExpanded = expandedHeaderHeight + hybridSectionPaddingBorderExpanded;
    const hybridNonItemSpaceCollapsed = collapsedHeaderHeight + hybridSectionPaddingBorderCollapsed;
    // Indica
    const indicaItemsAndHeights = sectionComponent?.getIndicaItemComponentsWithHeights();
    const indicaItemCombinedHeight = indicaItemsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const indicaSectionPaddingBorderExpanded = sectionHeightWithoutMargins
      - (expandedHeaderHeight + indicaItemCombinedHeight);
    const indicaSectionPaddingBorderCollapsed =
      (sectionHeightWithoutMargins - expandedHeaderHeight + collapsedHeaderHeight)
      - (collapsedHeaderHeight + indicaItemCombinedHeight);
    const indicaNonItemSpaceExpanded = expandedHeaderHeight + indicaSectionPaddingBorderExpanded;
    const indicaNonItemSpaceCollapsed = collapsedHeaderHeight + indicaSectionPaddingBorderCollapsed;
    // Check if cursor needs to move to a new page
    let nonItemUsedSpaceExpanded;
    let nonItemUsedSpaceCollapsed;
    let itemComponentsAndHeights;
    const largestHeight = Math.max(sativaItemCombinedHeight, hybridItemCombinedHeight, indicaItemCombinedHeight);
    switch (largestHeight) {
      case sativaItemCombinedHeight:
        nonItemUsedSpaceExpanded = sativaNonItemSpaceExpanded;
        nonItemUsedSpaceCollapsed = sativaNonItemSpaceCollapsed;
        itemComponentsAndHeights = sativaItemsAndHeights;
        break;
      case hybridItemCombinedHeight:
        nonItemUsedSpaceExpanded = hybridNonItemSpaceExpanded;
        nonItemUsedSpaceCollapsed = hybridNonItemSpaceCollapsed;
        itemComponentsAndHeights = hybridItemsAndHeights;
        break;
      default:
        nonItemUsedSpaceExpanded = indicaNonItemSpaceExpanded;
        nonItemUsedSpaceCollapsed = indicaNonItemSpaceCollapsed;
        itemComponentsAndHeights = indicaItemsAndHeights;
        break;
    }
    const nonItemUsedSpace = this.shouldExpandSectionHeader()
      ? nonItemUsedSpaceExpanded
      : nonItemUsedSpaceCollapsed;
    const oneItemFitsWithHeader = this.headerAndOneItemFitsOntoPageAtCursorPosition(
      nonItemUsedSpace,
      itemComponentsAndHeights,
      sectionDimensions.copyWith(sectionHeightWithoutMargins)
    );
    if (!oneItemFitsWithHeader) {
      this.moveCursorToNewPage();
    }
    if (sectionComponent.section instanceof CopyableProductSection) {
      this.layoutSativaHybridIndicaSplitProductSectionOntoPage(
        sectionComponent.section,
        nonItemUsedSpaceExpanded,
        nonItemUsedSpaceCollapsed,
        sectionDimensions.copyWith(sectionHeightWithoutMargins),
        sativaItemsAndHeights,
        hybridItemsAndHeights,
        indicaItemsAndHeights
      ); // -> goto [4]
    }
  }

  protected calculateSplitProductOverflow(
    sectionComponent: SplitProductSectionComponent,
    sectionDimensions: SectionDimensions
  ): void {
    const sectionHeaderHeight = this.getSectionHeaderHeight(sectionComponent);
    const itemCompsAndHeights = sectionComponent?.getMenuItemComponentsWithHeights();
    // this is counterintuitive because the index starts at 0, therefore, the first odd item starts at 0
    const oddItems = itemCompsAndHeights?.filter((item, index) => index % 2 === 0);
    const combinedItemHeight = oddItems
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const sectionPaddingBorder = sectionDimensions?.getHeightWithoutMargins()
      - (sectionHeaderHeight + combinedItemHeight);
    const originalNonItemUsedSpace = sectionHeaderHeight + sectionPaddingBorder;
    const oneItemAndHeaderFits = this.headerAndOneItemFitsOntoPageAtCursorPosition(
      originalNonItemUsedSpace,
      itemCompsAndHeights,
      sectionDimensions
    );
    if (!oneItemAndHeaderFits) {
      this.moveCursorToNewPage();
    }
    if (sectionComponent.section instanceof CopyableProductSection) {
      this.layoutProductSectionOntoPage(
        sectionComponent?.section,
        sectionComponent,
        originalNonItemUsedSpace,
        itemCompsAndHeights,
        sectionDimensions
      ); // -> goto [3]
    }
  }

  /* Entrypoint into [2] if ProductSectionComponent or SplitProductSection */
  protected calculateProductSectionOverflow(
    sectionComponent: ProductSectionComponent,
    sectionDimensions: SectionDimensions
  ): void {
    const sectionHeaderHeight = this.getSectionHeaderHeight(sectionComponent);
    const itemCompsAndHeights = sectionComponent?.getMenuItemComponentsWithHeights();
    const combinedItemHeight = itemCompsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const sectionPaddingBorder = sectionDimensions?.getHeightWithoutMargins()
      - (sectionHeaderHeight + combinedItemHeight);
    const originalNonItemUsedSpace = sectionHeaderHeight + sectionPaddingBorder;
    const oneItemAndHeaderFits = this.headerAndOneItemFitsOntoPageAtCursorPosition(
      originalNonItemUsedSpace,
      itemCompsAndHeights,
      sectionDimensions
    );
    if (!oneItemAndHeaderFits) {
      this.moveCursorToNewPage();
    }
    if (sectionComponent.section instanceof CopyableProductSection) {
      this.layoutProductSectionOntoPage(
        sectionComponent?.section,
        sectionComponent,
        originalNonItemUsedSpace,
        itemCompsAndHeights,
        sectionDimensions
      ); // -> goto [3]
    }
  }

  protected getNonItemUsedSpace(
    headerHeight: number,
    originalNonItemUsedSpace: number,
  ): number {
    if (this.menu?.getOnlyShowFirstSectionHeader()) {
      if (this.pageIndex === 0 && this.runningPageHeight === 0) {
        return originalNonItemUsedSpace;
      } else {
        return originalNonItemUsedSpace - headerHeight;
      }
    }
    return originalNonItemUsedSpace;
  }

  protected headerAndOneItemFitsOntoPageAtCursorPosition(
    headerHeight: number,
    itemCompsAndHeights: [MenuItemComponent, number][],
    sectionDimensions: SectionDimensions
  ): boolean {
    const margins = this.getSectionCombinedMargins(sectionDimensions);
    const pageHeightWithHeaderAndOneItem = this.runningPageHeight
      + this.getHeaderAndNItemsHeight(headerHeight, itemCompsAndHeights, 1)
      + margins;
    return pageHeightWithHeaderAndOneItem <= this.pageHeight;
  }

  /* [3] ********************* Layout Product Section Onto Page ********************* [3] */

  /* Entrypoint into [3] */
  protected layoutProductSectionOntoPage(
    section: CopyableProductSection,
    sectionComponent: ProductSectionComponent,
    originalNonItemUsedSpace: number,
    itemCompsAndHeights: [MenuItemComponent, number][],
    sectionDimensions: SectionDimensions
  ): void {
    if (this.stopSectionLayoutForScreenshotMode()) {
      return;
    }
    const headerHeight = this.getNonItemUsedSpace(
      sectionComponent?.getHeaderHeight(),
      originalNonItemUsedSpace
    );
    let nProductsFitOntoPage;
    let productSectionDimensions;
    if (sectionComponent instanceof SplitProductSectionComponent) {
      [nProductsFitOntoPage, productSectionDimensions] =
        this.nSplitProductsFitOntoPage(headerHeight, itemCompsAndHeights, sectionDimensions) || [0, null];
    } else {
      [nProductsFitOntoPage, productSectionDimensions] =
        this.nProductsFitOntoPage(headerHeight, itemCompsAndHeights, sectionDimensions) || [0, null];
    }
    const noItemsFit = nProductsFitOntoPage === 0;
    const allItemsFit = nProductsFitOntoPage === itemCompsAndHeights?.length;
    const itemHeight = itemCompsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const empty = itemHeight === 0;
    const oneItemFitsWithHeader = this.headerAndOneItemFitsOntoPageAtCursorPosition(
      headerHeight,
      itemCompsAndHeights,
      sectionDimensions
    );
    if (empty) {
      return;
    } else if (noItemsFit || !oneItemFitsWithHeader) {
      this.moveCursorToNewPage();
      this.layoutProductSectionOntoPage(
        section,
        sectionComponent,
        originalNonItemUsedSpace,
        itemCompsAndHeights,
        sectionDimensions
      );
      return;
    } else if (allItemsFit) {
      this.addSectionToOverflowAndUpdateCursorPosition(section, productSectionDimensions);
    } else {
      this.splitUpProductSection(
        section,
        sectionComponent,
        originalNonItemUsedSpace,
        itemCompsAndHeights,
        sectionDimensions
      );
    }
  }

  /**
   * returns [nItems, sectionMarginsAndHeaderAndItemsCombinedHeight]
   */
  protected nSplitProductsFitOntoPage(
    headerHeight: number,
    itemCompsAndHeights: [MenuItemComponent, number][],
    sectionDimensions: SectionDimensions
  ): [number, SectionDimensions] {
    let additionalHeight;
    const margins = this.getSectionCombinedMargins(sectionDimensions);
    let combinedHeight = headerHeight || 0;
    let nItems = 0;
    // this is counterintuitive because the index starts at 0, therefore, the first odd item starts at 0
    const oddItems = itemCompsAndHeights?.filter((item, index) => index % 2 === 0);
    for (const [component, itemHeight] of oddItems) {
      const [lastOddItem] = oddItems?.last() || [null, 0];
      const [lastItemOutOfAll] = itemCompsAndHeights?.last() || [null, 0];
      const lastOdd = lastOddItem === component;
      const lastOutOfAll = lastItemOutOfAll === component;
      additionalHeight = itemHeight;
      combinedHeight += additionalHeight;
      const newRunningPageHeight = this.runningPageHeight + margins + combinedHeight;
      if (newRunningPageHeight <= this.pageHeight) {
        const lastItemWithNoItemNextToIt = lastOdd && lastOutOfAll;
        nItems += lastItemWithNoItemNextToIt ? 1 : 2;
      } else {
        combinedHeight -= additionalHeight;
        break;
      }
    }
    return [nItems, sectionDimensions.copyWith(combinedHeight)];
  }

  /**
   * returns [nItems, sectionMarginsAndHeaderAndItemsCombinedHeight]
   */
  protected nProductsFitOntoPage(
    headerHeight: number,
    itemCompsAndHeights: [MenuItemComponent, number][],
    sectionDimensions: SectionDimensions
  ): [number, SectionDimensions] {
    const margins = this.getSectionCombinedMargins(sectionDimensions);
    let combinedHeight = headerHeight || 0;
    let nItems = 0;
    for (const [, itemHeight] of itemCompsAndHeights) {
      combinedHeight += itemHeight;
      const newRunningPageHeight = this.runningPageHeight + margins + combinedHeight;
      if (newRunningPageHeight <= this.pageHeight) {
        nItems++;
      } else {
        combinedHeight -= itemHeight;
        break;
      }
    }
    return [nItems, sectionDimensions.copyWith(combinedHeight)];
  }

  protected splitUpProductSection(
    section: CopyableProductSection,
    sectionComponent: ProductSectionComponent,
    originalNonItemUsedSpace: number,
    itemCompsAndHeights: [MenuItemComponent, number][],
    sectionDimensions: SectionDimensions
  ): void {
    const headerHeight = this.getNonItemUsedSpace(
      sectionComponent?.getHeaderHeight(),
      originalNonItemUsedSpace
    );
    let nLineItemsFitOntoPage;
    let productSectionDimensions;
    if (sectionComponent instanceof SplitProductSectionComponent) {
      [nLineItemsFitOntoPage, productSectionDimensions] =
        this.nSplitProductsFitOntoPage(headerHeight, itemCompsAndHeights, sectionDimensions) || [0, null];
    } else {
      [nLineItemsFitOntoPage, productSectionDimensions] =
        this.nProductsFitOntoPage(headerHeight, itemCompsAndHeights, sectionDimensions) || [0, null];
    }
    const sectionRowViewModels = itemCompsAndHeights?.map(([comp, ]) => comp.rowViewModel);
    const variantPool = sectionRowViewModels
      ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
      ?.flatten<Variant[]>();
    const laidOutVariants = sectionRowViewModels
      ?.take(nLineItemsFitOntoPage)
      ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
      ?.flatten<Variant[]>();
    const laidOutVariantIds = laidOutVariants?.map(v => v?.id);
    const variantsToLayout = section.variants?.filter(variant => !laidOutVariantIds?.contains(variant.id));
    const laidOutSection = section
      ?.changeVariantPool(variantPool)
      ?.copyWithout(this.menu, this.companyConfig, this.locationConfig, variantsToLayout);
    this.addSectionToOverflowAndUpdateCursorPosition(laidOutSection, productSectionDimensions);
    if (this.runningPageHeight !== 0) this.moveCursorToNewPage();
    // layout overflow
    const overflowSection = section
      ?.changeVariantPool(variantPool)
      ?.copyWithout(this.menu, this.companyConfig, this.locationConfig, laidOutVariants);
    overflowSection.id += 'split';
    const overflowItemCompsAndHeights = itemCompsAndHeights?.removeNFromFront(nLineItemsFitOntoPage);
    this.layoutProductSectionOntoPage(
      overflowSection,
      sectionComponent,
      originalNonItemUsedSpace,
      overflowItemCompsAndHeights,
      productSectionDimensions
    );
  }

  /* [4] **** Add SativaHybridIndicaSplitProductSectionComponent To Overflow **** [4] */

  /* Entrypoint into [4] */
  protected layoutSativaHybridIndicaSplitProductSectionOntoPage(
    section: CopyableProductSection,
    expandedHeaderHeight: number,
    collapsedHeaderHeight: number,
    sectionDimensions: SectionDimensions,
    sativaItemComponentsAndHeights: [MenuItemComponent, number][],
    hybridItemComponentsAndHeights: [MenuItemComponent, number][],
    indicaItemComponentsAndHeights: [MenuItemComponent, number][]
  ): void {
    if (this.stopSectionLayoutForScreenshotMode()) {
      return;
    }
    const headerHeight = this.shouldExpandSectionHeader() ? expandedHeaderHeight : collapsedHeaderHeight;
    // Sativa
    const [nSativaProductsFitOntoPage, sativaSectionDimensions] = this.nProductsFitOntoPage(
      headerHeight,
      sativaItemComponentsAndHeights,
      sectionDimensions
    ) || [0, null];
    const noSativaItemsFit = nSativaProductsFitOntoPage === 0;
    const allSativaItemsFit = nSativaProductsFitOntoPage === sativaItemComponentsAndHeights?.length;
    // Hybrid
    const [nHybridProductsFitOntoPage, hybridSectionDimensions] = this.nProductsFitOntoPage(
      headerHeight,
      hybridItemComponentsAndHeights,
      sectionDimensions
    ) || [0, null];
    const noHybridItemsFit = nHybridProductsFitOntoPage === 0;
    const allHybridItemsFit = nHybridProductsFitOntoPage === hybridItemComponentsAndHeights?.length;
    // Indica
    const [nIndicaProductsFitOntoPage, indicaSectionDimensions] = this.nProductsFitOntoPage(
      headerHeight,
      indicaItemComponentsAndHeights,
      sectionDimensions
    ) || [0, null];
    const noIndicaItemsFit = nIndicaProductsFitOntoPage === 0;
    const allIndicaItemsFit = nIndicaProductsFitOntoPage === indicaItemComponentsAndHeights?.length;
    // Get largest section
    let itemCompsAndHeights;
    let productSectionDimensions;
    const largestHeight = Math.max(
      sativaSectionDimensions?.getHeightWithoutMargins(),
      hybridSectionDimensions?.getHeightWithoutMargins(),
      indicaSectionDimensions?.getHeightWithoutMargins()
    );
    switch (largestHeight) {
      case sativaSectionDimensions?.getHeightWithoutMargins():
        itemCompsAndHeights = sativaItemComponentsAndHeights;
        productSectionDimensions = sativaSectionDimensions;
        break;
      case hybridSectionDimensions?.getHeightWithoutMargins():
        itemCompsAndHeights = hybridItemComponentsAndHeights;
        productSectionDimensions = hybridSectionDimensions;
        break;
      default:
        itemCompsAndHeights = indicaItemComponentsAndHeights;
        productSectionDimensions = indicaSectionDimensions;
        break;
    }
    // Compute if section can fit onto page
    const sativaHeight = sativaItemComponentsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const hybridHeight = hybridItemComponentsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const indicaHeight = indicaItemComponentsAndHeights
      ?.map(([, height]) => height)
      ?.reduce((a, b) => a + b, 0) || 0;
    const empty = sativaHeight === 0 && hybridHeight === 0 && indicaHeight === 0;
    const noItemsFit = noSativaItemsFit && noHybridItemsFit && noIndicaItemsFit;
    const allItemsFit = allSativaItemsFit && allHybridItemsFit && allIndicaItemsFit;
    const oneItemAndHeaderFits = this.headerAndOneItemFitsOntoPageAtCursorPosition(
      headerHeight,
      itemCompsAndHeights,
      sectionDimensions
    );
    if (empty) {
      return;
    } else if (noItemsFit || !oneItemAndHeaderFits) {
      this.moveCursorToNewPage();
      this.layoutSativaHybridIndicaSplitProductSectionOntoPage(
        section,
        expandedHeaderHeight,
        collapsedHeaderHeight,
        sectionDimensions,
        sativaItemComponentsAndHeights,
        hybridItemComponentsAndHeights,
        indicaItemComponentsAndHeights
      );
      return;
    } else if (allItemsFit) {
      this.addSectionToOverflowAndUpdateCursorPosition(section, productSectionDimensions);
    } else {
      this.splitUpSativaHybridIndicaSection(
        section,
        expandedHeaderHeight,
        collapsedHeaderHeight,
        sectionDimensions,
        sativaItemComponentsAndHeights,
        hybridItemComponentsAndHeights,
        indicaItemComponentsAndHeights
      );
    }
  }

  protected splitUpSativaHybridIndicaSection(
    section: CopyableProductSection,
    expandedHeaderHeight: number,
    collapsedHeaderHeight: number,
    sectionDimensions: SectionDimensions,
    sativaItemComponentsAndHeights: [MenuItemComponent, number][],
    hybridItemComponentsAndHeights: [MenuItemComponent, number][],
    indicaItemComponentsAndHeights: [MenuItemComponent, number][]
  ): void {
    const headerHeight = this.shouldExpandSectionHeader() ? expandedHeaderHeight : collapsedHeaderHeight;
    const [nSativaProductsFitOntoPage, sativaSectionDimensions] = this.nProductsFitOntoPage(
      headerHeight,
      sativaItemComponentsAndHeights,
      sectionDimensions
    ) || [0, null];
    const [nHybridProductsFitOntoPage, hybridSectionDimensions] = this.nProductsFitOntoPage(
      headerHeight,
      hybridItemComponentsAndHeights,
      sectionDimensions
    ) || [0, null];
    const [nIndicaProductsFitOntoPage, indicaSectionDimensions] = this.nProductsFitOntoPage(
      headerHeight,
      indicaItemComponentsAndHeights,
      sectionDimensions
    ) || [0, null];
    const sativaSectionRowViewModels = sativaItemComponentsAndHeights
      ?.map(([comp, ]) => comp.rowViewModel);
    const sativaLaidOutVariants = sativaSectionRowViewModels
      ?.take(nSativaProductsFitOntoPage)
      ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
      ?.flatten<Variant[]>()
      ?.filterNulls() ?? [];
    const hybridSectionRowViewModels = hybridItemComponentsAndHeights
      ?.map(([comp, ]) => comp.rowViewModel);
    const hybridLaidOutVariants = hybridSectionRowViewModels
      ?.take(nHybridProductsFitOntoPage)
      ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
      ?.flatten<Variant[]>()
      ?.filterNulls() ?? [];
    const indicaSectionRowViewModels = indicaItemComponentsAndHeights
      ?.map(([comp, ]) => comp.rowViewModel);
    const indicaLaidOutVariants = indicaSectionRowViewModels
      ?.take(nIndicaProductsFitOntoPage)
      ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
      ?.flatten<Variant[]>()
      ?.filterNulls() ?? [];
    const laidOutVariants = [...sativaLaidOutVariants, ...hybridLaidOutVariants, ...indicaLaidOutVariants];
    const laidOutVariantIds = [
      ...(sativaLaidOutVariants?.map(v => v?.id) || []),
      ...(hybridLaidOutVariants?.map(v => v?.id) || []),
      ...(indicaLaidOutVariants?.map(v => v?.id) || []),
    ]?.filterNulls();
    const sectionHeight = Math.max(
      sativaSectionDimensions?.getHeightWithoutMargins(),
      hybridSectionDimensions?.getHeightWithoutMargins(),
      indicaSectionDimensions?.getHeightWithoutMargins()
    );
    const sectionRowViewModels = [
      ...(sativaSectionRowViewModels || []),
      ...(hybridSectionRowViewModels || []),
      ...(indicaSectionRowViewModels || []),
    ];
    const variantPool = sectionRowViewModels
      ?.map(sectionRowViewModel => sectionRowViewModel.rowVariants)
      ?.flatten<Variant[]>();
    const variantsToLayout = section?.variants?.filter(variant => !laidOutVariantIds?.contains(variant.id)) ?? [];
    const laidOutSection = section
      ?.changeVariantPool(variantPool)
      ?.copyWithout(this.menu, this.companyConfig, this.locationConfig, variantsToLayout);
    this.addSectionToOverflowAndUpdateCursorPosition(
      laidOutSection,
      sectionDimensions.copyWith(sectionHeight)
    );
    if (this.runningPageHeight !== 0) this.moveCursorToNewPage();
    // layout overflow
    const overflowSection = section
      ?.changeVariantPool(variantPool)
      ?.copyWithout(this.menu, this.companyConfig, this.locationConfig, laidOutVariants);
    const sativaOverflowItemCompsAndHeights = sativaItemComponentsAndHeights
      ?.removeNFromFront(nSativaProductsFitOntoPage);
    const hybridOverflowItemCompsAndHeights = hybridItemComponentsAndHeights
      ?.removeNFromFront(nHybridProductsFitOntoPage);
    const indicaOverflowItemCompsAndHeights = indicaItemComponentsAndHeights
      ?.removeNFromFront(nIndicaProductsFitOntoPage);
    this.layoutSativaHybridIndicaSplitProductSectionOntoPage(
      overflowSection,
      expandedHeaderHeight,
      collapsedHeaderHeight,
      sectionDimensions,
      sativaOverflowItemCompsAndHeights,
      hybridOverflowItemCompsAndHeights,
      indicaOverflowItemCompsAndHeights
    );
  }

  protected shouldExpandSectionHeader(): boolean {
    const last = this.overflowSections?.last();
    return !last || last?.[0]?.lastOnPage;
  }

  /* ********************** Header Utils ********************* */

  getSectionHeaderHeight(productSectionComponent: ProductSectionComponent): number {
    let headerHeight;
    if (this.menu?.getOnlyShowFirstSectionHeader()) {
      if (this.pageIndex === 0 && this.runningPageHeight === 0) {
        headerHeight = productSectionComponent.getHeaderHeight() || 0;
      } else {
        headerHeight = 0;
      }
    } else {
      headerHeight = productSectionComponent.getHeaderHeight() || 0;
    }
    return headerHeight;
  }

  /* ****************** Section Margin Utils ***************** */

  getSectionHeightWithoutMargins(sectionDimensions: SectionDimensions): number {
    return sectionDimensions?.heightWithoutMargins ?? 0;
  }

  /** Accounts for collapsing margins */
  getSectionHeightWithMargins(sectionDimensions: SectionDimensions): number {
    return (sectionDimensions?.heightWithoutMargins ?? 0) + this.getSectionCombinedMargins(sectionDimensions);
  }

  getSectionHeightWithoutTopMargin(sectionDimensions: SectionDimensions): number {
    return sectionDimensions?.getHeightWithoutTopMargin() ?? 0;
  }

  /**
   * Digital menus use .sections-container { display: flex },
   * which prevents collapsing margins between sections.
   * See "Mastering Margin Collapsing" for more info:
   * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
   */
  getSectionCombinedMargins(sectionDimensions: SectionDimensions): number {
    return sectionDimensions?.getTotalMarginHeight() ?? 0;
  }

  accountForCollapsingMarginIfNoHeaderAndAtTopOfPage(): boolean {
    if (this.menu instanceof PrintMenu) {
      const topOfPage = this.runningPageHeight === 0;
      const accountForCollapsingMargin = topOfPage && !this.menu?.wrappingOverflowSwimlanesEnabled();
      if (accountForCollapsingMargin) {
        const headerCanBeThere = this.menu?.getShowHeader();
        if (!headerCanBeThere) {
          return true;
        } else if (this.pageIndex === 0 && headerCanBeThere) { // no header on first page
          return this.menu?.getPrintHeaderLayoutType() === PrintHeaderLayoutType.None;
        } else if (this.pageIndex !== 0 && headerCanBeThere) { // no header on subsequent pages
          return this.menu?.getPrintHeaderLayoutType() !== PrintHeaderLayoutType.AllPages;
        }
      }
    }
    // don't account for collapsing margin at top of page
    return false;
  }

  /* ********************** Empty Section ********************* */

  /**
   * Can return multiple sections, because plant life will fill
   * remaining space with an empty grid section,
   * and then fill any remaining space left over with an empty section,
   * hence why this was changed to return multiple sections.
   */
  protected getEmptySections(pageIndex: number, remainingSpaceInPx: number): [number, Section][] {
    const menu = this.menu;
    const leaveNPixelsAtBottom = 1;
    const firstOnPage = this.runningPageHeight === 0;
    const component = this.currentProductComponent;
    const overflowSections = this.overflowSections;
    const EmptySectionType = menu?.getEmptySectionType();
    switch (true) {
      case component instanceof SativaHybridIndicaSplitProductSectionComponent: {
        const castedComponent = component as SativaHybridIndicaSplitProductSectionComponent;
        const getEmpty = EmptySectionUtils.handleSativaHybridIndicaSplitProductEmptySection;
        return getEmpty(menu, firstOnPage, pageIndex, remainingSpaceInPx, leaveNPixelsAtBottom, castedComponent);
      }
      case EmptySectionType === PlantlifeNonSmokableEmptySection: {
        const getEmpty = EmptySectionUtils.handlePlantlifeNonSmokableEmptySection;
        return getEmpty(menu, firstOnPage, pageIndex, remainingSpaceInPx, leaveNPixelsAtBottom, overflowSections);
      }
      case EmptySectionType === PlantlifeEmptySection: {
        const getEmpty = EmptySectionUtils.handlePlantlifeEmptySection;
        return getEmpty(menu, firstOnPage, pageIndex, remainingSpaceInPx, leaveNPixelsAtBottom);
      }
      case EmptySectionType === DoubleDutchEmptySection: {
        const getEmpty = EmptySectionUtils.handleDoubleDutchEmptySection;
        return getEmpty(menu, firstOnPage, pageIndex, remainingSpaceInPx, leaveNPixelsAtBottom, overflowSections);
      }
    }
    const heightInPixels = remainingSpaceInPx - leaveNPixelsAtBottom;
    const emptySection: EmptySection = new EmptySection(this.pageIndex, heightInPixels, firstOnPage);
    return [[heightInPixels, emptySection]];
  }

  /* ********************* Cursor Utility ********************* */

  protected addSectionToOverflowAndUpdateCursorPosition(
    section: Section,
    dimensions: SectionDimensions
  ): void {
    if (exists(section)) {
      section.pageIndex = this.pageIndex;
      if (this.runningPageHeight === 0) {
        section.firstOnPage = true;
      }
      const prevSection = this.overflowSections?.last()?.[0];
      if (exists(prevSection)) {
        prevSection.beforeTitleSection = section instanceof TitleSection;
        prevSection.beforeAssetSection = section instanceof AssetSection;
      }
      section.afterAssetSection = prevSection instanceof AssetSection;
      switch (true) {
        case this.shouldRemoveTopMarginFromHeightCalculation(section):
          const sectionHeightWithoutMargins = this.getSectionHeightWithoutTopMargin(dimensions);
          this.addToCursorPosition(sectionHeightWithoutMargins);
          break;
        default:
          const sectionHeightWithMargins = this.getSectionHeightWithMargins(dimensions);
          this.addToCursorPosition(sectionHeightWithMargins);
      }
      this.overflowSections.push([section, dimensions]);
      if (this.runningPageHeight >= this.pageHeight) {
        this.moveCursorToNewPage();
      }
    }
  }

  protected addToCursorPosition(add: number) {
    this.runningPageHeight += add;
  }

  protected moveCursorToNewPage(): void {
    this.fillRemainingSpace();
    if (this.overflowSections?.filter(this.notPageBreak)?.length > 0) {
      const [lastSectionAdded, ] = this.overflowSections?.filter(this.notPageBreak)?.last() || [null, null];
      if (!!lastSectionAdded) lastSectionAdded.lastOnPage = true;
    }
    if (this.overflowSections?.filter(this.isProductSection)?.length > 0) {
      const [lastProductSection, ] = this.overflowSections
        ?.filter(this.isProductSection)
        ?.last() as [ProductSection, SectionDimensions] || [null, null];
      if (!!lastProductSection) lastProductSection.lastProductSectionOnPage = true;
    }
    this.runningPageHeight = 0;
    this.pageIndex += 1;
    this.calculatePageHeight();
  }

  protected notPageBreak([s, ]: [Section, SectionDimensions]): boolean {
    return !(s instanceof PageBreakSection);
  }

  /**
   * Prevent margin stripping from pushing content onto previous page.
   */
  protected fillRemainingSpace(): void {
    const remainingSpace = this.pageHeight - this.runningPageHeight;
    if (remainingSpace > 0) {
      const emptySections = this.getEmptySections(this.pageIndex, remainingSpace);
      emptySections?.forEach(([height, emptySection]) => {
        const dimensions = new SectionDimensions(0, 0, height);
        this.overflowSections.push([emptySection, dimensions]);
      });
    }
  }

  protected getHeaderAndNItemsHeight(
    headerHeight: number,
    itemCompsAndHeights: [MenuItemComponent, number][],
    nItems: number
  ): number {
    let combinedHeight = headerHeight || 0;
    const productItems = itemCompsAndHeights.take(nItems);
    productItems?.forEach(([_, itemHeight]) => combinedHeight += itemHeight);
    return combinedHeight;
  }

  protected shouldRemoveTopMarginFromHeightCalculation(section: Section): boolean {
    return section?.firstOnPage && SectionUtils.isTitleSection(section)
        || section?.firstOnPage && SectionUtils.isProductSection(section);
  }

  protected handleLastSection(): void {
    if (!!this.overflowSections?.filter(this.notPageBreak)?.last()) {
      const [lastSection, ] = this.overflowSections?.filter(this.notPageBreak)?.last() || [null, null];
      if (!!lastSection) lastSection.lastSection = true;
    }
    if (this?.overflowSections?.filter(this.isProductSection)?.length > 0) {
      const [lastProductSection, ] = this.overflowSections
        ?.filter(this.isProductSection)
        ?.last() as [ProductSection, SectionDimensions] || [null, null];
      if (!!lastProductSection) lastProductSection.lastProductSectionOnPage = true;
    }
  }

  protected resetOverflowSectionData(sectionHeights: [MenuSectionComponent, SectionDimensions][]): void {
    sectionHeights?.forEach(([sectionComponent, ]) => sectionComponent?.section?.resetOverflowMetaData());
  }

  protected calculatePageHeight(): void {
    this.pageHeight = this.canvasHeight;
  }

  protected isProductSection([s, ]: [Section, SectionDimensions]): boolean {
    return s instanceof ProductSection;
  }

}
