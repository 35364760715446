<!-- [style.background-image] doesn't work. Don't use this. -->
<div
  #sectionContainer
  *ngIf="!section.empty"
  class="section-container product-section-container"
  [ngClass]="menu | productMenuBasicMethod: 'getSectionWrapperClass' : index"
  [class.section-level-overflow]="viewModel.isSectionLevelOverflow$ | async"
  [class.first-on-page]="section?.firstOnPage"
  [class.before-asset-section]="section?.beforeAssetSection"
  [class.after-asset-section]="section?.afterAssetSection"
  [class.before-title-section]="section?.beforeTitleSection"
  [class.last-product-section-on-page]="section | lastProductSectionOnPage"
  [ngStyle]="{
    'background-image':
      (menu | productMenuBasicMethod: 'getMakeHeaderSectionImageFillEntireSection') ? (asset | async) : null
  }"
  [style.height.px]="section?.fixedHeightInPx"
  [style.background-color]="menu | productMenuBasicMethod: 'getProductSectionBackgroundColor' : section"
  [style.background-size]="'cover'"
  [style.color]="menu | productMenuBasicMethod: 'getSectionBodyTextColor' : section"
  [style.max-height]="(menu | productMenuBasicMethod: 'getShouldOverflowHorizontallyElseVertically') ? '100%' : null"
  [style.margin-top]="!calculationMode && section?.firstOnPage ? '0' : null"
  [style.padding-bottom]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <app-product-menu-section-header-inflator
    #sectionHeader
    *ngIf="(menu | productMenuBasicMethod: 'getOnlyShowFirstSectionHeader') ? index === 0 : true"
    [calculationMode]="calculationMode"
    [locationConfig]="locationConfig"
    [reset]="reset"
    [menu]="menu"
    [sectionRowViewModels]="viewModel.sectionRowViewModels$ | async"
    [section]="section"
    [index]="index">
  </app-product-menu-section-header-inflator>
  <div
    #productsContainer
    class="products-container"
    [style.overflow-y]="(viewModel.sectionLevelScrolling$ | async) ? 'hidden' : null"
    [style.height.px]="productsContainerFixedHeight$ | async"
    [style.background-color]="section?.metadata?.productsContainerBackgroundColor">
    <!-- keyed in order to sync timing of section transitions (if they are enabled) -->
    <ng-container
      *rxFor="
        let pageOfRowViewModels of viewModel.keyedSectionRowViewModels$
          | async
          | getKeyedSectionRowViewModels: (viewModel.sectionId$ | async);
        count as nPages;
        index as listIndex;
        trackBy: viewModel.trackListByIndex;
        strategy: viewModel.productItemRenderStrategy$
      ">
      <div
        *ngIf="viewModel | isProductPageVisible: listIndex : nPages | async"
        [hidden]="viewModel | isProductPageHidden: listIndex : nPages | async"
        class="products-page"
        [@productSectionPagingAnimations]="viewModel | getSectionAnimationState: listIndex : nPages | async">
        <app-product-menu-item-inflator
          *rxFor="
            let secVm of pageOfRowViewModels;
            trackBy: viewModel.trackByUniqueRowViewModelTransitionId;
            index as indexOnPage;
            first as first;
            last as last;
            strategy: 'immediate'
          "
          [style.display]="'block'"
          [style.overflow-y]="'hidden'"
          [calculationMode]="calculationMode"
          [locationConfig]="locationConfig"
          [reset]="reset"
          [odd]="viewModel | isLineItemOdd: secVm : indexOnPage | async"
          [locationId]="locationId"
          [menu]="menu"
          [sectionRowViewModels]="viewModel.sectionRowViewModels$ | async"
          [rowViewModel]="secVm"
          [hidden]="(secVm | sectionRowViewModelBasicMethod: 'hideOverflowItem') || false"
          [sectionIndex]="index"
          [itemIndex]="indexOnPage"
          [first]="first"
          [last]="last">
        </app-product-menu-item-inflator>
      </div>
    </ng-container>
  </div>
</div>
