import { Component, EnvironmentInjector, Injector, Input, OnChanges, Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../building-blocks/menu-header/product-menu-header.component';
import { ProductMenuHeaderInflatorComponent } from './product-menu-header-inflator.component';
import { PrintMenu } from '../../../../../../../models/menu/print-menu';

@Component({
  selector: 'app-product-menu-empty-header-replacer-inflator',
  templateUrl: './product-menu-header-inflator.component.html',
})
export class PrintMenuEmptyHeaderReplacerInflatorComponent extends ProductMenuHeaderInflatorComponent
  implements OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() menu: PrintMenu;

  getInflationType(): Type<ProductMenuHeaderComponent> {
    return this.menu?.getEmptyHeaderReplacement();
  }

}
