@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/marketing-featured-product-menu/components/featured-product-description/featured-product-description.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.featured-product-description {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  display: block;
  width: 100%;
  color: #222222;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.5rem;
  margin: 40px 0;
}
/*# sourceMappingURL=featured-product-description.component.css.map */
