/* src/app/modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-price/card-price.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.product-card-price-container {
  font-family: poppins, sans-serif;
  font-weight: 700;
  height: 100%;
}
/*# sourceMappingURL=card-price.component.css.map */
