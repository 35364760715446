import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { PrintMenu } from '../../../models/menu/print-menu';

@Pipe({
  name: 'castToPrintMenu'
})
export class CastToPrintMenuPipe implements PipeTransform {

  transform(value: Menu): PrintMenu | null {
    if (value instanceof PrintMenu) {
      return value as PrintMenu;
    }
    return null;
  }

}
