import { Component, ElementRef, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import type { SectionWithProducts } from '../../../../../../../../models/menu/section/section-with-products';
import { DomSanitizer } from '@angular/platform-browser';
import { FeaturedCategoryCardComponent } from '../featured-category-card.component';
import { HeaderImageCarouselCardSectionViewModel } from './header-image-carousel-card-section-view-model';
import { featuredCategoryCardSectionAnimations } from '../card_animations';

@Component({
  selector: 'app-header-image-carousel-card-section',
  templateUrl: './header-image-carousel-card-section.component.html',
  styleUrls: ['./header-image-carousel-card-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    HeaderImageCarouselCardSectionViewModel,
    { provide: FeaturedCategoryCardComponent, useExisting: forwardRef(() => HeaderImageCarouselCardSectionComponent) }
  ],
  animations: [featuredCategoryCardSectionAnimations]
})
export class HeaderImageCarouselCardSectionComponent extends FeaturedCategoryCardComponent {

  constructor(
    public override viewModel: HeaderImageCarouselCardSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

  @Input() index: number;
  @Input() override section: SectionWithProducts;

  getImageSectionAlignment(): string {
    const alignmentString = this.section.metadata?.alignment;
    if (alignmentString === 'center') {
      return 'center';
    } else if (alignmentString === 'right') {
      return 'flex-end';
    }

    return 'flex-start';
  }

  getImageSectionWidth(): string {
    const width = this.section.metadata?.width || '100';
    return width + '%';
  }

}
