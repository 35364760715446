import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { ProductSectionWithTransitionsViewModel } from './product-section-with-transitions-view-model';
import { productSectionPagingAnimations } from '../../../menu-item/product-section-item/product-section-item-animations';
import { ProductSectionComponent } from '../product-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { filter, map, observeOn, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { SectionDimensions } from '../../section-dimensions';
import { HtmlUtils } from '../../../../../../../../../utils/html-utils';
import { StaticProductSectionDimensions } from '../static-product-section-dimensions';
import { animationFrameScheduler, BehaviorSubject, combineLatest, from, merge, Observable, of } from 'rxjs';
import { MenuItemInflatorComponent } from '../../../../../menu/inflators/menu-item-inflator-component';
import fastdom from 'fastdom';

@Component({
  selector: 'app-product-section-with-transitions',
  templateUrl: './product-section-with-transitions.component.html',
  providers: [
    ProductSectionWithTransitionsViewModel,
    { provide: ProductSectionComponent, useExisting: forwardRef(() => ProductSectionWithTransitionsComponent) }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [productSectionPagingAnimations]
})
export class ProductSectionWithTransitionsComponent extends ProductSectionComponent
  implements AfterViewInit, OnChanges {

  constructor(
    public viewModel: ProductSectionWithTransitionsViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

  /**
   * DANGER: Do not pass the product container into the view model. This causes a memory leak.
   * Why? I don't know, perhaps the creators of Angular, RxJS, and Chromium seek to destroy me.
   */
  private _productsContainer = new BehaviorSubject<any>(null);
  public productsContainer$ = this._productsContainer as Observable<any>;

  /**
   * DANGER: Do not pass the section item inflator into the view model. This causes a memory leak.
   * Why? I don't know, perhaps the creators of Angular, RxJS, and Chromium seek to destroy me.
   */
  private _sectionItemInflators = new BehaviorSubject<MenuItemInflatorComponent[]>(null);
  public sectionItemInflators$ = this._sectionItemInflators as Observable<MenuItemInflatorComponent[]>;

  public productsContainerFixedHeight$ = combineLatest([
    this.viewModel.sectionLevelScrolling$,
    this.sectionItemInflators$,
    this.viewModel.rowCount$
  ]).pipe(
    switchMap(([sectionLevelScrolling, inflators, rowCount]) => {
      if (sectionLevelScrolling && rowCount > 0) {
        const promise = inflators
          ?.take(rowCount)
          ?.map(i => HtmlUtils.getElementTotalHeightAsync(i.getNativeElement()))
          ?.reduce(async (a, b) => (await a) + (await b), Promise.resolve(0)) || Promise.resolve(null);
        return from(promise);
      }
      return of(null);
    })
  );

  setupViews() {
    super.setupViews();
    this.viewModel.connectToReset(this.reset);
  }

  setupBindings() {
    super.setupBindings();
    this.itemInflators?.changes?.pipe(takeUntil(this.onDestroy)).subscribe(changes => {
      this._sectionItemInflators.next(changes?.toArray());
    });
    const realignOnReset$ = this.viewModel.reset$.pipe(filter(reset => reset));
    merge(realignOnReset$, this.viewModel.realignScrollContainer$).pipe(
      switchMap(() => this.sectionItemInflators$.pipe(filter(it => it?.length > 0), take(1))),
      switchMap(sectionItemInflators => {
        const item = sectionItemInflators?.firstOrNull();
        return (!!item ? from(item.getHeightInPx()) : of(0)).pipe(withLatestFrom(this.productsContainer$));
      })
    ).subscribeWhileAlive({
      owner: this,
      next: ([height, productsContainer]) => productsContainer?.scrollTo(0, height)
    });
    combineLatest([
      this.viewModel.reset$,
      this.viewModel.calculationMode$,
      this.viewModel.sectionLevelScrolling$,
      this.productsContainer$,
      this.sectionItemInflators$
    ]).pipe(observeOn(animationFrameScheduler), takeUntil(this.onDestroy))
      .subscribe(([reset, calculationMode, sectionLevelScrolling, productsContainer, inflators]) => {
        if (!reset && !calculationMode && sectionLevelScrolling && !!productsContainer && (inflators?.length > 0)) {
          const distanceToCover = [inflators?.firstOrNull(), inflators?.last()];
          this.waitForSectionScroll(productsContainer, distanceToCover).then();
        }
      });
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._productsContainer.next(this.productsContainer?.nativeElement);
    this._sectionItemInflators.next(this.itemInflators?.toArray());
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (!!changes.reset) this.viewModel.connectToReset(this.reset);
  }

  private async waitForSectionScroll(productsContainer: any, inflators: MenuItemInflatorComponent[]): Promise<void> {
    const distance = await inflators
      ?.map(i => i.getHeightInPx())
      ?.reduce(async (a, b) => (await a) + (await b), Promise.resolve(0)) || 0;
    const framesPerSecond = this.menu?.getSnapSectionFramesPerSecond();
    const totalFrames = framesPerSecond * (ProductSectionWithTransitionsViewModel.SCROLL_TIME_IN_MILLI_SEC / 1000);
    let pixelsPerFrame = distance / totalFrames;
    if (pixelsPerFrame < 1) pixelsPerFrame = 1;
    const timePerFrame = ProductSectionWithTransitionsViewModel.SCROLL_TIME_IN_MILLI_SEC / totalFrames;
    const scrollBy = () => productsContainer?.scrollBy({ left: 0, top: pixelsPerFrame, behavior: 'instant' });
    for (let i = 0; i < totalFrames; i++) {
      await new Promise(resolve => setTimeout(() => { resolve(undefined); }, timePerFrame));
      fastdom.mutate(scrollBy);
    }
  }

  protected override getSectionDimensions(): Observable<SectionDimensions> {
    if (this.menu?.isSectionLevelOverflow()) {
      return this.getFirstItemHeight().pipe(
        map(firstItemHeight => {
          const nativeElement = this.sectionContainer?.nativeElement;
          const headerHeight = this.getHeaderHeight();
          const sectionPaddingAndBorderHeight = HtmlUtils.getElementPaddingAndBorderHeight(nativeElement);
          const sectionContainerHeightWithoutMargins = this.section?.rowCount > 0
            ? (sectionPaddingAndBorderHeight + headerHeight + (this.section?.rowCount * firstItemHeight))
            : ProductSectionComponent.getSectionContainerHeightWithoutMargins(nativeElement);
          return new StaticProductSectionDimensions(
            HtmlUtils.getElementTopMargin(nativeElement),
            HtmlUtils.getElementBottomMargin(nativeElement),
            sectionContainerHeightWithoutMargins,
            sectionPaddingAndBorderHeight,
            headerHeight,
            firstItemHeight,
            this.section?.rowCount
          );
        })
      );
    } else {
      return super.getSectionDimensions();
    }
  }

}
