import { Injectable } from '@angular/core';
import { MenuSectionOverflowCalculatorViewModel } from '../../menu/menu-section-overflow-calculator/menu-section-overflow-calculator-view-model';
import { IsMenuReadyService } from '../../../../../services/is-menu-ready.service';
import { DisplayMenuCoupling } from '../../../../../../couplings/display-menu-coupling.service';

@Injectable()
export class SpotlightMenuSectionOverflowCalculatorComponentViewModel extends MenuSectionOverflowCalculatorViewModel {

  constructor(
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected isMenuReadyService: IsMenuReadyService
  ) {
    super(displayMenuCoupling, isMenuReadyService);
  }

}
