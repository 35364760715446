import { MenuWithScrollableSectionsViewModel } from '../scrollable-sections/menu-with-scrollable-sections-view-model';
import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Section } from '../../../../../models/menu/section/section';
import { DisplayDomainModel } from '../../../../../domain/display-domain-model';
import { DisplayMenuCoupling } from '../../../../../couplings/display-menu-coupling.service';
import { OrientationService } from '../../../../../services/orientation.service';
import { PrintMenu } from '../../../../../models/menu/print-menu';
import { Injectable } from '@angular/core';
import { SizeUnit } from '../../../../../models/enum/dto/size-unit.enum';
import { ProductSection } from '../../../../../models/menu/section/product-section';
import { SectionUtils } from '../../../../../utils/section-utils';
import { OpacityLocation } from '../../../../../models/shared/opacity-location.enum';
import { IsMenuReadyService } from '../../../../services/is-menu-ready.service';

@Injectable()
export class PrintMenuViewModel extends MenuWithScrollableSectionsViewModel {

  constructor(
    dm: DisplayDomainModel,
    displayMenuCoupling: DisplayMenuCoupling,
    isMenuReadyService: IsMenuReadyService,
    orientationService: OrientationService
  ) {
    super(dm, displayMenuCoupling, isMenuReadyService, orientationService);
  }

  public _menu = new BehaviorSubject<PrintMenu>(null);
  public sections$ = this.distinctNotNullMenu$.pipe(
    map(menu => {
      return menu?.sections.filter(s => {
        if (SectionUtils.isSectionWithProducts(s)) {
          const enabledVariants = s.variants?.filter(v => s.enabledVariantIds?.contains(v.id));
          return s.showZeroStockItems || enabledVariants?.some(v => v.inStock());
        }
      });
    })
  );
  public pageLengthInPixels$ = defer(() => this._menu).pipe(
    map(menu => this.getPrintDigitalHeight(menu))
  );
  public pageWidthInPixels$ = defer(() => this._menu).pipe(
    map(menu => this.getPrintDigitalWidth(menu))
  );

  public themeClass$ = defer(() => this._menu).pipe(map(m => m.getThemeClass()));
  public menuWrapperClass$ = defer(() => this._menu).pipe(map(m => m.getMenuWrapperClass()));
  public menuClasses$ = combineLatest([
    this.themeClass$,
    this.menuWrapperClass$
  ]).pipe(map(([theme, wrapper]) => [theme, wrapper]));
  public menuOpacity$ = defer(() => this._menu).pipe(
    map(m => {
      if (m.getOpacityEnabled()) {
        return (m.getOpacityLocation() === OpacityLocation.ENTIRE_MENU)
          ? (m?.menuOptions?.backgroundOpacity || '1')
          : '1';
      }
      return '1';
    })
  );

  // Overflowed sections
  private _overflowedSections = new BehaviorSubject<Section[]>([]);
  private overflowedSections$ = this._overflowedSections as Observable<Section[]>;
  public pageLimitedOverflowedSections$ = combineLatest([
    this._overflowedSections as Observable<Section[]>,
    this.pageIndex$
  ]).pipe(
    map(([sections, pageIndex]) => {
      return Number.isFinite(pageIndex) && pageIndex >= 0
        ? sections?.filter(section => section?.pageIndex === pageIndex)
        : sections;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public nPages$ = this.overflowedSections$.pipe(
    map(sections => {
      const pageNumbers = sections
        ?.map(s => Number.isFinite(s?.pageIndex) ? s.pageIndex + 1 : null)
        ?.filterNulls() || [];
      return Math.max(...pageNumbers, 0);
    }),
    distinctUntilChanged()
  );

  public lastProductSections$ = this.overflowedSections$.pipe(
    map(sections => sections?.filter(s => s instanceof ProductSection) as ProductSection[]),
    map(sections => sections?.filter(s => s.lastProductSectionOnPage))
  );

  public connectToOverflowedSections(overflowed: Section[]) {
    this._overflowedSections.next(overflowed);
  }

  public getPrintDigitalHeight(m: PrintMenu): number {
    const height = m?.displaySize?.height;
    // convert inches to pixels
    switch (m?.displaySize?.unit) {
      case SizeUnit.Imperial:
        return PrintMenu.convertImperialToPixels(height);
      case SizeUnit.Metric:
        return PrintMenu.convertMetricToPixels(height);
      default:
        return height;
    }
  }

  public getPrintDigitalWidth(m: PrintMenu): number {
    const width = m?.displaySize?.width;
    // convert inches to pixels
    switch (m?.displaySize?.unit) {
      case SizeUnit.Imperial:
        return PrintMenu.convertImperialToPixels(width);
      case SizeUnit.Metric:
        return PrintMenu.convertMetricToPixels(width);
      default:
        return width;
    }
  }

}
