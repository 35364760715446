import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { BaseProductCardComponent } from '../base-product-card/base-product-card.component';
import { OrientationService } from '../../../../../../../../services/orientation.service';
import { EmbeddedImageCardViewModel } from './embedded-image-card-view-model';

@Component({
  selector: 'app-embedded-image-card',
  templateUrl: './embedded-image-card.component.html',
  styleUrls: [
    './embedded-image-card.component.scss',
    '../base-product-card/base-card.component.scss'
  ],
  providers: [EmbeddedImageCardViewModel],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedImageCardComponent extends BaseProductCardComponent {

  constructor(
    public viewModel: EmbeddedImageCardViewModel,
    orientationService: OrientationService
  ) {
    super(viewModel, orientationService);
  }

}
