import { Component, Input } from '@angular/core';
import { MarketingMenu } from '../../../../../models/menu/marketing-menu';
import { BaseMenuViewModel } from '../menu/base-menu-view-model';
import { BaseMenuComponent } from '../menu/base-menu.component';
import { MarketingMenuInterface } from './inflators/marketing-menu-inflator/marketing-menu.interface';

@Component({ selector: 'app-marketing-menu', template: '' })
export abstract class MarketingMenuComponent extends BaseMenuComponent implements MarketingMenuInterface {

  protected constructor(
    vm: BaseMenuViewModel
  ) {
    super(vm);
  }

  @Input() public override menu: MarketingMenu = null;

}
