import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {

  transform<T>(list: T[], filterBy: (T) => boolean): T[] {
    return list?.filter(filterBy);
  }

}
