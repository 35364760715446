import { Component, Input } from '@angular/core';
import { DisplayableProductCard } from '../displayable-product-card';

@Component({
  selector: 'app-card-price',
  templateUrl: './card-price.component.html',
  styleUrls: ['./card-price.component.scss']
})
export class CardPriceComponent {

  @Input() card: DisplayableProductCard;

  constructor() {
  }

}
