import { MenuWithPaginatedSectionsViewModel } from './menu-with-paginated-sections-view-model.service';
import { BaseMenuComponent } from '../menu/base-menu.component';
import { Component } from '@angular/core';

@Component({ selector: 'app-menu-with-multiple-scrollable-sections', template: '' })
export abstract class MenuWithMultipleScrollableSectionsComponent extends BaseMenuComponent {

  public constructor(
    public viewModel: MenuWithPaginatedSectionsViewModel
  ) {
    super(viewModel);
  }

}
