@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/marketing-product-linked-looping-menu/marketing-smart-playlist-menu.component.scss */
.marketing-loop-theme {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: black;
}
.loop-asset {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.transparent-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  background: transparent;
}
.stop-video-from-sleeping {
  position: fixed;
  right: -200;
  top: -200;
  overflow-x: visible;
  white-space: nowrap;
}
/*# sourceMappingURL=marketing-smart-playlist-menu.component.css.map */
