import { Injectable } from '@angular/core';
import { FeaturedCategoryCardViewModel } from '../featured-category-card-view-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaUtils } from '../../../../../../../../utils/media-utils';

@Injectable()
export class ProductDealCarouselCardSectionViewModel extends FeaturedCategoryCardViewModel {

  public is9CardPortrait$ = combineLatest([
    this.isPortrait$,
    this.cardCount$,
  ]).pipe(
    map(([isPortrait, cardCount]) => {
      return isPortrait && cardCount === '9';
    })
  );

  public is12CardPortrait$ = combineLatest([
    this.isPortrait$,
    this.cardCount$,
  ]).pipe(
    map(([isPortrait, cardCount]) => {
      return isPortrait && cardCount === '12';
    })
  );

  public showCardNumber$ = this.menu$.pipe(
    map(menu => menu?.metadata?.hideCardNumber === 'false')
  );

  public cardHeaderImage$ = this.sectionImage$.pipe(
    map(asset => (MediaUtils.isImage(asset?.mediaType) ? asset : null))
  );

  public cardSecondaryImage$ = this.secondaryImage$.pipe(
    map(asset => (MediaUtils.isImage(asset?.mediaType) ? asset : null))
  );

}
