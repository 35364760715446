import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnCannabinoidType } from '../../../../../models/enum/shared/section-column-cannabinoid-type';
import { SectionColumnType } from '../../../../../models/enum/shared/section-column-type';

@Pipe({
  name: 'isCannabinoidColumn',
  standalone: true
})
export class IsCannabinoidColumnPipe implements PipeTransform {

  transform(columnType: SectionColumnType): SectionColumnCannabinoidType | null {
    const isCannabinoid = Object.values(SectionColumnCannabinoidType)
      .includes(columnType as SectionColumnCannabinoidType);
    return isCannabinoid ? columnType as SectionColumnCannabinoidType : null;
  }

}
