import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MenuWithScrollableSectionsComponent } from '../scrollable-sections/menu-with-scrollable-sections.component';
import { ProductMenuSectionInflatorComponent } from '../product-menu/inflators/product-menu-section-inflator/product-menu-section-inflator.component';
import { PrintMenu } from '../../../../../models/menu/print-menu';
import { PrintMenuViewModel } from './print-menu-view-model';
import { PrintMenuInterface } from './inflators/print-menu-inflator/print-menu.interface';
import { SyncSectionTransitionsService } from '../services/sync-section-transitions.service';
import { RenderContextService } from '../../../../services/render-context.service';

@Component({
  selector: 'app-print-component',
  templateUrl: './print-menu.component.html',
  providers: [PrintMenuViewModel, SyncSectionTransitionsService, RenderContextService]
})
export class PrintMenuComponent extends MenuWithScrollableSectionsComponent implements PrintMenuInterface, OnInit {

  constructor(
    public viewModel: PrintMenuViewModel
  ) {
    super(viewModel);
  }

  @Input() public override menu: PrintMenu = null;
  @Output() nPages = new EventEmitter<number>(true);
  @ViewChildren(ProductMenuSectionInflatorComponent)
  override scrollableItems: QueryList<ProductMenuSectionInflatorComponent>;

  ngOnInit() {
    super.ngOnInit();
    this.viewModel.nPages$.subscribeWhileAlive({
      owner: this,
      next: (nPages) => this.nPages.emit(this.menu?.menuOptions?.singlePageMenu ? 1 : nPages)
    });
  }

}
