export class SectionDimensions {

  constructor(topMargin?: number, bottomMargin?: number, heightWithoutMargins?: number) {
    this.topMargin = topMargin;
    this.bottomMargin = bottomMargin;
    this.heightWithoutMargins = heightWithoutMargins;
  }

  public topMargin: number;
  public bottomMargin: number;
  public heightWithoutMargins: number;

  getTotalHeight(): number {
    return this.heightWithoutMargins + this.topMargin + this.bottomMargin;
  }

  getHeightWithoutTopMargin(): number {
    return this.heightWithoutMargins + this.bottomMargin;
  }

  getHeightWithoutBottomMargin(): number {
    return this.heightWithoutMargins + this.topMargin;
  }

  getHeightWithoutMargins(): number {
    return this.heightWithoutMargins;
  }

  getTotalMarginHeight(): number {
    return this.topMargin + this.bottomMargin;
  }

  getMarginDifference(aboveMe: SectionDimensions): number {
    return Math.abs(this.topMargin - aboveMe.bottomMargin);
  }

  public getHeightWithCollapsedMargin(aboveMe: SectionDimensions): number {
    if (this.topMargin <= aboveMe?.bottomMargin) {
      return this.getHeightWithoutTopMargin();
    } else if (this.topMargin > aboveMe?.bottomMargin) {
      return this.getHeightWithoutTopMargin() + this.getMarginDifference(aboveMe);
    } else {
      return this.getTotalHeight();
    }
  }

  public copyWith(newHeightWithoutMargins: number): SectionDimensions {
    return new SectionDimensions(this.topMargin, this.bottomMargin, newHeightWithoutMargins);
  }

  public getMarginsAccountingForCollapsedMargin(aboveMe: SectionDimensions): number {
    return this.getHeightWithCollapsedMargin(aboveMe) - this.getHeightWithoutMargins();
  }

}
