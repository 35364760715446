import { Pipe, PipeTransform } from '@angular/core';
import { ProductMenu } from '../../../models/menu/product-menu';
import { SpotlightMenu } from '../../../models/menu/spotlight-menu';
import { PrintMenu } from '../../../models/menu/print-menu';
import { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'isBaseLevelProductMenu'
})
export class IsBaseLevelProductMenuPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return (value instanceof ProductMenu)
        && !(value instanceof SpotlightMenu)
        && !(value instanceof PrintMenu);
  }

}
