import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductSectionComponent } from '../../../../product-menu/building-blocks/menu-section/product-section/product-section.component';
import type { SectionWithProducts } from '../../../../../../../../models/menu/section/section-with-products';
import { StaticFeaturedCategoryCardViewModel } from '../static-featured-category-card-view-model';

@Component({
  selector: 'app-header-image-card-section',
  templateUrl: './header-image-card-section.component.html',
  providers: [
    StaticFeaturedCategoryCardViewModel,
    { provide: ProductSectionComponent, useExisting: forwardRef(() => HeaderImageCardSectionComponent) }
  ]
})
export class HeaderImageCardSectionComponent extends ProductSectionComponent {

  @Input() index: number;
  @Input() override section: SectionWithProducts;

  constructor(
    public viewModel: StaticFeaturedCategoryCardViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

  getImageSectionAlignment(): string {
    const alignmentString = this.section.metadata?.alignment;
    if (alignmentString === 'center') {
      return 'center';
    } else if (alignmentString === 'right') {
      return 'flex-end';
    }

    return 'flex-start';
  }

  getImageSectionWidth(): string {
    const width = this.section.metadata?.width || '100';
    return width + '%';
  }

}
