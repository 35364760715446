import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import type { ProductMenu } from '../../../../../../../../models/menu/product-menu';
import type { Observable } from 'rxjs';
import type { SectionColumnViewModel } from '../../menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionColumnProductInfoType } from '../../../../../../../../models/enum/shared/section-column-product-info-type';

@Pipe({
  name: 'ignoreLabelColumnWidth'
})
export class IgnoreLabelColumnWidthPipe implements PipeTransform {

  transform(
    colVM: SectionColumnViewModel,
    menu$: Observable<ProductMenu>
  ): Observable<boolean> {
    return menu$.pipe(
      map(menu => colVM.columnType === SectionColumnProductInfoType.Label && menu?.ignoreLabelColumnWidth()),
    );
  }

}
