import { FeaturedCategoryCardViewModel } from '../featured-category-card-view-model';
import { Injectable } from '@angular/core';
import { DisplayDomainModel } from '../../../../../../../../domain/display-domain-model';
import { ImageAPI } from '../../../../../../../../api/image-api';
import { map } from 'rxjs/operators';
import { ColorUtils } from '../../../../../../../../utils/color-utils';
import { DisplayMenuCoupling } from '../../../../../../../../couplings/display-menu-coupling.service';
import { VariantAssetService } from '../../../../../../../services/variant-asset-service';

@Injectable()
export class HeaderImageCarouselCardSectionViewModel extends FeaturedCategoryCardViewModel {

  constructor(
    protected domainModel: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected imageAPI: ImageAPI,
    protected variantAssetService: VariantAssetService
  ) {
    super(domainModel, displayMenuCoupling, imageAPI, variantAssetService);
  }

  public is2Card$ = this.cardCount$.pipe(map(cardCount => cardCount === '2'));

  public imageSectionWidth$ = this.section$.pipe(
    map((section) => {
      const width = parseInt(section?.metadata?.width || '100', 10);
      return `${width}%`;
    })
  );

  public imageSectionFloat$ = this.is2Card$.pipe(map(is2Card => (is2Card ? 'right' : null)));

  public showCardNumber$ = this.menu$.pipe(
    map(menu => menu?.metadata?.hideCardNumber === 'false')
  );

  public cardColor$ = this.section$.pipe(
    map(section => {
      const [R, G, B] = ColorUtils.hexToRgb('#FFFFFF');
      const opacity = Number(section?.metadata?.cardOpacity || '1.0');
      return `rgba(${R}, ${G}, ${B}, ${opacity})`;
    })
  );

  public productsInfoBackgroundColor$ = this.section$.pipe(
    map(menu => menu?.metadata?.productsInfoBackgroundColor)
  );

}
