import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { ProductMenuViewModel } from './product-menu-view-model';
import { ProductMenu } from '../../../../../models/menu/product-menu';
import { MenuWithScrollableSectionsComponent } from '../scrollable-sections/menu-with-scrollable-sections.component';
import { ProductMenuSectionInflatorComponent } from './inflators/product-menu-section-inflator/product-menu-section-inflator.component';
import { SyncSectionTransitionsService } from '../services/sync-section-transitions.service';
import { RenderContextService } from '../../../../services/render-context.service';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  providers: [ProductMenuViewModel, SyncSectionTransitionsService, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMenuComponent extends MenuWithScrollableSectionsComponent {

  constructor(
    public vm: ProductMenuViewModel
  ) {
    super(vm);
  }

  @Input() public override menu: ProductMenu = null;
  @ViewChildren(ProductMenuSectionInflatorComponent)
  override scrollableItems: QueryList<ProductMenuSectionInflatorComponent>;

}
