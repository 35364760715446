import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { Section } from '../../../../../models/menu/section/section';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BaseDirective } from '../../../../../models/base/base-directive';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FeaturedCategorySection } from '../../../../../models/menu/section/featured-category/featured-category-section';
import { CardType } from '../marketing-menu/building-blocks/menu-cards/card-type.enum';
import { OrientationService } from '../../../../../services/orientation.service';

@Directive({
  selector: '[appCardGridManager]'
})
export class CardGridManagerDirective extends BaseDirective implements OnChanges {

  @Input() limitedSections: Section[];

  constructor(
    private orientationService: OrientationService,
    private elementRef: ElementRef,
    private renderer2: Renderer2
  ) {
    super();
  }

  private _sections = new BehaviorSubject<Section[]>(null);
  public sections$ = this._sections as Observable<Section[]>;
  public nSections$ = this.sections$.pipe(map(sections => sections?.length || 0));
  public portrait$ = this.orientationService.isPortrait$;

  private featuredCategory = (s: Section): s is FeaturedCategorySection => s instanceof FeaturedCategorySection;
  public cardType$ = this.sections$.pipe(
    map(sections => sections?.filter(this.featuredCategory)),
    map(sections => sections?.map(section => section.cardType)?.mode() as CardType)
  );

  private setProductDealCardGrid = (portrait: boolean, nCards: number): void => {
    this.renderer2.setStyle(this.elementRef.nativeElement, 'display', 'grid');
    this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-gap', '1.5rem');
    if (portrait) {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(3, minmax(0, 1fr))');
      if (nCards > 9) {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-rows', 'repeat(4, minmax(0, 1fr))');
      } else {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-rows', 'repeat(3, minmax(0, 1fr))');
      }
    } else {
      if (nCards > 9) {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(4, minmax(0, 1fr))');
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-rows', 'repeat(3, minmax(0, 1fr))');
      } else {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(3, minmax(0, 1fr))');
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-rows', 'repeat(3, minmax(0, 1fr))');
      }
    }
  };

  private setHeaderImageCardGrid = (portrait: boolean, nCards: number): void => {
    this.renderer2.setStyle(this.elementRef.nativeElement, 'display', 'grid');
    this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-gap', '1.5rem');
    if (portrait) {
      if (nCards > 2) {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(2, minmax(0, 1fr))');
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-rows', 'repeat(2, minmax(0, 1fr))');
      } else {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(1, minmax(0, 1fr))');
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-rows', 'repeat(2, minmax(0, 1fr))');
      }
    } else {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-rows', 'repeat(1, minmax(0, 1fr))');
      if (nCards >= 4) {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(4, minmax(0, 1fr))');
      } else if (nCards === 3) {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(3, minmax(0, 1fr))');
      } else {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'grid-template-columns', 'repeat(2, minmax(0, 1fr))');
      }
    }
  };

  private setHeaderImageCarouselCardGrid = this.setHeaderImageCardGrid;

  private setDefaultGrid = this.setProductDealCardGrid;

  setupViews() {
    this._sections.next(this.limitedSections);
  }

  setupBindings() {
    combineLatest([this.portrait$, this.cardType$, this.nSections$])
      .pipe(filter(([_, __, nSections]) => nSections > 0), takeUntil(this.onDestroy))
      .subscribe(([portrait, cardType, nSectionOnScreen]) => {
        const setLayoutManager = {
          [CardType.HeaderImage]: this.setHeaderImageCardGrid,
          [CardType.HeaderImageCarousel]: this.setHeaderImageCarouselCardGrid,
          [CardType.ProductDealCarousel]: this.setProductDealCardGrid,
        };
        (setLayoutManager?.[cardType] || this.setDefaultGrid)?.(portrait, nSectionOnScreen);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.limitedSections) this._sections.next(this.limitedSections);
  }

}
