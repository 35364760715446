import { AfterViewInit, Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MenuSectionComponent } from '../menu-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductMenuSectionHeaderInflatorComponent } from '../../../inflators/product-menu-section-header-inflator/product-menu-section-header-inflator.component';
import { MenuItemComponent } from '../../menu-item/menu-item.component';
import { MenuItemInflatorComponent } from '../../../../menu/inflators/menu-item-inflator-component';
import type { SectionWithProducts } from '../../../../../../../../models/menu/section/section-with-products';
import { ProductMenu } from '../../../../../../../../models/menu/product-menu';
import { ProductSectionViewModel } from './product-section-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { exists } from '../../../../../../../../functions/exists';

@Component({ selector: 'app-product-section', template: '' })
export abstract class ProductSectionComponent extends MenuSectionComponent implements AfterViewInit, OnChanges {

  protected constructor(
    public viewModel: ProductSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(sanitizer, element);
  }

  @Input() override menu: ProductMenu;
  @Input() override section: SectionWithProducts;
  @ViewChild('sectionHeader') sectionHeader: ProductMenuSectionHeaderInflatorComponent;
  @ViewChild('productsContainer') productsContainer: ElementRef;
  @ViewChildren(MenuItemInflatorComponent) itemInflators: QueryList<MenuItemInflatorComponent>;
  private _itemInflators = new BehaviorSubject<MenuItemInflatorComponent[]>(null);
  public itemInflators$ = this._itemInflators as Observable<MenuItemInflatorComponent[]>;

  setupViews() {
    super.setupViews();
    this.initViewModel();
    this.viewModel.connectToBackOfMenuFlipper(this.backOfMenuFlipper);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (exists(changes.menu) || exists(changes.section)) this.initViewModel();
    if (changes.backOfMenuFlipper) this.viewModel.connectToBackOfMenuFlipper(this.backOfMenuFlipper);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this._itemInflators.next(this.itemInflators?.toArray());
    this.itemInflators.changes
      .pipe(takeUntil(this.onDestroy))
      .subscribe(inflators => this._itemInflators.next(inflators?.toArray()));
  }

  private initViewModel() {
    this.viewModel.init(this.menu, this.section, this.calculationMode);
  }

  public getHeaderHeight(): number {
    return this.sectionHeader?.getHeaderHeight();
  }

  public getFirstItemHeight(): Observable<number> {
    return this.itemInflators$?.pipe(
      filter(items => items?.length > 0),
      map(inflators => inflators?.firstOrNull()?.getMenuItemComponent()?.getItemHeight()),
      distinctUntilChanged()
    );
  }

  public getMenuItemComponents(): MenuItemComponent[] {
    return this.itemInflators?.toArray()?.map(inflator => inflator?.getMenuItemComponent());
  }

  public getMenuItemComponentsWithHeights(): [MenuItemComponent, number][] {
    return this.itemInflators?.toArray()?.map(inflator => {
      const component = inflator?.getMenuItemComponent();
      const height = component?.getItemHeight();
      return [component, height];
    });
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

  destroy() {
    super.destroy();
    this._itemInflators.complete();
  }

}
