import { Component, ComponentRef, ElementRef, EnvironmentInjector, Injector, Input, OnChanges, reflectComponentType, SimpleChanges, Type, ViewChild } from '@angular/core';
import { ProductMenu } from '../../../../../../models/menu/product-menu';
import { Section } from '../../../../../../models/menu/section/section';
import { MenuSectionInflatorInterface } from '../../interface/menu-section-inflator-interface';
import { MenuSectionComponent } from '../../product-menu/building-blocks/menu-section/menu-section.component';
import { LocationConfiguration } from '../../../../../../models/company/dto/location-configuration';
import { InflatorContainerDirective } from '../../../../../inflator/inflator-container.directive';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { exists } from '../../../../../../functions/exists';
import { Subscribable } from '../../../../../../models/base/subscribable';

@Component({ selector: 'app-menu-section', template: '' })
export abstract class MenuSectionInflatorComponent extends Subscribable
  implements MenuSectionInflatorInterface, OnChanges {

  protected constructor(
    protected injector: Injector,
    protected environmentInjector: EnvironmentInjector,
    public element: ElementRef,
  ) {
    super();
  }

  abstract getUniqueIdentifier(...opts: any): string;

  @ViewChild(InflatorContainerDirective, { static: true }) contentContainer: InflatorContainerDirective;
  @Input() calculationMode: boolean = false;
  @Input() signalPagination: any;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: ProductMenu;
  @Input() section: Section;
  @Input() index: number;
  @Input() last: boolean = false;
  @Input() reset: boolean;
  @Input() backOfMenuFlipper: boolean;

  protected compRef: ComponentRef<any>;
  protected _componentType = new BehaviorSubject<Type<any>>(null);
  private listenToComponentType = this._componentType.pipe(takeUntil(this.onDestroy)).subscribe(t => this.inflate(t));

  abstract getChildSectionComponent(): MenuSectionComponent;
  abstract getMenuSectionInflatorId(): string;
  setupViews() {}
  setupBindings() {}

  connectToComponentType(type: Type<any>) {
    this._componentType.next(type);
  }

  inflate(type: Type<any>) {
    this.contentContainer?.viewContainerRef?.clear();
    this.compRef = null;
    if (exists(type)) {
      this.compRef = this.contentContainer
        ?.viewContainerRef
        ?.createComponent(type, { injector: this.injector, environmentInjector: this.environmentInjector });
      this.forwardInputs();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.forwardInputChanges(changes);
  }

  private forwardInputs(): void {
    if (exists(this.compRef)) {
      reflectComponentType(this.compRef.componentType)
        ?.inputs
        ?.forEach(input => this.compRef.setInput(input.propName, this[input.propName]));
    }
  }

  private forwardInputChanges(changes: SimpleChanges): void {
    if (exists(this.compRef)) {
      Object.keys(changes)?.forEach(propertyName => this.compRef.setInput(propertyName, this[propertyName]));
      this.compRef?.instance?.ngOnChanges(changes);
    }
  }

}
