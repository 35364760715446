@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/building-blocks/menu-cards/full-image-card/full-image-card.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.full-image-card-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.full-image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background:
    linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.24),
      rgba(0, 0, 0, 0.24));
}
.full-image-badge-asset {
  display: block;
  margin-left: 0.5rem;
}
.card-full-image-asset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}
.card-fallback-icon {
  width: 100%;
  height: 100%;
}
.full-image-card {
  color: #ffffff;
}
.full-image-card .card-price.black-shadow {
  text-shadow: 1px 1px 2px #222222;
}
.full-image-card .card-price.white-shadow {
  text-shadow: 1px 1px 2px white;
}
.full-image-card .marketing-card-product-thc {
  font-size: 1.25rem;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
.full-image-card .marketing-card-product-cbd {
  font-size: 1.25rem;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
.full-image-card .marketing-card-product-size {
  font-size: 1.25rem;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
.full-image-card .thc-cbd-size {
  line-height: 1;
  margin-top: 3%;
}
.full-image-card .thc-cbd-size.black-shadow {
  text-shadow: 1px 1px 2px #222222;
}
.full-image-card .thc-cbd-size.white-shadow {
  text-shadow: 1px 1px 2px white;
}
.full-image-card .name-desc {
  max-height: 100%;
}
.full-image-card .name-desc .marketing-card-product-name {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-family: poppins, sans-serif;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
}
.full-image-card .name-desc .marketing-card-product-desc {
  font-family: poppins, sans-serif;
  font-weight: 400;
  margin-top: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.25;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.full-image-card .name-desc.black-shadow {
  text-shadow: 1px 1px 2px #222222;
}
.full-image-card .name-desc.white-shadow {
  text-shadow: 1px 1px 2px white;
}
/*# sourceMappingURL=full-image-card.component.css.map */
