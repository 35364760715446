import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DisplayableProductCard } from '../displayable-product-card';
import { fromEvent } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { ColorUtils } from '../../../../../../../../utils/color-utils';

@Component({
  selector: 'app-card-number',
  templateUrl: './card-number.component.html',
  styleUrls: ['./card-number.component.scss']
})
export class CardNumberComponent extends RenderContextComponent implements AfterViewInit {

  constructor(
    public elementRef: ElementRef
  ) {
    super();
  }

  @Input() card: DisplayableProductCard;
  @Input() index: number;
  @Input() changeBackgroundColor: boolean = true;
  @Output() width = new EventEmitter();

  setupViews() {
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.emitHeight();
  }

  getAutomaticNumberTextColor(): string {
    if (this.changeBackgroundColor) {
      return ColorUtils.isDarkColor(this.card?.getCardColor() || '#FFFFFF') ? '#FFFFFF' : '#222222';
    } else {
      return '#222222';
    }
  }

  setupBindings() {
    const s = fromEvent(window, 'resize').pipe(
      startWith(false),
      debounceTime(1),
      takeUntil(this.onDestroy),
    ).subscribe(it => {
      if (it) {
        this.emitHeight();
      }
    });
    this.pushSub(s);
  }

  isCardColorDark(): boolean {
    return ColorUtils.isDarkColor(this.card?.getCardTextColor());
  }

  emitHeight() {
    this.width.emit(`${this.elementRef.nativeElement.scrollHeight}px`);
  }

}
