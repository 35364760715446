import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { DisplayDomainModel } from '../../../../domain/display-domain-model';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LoadingOptions } from '../../../shared/loading/loading-options';
import { LoadingSpinnerSize } from '../../../shared/loading/loading-spinner-size.enum';
import { Menu } from '../../../../models/menu/menu';
import { MenuType } from '../../../../models/enum/dto/menu-type.enum';
import { CachePolicy } from '../../../../models/enum/shared/cachable-image-policy.enum';
import { IsMenuReadyService } from '../../../services/is-menu-ready.service';
import { DisplayMenuCoupling } from '../../../../couplings/display-menu-coupling.service';
import { MediaUtils } from '../../../../utils/media-utils';

@Injectable()
export class SplashViewModel extends BaseViewModel {

  constructor(
    private isMenuReadyService: IsMenuReadyService,
    private dm: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling
  ) {
    super();
    this.initLoadingSpinner();
    this.loadLogo();
  }

  // Loading Data
  private isDisplay = new BehaviorSubject<boolean>(false);
  public loadingOpts: LoadingOptions;
  public loading$ = this.isMenuReadyService.showSplashScreen$;
  public screenshotMode$ = this.displayMenuCoupling.screenshotMode;
  private hasCompanyLogo = new BehaviorSubject<boolean>(false);
  private typeOfMenuLoading$ = this.dm.classificationOfMenu.asObservable();
  public hideLoadingSpinner$ = combineLatest([
    this.typeOfMenuLoading$,
    this.hasCompanyLogo
  ]).pipe(
    debounceTime(1),
    map(([type, hasCompLogo]) => ((type !== MenuType.WebMenu) || hasCompLogo))
  );
  public hideVersionNumber$ = combineLatest([
    this.typeOfMenuLoading$,
    this.hasCompanyLogo
  ]).pipe(
    debounceTime(1),
    map(([type, hasCompLogo]) => ((type === MenuType.WebMenu) || hasCompLogo))
  );

  // Other
  public versionString = new BehaviorSubject<string>(require('package.json')?.version);
  public img: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);

  private initLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.isLoading = true;
    this.loadingOpts.backgroundColor = '#FFFFFF';
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Large;
  }

  private loadLogo() {
    const s = combineLatest([
      this.isDisplay,
      this.dm.menuToDisplay.pipe(map(it => it?.menu)),
      this.loading$.notNull()
    ]).pipe(debounceTime(100))
      .subscribe(([isDisplay, menu, loading]) => this.bindToImageSub(menu));
    this.pushSub(s);
  }

  private bindToImageSub(menu: Menu) {
    if (menu && !menu?.companyLogo?.isEmpty() && !menu.menuOptions?.showAltLogo) {
      SplashViewModel.fetchAsset(menu);
      const imageSubKey = 'cLogo';
      this.destroyImageSub(imageSubKey);
      if (menu.companyLogo) {
        const profilePicSub = this.img.bind(
          menu.companyLogo.sizePriorityUrl$.pipe(
            map(url => {
              if (!url) {
                this.hasCompanyLogo.next(false);
                url = 'assets/logo/dark/logo-stroke.svg';
              } else {
                this.hasCompanyLogo.next(true);
              }
              return url;
            })
          )
        );
        this.pushImageSub(imageSubKey, profilePicSub);
      }
    } else {
      this.hasCompanyLogo.next(false);
      this.img.next('assets/logo/dark/logo-stroke.svg');
    }
  }

  private static fetchAsset(menu: Menu) {
    const asset = menu?.companyLogo;
    const size = MediaUtils.DefaultImageSize;
    const cacheForNSeconds = MediaUtils.DefaultCacheTimeInSeconds;
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (!!asset && !!size && validCacheTime) {
      const policy = CachePolicy.Service;
      asset.getAsset(policy, size, cacheForNSeconds);
    }
  }

  connectToIsDisplay(isDisplay: boolean) {
    this.isDisplay.next(isDisplay);
  }

}
