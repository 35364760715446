import { Component, ElementRef, EnvironmentInjector, forwardRef, Injector, OnChanges, SimpleChanges, Type } from '@angular/core';
import { MenuSectionComponent } from '../../building-blocks/menu-section/menu-section.component';
import { MenuSectionInflatorComponent } from '../../../menu/inflators/menu-section-inflator-component';
import { exists } from '../../../../../../../functions/exists';
import { SectionInflatorUtils } from '../../../../../../../utils/section-inflator-utils';
import { WrappingSwimlaneSection } from '../../../../../../../models/menu/section/wrapping-swimlane-section';
import { WrappingSwimlaneSectionComponent } from '../../building-blocks/menu-section/product-section/wrapping-swimlane-section/wrapping-swimlane-section.component';

@Component({
  selector: 'app-product-menu-section-inflator',
  templateUrl: './product-menu-section-inflator.component.html',
  providers: [
    { provide: MenuSectionInflatorComponent, useExisting: forwardRef(() => ProductMenuSectionInflatorComponent) }
  ]
})
export class ProductMenuSectionInflatorComponent extends MenuSectionInflatorComponent implements OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector,
    element: ElementRef
  ) {
    super(injector, environmentInjector, element);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const sectionChanged = exists(changes.section);
    const changed = changes.section?.previousValue?.id !== changes.section?.currentValue?.id;
    const sectionWithoutId = !changes.section?.currentValue?.id;
    if (sectionChanged && (changed || sectionWithoutId)) {
      this.connectToComponentType(this.getSectionType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  /**
   * Angular's circular dependency detection at compile time isn't smart enough to detect that
   * WrappingSwimlaneSectionComponent is not actually a circular dependency, so we have to bring
   * it outside SectionInflatorUtils.getSectionComponentType, and create another inflator component
   * specifically for WrappingSwimlaneSectionComponent called "app-swimlane-section-inflator".
   */
  getSectionType(): Type<MenuSectionComponent> {
    if (this.section instanceof WrappingSwimlaneSection) {
      return WrappingSwimlaneSectionComponent;
    }
    return SectionInflatorUtils.getSectionComponentType(this.menu, this.section);
  }

  public getMenuSectionInflatorId(): string {
    return this.element?.nativeElement?.id;
  }

  public getChildSectionComponent(): MenuSectionComponent {
    return this.compRef.instance as MenuSectionComponent;
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
