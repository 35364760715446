<!-- hidden label to calculate if there is a label or not -->
<app-label-inflator #labelCalculator hidden [labelComponentInterface]="card"></app-label-inflator>

<div class="full-image-card-container" #productCard>
  <!-- Image -->
  <app-asset
    *ngIf="viewModel.hasAsset$ | async; else fallbackIcon"
    class="card-full-image-asset"
    [asset]="card.getAsset()"></app-asset>
  <ng-template #fallbackIcon>
    <img class="card-fallback-icon" [src]="viewModel.assetFallBackIconUrl$ | async" alt="" />
  </ng-template>
  <div class="full-image-overlay"></div>

  <!-- card content -->
  <div class="marketing-card-container full-image-card" fxLayout="column">
    <div class="top-row" fxLayout="row" [style.height.%]="16">
      <app-card-number #num [style.width]="num.width | async" [card]="card" [index]="index"> </app-card-number>
      <app-asset
        *ngFor="let badge of card.getBadges()"
        class="full-image-badge-asset"
        [style.width]="num.width | async"
        [asset]="badge.image"></app-asset>
      <div fxFlex>
        <app-card-price
          class="card-price"
          [ngClass]="{ 'black-shadow': !isDarkTextColor(), 'white-shadow': isDarkTextColor() }"
          [card]="card"></app-card-price>
      </div>
    </div>
    <div class="product-info" fxFlex fxLayout="column" fxLayoutAlign="end stretch">
      <div
        class="name-desc"
        [ngStyle]="{ color: card.getCardTextColor() }"
        [ngClass]="{ 'black-shadow': !isDarkTextColor(), 'white-shadow': isDarkTextColor() }"
        fxLayout="column"
        fxLayoutAlign="end stretch"
        fxFlex>
        <app-label-inflator
          *ngIf="!!(labelCalculator.currentLabelText$ | async)"
          [style.display]="'block'"
          [style.align-self]="'flex-end'"
          [labelComponentInterface]="card"
          [fitTextParentContainer]="productCard"
          [fitTextPercentageOfParent]="getLabelPercentage()"
          [fitTextDisabled]="false">
        </app-label-inflator>
        <div fxFlex="10">
          <div
            class="marketing-card-product-name"
            appFitText
            [percentageOfParent]="getProductNameTextPercentage()"
            [parentContainer]="productCard">
            {{ card?.getProductName() }}
          </div>
        </div>
        <div
          *ngIf="card?.getProductDesc()"
          class="marketing-card-product-desc"
          [ngStyle]="{
            '-webkit-line-clamp': cardCount < 9 ? '4' : '3'
          }"
          appFitText
          [percentageOfParent]="getProductDescTextPercentage()"
          [parentContainer]="productCard">
          {{ card?.getProductDesc() }}
        </div>
      </div>
      <div
        *ngIf="card.getIsThcAndCbdProduct()"
        class="thc-cbd-size"
        [ngStyle]="{ color: card.getCardTextColor() }"
        [ngClass]="{ 'black-shadow': !isDarkTextColor(), 'white-shadow': isDarkTextColor() }"
        [style.maxHeight.%]="5"
        fxLayout="row"
        fxLayoutAlign="space-between">
        <div [style.display]="card.getIsCannabinoidRange() ? 'block' : 'contents'">
          <div
            class="marketing-card-product-thc"
            appFitText
            [percentageOfParent]="getProductCannabinoidTextPercentage()"
            [parentContainer]="productCard">
            {{ card?.getThcString() }}
          </div>
          <div
            class="marketing-card-product-cbd"
            appFitText
            [percentageOfParent]="getProductCannabinoidTextPercentage()"
            [parentContainer]="productCard">
            {{ card?.getCbdString() }}
          </div>
        </div>
        <div
          class="marketing-card-product-size"
          appFitText
          [percentageOfParent]="getProductCannabinoidTextPercentage()"
          [parentContainer]="productCard">
          {{ card?.getSizeString() }}
        </div>
      </div>
    </div>
  </div>
</div>
