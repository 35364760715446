import { CardType } from '../../../modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-type.enum';
import { ProductCard } from './product-card';
import { Asset } from '../../image/dto/asset';
import { Variant } from '../../product/dto/variant';
import { CompanyConfiguration } from '../../company/dto/company-configuration';
import { LocationConfiguration } from '../../company/dto/location-configuration';
import { DeprecatedMarketingMenu } from '../deprecated-marketing-menu';

export class ComboCard extends ProductCard {

  type: CardType;

  constructor(
    menu: DeprecatedMarketingMenu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variant: Variant,
    asset: Asset,
  ) {
    super();
    this.menu = menu;
    this.type = menu?.metadata?.cardType || CardType.Full;
    this.companyConfig = companyConfig;
    this.locationConfig = locationConfig;
    this.variant = variant;
    this.asset = asset;
  }

  getSalePrice(): string {
    const override = this.menu?.hydratedVariantFeature?.priceOverrideMap?.get(this.variant?.id);
    const hasOverridePrice = (override !== null && override !== undefined && override >= 0);
    const overridePrice = hasOverridePrice ? `$${override?.toFixed(2)}` : null;
    const salePrice = super.getSalePrice();
    return overridePrice ?? salePrice;
  }

}
