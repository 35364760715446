import { AfterViewInit, Component, ElementRef, forwardRef, OnChanges, QueryList, SimpleChanges, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FeaturedCategoryCardComponent } from '../../featured-category-card.component';
import { featuredCategoryCardSectionAnimations } from '../../card_animations';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { ProductDealCarouselCardLandscapeSectionViewModel } from './product-deal-carousel-card-landscape-section-view-model';

@Component({
  selector: 'app-product-deal-carousel-card-landscape-section',
  templateUrl: './product-deal-carousel-card-landscape-section.component.html',
  styleUrls: ['./product-deal-carousel-card-landscape-section.component.scss'],
  providers: [
    ProductDealCarouselCardLandscapeSectionViewModel,
    {
      provide: FeaturedCategoryCardComponent,
      useExisting: forwardRef(() => ProductDealCarouselCardLandscapeSectionComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [featuredCategoryCardSectionAnimations]
})
export class ProductDealCarouselCardLandscapeSectionComponent extends FeaturedCategoryCardComponent
  implements OnChanges, AfterViewInit {

  constructor(
    public override viewModel: ProductDealCarouselCardLandscapeSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

  @ViewChildren('carouselContainer') carouselContainers: QueryList<ElementRef>;
  @ViewChildren('leftSideOfCard') leftSideOfCards: QueryList<ElementRef>;
  @ViewChildren('productDealInfoContainer') productDealInfoContainers: QueryList<ElementRef>;

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.signalPagination) this.viewModel?.connectToUpdateCurrentCarouselPosition(this.signalPagination);
    if (changes.reset) this.viewModel?.connectToReset(this.reset);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    const getContainerWidth = (containers: QueryList<ElementRef>, update: (width: number) => void) => {
      if (containers?.length > 0) {
        const container = containers.first;
        const carouselContainerWidth = container.nativeElement.clientWidth;
        update(carouselContainerWidth);
      }
    };
    this.carouselContainers.changes.pipe(takeUntil(this.onDestroy)).subscribe(carouselContainers => {
      getContainerWidth(carouselContainers, this.viewModel.connectToCarouselContainerWidth);
    });
    this.leftSideOfCards.changes.pipe(takeUntil(this.onDestroy)).subscribe(leftSideOfCards => {
      getContainerWidth(leftSideOfCards, this.viewModel.connectToLeftSideOfCardWidth);
    });
    this.productDealInfoContainers.changes.pipe(takeUntil(this.onDestroy)).subscribe(productDealInfoContainers => {
      getContainerWidth(productDealInfoContainers, this.viewModel.connectToProductDealInfoContainerWidth);
    });
  }

}
