<!-- [style.background-image] doesn't work. Don't use this. -->
<div
  #sectionContainer
  *ngIf="!section.empty"
  class="section-container product-section-container"
  [ngClass]="menu | productMenuBasicMethod: 'getSectionWrapperClass' : index"
  [ngStyle]="{
    'background-image':
      (menu | productMenuBasicMethod: 'getMakeHeaderSectionImageFillEntireSection') ? (asset | async) : null
  }"
  [style.height.px]="section?.fixedHeightInPx"
  [style.background-color]="menu | productMenuBasicMethod: 'getProductSectionBackgroundColor' : section"
  [style.background-size]="'cover'"
  [style.color]="menu | productMenuBasicMethod: 'getSectionBodyTextColor' : section"
  [style.max-height]="(menu | productMenuBasicMethod: 'getShouldOverflowHorizontallyElseVertically') ? '100%' : null"
  [style.margin-top]="!calculationMode && section?.firstOnPage ? '0' : null"
  [style.padding-bottom]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <app-product-menu-section-header-inflator
    #sectionHeader
    *ngIf="(menu | productMenuBasicMethod: 'getOnlyShowFirstSectionHeader') ? index === 0 : true"
    [calculationMode]="calculationMode"
    [locationConfig]="locationConfig"
    [reset]="reset"
    [menu]="menu"
    [sectionRowViewModels]="viewModel.sectionRowViewModels$ | async"
    [section]="section"
    [index]="index">
  </app-product-menu-section-header-inflator>
  <div
    #productsContainer
    class="products-container"
    [style.background-color]="section?.metadata?.productsContainerBackgroundColor">
    <div class="products-page">
      <app-product-menu-item-inflator
        *ngFor="
          let secVm of viewModel.sectionRowViewModels$ | async;
          trackBy: viewModel.trackByUniqueRowViewModelTransitionId;
          index as indexOnPage;
          first as first;
          last as last;
          odd as odd
        "
        [style.display]="'block'"
        [style.overflow-y]="'hidden'"
        [calculationMode]="calculationMode"
        [locationConfig]="locationConfig"
        [reset]="reset"
        [odd]="odd"
        [locationId]="locationId"
        [menu]="menu"
        [sectionRowViewModels]="viewModel.sectionRowViewModels$ | async"
        [rowViewModel]="secVm"
        [hidden]="(secVm | sectionRowViewModelBasicMethod: 'hideOverflowItem') || false"
        [sectionIndex]="index"
        [itemIndex]="indexOnPage"
        [first]="first"
        [last]="last">
      </app-product-menu-item-inflator>
    </div>
  </div>
</div>
