<div
  #terpeneColumn
  *ngIf="colVm.columnType === terpeneColumnType"
  [class.product-low-amount]="productStylingVm | isLowAmountTerpene: terpeneColumnType | async"
  [ngStyle]="
    productStylingVm | lowAmountStyling: colVm : (productStylingVm | isLowAmountTerpene: terpeneColumnType) | async
  "
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  class="product-column-general product-terpene">
  <!-- product-${terpene-kebab-cased} class gets added in the component -->
  <ng-container *ngIf="useNgContent"><ng-content></ng-content></ng-container>
  <ng-container *ngIf="!useNgContent">{{
    rowVm | sectionRowViewModelBasicMethod: 'getTerpene' : terpeneColumnType
  }}</ng-container>
</div>
