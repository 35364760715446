<div
  #sectionContainer
  class="section-container title-section-container"
  [ngClass]="menu | productMenuBasicMethod : 'getSectionWrapperClass' : index"
  [style.color]="menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''"
  [style.margin-top]="!section?.firstOnPage ? (menu | productMenuBasicMethod : 'getTitleSectionTopMargin') : null"
  [style.margin-bottom]="!last ? (menu | productMenuBasicMethod : 'getTitleSectionBottomMargin') : null"
  [style.text-align]="section.metadata?.alignment">
  <div class="title-section-content-wrapper">
    <div class="title-section-image-container" [style.justify-content]="section?.metadata?.imageAlignment">
      <img
        *ngIf="
          menu?.hydratedTheme?.themeFeatures?.titleSectionBackgroundImage &&
          !section?.image &&
          !!(menu | productMenuBasicMethod : 'getDefaultTitleSectionImageSrc')
        "
        class="title-section-background-image"
        [style.height.%]="100"
        [style.width.%]="section?.metadata?.objectFit === 'cover' ? section?.metadata?.width : null"
        [style.object-fit]="section?.metadata?.objectFit"
        [src]="menu | productMenuBasicMethod : 'getDefaultTitleSectionImageSrc'"
        alt="section image" />
      <img
        appImage
        *ngIf="!!section?.image"
        class="title-section-background-image"
        [asset]="section?.image"
        [size]="Types.ImageSize.Large"
        [reset]="reset"
        [style.height.%]="100"
        [style.width.%]="section?.metadata?.objectFit === 'cover' ? section?.metadata?.width : null"
        [style.object-fit]="section?.metadata?.objectFit"
        alt="section image"
        src="" />
    </div>

    <ng-container *ngIf="section?.metadata?.alignment === 'left'">
      <div class="title-section" [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ section.title }}
        <div
          style="flex: 1; height: 0.5rem; margin-left: 1.625rem; border-radius: 0.5rem"
          [style.background-color]="section?.metadata?.titleSectionBackgroundColor"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="section?.metadata?.alignment === 'center'">
      <div class="title-section" [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        <div
          style="flex: 1; height: 0.5rem; margin-right: 1.625rem; border-radius: 0.5rem"
          [style.background-color]="section?.metadata?.titleSectionBackgroundColor"></div>
        {{ section.title }}
        <div
          style="flex: 1; height: 0.5rem; margin-left: 1.625rem; border-radius: 0.5rem"
          [style.background-color]="section?.metadata?.titleSectionBackgroundColor"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="section?.metadata?.alignment === 'right'">
      <div class="title-section" [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        <div
          style="flex: 1; height: 0.5rem; margin-right: 1.625rem; border-radius: 0.5rem"
          [style.background-color]="section?.metadata?.titleSectionBackgroundColor"></div>
        {{ section.title }}
      </div>
    </ng-container>
  </div>
</div>
