<div
  #sectionContainer
  class="section-container title-section-container"
  [ngClass]="menu | productMenuBasicMethod : 'getSectionWrapperClass' : index"
  [class.first-on-page]="section?.firstOnPage"
  [style.color]="menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''"
  [style.margin-top]="!section?.firstOnPage ? (menu | productMenuBasicMethod : 'getTitleSectionTopMargin') : null"
  [style.margin-bottom]="!last ? (menu | productMenuBasicMethod : 'getTitleSectionBottomMargin') : null"
  [style.text-align]="section.metadata?.alignment">
  <div class="title-section-content-wrapper" [style.background-color]="section?.metadata?.titleSectionBackgroundColor">
    <div class="title-section-image-container" [style.justify-content]="section?.metadata?.imageAlignment">
      <img
        *ngIf="
          menu?.hydratedTheme?.themeFeatures?.titleSectionBackgroundImage &&
          !section?.image &&
          !!(menu | productMenuBasicMethod : 'getDefaultTitleSectionImageSrc')
        "
        class="title-section-background-image"
        [style.height.%]="100"
        [style.width.%]="section?.metadata?.objectFit === 'cover' ? section?.metadata?.width : null"
        [style.object-fit]="section?.metadata?.objectFit"
        [src]="menu | productMenuBasicMethod : 'getDefaultTitleSectionImageSrc'"
        alt="section image" />
      <img
        appImage
        *ngIf="!!section?.image"
        class="title-section-background-image"
        [asset]="section?.image"
        [size]="Types.ImageSize.Large"
        [reset]="reset"
        [style.height.%]="100"
        [style.width.%]="section?.metadata?.objectFit === 'cover' ? section?.metadata?.width : null"
        [style.object-fit]="section?.metadata?.objectFit"
        alt="section image"
        src="" />
    </div>
    <div
      class="title-section"
      [class.dark-text]="!(section?.metadata?.titleSectionBackgroundColor | isDarkColor)"
      [class.light-text]="section?.metadata?.titleSectionBackgroundColor | isDarkColor"
      [style.color]="menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ section.title }}
    </div>
    <div *ngIf="!!section?.subTitle" class="title-section-subtitle" [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ section.subTitle }}
    </div>
  </div>
</div>
