import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuSectionOverflowCalculatorComponent } from '../../menu/menu-section-overflow-calculator/menu-section-overflow-calculator-component';
import { PrintMenuSectionOverflowCalculatorViewModel } from './print-menu-section-overflow-calculator-view-model';
import { PrintMenu } from '../../../../../../models/menu/print-menu';

@Component({
  selector: 'app-print-menu-section-overflow-calculator',
  templateUrl: './print-menu-section-overflow-calculator.component.html',
  styleUrls: ['./print-menu-section-overflow-calculator.component.scss'],
  providers: [PrintMenuSectionOverflowCalculatorViewModel]
})
export class PrintMenuSectionOverflowCalculatorComponent extends MenuSectionOverflowCalculatorComponent
  implements OnChanges {

  @Input() public menu: PrintMenu = null;
  @Input() public pageHeightInPixels: number;

  constructor(
    public viewModel: PrintMenuSectionOverflowCalculatorViewModel
  ) {
    super(viewModel);
  }

  setupViews() {
    super.setupViews();
    this.viewModel.connectToPageHeightInPx(this.pageHeightInPixels);
  }

  setupBindings() {
    super.setupBindings();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.pageHeightInPixels) {
      this.viewModel.connectToPageHeightInPx(this.pageHeightInPixels);
    }
  }

}
