import { Pipe, PipeTransform } from '@angular/core';
import { SectionRowViewModel } from '../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';

@Pipe({
  name: 'getKeyedSectionRowViewModels'
})
export class GetKeyedSectionRowViewModelsPipe implements PipeTransform {

  transform(keyedMap: Map<string, SectionRowViewModel[][]>, key: string): SectionRowViewModel[][] {
    return keyedMap?.get(key) || [];
  }

}
