import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../product-menu-header.component';

@Component({
  selector: 'app-menu-header-company-logo',
  templateUrl: './menu-header-company-logo.component.html',
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderCompanyLogoComponent }]
})
export class MenuHeaderCompanyLogoComponent extends ProductMenuHeaderComponent {
}
