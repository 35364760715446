import { Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuSectionComponent } from '../../menu-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { WrappingSwimlaneSectionViewModel } from './wrapping-swimlane-section-view-model';
import type { PrintMenu } from '../../../../../../../../../models/menu/print-menu';
import type { WrappingSwimlaneSection } from '../../../../../../../../../models/menu/section/wrapping-swimlane-section';

@Component({
  selector: 'app-wrapping-swimlane-section',
  templateUrl: './wrapping-swimlane-section.component.html',
  styleUrls: ['./wrapping-swimlane-section.component.scss'],
  providers: [
    WrappingSwimlaneSectionViewModel,
    { provide: MenuSectionComponent, useExisting: forwardRef(() => WrappingSwimlaneSectionComponent) }
  ]
})
export class WrappingSwimlaneSectionComponent extends MenuSectionComponent implements OnChanges {

  constructor(
    public viewModel: WrappingSwimlaneSectionViewModel,
    public sanitizer: DomSanitizer,
    public element: ElementRef
  ) {
    super(sanitizer, element);
  }

  @Input() override menu: PrintMenu;
  @Input() override section: WrappingSwimlaneSection;

  setupViews() {
    super.setupViews();
    this.viewModel.connectToMenu(this.menu);
    this.viewModel.connectToSection(this.section);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
