// noinspection JSUnusedLocalSymbols

import { ProductSectionViewModel } from '../product-section-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, mapTo, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OverflowState } from '../../../../../../../../../models/enum/shared/overflow-transition-state.enum';
import { DistinctUtils } from '../../../../../../../../../utils/distinct.utils';
import { SectionRowViewModel } from '../section-row-view-models/SectionRowViewModel';
import { DisplayDomainModel } from '../../../../../../../../../domain/display-domain-model';
import { DisplayMenuCoupling } from '../../../../../../../../../couplings/display-menu-coupling.service';
import { SyncSectionTransitionsService } from '../../../../../services/sync-section-transitions.service';
import { exists } from '../../../../../../../../../functions/exists';

@Injectable()
export class ProductSectionWithTransitionsViewModel extends ProductSectionViewModel {

  static readonly SCROLL_TIME_IN_MILLI_SEC = 1125;
  static readonly SCROLL_LAG_BUFFER_IN_MILLI_SEC = 3000;

  constructor(
    protected domainModel: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected syncSectionTransitionsService: SyncSectionTransitionsService
  ) {
    super(domainModel, displayMenuCoupling);
  }

  private readonly _realignScrollContainer = new Subject<void>();
  public readonly realignScrollContainer$ = this._realignScrollContainer as Observable<void>;
  private connectToRealignScrollContainer = () => this._realignScrollContainer.next();

  private _distinctSectionRowViewModelIds = new BehaviorSubject<string[]>([]);
  public distinctSectionRowViewModelIds$ = this._distinctSectionRowViewModelIds as Observable<string[]>;

  private _reset = new BehaviorSubject<boolean>(false);
  public reset$ = this._reset.pipe(distinctUntilChanged());
  connectToReset = (reset: boolean) => this._reset.next(reset);

  public sectionId$ = this.section$.pipe(map(section => section?.id), distinctUntilChanged());
  public overflowState$ = this.menu$.pipe(
    map(menu => menu?.overflowState),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  private isMenuLevelOverflow$ = this.menu$.pipe(map(menu => menu?.isMenuLevelOverflow()), distinctUntilChanged());
  public isSectionLevelOverflow$ = this.menu$.pipe(map(menu => menu?.isSectionLevelOverflow()), distinctUntilChanged());
  private sectionOverflowTimer$ = combineLatest([
    this.reset$,
    this.calculationMode$
  ]).pipe(
    switchMap(([reset, calcMode]) => {
      return iif(() => reset || calcMode, of(0), this.syncSectionTransitionsService.sectionOverflowTimer$);
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public sectionLevelScrolling$ = this.overflowState$.pipe(
    map(state => state === OverflowState.SECTION_SCROLL),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public sectionLevelPaging$ = this.overflowState$.pipe(
    map(state => state === OverflowState.SECTION_PAGING),
    distinctUntilChanged()
  );
  public rowCount$ = this.section$.pipe(map(section => section?.rowCount), distinctUntilChanged());
  private notEnoughRowViewModelsForTransition$ = combineLatest([
    this.sectionRowViewModels$,
    this.rowCount$
  ]).pipe(
    map(([rowVms, rowCount]) => (rowVms?.length <= rowCount) || rowCount === 0),
    distinctUntilChanged()
  );
  private alwaysShowBaseRowViewModel$ = combineLatest([
    this.calculationMode$,
    this.isMenuLevelOverflow$,
    this.notEnoughRowViewModelsForTransition$
  ]).pipe(
    map(([calculationMode, isMenuLevelOverflow, notEnoughRowViewModelsForTransition]) => {
      return calculationMode || isMenuLevelOverflow || notEnoughRowViewModelsForTransition;
    }),
    distinctUntilChanged()
  );

  public readonly productItemRenderStrategy$ = this.backOfMenuFlipper$.pipe(
    map(backOfMenuFlipper => backOfMenuFlipper ? 'normal' : 'immediate'),
  );

  private _oddRotationTracker = new BehaviorSubject<Map<string, number>>(new Map<string, number>());
  private oddRotationTracker$ = this._oddRotationTracker as Observable<Map<string, number>>;
  private rotatedViewModels: SectionRowViewModel[] = [];
  private updatedData = false;
  /**
   * If sectionRowViewModels$ emits, that means the data was fetched from the server, and said changes need to be
   * reflected in the view.
   */
  private sectionScrollTransitionRowViewModels$ = combineLatest([
    this.sectionOverflowTimer$, // goes to 0 on reset
    this.sectionRowViewModels$.pipe(tap(() => this.updatedData = true))
  ]).pipe(
    map(([timeIndex, vms]) => {
      // if sectionRowViewModels change, then this.updatedData will be true when the pipe is called again,
      // so we then send the updated data into the transition pipe.
      if (timeIndex === 0 || this.updatedData) {
        this.rotatedViewModels = (timeIndex === 0)
          ? vms?.shallowCopy()
          : this.getNewRowViewModelsInOldRowViewModelOrder(vms, this.rotatedViewModels);
      }
      this.getSectionScrollTransitionRowViewModels(timeIndex, this.updatedData, this.rotatedViewModels);
      this.updatedData = false;
      return this.rotatedViewModels;
    }),
    switchMap(rowViewModels => {
      const extended = (rowCount: number) => {
        const angularTrackByIsGoingToFail = rowViewModels?.length === rowCount + 1;
        const itemSnappingOutOfView: SectionRowViewModel = rowViewModels?.last();
        const newDataToSnapIntoView = rowViewModels?.slice(0, rowCount);
        if (angularTrackByIsGoingToFail) {
          // change transition unique id to trick trackBy into thinking it's a different item,
          // so that trackBy doesn't destroy all the DOM nodes in the list and re-create them
          const next: SectionRowViewModel = newDataToSnapIntoView?.last();
          const copy: SectionRowViewModel = Object.assign(Object.create(next.constructor.prototype), next);
          copy?.incrementUniqueIdOffset();
          newDataToSnapIntoView?.pop();
          newDataToSnapIntoView?.push(copy);
        }
        return [[itemSnappingOutOfView, ...(newDataToSnapIntoView || [])]];
      };
      return this.rowCount$.pipe(map(extended));
    })
  );

  private getNewRowViewModelsInOldRowViewModelOrder(
    newRowViewModels: SectionRowViewModel[],
    oldRowViewModels: SectionRowViewModel[]
  ): SectionRowViewModel[] {
    const updatedRowViewModels = newRowViewModels?.shallowCopy();
    const oldRowViewModelIds = oldRowViewModels?.map(vm => vm?.uniqueId());
    const firstIntersectionIndexInOldList = oldRowViewModelIds
      ?.findIndex(id => newRowViewModels?.find(vm => vm?.uniqueId() === id));
    const hasSharedItem = firstIntersectionIndexInOldList !== -1;
    const canRotate = ((newRowViewModels?.length - 1) >= firstIntersectionIndexInOldList);
    if (hasSharedItem && canRotate) {
      for (let i = 0; i < updatedRowViewModels?.length; i++) {
        const updatedIndex = updatedRowViewModels?.findIndex(vm => {
          return vm?.uniqueId() === oldRowViewModelIds[firstIntersectionIndexInOldList];
        });
        if (updatedIndex !== firstIntersectionIndexInOldList) {
          updatedRowViewModels?.rotateInPlaceCounterClockwise();
        } else {
          break;
        }
      }
    }
    this.connectToRealignScrollContainer();
    this._oddRotationTracker.next(new Map());
    return updatedRowViewModels;
  }

  /**
   * A side effect happens within this method.
   *
   * The side effect sets the oddRotationTracker data after the scroll animation has completed.
   * This is done to prevent line items from changing background color while scrolling out of view.
   */
  private getSectionScrollTransitionRowViewModels(
    timeIndex: number,
    rowViewModelsChanged: boolean,
    rowViewModels: SectionRowViewModel[]
  ) {
    if (timeIndex !== 0 && !rowViewModelsChanged) {
      rowViewModels?.rotateInPlaceCounterClockwise();
      const lastId = rowViewModels?.last()?.uniqueId();
      const scrollTime = ProductSectionWithTransitionsViewModel.SCROLL_TIME_IN_MILLI_SEC;
      const buffer = ProductSectionWithTransitionsViewModel.SCROLL_LAG_BUFFER_IN_MILLI_SEC;
      this.oddRotationTracker$.pipe(
        delay(scrollTime + buffer),
        take(1),
        takeUntil(this.onDestroy),
      ).subscribe(oddRotationTracker => {
        // doing a shallowCopy or a deepCopy here seems to break the odd order on BuzzTV devices
        // I'm not 100% sure why, so putting this as a comment so someone doesn't add it back in
        const update = oddRotationTracker || new Map<string, number>();
        update.set(lastId, oddRotationTracker.has(lastId) ? (oddRotationTracker.get(lastId) + 1) : 1);
        this._oddRotationTracker.next(update);
      });
    } else {
      setTimeout(() => this._oddRotationTracker.next(new Map()), 1000);
    }
    return rowViewModels;
  }

  private chunkedRowViewModels = (rowCount: number) => {
    return this.sectionRowViewModels$.pipe(map(rowViewModels => rowViewModels.chunkedList(rowCount)));
  };

  private sectionPagingTransitionRowViewModels$ = this.rowCount$.pipe(
    switchMap(rowCount => this.chunkedRowViewModels(rowCount))
  );

  /**
   * These are the row view models that are used for section level transition animation.
   * The pipe outputs section row view models that are in the correct order and the correct amount for the specified
   * section level overflow state.
   */
  protected transitionableSectionRowViewModels$: Observable<SectionRowViewModel[][]> = combineLatest([
    this.alwaysShowBaseRowViewModel$,
    this.sectionRowViewModels$,
    this.overflowState$
  ]).pipe(
    switchMap(([alwaysShowBaseRowViewModel, rowVms, overflowType]) => {
      const sectionScrolling = (overflowType === OverflowState.SECTION_SCROLL);
      const sectionPaginates = (overflowType === OverflowState.SECTION_PAGING);
      /* This fires every time the sectionOverflowTimer$ emits, because the syncSectionTransitionsService
       * relies on a zip operator, which waits for all sections on the screen to emit their rowVMs, before it can
       * sync the animations. All section level transition modes rely on the syncSectionTransitionsService. Therefore,
       * return this pipeline if alwaysShowBaseRowViewModel is true and using a section level transition. */
      const baseRowViewModelsUponOverflowTimer$ = this.sectionOverflowTimer$.pipe(mapTo([rowVms]));
      switch (true) {
        case sectionScrolling: {
          return alwaysShowBaseRowViewModel
            ? baseRowViewModelsUponOverflowTimer$
            : this.sectionScrollTransitionRowViewModels$;
        }
        case sectionPaginates: {
          return alwaysShowBaseRowViewModel
            ? baseRowViewModelsUponOverflowTimer$
            : this.sectionPagingTransitionRowViewModels$;
        }
        default:
          return of([rowVms]);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * This pipe is necessary in order to sync up all section row view model calculations.
   * This allows for transitions to happen at the same time.
   */
  protected keyedSyncedSectionRowViewModels$ = this.syncSectionTransitionsService.keyedAndSyncedSectionRowViewModels$;

  /**
   * Bottom of long complicated section row view model pipe.
   *
   * The output of this pipe is Map<string, SectionRowViewModel[][]> where the key is the section id.
   * The 2D array exists because for section level transitions using paging need all pages to be calculated
   * at the same time, so that the content all lives in the DOM, and then is shown/hidden as needed via
   * animation.
   *
   * If in calculation mode, aka the section is being laid out by the overflow calculator, then
   * the section row view models skip the sync/transition pipe and pass through the original
   * section row view models.
   *
   * If not in calculation mode, then this pipe will output synced/transition capable section
   * row view models, or it will output the original section row view models.
   */
  public keyedSectionRowViewModels$ = combineLatest([
    this.calculationMode$,
    this.section$,
    this.isSectionLevelOverflow$
  ]).pipe(
    switchMap(([calculationMode, section, isSectionLevelOverflow]) => {
      const key = section?.id;
      const base$ = this.sectionRowViewModels$.pipe(map(vms => new Map([[key, [vms]]])));
      const syncedTransition$ = this.keyedSyncedSectionRowViewModels$;
      const transition$ = this.transitionableSectionRowViewModels$.pipe(map(vms => new Map([[key, vms]])));
      const needsSyncedTransition = isSectionLevelOverflow && exists(section?.rowCount);
      switch (true) {
        case calculationMode:
          return base$;
        case needsSyncedTransition:
          return syncedTransition$;
        default:
          return transition$;
      }
    })
  );

  private connectToSyncedRowViewModels = combineLatest([
    this.calculationMode$,
    this.section$,
    this.isSectionLevelOverflow$
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([calculationMode, section, isSectionLevelOverflow]) => {
      const key = section?.id;
      if (!calculationMode && exists(key) && exists(section?.rowCount) && isSectionLevelOverflow) {
        this.syncSectionTransitionsService.connectToSync(key, this.transitionableSectionRowViewModels$);
      } else if (!calculationMode && exists(key)) {
        this.syncSectionTransitionsService.disconnectFromSync(key);
      }
    });

  private listenToSectionRowViewModels = this.sectionRowViewModels$.pipe(
    map(rowVms => rowVms?.map(r => r.uniqueId())),
    distinctUntilChanged(DistinctUtils.distinctStrings),
    takeUntil(this.onDestroy)
  ).subscribe(ids => this._distinctSectionRowViewModelIds.next(ids));

  /**
   * Why is this odd logic so complicated you ask?
   *
   * ********************** Infinite Scroll **********************
   *
   * Well, it's because when using an infinite scroll with an odd number of items,
   * the oddness of an item tumbles on each iteration.
   *
   *   0   1   2   3   4   5   6   7   8   9   10  11  12  13  14  <-- index
   * -------------------------------------------------------------
   * | 1   2   3   1   2   3   1   2   3   1   2   3   1   2   3 |
   * | o   e   o   e   o   e   o   e   o   e   o   e   o   e   o |
   * | ↑           ↑           ↑           ↑           ↑         |
   * -------------------------------------------------------------
   *
   * Looking at item 1, we can see that its oddness tumbles back and forth in an
   * infinite scrolling list containing an odd amount of items.
   *
   * ************************ Pagination **************************
   *
   * The oddness of an item when using pagination pertains to where it's
   * located on the page, and not the index of the item in the total list.
   *
   *   0   1   2   3   4   5   6   7   8  <-- index
   * -------------------------------------
   * |     Oddness Without Correction    |
   * | 1   2   3 | 4   5   6 | 7   8   9 |
   * | o   e   o | e   o   e | o   e   o |
   * | ↑         | ↑         | ↑         |
   * |       Oddness With Correction     |
   * | 1   2   3 | 4   5   6 | 7   8   9 |
   * | o   e   o | o   e   o | o   e   o |
   * | ↑         | ↑         | ↑         |
   * |  Page 1   |  Page 2   |  Page 3   |
   * -------------------------------------
   *
   * ********************* Addition Information *********************
   *
   * - If we are using indices that start counting at 0, and want to know if the position is odd,
   *   then use (index % 2 === 0)
   * - If we are trying to figure out if an integer is odd, then use (integer % 2 === 1)
   */
  public isRowViewModelOdd = (rowVm: SectionRowViewModel, indexOnPage: number): Observable<boolean> => {
    return combineLatest([
      this.rowCount$,
      this.overflowState$,
      this.distinctSectionRowViewModelIds$
    ]).pipe(
      switchMap(([rowCount, overflowState, ids]) => {
        const sectionLevelScrolling = (overflowState === OverflowState.SECTION_SCROLL) && ((ids?.length % 2) === 1);
        const sectionLevelPaging = (overflowState === OverflowState.SECTION_PAGING);
        switch (true) {
          case sectionLevelScrolling:
            return this.scrollingItemOddness(rowVm, ids);
          case sectionLevelPaging:
            return of(indexOnPage % 2 === 0);
          default:
            return of(ids?.indexOf(rowVm.uniqueId()) % 2 === 0);
        }
      }),
      distinctUntilChanged()
    );
  };

  private scrollingItemOddness(rowVm: SectionRowViewModel, ids: string[]): Observable<boolean> {
    return this.oddRotationTracker$.pipe(
      map(oddRotationTracker => {
        const offset = oddRotationTracker?.get(rowVm?.uniqueId()) || 0;
        const index = ids.indexOf(rowVm.uniqueId());
        return ((index + offset) % 2 === 0);
      })
    );
  }

  private readonly animationState$: Observable<OverflowState> = this.isSectionLevelOverflow$.pipe(
    switchMap(sectionLevelOverflow => iif(() => sectionLevelOverflow, this.overflowState$, of(null))),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getAnimationState$(index: number, length: number): Observable<OverflowState|null> {
    return this.animationState$.pipe(
      switchMap(state => {
        const sectionPagingState$ = this.sectionOverflowTimer$.pipe(
          map(timeIndex => {
            const paging = (state === OverflowState.SECTION_PAGING);
            const nextPage = ((timeIndex + 1) % length) === (index);
            if (paging && nextPage) return OverflowState.SECTION_PAGING_HIDDEN;
            return state;
          })
        );
        const otherAnimationState$ = of(state);
        return iif(() => state === OverflowState.SECTION_PAGING, sectionPagingState$, otherAnimationState$);
      }),
      distinctUntilChanged()
    );
  }

  public isProductPageVisible$(index: number, length: number): Observable<boolean> {
    return this.alwaysShowBaseRowViewModel$.pipe(
      switchMap(alwaysShowBaseRowViewModels => {
        const alwaysVisible$ = of(true);
        const isPageVisible$ = this.sectionOverflowTimer$.pipe(
          map(timeIndex => {
            const currentPage = (timeIndex % length) === index;
            const nextPage = ((timeIndex + 1) % length) === (index);
            return currentPage || nextPage;
          })
        );
        const visibility2WayValve = (paging: boolean) => iif(() => paging, isPageVisible$, alwaysVisible$);
        const visibility$ = this.sectionLevelPaging$.pipe(switchMap(visibility2WayValve));
        return iif(() => alwaysShowBaseRowViewModels, alwaysVisible$, visibility$);
      }),
      startWith(false),
      distinctUntilChanged()
    );
  }

  public isProductPageHidden$(index: number, length: number): Observable<boolean> {
    return this.alwaysShowBaseRowViewModel$.pipe(
      switchMap(alwaysShowBaseRowViewModels => {
        const alwaysVisible$ = of(false);
        const isPageHidden$ = this.sectionOverflowTimer$.pipe(
          // is next page (not current page)
          map(timeIndex =>  ((timeIndex + 1) % length) === (index))
        );
        const hidden2WayValve = (paging: boolean) => iif(() => paging, isPageHidden$, alwaysVisible$);
        const visibility$ = this.sectionLevelPaging$.pipe(switchMap(hidden2WayValve));
        return iif(() => alwaysShowBaseRowViewModels, alwaysVisible$, visibility$);
      }),
      startWith(false),
      distinctUntilChanged()
    );
  }

  destroy() {
    super.destroy();
    this.sectionId$.pipe(take(1)).subscribe(key => this.syncSectionTransitionsService.disconnectFromSync(key));
  }

}
