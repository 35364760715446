import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appProductMenuFooterInflatorContainer]'
})
export class ProductMenuFooterInflatorContainerDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
