import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';

@Directive({
  selector: '[appTypeturaContext]'
})
export class TypeturaContextDirective implements AfterViewInit, OnDestroy {

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2
  ) {
  }

  protected ro: ResizeObserver;

  ngAfterViewInit(): void {
    this.ro = new ResizeObserver((entries, _) => {
      const style = getComputedStyle(this.elementRef?.nativeElement);
      const borderLeftWidth = parseInt(style?.borderLeftWidth, 10) || 0;
      const borderRightWidth = parseInt(style?.borderRightWidth, 10) || 0;
      const width = this.elementRef.nativeElement?.clientWidth || 0;
      const binding = width + borderLeftWidth + borderRightWidth;
      this.renderer2.setStyle(this.elementRef.nativeElement, '--tt-bind', binding, RendererStyleFlags2.DashCase);
    });
    this.ro.observe(this.elementRef?.nativeElement);
  }

  ngOnDestroy(): void {
    this.ro?.disconnect();
  }

}
