import { SectionDimensions } from '../section-dimensions';

export class StaticProductSectionDimensions extends SectionDimensions {

  constructor(
    topMargin?: number,
    bottomMargin?: number,
    heightWithoutMargins?: number,
    sectionPaddingAndBorderHeight?: number,
    heightOfHeader?: number,
    heightOfFirstLineItem?: number,
    rowCount?: number
  ) {
    super(topMargin, bottomMargin, heightWithoutMargins);
    this.sectionPaddingAndBorderHeight = sectionPaddingAndBorderHeight;
    this.heightOfHeader = heightOfHeader;
    this.heightOfFirstLineItem = heightOfFirstLineItem;
    this.rowCount = rowCount;
  }

  public sectionPaddingAndBorderHeight: number;
  public heightOfHeader: number;
  public heightOfFirstLineItem: number;
  public rowCount: number;

  public hasLineItemHeight(): boolean {
    return this.heightOfFirstLineItem > 0;
  }

  public getHeightOfSectionWithNItems(n: number): number {
    return this.topMargin
         + this.sectionPaddingAndBorderHeight
         + this.heightOfHeader
         + (n * this.heightOfFirstLineItem)
         + this.bottomMargin;
  }

  public adjustForNItems(n: number): void {
    this.heightWithoutMargins = this.getHeightOfSectionWithNItems(n) - this.topMargin - this.bottomMargin;
  }

  public copyWith(newHeightWithoutMargins: number): SectionDimensions {
    return new StaticProductSectionDimensions(
      this.topMargin,
      this.bottomMargin,
      newHeightWithoutMargins,
      this.sectionPaddingAndBorderHeight,
      this.heightOfHeader,
      this.heightOfFirstLineItem,
      this.rowCount
    );
  }

}
