import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SplashComponent } from './components/splash/splash.component';
import { DisplayComponent } from './components/display/display.component';
import { RenderPrintMenuLiveViewComponent } from './components/menus/render-print-menu-live-view/render-print-menu-live-view.component';

const routes: Routes = [
  {
    path: '',
    component: SplashComponent
  },
  {
    path: 'splash',
    component: SplashComponent
  },
  {
    path: 'display/:displayId',
    component: DisplayComponent
  },
  {
    path: 'menu/:locationId/:menuId',
    component: DisplayComponent
  },
  {
    path: 'template/:locationId/:menuTemplateId',
    component: DisplayComponent
  },
  {
    path: 'templatecollection/:locationId/:templateCollectionId',
    component: DisplayComponent
  },
  {
    path: 'online/:displayId',
    component: DisplayComponent,
    data: {
      hidePrices: true
    }
  },
  {
    path: 'print/:locationId/:menuId',
    component: DisplayComponent
  },
  {
    path: 'print/live-view',
    component: RenderPrintMenuLiveViewComponent
  },
  {
    path: 'web/:locationId/:menuId',
    component: DisplayComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisplayRoutingModule {
}
