import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MarketingMenuComponent } from '../marketing-menu.component';
import { EmptyMarketingMenuViewModel } from '../empty-marketing-menu-view-model';
import { RenderContextService } from '../../../../../services/render-context.service';

@Component({
  selector: 'app-empty-marketing-menu',
  templateUrl: './empty-marketing-menu.component.html',
  styleUrls: ['./empty-marketing-menu.component.scss'],
  providers: [EmptyMarketingMenuViewModel, RenderContextService]
})
export class EmptyMarketingMenuComponent extends MarketingMenuComponent
  implements OnInit, AfterViewInit, OnChanges {

  constructor(
    public override viewModel: EmptyMarketingMenuViewModel
  ) {
    super(viewModel);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupViews();
  }

  setupViews() {
    super.setupViews();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  setupBindings() {
    super.setupBindings();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

}
