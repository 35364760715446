<div
  #sectionOverflowContainer
  class="menu-section-overflow-calculator"
  [style.width.%]="menu | productMenuBasicMethod : 'getSectionWidthPercentage'">
  <ng-container *ngIf="viewModel.renderOverflow$ | async">
    <app-product-menu-section-inflator
      *ngIf="menu | productMenuBasicMethod : 'getShowHeaderAsTitleSection'"
      [calculationMode]="true"
      [reset]="reset"
      [hidden]="menu?.menuOptions?.hideTitle"
      [index]="0"
      [last]="menu?.sections?.length <= 0"
      [style.width.%]="menu | productMenuBasicMethod : 'getSectionWidthPercentage'"
      [locationId]="locationId"
      [menu]="menu"
      [section]="menu | productMenuBasicMethod : 'getShowHeaderAsTitleSection'"
      [ngStyle]="{
        'max-height': (menu | productMenuBasicMethod : 'getShouldOverflowHorizontallyElseVertically') ? '100%' : null
      }">
    </app-product-menu-section-inflator>
    <app-product-menu-section-inflator
      *rxFor="
        let s of menu?.sections;
        index as i;
        last as last;
        trackBy: viewModel.trackBySectionUniqueIdentifier;
        strategy: viewModel.productSectionInflatorRenderStrategy$
      "
      [calculationMode]="true"
      [reset]="reset"
      [style.width.%]="menu | productMenuBasicMethod : 'getSectionWidthPercentage'"
      [locationId]="locationId"
      [menu]="menu"
      [index]="(menu | productMenuBasicMethod : 'getShowHeaderAsTitleSection') ? i + 1 : i"
      [last]="last"
      [section]="s"
      [ngStyle]="{
        'max-height': (menu | productMenuBasicMethod : 'getShouldOverflowHorizontallyElseVertically') ? '100%' : null
      }">
      Section
    </app-product-menu-section-inflator>
  </ng-container>
</div>
