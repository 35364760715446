import { MenuItemComponent } from '../../product-menu/building-blocks/menu-item/menu-item.component';
import { Component, ComponentRef, ElementRef, EnvironmentInjector, Injector, Input } from '@angular/core';
import { SectionRowViewModel } from '../../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { Menu } from '../../../../../../models/menu/menu';
import { LocationConfiguration } from '../../../../../../models/company/dto/location-configuration';
import { HtmlUtils } from '../../../../../../utils/html-utils';
import { InflatorComponent } from '../../../../../inflator/inflator.component';

@Component({ selector: 'app-menu-item-inflator', template: '' })
export abstract class MenuItemInflatorComponent extends InflatorComponent {

  protected constructor(
    protected elementRef: ElementRef,
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() calculationMode: boolean = false;
  @Input() locationId: number;
  @Input() locationConfig: LocationConfiguration;
  @Input() sectionRowViewModels: SectionRowViewModel[];
  @Input() rowViewModel: SectionRowViewModel;
  @Input() menu: Menu;
  @Input() odd: boolean;
  @Input() sectionOffsetBottom: number;
  @Input() sectionIndex: number;
  @Input() itemIndex: number;
  @Input() reset: boolean;
  @Input() first: boolean;
  @Input() last: boolean;

  protected compRef: ComponentRef<MenuItemComponent>;

  getMenuItemComponent(): MenuItemComponent {
    return this.compRef.instance;
  }

  getNativeElement(): any {
    return this.elementRef?.nativeElement;
  }

  getHeightInPx(): Promise<number> {
    return HtmlUtils.getElementTotalHeightAsync(this.getNativeElement());
  }

}
