import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SplashViewModel } from './splash-view-model';
import { CacheService } from '../../../services/cache-service';
import { ToastService } from '../../../../services/toast-service';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  providers: [SplashViewModel]
})
export class SplashComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(
    public vm: SplashViewModel,
    private cacheService: CacheService,
    private toastService: ToastService
  ) {
    super();
  }

  @Input() isDisplay: boolean = false;

  setupViews() {
    this.vm.connectToIsDisplay(this.isDisplay);
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisplay) this.vm.connectToIsDisplay(this.isDisplay);
  }

  clearCaches() {
    this.toastService.publishInfoMessage('', 'Cache Cleared');
    this.cacheService.clearSessionCache();
    this.cacheService.clearPersistentCache();
  }

}
