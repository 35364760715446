<div
  #sectionContainer
  *ngIf="!section.empty"
  class="section-container {{ menu.getSectionWrapperClass(index) }}"
  [ngStyle]="{
    'background-image': menu.getMakeHeaderSectionImageFillEntireSection() ? (asset | async) : null
  }"
  [style.background-color]="menu?.getProductSectionBackgroundColor(section)"
  [style.background-size]="'cover'"
  [style.color]="menu?.getSectionBodyTextColor(section)"
  [style.max-height]="menu?.getShouldOverflowHorizontallyElseVertically() ? '100%' : null">
  <div #productsContainer class="products-container">
    <!-- Odd logic is reversed because we start at 0 in computer science -->
    <app-spotlight-menu-item-inflator
      *ngFor="let secVm of viewModel.sectionRowViewModels$ | async | castToSpotLightSectionRowVMs; index as itemIndex"
      [reset]="reset"
      [odd]="itemIndex % 2 === 0"
      [locationId]="locationId"
      [locationConfig]="locationConfig"
      [menu]="menu"
      [sectionRowViewModels]="viewModel.sectionRowViewModels$ | async | castToSpotLightSectionRowVMs"
      [rowViewModel]="secVm"
      [hidden]="secVm.hideOverflowItem() || false"
      [sectionIndex]="index"
      [itemIndex]="itemIndex">
    </app-spotlight-menu-item-inflator>
  </div>
</div>
