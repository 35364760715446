import { Pipe, PipeTransform } from '@angular/core';
import type { SectionRowViewModel } from '../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';

@Pipe({
  name: 'getSectionScrollAnimationState'
})
export class GetSectionScrollAnimationStatePipe implements PipeTransform {

  transform(
    isSectionLevelOverflow: boolean,
    rowCount: number,
    keyedVms: SectionRowViewModel[],
    me: SectionRowViewModel,
  ): string {
    if (!isSectionLevelOverflow || keyedVms?.length <= rowCount || keyedVms?.last() !== me) {
      return null;
    }
    return 'scrollIntoView';
  }

}
