import { ChangeDetectionStrategy, Component, ElementRef, EnvironmentInjector, Injector, OnChanges, SimpleChanges, Type } from '@angular/core';
import { MenuSectionInflatorComponent } from '../../../menu/inflators/menu-section-inflator-component';
import { exists } from '../../../../../../../functions/exists';
import { MenuSectionComponent } from '../../building-blocks/menu-section/menu-section.component';
import { SectionInflatorUtils } from '../../../../../../../utils/section-inflator-utils';

@Component({
  selector: 'app-swimlane-section-inflator',
  templateUrl: './swimlane-section-inflator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwimlaneSectionInflatorComponent extends MenuSectionInflatorComponent implements OnChanges {

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector,
    element: ElementRef
  ) {
    super(injector, environmentInjector, element);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (exists(changes.menu) || exists(changes.section)) {
      this.connectToComponentType(this.getSectionType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  getSectionType(): Type<MenuSectionComponent> {
    return SectionInflatorUtils.getSectionComponentType(this.menu, this.section);
  }

  public getMenuSectionInflatorId(): string {
    return this.element?.nativeElement?.id;
  }

  public getChildSectionComponent(): MenuSectionComponent {
    return this.compRef.instance as MenuSectionComponent;
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
