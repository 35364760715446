import { Component } from '@angular/core';
import { MenuSectionHeaderComponent } from '../menu-section-header.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-empty-section-header',
  templateUrl: './empty-section-header.component.html',
})
export class EmptySectionHeaderComponent extends MenuSectionHeaderComponent {

  constructor(
    public sanitizer: DomSanitizer
  ) {
    super(sanitizer);
  }

  getHeaderHeight(): number {
    return 0;
  }

}
