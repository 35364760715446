/* src/app/modules/display/components/menus/marketing-menu/building-blocks/card-sections/card-section-number/card-section-number.component.scss */
.card-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
/*# sourceMappingURL=card-section-number.component.css.map */
