import { Component, ElementRef, EnvironmentInjector, forwardRef, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { SpotlightMenu } from '../../../../../../../models/menu/spotlight-menu';
import { SpotlightMenuItemComponent } from '../../building-blocks/menu-item/spotlight-menu-item.component';
import { SpotlightSectionRowViewModel } from '../../building-blocks/menu-section/spotlight-product-section/spotlight-section-row-view-model';
import { MenuItemInflatorComponent } from '../../../menu/inflators/menu-item-inflator-component';
import { exists } from '../../../../../../../functions/exists';

@Component({
  selector: 'app-spotlight-menu-item-inflator',
  templateUrl: './spotlight-menu-item-inflator.component.html',
  providers: [
    { provide: MenuItemInflatorComponent, useExisting: forwardRef(() => SpotlightMenuItemInflatorComponent) }
  ]
})
export class SpotlightMenuItemInflatorComponent extends MenuItemInflatorComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(elementRef, injector, environmentInjector);
  }

  @Input() menu: SpotlightMenu;
  @Input() rowViewModel: SpotlightSectionRowViewModel;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rowViewModel) {
      this.connectToComponentType(this.getMenuItemType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  protected override forwardInputs(): void {
    super.forwardInputs();
    if (exists(this.compRef)) this.compRef.instance.section = this.rowViewModel?.section;
  }

  protected override forwardInputChanges(changes: SimpleChanges): void {
    if (exists(this.compRef)) {
      Object.keys(changes)
        ?.filter(propName => propName !== 'section')
        ?.forEach(propertyName => this.compRef.setInput(propertyName, this[propertyName]));
      if (!!changes.rowViewModel) this.compRef.instance.section = this.rowViewModel.section;
      this.compRef?.instance?.ngOnChanges(changes);
    }
  }

  protected getMenuItemType(): Type<SpotlightMenuItemComponent> {
    return this.rowViewModel?.section?.getMenuItemType();
  }

}
