import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { DisplayableProductCard } from '../displayable-product-card';
import { ColorUtils } from '../../../../../../../../utils/color-utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';
import { map } from 'rxjs/operators';
import { OrientationService } from '../../../../../../../../services/orientation.service';
import { Orientation } from '../../../../../../../../models/enum/dto/orientation.enum';
import { BaseProductCardViewModel } from './base-product-card-view-model';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss'],
  providers: [BaseProductCardViewModel]
})
export class BaseProductCardComponent extends RenderContextComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    public viewModel: BaseProductCardViewModel,
    protected orientationService: OrientationService
  ) {
    super();
  }

  @Input() card: DisplayableProductCard;
  @Input() index: number;
  @Input() cardCount: number;

  @ViewChild('productCard') productCard: ElementRef;
  public ro: ResizeObserver;
  private cardContainerDomRec = new BehaviorSubject<DOMRectReadOnly | null>(null);
  public cardContainerBottom$ = combineLatest([
    this.orientationService.virtuallyRotated$,
    this.orientationService.virtualOrientation$,
    this.cardContainerDomRec,
  ]).pipe(
    map(([virtuallyRotated, virtualOrientation, cardContainerDomRec]) => {
      if (virtuallyRotated) {
        switch (virtualOrientation) {
          case Orientation.Portrait:
            return cardContainerDomRec?.left;
          case Orientation.ReversePortrait:
            return cardContainerDomRec?.right;
          default:
            return cardContainerDomRec?.bottom;
        }
      } else {
        return cardContainerDomRec?.bottom;
      }
    })
  );
  public cardContainerClientHeight$ = this.cardContainerDomRec.pipe(map(rect => rect?.height));

  ngOnInit() {
    super.ngOnInit();
    this.viewModel.connectToCard(this.card);
    this.viewModel.connectToIndex(this.index);
    this.viewModel.connectToCardCount(this.cardCount);
  }

  setupViews() {
  }

  setupBindings() {
    this.observeProductCardContainer();
  }

  observeProductCardContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const productCardRec = this.productCard?.nativeElement?.getBoundingClientRect();
        this.cardContainerDomRec.next(productCardRec as DOMRectReadOnly);
      }
    });
    // Element for which to observe height and width
    this.ro.observe(this.productCard.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.card) this.viewModel.connectToCard(this.card);
    if (changes.index) this.viewModel.connectToIndex(this.index);
    if (changes.cardCount) this.viewModel.connectToCardCount(this.cardCount);
  }

  isDarkTextColor(): boolean {
    return ColorUtils.isDarkColor(this.card.getCardTextColor());
  }

  getLabelPercentage(): number {
    return 0.045;
  }

  getProductNameTextPercentage(): number {
    return 0.08;
  }

  getProductDescTextPercentage(): number {
    return this.getProductNameTextPercentage() * 0.6;
  }

  getProductInfoTextPercentage(): number {
    return this.getProductNameTextPercentage() * 0.66;
  }

  getProductCannabinoidTextPercentage(): number {
    return this.card.getIsCannabinoidRange()
      ? this.getProductNameTextPercentage() * 0.45
      : this.getProductNameTextPercentage() * 0.66;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.ro?.disconnect();
  }

}
