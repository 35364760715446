// noinspection JSUnusedLocalSymbols

import { Component } from '@angular/core';
import { LoggingService } from './services/logging-service';
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { InjectorService } from './modules/services/injector.service';
import { ScalingService } from './services/scaling.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'budsense-client-display';

  constructor(
    private loggingService: LoggingService,
    private injectorService: InjectorService, // Do not remove, or else injector service does not work.
    private scalingService: ScalingService, // Do not remove, or else scaling service does not get init
  ) {
    // Initialize Firebase
    const firebaseApp = initializeApp(environment.firebaseConfig);
    const userProperties: { [key: string]: any } = {};
    userProperties.Platform = 'Display';
    const analytics = getAnalytics(firebaseApp);
    setUserProperties(analytics, userProperties);
    this.loggingService.setFirebaseAppInstance(firebaseApp);
  }

}
