// noinspection JSUnusedLocalSymbols

import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { DisplayDomainModel } from '../../../../domain/display-domain-model';
import { asapScheduler, BehaviorSubject, combineLatest, fromEvent, iif, interval, merge, Observable, of, Subscription, timer } from 'rxjs';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { CacheService } from '../../../services/cache-service';
import { Display } from '../../../../models/display/dto/display';
import { DisplayOptions } from '../../../../models/shared/display-options';
import { Size } from '../../../../models/shared/size';
import { debounceTime, delayWhen, distinctUntilChanged, filter, map, observeOn, pairwise, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Menu } from '../../../../models/menu/menu';
import { RotationState } from '../../../../models/enum/shared/rotation-state.enum';
import { DistinctUtils } from '../../../../utils/distinct.utils';
import { Theme } from '../../../../models/menu/dto/theme';
import { MenuType } from '../../../../models/enum/dto/menu-type.enum';
import { DateUtils } from '../../../../utils/date-utils';
import { ToastService } from '../../../../services/toast-service';
import { LoggingService } from '../../../../services/logging-service';
import { DisplayMenuCoupling } from '../../../../couplings/display-menu-coupling.service';
import { SortUtils } from '../../../../utils/sort-utils';
import { Orientation } from '../../../../models/enum/dto/orientation.enum';
import { OrientationService } from '../../../../services/orientation.service';
import { IsMenuReadyService } from '../../../services/is-menu-ready.service';
import { document } from 'ngx-bootstrap/utils';
import { NumberUtils } from '../../../../utils/number.utils';
import { exists } from '../../../../functions/exists';
import { LocationDomainModel } from '../../../../domain/location-domain-model';
import { CompanyDomainModel } from '../../../../domain/company-domain-model';
import { MenuToDisplay } from '../../../../models/menu/menu-to-display';
import { InterfaceUtils } from '../../../../utils/interface-utils';
import { ProductMenu } from '../../../../models/menu/product-menu';

interface AndroidWebAppInterface {
  reloadPage(): void;
}

declare let android: AndroidWebAppInterface;

export const SCROLL_DELAY_SECONDS = 5;
export const REFRESH_INTERVAL_SECONDS = 30;
export const FIRST_REFRESH_INTERVAL_LOWER_BOUND_SECONDS = 25;
export const FIRST_REFRESH_INTERVAL_UPPER_BOUND_SECONDS = 35;

@Injectable()
export class DisplayViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private isMenuReadyService: IsMenuReadyService,
    private displayDomainModel: DisplayDomainModel,
    private cache: CacheService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private logging: LoggingService,
    private displayMenuCoupling: DisplayMenuCoupling,
    private orientationService: OrientationService,
    private locationDomainModel: LocationDomainModel,
  ) {
    super();
  }

  // From display/:displayId
  public displayId = new BehaviorSubject<string>(null);
  // From templatecollection/:locationId/:templateCollectionId
  public templateCollectionId = new BehaviorSubject<string>(null);
  public isCollection$ = this.templateCollectionId.pipe(map(id => !!id));
  // From display/:displayId?IgnoreLastSession
  public ignoreLastSession = new BehaviorSubject<boolean>(null);
  // From menu/:locationId/:menuId
  public menuId = new BehaviorSubject<string>(null);
  // From template/:locationId/:menuTemplateId
  public menuTemplateId = new BehaviorSubject<string>(null);
  // Company Configuration
  public companyId = new BehaviorSubject<number>(null);
  public companyConfig$ = this.displayDomainModel.companyConfig$;
  private listenToCompanyId = this.companyId.notNull().pipe(
    distinctUntilChanged(),
    switchMap(companyId => this.companyDomainModel.getCompanyConfig(companyId))
  ).subscribeWhileAlive({ owner: this });

  // From menu/:locationId/:menuId || template/:locationId/:menuTemplateId
  private _locationId = new BehaviorSubject<number>(null);
  public locationId$ = this._locationId as Observable<number>;
  public locationConfig$ = this.displayDomainModel.locationConfig$;
  private distinctNotNullLocationId$ = this.locationId$.notNull().pipe(distinctUntilChanged());
  private distinctNotNullCompanyId$ = this.companyId.notNull().pipe(distinctUntilChanged());
  private listenToLocationId = combineLatest([this.distinctNotNullLocationId$, this.distinctNotNullCompanyId$])
    .pipe(
      switchMap(([locationId, companyId]) => this.locationDomainModel.getLocationConfiguration(companyId, locationId)),
      takeUntil(this.onDestroy)
    )
    .subscribe();
  // A display can have multiple menus associated with it.
  private _display = new BehaviorSubject<Display>(null);
  public display$ = combineLatest([
    this._display,
    this.companyConfig$,
    this.locationConfig$
  ]).pipe(
    map(([display, companyConfig, locationConfig]) => {
      if (exists(companyConfig) && exists(locationConfig)) {
        display?.configurations?.forEach(menu => menu.setImplicitProperties(companyConfig, locationConfig));
        return display;
      }
      return null;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly hasScheduledMenus$ = this.display$.pipe(map(display => display?.hasScheduledMenus()));
  // Session start - set on app reload
  public session = new BehaviorSubject<number>(null);
  // Solo menu - when viewing a singular menu, and not a display
  public _soloMenu = new BehaviorSubject<Menu>(null);
  public soloMenu$ = combineLatest([
    this._soloMenu,
    this.companyConfig$,
    this.locationConfig$
  ]).pipe(
    map(([menu, companyConfig, locationConfig]) => {
      if (exists(companyConfig) && exists(locationConfig)) {
        menu?.setImplicitProperties(companyConfig, locationConfig);
        return menu;
      }
      return null;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  private listenToSoloMenu = this._soloMenu
    .notNull()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(solo => this.orientationService.setVirtualOrientation(solo.displaySize));
  // Menu Data
  public menuToDisplay$ = this.displayDomainModel.menuToDisplay.asObservable();
  private _activeScheduledMenus = new BehaviorSubject<Menu[] | null>(null);
  public activeScheduledMenus$ = this._activeScheduledMenus.pipe(
    distinctUntilChanged(DistinctUtils.distinctScheduledMenus),
    pairwise(),
  );
  // menusToRotate includes menus on schedule
  private menusToRotate = new BehaviorSubject<Menu[] | null>(null);
  public menusToRotate$ = this.menusToRotate.pipe(
    distinctUntilChanged(DistinctUtils.distinctMenus),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public nMenusToRotate$ = this.menusToRotate$.notNull().pipe(
    map(menus => menus?.length),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  private readonly noSteadyScrollContentMenuRotation$ = this.displayMenuCoupling.noSteadyScrollContentMenuRotation$;
  private readonly steadyScrollFinishedRotateMenu$ = this.displayMenuCoupling.steadyScrollFinishedRotateMenu$;
  private holdRotation$ = combineLatest([
    this.menuToDisplay$.pipe(distinctUntilChanged(DistinctUtils.distinctDisplayableMenu)),
    this.displayMenuCoupling.holdDisplayRotation
  ]).pipe(
    map(([currMenu, req]) => {
      if (!!currMenu && !!req) {
        return currMenu.menu.id === req.menuIdRequestingHold;
      } else {
        return false;
      }
    })
  );
  public readonly locationTimeZone$ = this.locationDomainModel.locationTimeZone$;
  private calcWhatMenusToRotate$ = combineLatest([
    timer(0, 5000), // 5s
    this.display$,
    this.soloMenu$,
    this.locationTimeZone$
  ]);
  // Loading
  public loading$ = this.isMenuReadyService.showSplashScreen$;
  // Menu settings
  public hidePrices = new BehaviorSubject<boolean>(null);
  public displaySettings = new BehaviorSubject<Size>(null);
  private listenToDisplaySettings = this.displaySettings
    .notNull()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(ds => this.orientationService.setVirtualOrientation(ds));
  // Movement mechanisms for refreshing data and product-menu rotations
  private intervalSub: Subscription;
  private intervalAboutToToggleSub: Subscription;
  /**
   * Random first interval between 25:00 and 35:59 minutes to avoid all displays refreshing at the same time.
   * Subsequent intervals after the first happen every 30 minutes.
   * 60000ms === 1 minute.
   * Must startWith a non-null, non-undefined value. Since first interval will return 0, we start with -1
   */
  private refreshDataInterval$ = timer(
    this.getInitialRefreshInterval(),
    REFRESH_INTERVAL_SECONDS * 60000
  ).pipe(
    startWith(-1),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private getInitialRefreshInterval(): number {
    const minuteInterval = NumberUtils.randomIntegerWithin(
      FIRST_REFRESH_INTERVAL_LOWER_BOUND_SECONDS,
      FIRST_REFRESH_INTERVAL_UPPER_BOUND_SECONDS
    );
    const secondInterval = NumberUtils.randomIntegerWithin(0, 60);
    // eslint-disable-next-line no-console
    console.log(`Initial refresh interval is ${minuteInterval} min ${secondInterval} sec.`);
    return (minuteInterval * 60000) + (secondInterval * 1000);
  }

  public menuRotationPosition: number = 0;
  private displayRotationInstructions = new BehaviorSubject<DisplayOptions>(null);
  private rotationState = new BehaviorSubject<RotationState>(RotationState.START);
  // Mechanism to rotate menus
  private rotationMechanism$ = combineLatest([
    this.activeScheduledMenus$,
    this.menusToRotate$.notNull(),
    this.rotationState.notNull(),
  ]).pipe(
    debounceTime(50),
    observeOn(asapScheduler)
  );
  private rotationStartup: boolean = true;
  //
  private storePreviousScheduledMenuIds = null;
  private storeActiveScheduledMenuIds = null;
  private refreshDisplayMechanism$ = combineLatest([
    this.refreshDataInterval$.notNull().pipe(delayWhen((i) => {
      // On the very first load, we want to wait a random amount of time before fetching display (0-5s)
      return i === -1 ? interval(Math.random() * 5 * 1000) : interval(0);
    })),
    this.displayId.notNull(),
    this.ignoreLastSession.notNull()
  ]);
  // Mechanism to refresh template collections
  private refreshCollectionMechanism$ = combineLatest([
    this.refreshDataInterval$.notNull(),
    this.locationId$.notNull(),
    this.templateCollectionId.notNull(),
  ]);
  private refreshMenuTemplateMechanism$ = combineLatest([
    this.refreshDataInterval$.notNull(),
    this.locationId$.notNull(),
    this.menuTemplateId.notNull(),
  ]);
  // Mechanism to refresh menus
  private refreshMenuMechanism$ = combineLatest([
    this.refreshDataInterval$.notNull(),
    this.locationId$.notNull(),
    this.menuId.notNull(),
  ]);
  // Refresh session mechanism
  private maxSessionLength = 86400; // 24 hrs in seconds - 86400
  private checkForExpiredSessionTime = 60000; // 1 minute in milliseconds
  private refreshSessionMechanism$ = combineLatest([
    this.display$.notNull(),
    this.session.notNull(),
    this.ignoreLastSession
  ]);
  // Check internet connection
  private internetConnection$ = merge(
    fromEvent(window, 'offline').pipe(map(_ => false)),
    fromEvent(window, 'online').pipe(map(_ => true)),
  );
  // Mechanism for rotating webView
  /** canRotateWebView means that the browser viewport is in charge or rotating the screen. Not the OS */
  private canRotateWebView = new BehaviorSubject<boolean>(false);
  public canRotateWebView$ = this.canRotateWebView.asObservable();
  private listenToCanRotateWebView = this.canRotateWebView$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(canRotate => this.orientationService.setCanRotateWebView(canRotate));
  /** rotateWebViewBy$ sets a class on the document body tag, rotating the entire viewport if specified */
  public rotateWebViewBy$ = combineLatest([
    this.canRotateWebView.pipe(distinctUntilChanged()),
    this.displaySettings,
  ]).pipe(debounceTime(1), takeUntil(this.onDestroy))
    .subscribe(([canRotate, settings]) => {
      let bodyClass = '';
      if (!!canRotate && !!settings) {
        switch (settings.orientation) {
          case Orientation.Landscape:
            bodyClass = '';
            break;
          case Orientation.Portrait:
            bodyClass = 'rotate90';
            break;
          case Orientation.ReversePortrait:
            bodyClass = 'rotate270';
            break;
          case Orientation.NA:
            bodyClass = '';
            break;
        }
      }
      document.body.className = bodyClass;
    });

  initFromUrl(params, queryParams: Params) {
    const displayId = params?.displayId;
    const templateCollectionId = params?.templateCollectionId;
    const menuId = params?.menuId;
    const menuTemplateId = params?.menuTemplateId;
    const locationId = params?.locationId ? Number.parseInt(params?.locationId, 10) : null;
    const ignoreLastSession = JSON.parse(queryParams?.IgnoreLastSession ?? false) ?? false;
    if (!!displayId || (!!templateCollectionId && !!locationId)) {
      if (!!displayId) this.displayId.next(displayId);
      if (!!templateCollectionId) {
        this.templateCollectionId.next(templateCollectionId);
        this._locationId.next(locationId);
      }
      this.ignoreLastSession.next(ignoreLastSession);
    } else if ((!!menuId || !!menuTemplateId) && !!locationId) {
      if (!!menuId) this.menuId.next(menuId);
      if (!!menuTemplateId) this.menuTemplateId.next(menuTemplateId);
      this._locationId.next(locationId);
      const cachedMenu = this.cache.getCachedObject<Menu>(Menu, Menu.buildCacheKey(locationId, menuId), true);
      if (cachedMenu) {
        this._soloMenu.next(cachedMenu);
        this.companyId.next(cachedMenu.companyId);
      }
    }
    const screenshotMode = JSON.parse(queryParams?.screenshotMode ?? false) as boolean;
    this.displayMenuCoupling.screenshotMode.next(screenshotMode);
    const hideDownloadToast = JSON.parse(queryParams?.hideDownloadToast ?? false) as boolean;
    this.displayMenuCoupling.connectToHideDownloadToast(hideDownloadToast);
  }

  initFromRouteData(data: Data) {
    if (data.hidePrices) {
      this.hidePrices.next(true);
    } else {
      this.hidePrices.next(false);
    }
  }

  // Binding Start --------------------------------------------------------
  bind() {
    this.parseUrl();
    this.internetSub();
    this.soloMenuSub();
    this.displaySub();
    this.refreshSubs();
    this.calcMenusToRotateSub();
    this.rotationMechanismSub();
    this.bindMenuToRotateCountSub();
    this.bindMenuIdsToRotateSub();
    this.bindToCurrentVisibleMenuIdSub();
  }

  private parseUrl() {
    const s = combineLatest([
      this.route.params,
      this.route.queryParams
    ]).subscribe(([params, queryParams]) => {
      this.initFromUrl(params, queryParams);
    });
    this.pushSub(s);
    //
    const ss = this.route.data.subscribe(data => {
      this.initFromRouteData(data);
    });
    this.pushSub(ss);
    //
    const sss = this.route.url.subscribe(urlChunks => {
      const segments = urlChunks.map(it => it.path);
      let type: MenuType;
      if (segments.contains('web')) {
        type = MenuType.WebMenu;
      } else if (segments.contains('print')) {
        type = MenuType.PrintMenu;
      } else {
        type = MenuType.DisplayMenu;
      }
      this.logging.loadedMenuType(type);
      this.displayDomainModel.classificationOfMenu.next(type);
    });
    this.pushSub(sss);
    // canRotateWebView query param
    const ssss = this.route.queryParams
      .pipe(filter(params => params.canRotateWebView))
      .subscribe(params => {
        const boolValue = JSON.parse(params?.canRotateWebView ?? 'false');
        this.canRotateWebView.next(boolValue);
      });
    this.pushSub(ssss);
  }

  private internetSub() {
    const s = this.internetConnection$.pipe(
      startWith(true),
      debounceTime(10000),
    ).subscribe(connected => {
      if (!connected) {
        this.logging.logInternetConnectionError();
        this.toastService.showConnectionError();
      }
    });
    this.pushSub(s);
  }

  private soloMenuSub() {
    // must be _soloMenu and not soloMenu$. soloMenu$ depends on this initialization.
    this._soloMenu.notNull().subscribeWhileAlive({ owner: this, next: it => this.initMenu(it) });
  }

  private displaySub() {
    // must be _display and not display$. Display$ depends on this initialization.
    this._display.notNull().subscribeWhileAlive({ owner: this, next: d => this.initDisplay(d) });
    // wait for loading$ to be done, then start rotations
    this.menuToDisplay$.notNull().pipe(
      tap(m => document.documentElement.className = m?.menu?.getMenuClass()),
      switchMap(() => this.loading$),
      filter(loading => !loading),
      take(1),
      switchMap(() => this.menuToDisplay$),
      distinctUntilChanged(DistinctUtils.distinctDisplayableMenu)
    ).subscribeWhileAlive({
      owner: this,
      next: (m) => {
        if (m?.menu) {
          document.documentElement.className = m.menu.getMenuClass();
          this.setMenuRotationTime(m);
        }
      }
    });
  }

  private refreshSubs() {
    const refreshCollectionSub = this.refreshCollectionMechanism$.subscribe(([refresh, locId, collectionId]) => {
      if (!!locId && !!collectionId && refresh !== null && refresh !== undefined) {
        this.fetchTemplateCollectionFromAPI(locId, collectionId);
      }
    });
    this.pushSub(refreshCollectionSub);
    //
    const refreshDisplaySub = this.refreshDisplayMechanism$.subscribe(([refresh, displayId, ignoreLastSession]) => {
      if (displayId && refresh !== null && refresh !== undefined) {
        this.fetchDisplayFromAPI(displayId, ignoreLastSession);
      }
    });
    this.pushSub(refreshDisplaySub);
    //
    const refreshMenuTempSub = this.refreshMenuTemplateMechanism$.subscribe(([refresh, locId, menuTemplateId]) => {
      if (!!locId && !!menuTemplateId && refresh !== null && refresh !== undefined) {
        this.fetchMenuTemplateFromAPI(locId, menuTemplateId);
      }
    });
    this.pushSub(refreshMenuTempSub);
    //
    const refreshMenuSub = this.refreshMenuMechanism$.subscribe(([refresh, locationId, menuId]) => {
      if (locationId && menuId && refresh !== null && refresh !== undefined) {
        this.fetchMenuFromAPI(locationId, menuId);
      }
    });
    this.pushSub(refreshMenuSub);
    //
    const refreshSessionSub = this.refreshSessionMechanism$.subscribe(([display, session, ignoreLastSession]) => {
      if (!ignoreLastSession) {
        const sessionExpired = (DateUtils.nowInUnixSeconds() - session) >= this.maxSessionLength;
        const resetFromApi = (DateUtils.nowInUnixSeconds() - display.lastSession) >= this.maxSessionLength;
        if (sessionExpired || resetFromApi) {
          // pass in lastModified property that doesn't match what's on backend to trick backend into
          // bypassing cached display data and fetching fresh data.
          this.cache.cacheGeneric(`last-modified-${display.id}`, Date.now());
          this.logging.sessionExpired(display.id);
          document.location.reload();
          if (!!android) android?.reloadPage();
        }
      }
    });
    this.pushSub(refreshSessionSub);
  }

  /**
   * This runs every 5 seconds.
   * menus get filtered and sorted on the display object upon entering the app by the deserializer.
   */
  private calcMenusToRotateSub() {
    const s = this.calcWhatMenusToRotate$.subscribe(([_, display, soloMenu, locationTimeZone]) => {
      const sort = SortUtils.sortMenusByRotationOrder;
      if (!!soloMenu) {
        this.menusToRotate.next(Array(soloMenu));
      } else if (!!display) {
        let menusToRotate = display.activeMenus;
        const activeIntervalMenus = display.getActiveIntervalMenus(locationTimeZone);
        if (!!activeIntervalMenus && activeIntervalMenus.length > 0) {
          // Append interval menus to rest of activeMenus
          menusToRotate = menusToRotate.concat(activeIntervalMenus);
          const opts = display.options;
          this._activeScheduledMenus.next(sort(activeIntervalMenus, opts));
        } else {
          this._activeScheduledMenus.next([]);
        }
        this.menusToRotate.next(sort(menusToRotate, display.options) ?? []);
      }
    });
    this.pushSub(s);
  }

  private rotationMechanismSub() {
    const rotationMecSub = this.rotationMechanism$.subscribe(
      ([[prevActiveScheduledMenus, activeScheduledMenus], menus, state]) => {
        const hasMenus = !!menus;
        const prevActiveScheduledMenuIds = prevActiveScheduledMenus?.map(m => m.id) ?? [];
        const activeScheduledMenuIds = activeScheduledMenus?.map(m => m.id) ?? [];
        // previous
        const storedPrevScheduleString = this.storePreviousScheduledMenuIds?.join(',');
        const prevActiveScheduledString = prevActiveScheduledMenuIds.join(',');
        const prevScheduledChanged = storedPrevScheduleString !== prevActiveScheduledString;
        // active
        const storedActiveScheduledString = this.storeActiveScheduledMenuIds?.join(',');
        const activeScheduledString = activeScheduledMenuIds?.join(',');
        const activeScheduledChanged = storedActiveScheduledString !== activeScheduledString;

        const scheduledChanged = prevScheduledChanged || activeScheduledChanged;
        const newlyAddedActiveScheduleMenuIds = activeScheduledMenuIds.filter(x => {
          return !prevActiveScheduledMenuIds.includes(x);
        });

        // new scheduled menu entered into the queue, so display it right away
        if (!this.rotationStartup && scheduledChanged && (newlyAddedActiveScheduleMenuIds?.length === 1)) {
          const menuIdToDisplay = newlyAddedActiveScheduleMenuIds?.firstOrNull();
          const position = menus?.findIndex(m => m.id === menuIdToDisplay);
          this.setMenu(menus, false, false, position);
        } else if (hasMenus) {
          switch (state) {
            case RotationState.START: {
              this.setMenu(menus, false, false);
              break;
            }
            case RotationState.ROTATE: {
              this.setMenu(menus, true, false);
              this.rotationState.next(RotationState.HOLD);
              break;
            }
            case RotationState.UPDATE_ROTATION_TIME: {
              this.setMenu(menus, false, false);
              break;
            }
            case RotationState.RESET: {
              this.setMenu(menus, false, true);
              break;
            }
            case RotationState.HOLD: {
              break;
            }
          }
        }
        this.rotationStartup = false;
        // store previous states
        this.storePreviousScheduledMenuIds = prevActiveScheduledMenuIds;
        this.storeActiveScheduledMenuIds = activeScheduledMenuIds;
      }
    );
    this.pushSub(rotationMecSub);
  }

  private bindMenuToRotateCountSub() {
    const menusToRotateSub = this.displayMenuCoupling.menuToRotateCount.bind(this.nMenusToRotate$);
    this.pushSub(menusToRotateSub);
  }

  private bindMenuIdsToRotateSub() {
    const menuIds$ = this.menusToRotate$.pipe(map(menus => menus?.map(m => m?.id)));
    const menusToRotateSub = this.displayMenuCoupling.menuIdsToRotate.bind(menuIds$);
    this.pushSub(menusToRotateSub);
  }

  private bindToCurrentVisibleMenuIdSub() {
    const menuId$ = this.menuToDisplay$.pipe(map(menu => menu?.menu?.id));
    const currentVisibleMenuIdSub = this.displayMenuCoupling.currentVisibleMenuId.bind(menuId$);
    this.pushSub(currentVisibleMenuIdSub);
  }

  private setMenu(
    menus: Menu[],
    rotate: boolean = false,
    reset: boolean = false,
    specificPosition?: number
  ) {
    if (reset) {
      this.menuRotationPosition = 0;
    } else if (rotate) {
      this.menuRotationPosition = ((this.menuRotationPosition + 1) % menus?.length);
    } else if (specificPosition !== null && specificPosition !== undefined && specificPosition > -1) {
      this.menuRotationPosition = specificPosition;
    }

    if (menus?.length > 1) {
      let currentMenu = menus[this.menuRotationPosition];
      if (!currentMenu) {
        this.menuRotationPosition = 0;
        currentMenu = menus[this.menuRotationPosition];
      }
      if (currentMenu) {
        this.displayDomainModel.setMenuToDisplay(currentMenu);
      }
      this.displayMenuCoupling.holdLoopingMenuRotation.next(false);
    } else if (menus?.length === 1) {
      this.displayDomainModel.setMenuToDisplay(menus?.firstOrNull());
    }
  }

  // -------------------------------------------------------- Binding End

  private setMenuRotationTime(menuToDisplay: MenuToDisplay) {
    const menu = menuToDisplay?.menu;
    const rotationTimeInSeconds = menuToDisplay?.displayMeForXSeconds;
    const preventMenuContentFromRotatingIfDisplayIsAboutToChangeMenus = () => {
      this.intervalAboutToToggleSub?.unsubscribe();
      this.intervalAboutToToggleSub = combineLatest([
        timer(0, (rotationTimeInSeconds - 1) * 1000),
        this.nMenusToRotate$
      ]).subscribe(([timerFired, nMenusRotating]) => {
        const singleMenuRotating = nMenusRotating <= 1;
        if (timerFired > 0 && !singleMenuRotating) {
          this.displayMenuCoupling.holdLoopingMenuRotation.next(true);
        }
      });
    };
    const rotateAfterIntervalFiresAndHoldFlagOff = () => {
      this.intervalSub?.unsubscribe();
      const waitForMenu$ = iif(
        () => menu instanceof ProductMenu,
        this.isMenuReadyService.waitUntilMenuRenders$(menu?.id),
        of(true)
      );
      this.intervalSub = waitForMenu$.pipe(
        filter(menuRendered => menuRendered),
        take(1),
        switchMap(() => {
          return combineLatest([
            timer(0, rotationTimeInSeconds * 1000),
            this.holdRotation$.pipe(map(it => exists(it))),
          ]);
        })
      ).subscribe(([timerFired, hold]) => {
        if ((timerFired > 0) && !hold) {
          this.rotationState.next(RotationState.ROTATE);
        }
      });
    };
    const rotateAfterScrollFinishes = () => {
      this.intervalSub?.unsubscribe();
      this.intervalSub = merge(
        this.noSteadyScrollContentMenuRotation$,
        this.steadyScrollFinishedRotateMenu$
      ).pipe(observeOn(asapScheduler)).subscribe(() => {
        this.rotationState.next(RotationState.ROTATE);
      });
    };
    const isSteadyScrolling = InterfaceUtils.isSectionsCanOverflowInterface(menu)
      ? (menu?.isInVerticalScrollingOverflowState() || menu?.isInHorizontalScrollingOverflowState())
      : false;
    if (isSteadyScrolling || rotationTimeInSeconds < 0) {
      rotateAfterScrollFinishes();
    } else if (rotationTimeInSeconds > 1) {
      preventMenuContentFromRotatingIfDisplayIsAboutToChangeMenus();
      rotateAfterIntervalFiresAndHoldFlagOff();
    } else {
      this.rotationState.next(RotationState.HOLD);
    }
  }

  private fetchTemplateCollectionFromAPI(locationId: number, templateCollectionId: string) {
    if (!!templateCollectionId && !!locationId) {
      this._display.next(null);
      this.displayDomainModel.getTemplateCollection(locationId, templateCollectionId).subscribe(t => {
        this._display.next(t);
        this.companyId.next(t.companyId);
        this.logging.refreshedTemplateCollectionData(locationId, templateCollectionId);
      });
    }
  }

  private fetchDisplayFromAPI(dId: string, ignoreLastSession: boolean) {
    if (dId) {
      this._display.next(null);
      this.displayDomainModel.getDisplay(dId, ignoreLastSession).subscribe(d => {
        this.cache.cacheGeneric(`last-modified-${dId}`, d?.lastModified);
        this._display.next(d);
        this.companyId.next(d.companyId);
        this.logging.refreshedDisplayData(dId);
      });
    }
  }

  private fetchMenuTemplateFromAPI(locationId: number, menuTemplateId: string) {
    if (!!menuTemplateId && !!locationId) {
      this._soloMenu.next(null);
      this.displayDomainModel.getMenuTemplate(locationId, menuTemplateId).subscribe(m => {
        this._soloMenu.next(m);
        this.companyId.next(m.companyId);
        this.logging.refreshedMenuTemplateData(locationId, menuTemplateId);
      });
    }
  }

  private fetchMenuFromAPI(locationId: number, menuId: string) {
    if (menuId && locationId) {
      this._soloMenu.next(null);
      this.displayDomainModel.getMenu(locationId, menuId).subscribe(m => {
        this._soloMenu.next(m);
        this.companyId.next(m.companyId);
        this.logging.refreshedMenuData(locationId, menuId);
      });
    }
  }

  private initDisplay(d: Display) {
    if (this.session.getValue() === null || this.session.getValue() === undefined) {
      this.session.next(d.lastSession); // init session if empty
    }
    this.displayRotationInstructions.next(d.options);
    // template collections do not store a location id within the locationId property (don't next in undefined)
    if (!d?.isTemplateCollection()) this._locationId.next(d.locationId);
    this.displaySettings.next(d.displaySize);
    const themes = [...d.activeMenus, ...d.intervalMenus].map(it => it.hydratedTheme);
    this.setThemesInDomainModel(themes);
    const version = require('package.json')?.version;
    this.logging.logVersionAndLocation(version, d.locationId);
  }

  private initMenu(m: Menu) {
    this.displayDomainModel.setMenuToDisplay(m);
    this.setThemesInDomainModel(Array(m.hydratedTheme));
  }

  private setThemesInDomainModel(themes: Theme[]) {
    this.displayDomainModel.connectToThemes(themes);
  }

}
