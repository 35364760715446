import { IsMenuReadyService } from '../../../../../../services/is-menu-ready.service';
import { Injectable } from '@angular/core';
import { ProductMenuSectionOverflowCalculatorViewModel } from '../product-menu-section-overflow-calculator/product-menu-section-overflow-calculator-view-model';
import { combineLatest, defer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../../utils/distinct.utils';
import { MenuSectionComponent } from '../../building-blocks/menu-section/menu-section.component';
import { SectionDimensions } from '../../building-blocks/menu-section/section-dimensions';
import { Section } from '../../../../../../../models/menu/section/section';
import { PageBreakSectionComponent } from '../../building-blocks/menu-section/page-break-section/page-break-section.component';
import { ProductSectionComponent } from '../../building-blocks/menu-section/product-section/product-section.component';
import { StaticProductSectionDimensions } from '../../building-blocks/menu-section/product-section/static-product-section-dimensions';
import { ProductMenu } from '../../../../../../../models/menu/product-menu';
import { ProductSection } from '../../../../../../../models/menu/section/product-section';
import { DisplayMenuCoupling } from '../../../../../../../couplings/display-menu-coupling.service';
import { exists } from '../../../../../../../functions/exists';
import { TitleSection } from '../../../../../../../models/menu/section/title-section';
import { AssetSection } from '../../../../../../../models/menu/section/asset-section';
import { CachedOverflowService } from '../../../../../../services/cached-overflow.service';

@Injectable()
export class ProductMenuStaticSectionOverflowCalculatorViewModel extends ProductMenuSectionOverflowCalculatorViewModel {

  constructor(
    displayMenuCoupling: DisplayMenuCoupling,
    isMenuReadyService: IsMenuReadyService,
    cachedOverflowService: CachedOverflowService
  ) {
    super(displayMenuCoupling, isMenuReadyService, cachedOverflowService);
  }

  public override overflowedSections$ = combineLatest([
    combineLatest([this.companyConfig$, this.locationConfig$]),
    defer(() => this._menu).notNull(),
    this.sectionsContainerHeight$.pipe(filter(height => height > 0)),
    this.sectionHeights$,
    this.screenshotMode$,
  ]).pipe(
    tap(_ => this.isMenuReadyService.overflow(true)),
    debounceTime(100),
    map(([[companyConfig, locationConfig], menu, pageHeight, sectionHeights, screenshotMode]) => {
      const shouldOverflow = menu?.isSectionLevelOverflow() && (pageHeight > 0);
      if (shouldOverflow) {
        this.menu = menu;
        this.companyConfig = companyConfig;
        this.locationConfig = locationConfig;
        this.canvasHeight = pageHeight;
        this.runningPageHeight = 0;
        this.pageIndex = 0;
        this.overflowSections = [];
        this.screenshotMode = screenshotMode;
        return this.createOverflowSections(menu, pageHeight, sectionHeights);
      } else {
        return menu?.sections || [];
      }
    }),
    tap(_ => this.isMenuReadyService.overflow(false)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected override createOverflowSections(
    menu: ProductMenu,
    pageHeight: number,
    sectionHeights: [MenuSectionComponent, SectionDimensions][]
  ): Section[] {
    this.resetOverflowSectionData(sectionHeights);
    this.calculatePageHeight();
    for (const [sectionComponent, sectionHeight] of sectionHeights) {
      if (this.pageIndex < menu?.getNumberOfColumns()) {
        this.calculateSectionPlacementAtPageCursorPosition(sectionComponent, sectionHeight);
      }
    }
    this.handleLastSection();
    return this.overflowSections?.map(([section]) => section);
  }

  protected override calculateSectionPlacementAtPageCursorPosition(
    sectionComponent: MenuSectionComponent,
    sectionHeight: SectionDimensions
  ): void {
    if (this.stopSectionLayoutForScreenshotMode()) {
      return;
    }
    if (sectionComponent instanceof PageBreakSectionComponent) {
      this.moveCursorToNewPage();
    } else if (this.sectionFitsOntoPageAtCurrentPosition(sectionComponent, sectionHeight)) {
      this.sectionFitsOnPage(sectionComponent?.section, sectionHeight);
    } else {
      this.sectionDidNotFitAtCurrentPosition(sectionComponent, sectionHeight);
    }
  }

  protected sectionFitsOnPage(section: Section, height: SectionDimensions): void {
    if (section instanceof ProductSection) {
      section.fixedHeightInPx = height.getHeightWithoutMargins();
    }
    this.addSectionToOverflowAndUpdateCursorPosition(section, height);
  }

  protected addSectionToOverflowAndUpdateCursorPosition(
    section: Section,
    dimensions: SectionDimensions
  ): void {
    if (!!section) {
      section.pageIndex = this.pageIndex;
      if (this.runningPageHeight === 0) {
        section.firstOnPage = true;
      }
      const prevSection = this.overflowSections?.last()?.[0];
      if (exists(prevSection)) {
        prevSection.beforeTitleSection = section instanceof TitleSection;
        prevSection.beforeAssetSection = section instanceof AssetSection;
      }
      section.afterAssetSection = prevSection instanceof AssetSection;
      if (this.pageIndex < this.menu?.getNumberOfColumns()) {
        switch (true) {
          case this.shouldRemoveTopMarginFromHeightCalculation(section):
            const sectionHeightWithoutTopMargin = this.getSectionHeightWithoutTopMargin(dimensions);
            this.addToCursorPosition(sectionHeightWithoutTopMargin);
            break;
          default:
            const sectionHeightWithMargins = this.getSectionHeightWithMargins(dimensions);
            this.addToCursorPosition(sectionHeightWithMargins);
        }
        this.overflowSections.push([section, dimensions]);
        if (this.runningPageHeight >= this.pageHeight) {
          this.moveCursorToNewPage();
        }
      }
    }
  }

  protected sectionDidNotFitAtCurrentPosition(
    component: MenuSectionComponent,
    dimensions: SectionDimensions
  ): void {
    if (this.runningPageHeight === 0) {
      this.calculateOverflowAtTopOfPage(component, dimensions);
    } else if (component instanceof ProductSectionComponent && !component?.section?.rowCount) {
      // If there isn't a row count set on the product section, then do the base overflow calculation and split
      // the section up into pieces that fit onto the page(s)
      this.calculateProductSectionOverflow(component, dimensions);
    } else if (this.menu?.getNumberOfColumns() === 1) {
      // If we end up in here, that means:
      // 1) We aren't at the top of the page
      // 2) There is a row count (aka row limiter) set greater than 0
      // 3) We are making calculations on a single column menu
      // 4) The content doesn't fit on the page at the current cursor position
      // Since we don't want to split up sections with row counts grater than 0, we do nothing, and don't add the
      // section to be rendered, because there is no point in rendering a section that will be bumped off the
      // screen when using section level overflow.
    } else {
      this.moveCursorToNewPage();
      this.calculateSectionPlacementAtPageCursorPosition(component, dimensions);
    }
  }

  private calculateOverflowAtTopOfPage(component: MenuSectionComponent, dimensions: SectionDimensions): void {
    const isProductSection = component instanceof ProductSectionComponent;
    const isProductSectionWithRowCount = component instanceof ProductSectionComponent
      && component.section.rowCount > 0;
    const isStaticProductDimensions = dimensions instanceof StaticProductSectionDimensions;
    if (isProductSectionWithRowCount && isStaticProductDimensions) {
      // Row count is set, but there are too many products to fit onto a single page.
      // Therefore, we adjust the row count to fit the section on the page so that it can use section level
      // transitions properly.
      const castedToProductSection = component as ProductSectionComponent;
      const castedToStaticDimensions = dimensions as StaticProductSectionDimensions;
      this.adjustSectionRowCountToFitPage(castedToProductSection, castedToStaticDimensions);
      this.addSectionToOverflowAndUpdateCursorPosition(component?.section, dimensions);
    } else if (isProductSection) {
      // A product section without a row count set. Follow normal overflow rules and split the section up
      // into pieces that fit onto the page(s).
      this.calculateProductSectionOverflow(component as ProductSectionComponent, dimensions);
    } else {
      this.addSectionToOverflowAndUpdateCursorPosition(component?.section, dimensions);
    }
  }

  protected adjustSectionRowCountToFitPage(
    sectionComponent: ProductSectionComponent,
    sectionHeight: StaticProductSectionDimensions
  ): void {
    let n = 0;
    if (sectionHeight.hasLineItemHeight()) {
      while (sectionHeight.getHeightOfSectionWithNItems(n + 1) <= this.pageHeight) {
        n++;
      }
      sectionHeight.adjustForNItems(n);
    }
    sectionComponent.section.fixedHeightInPx = sectionHeight.getHeightWithoutMargins();
    if (n > 0) sectionComponent.section.rowCount = n;
  }

}
