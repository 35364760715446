import { Pipe, PipeTransform } from '@angular/core';
import { Card } from '../../../../../../../../models/menu/product-menu-cards/card';
import { DisplayableProductCard } from '../../menu-cards/displayable-product-card';
import { ProductCard } from '../../../../../../../../models/menu/product-menu-cards/product-card';

@Pipe({
  name: 'castToProductCard'
})
export class CastToProductCardPipe implements PipeTransform {

  transform(value: Card): DisplayableProductCard {
    if (value instanceof ProductCard) {
      return value;
    }
    return null;
  }

}
