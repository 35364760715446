<div
  #sectionContainer
  class="section-container wrapping-swimlane-section-container"
  [ngClass]="menu | printMenuBasicMethod : 'getSectionWrapperClass' : index"
  [ngStyle]="{
    'background-image':
      (menu | printMenuBasicMethod : 'getMakeHeaderSectionImageFillEntireSection') ? (asset | async) : null
  }"
  [style.background-color]="menu | printMenuBasicMethod : 'getProductSectionBackgroundColor' : section"
  [style.background-size]="'cover'"
  [style.color]="menu | printMenuBasicMethod : 'getSectionBodyTextColor' : section"
  [style.max-height]="(menu | printMenuBasicMethod : 'getShouldOverflowHorizontallyElseVertically') ? '100%' : null">
  <div *ngFor="let i of viewModel.nSwimlanes$ | async | range" class="wrapping-swimlane">
    <app-swimlane-section-inflator
      *ngFor="let s of section?.contentInLanes[i]"
      [reset]="reset"
      [locationConfig]="locationConfig"
      [locationId]="locationId"
      [menu]="menu"
      [index]="i"
      [last]="last"
      [section]="s">
      Section
    </app-swimlane-section-inflator>
  </div>
</div>
