import { Component, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { CompanyConfiguration } from '../../../../../../../models/company/dto/company-configuration';
import { BaseMenuComponent } from '../../../menu/base-menu.component';
import { ProductMenuComponent } from '../../product-menu.component';
import { ProductMenu } from '../../../../../../../models/menu/product-menu';
import { MarketingFeaturedCategoryMenu } from '../../../../../../../models/menu/marketing/featured-category/marketing-featured-category-menu';
import { MarketingFeaturedCategoryMenuComponent } from '../../../marketing-menu/marketing-featured-category-menu/marketing-featured-category-menu.component';
import { MenuWithScrollableSectionsComponent } from '../../../scrollable-sections/menu-with-scrollable-sections.component';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import { MarketingFeaturedCategoryStaticGridMenu } from '../../../../../../../models/menu/marketing/featured-category/marketing-featured-category-static-grid-menu';
import { MarketingFeaturedCategoryStaticGridMenuComponent } from '../../../marketing-menu/marketing-featured-category-static-grid-menu/marketing-featured-category-static-grid-menu.component';
import { InflatorComponent } from '../../../../../../inflator/inflator.component';

type ProductMenuType = ProductMenuComponent
  | MenuWithScrollableSectionsComponent
  | MarketingFeaturedCategoryStaticGridMenuComponent;

@Component({
  selector: 'app-product-menu-inflator',
  templateUrl: './product-menu-inflator.component.html',
  styleUrls: ['./product-menu-inflator.component.scss']
})
export class ProductMenuInflatorComponent extends InflatorComponent implements OnChanges {

  constructor(
    injector: Injector,
    enironmentInjector: EnvironmentInjector
  ) {
    super(injector, enironmentInjector);
  }

  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: ProductMenu;
  @Input() reset: boolean;
  @Input() backOfMenuFlipper: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu?.previousValue?.id !== changes.menu?.currentValue?.id) {
      this.connectToComponentType(this.getProductMenuType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  getProductMenuType(): Type<BaseMenuComponent> {
    if (this.menu instanceof MarketingFeaturedCategoryStaticGridMenu) {
      return MarketingFeaturedCategoryStaticGridMenuComponent;
    } else if (this.menu instanceof MarketingFeaturedCategoryMenu) {
      return MarketingFeaturedCategoryMenuComponent;
    } else if (this.menu instanceof ProductMenu) {
      return ProductMenuComponent;
    }
  }

}
