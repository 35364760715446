<div
  class="footer-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.footerTextColor ? menu?.menuOptions?.footerTextColor : ''
  }">
  <div class="footer-oval">
    <img class="leaf-img" src="/assets/images/leaf.svg" alt="" />
  </div>
</div>
