import { BaseMenuViewModel } from '../menu/base-menu-view-model';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { MarketingUrlPlaylistMenu } from '../../../../../models/menu/marketing/marketing-url-playlist-menu';
import { combineLatest } from 'rxjs';

@Injectable()
export class EmptyMarketingMenuViewModel extends BaseMenuViewModel {

  public readonly isUrlPlaylistMenu$ = this.distinctNotNullMenu$.pipe(
    map(menu => menu instanceof MarketingUrlPlaylistMenu),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly menuName$ = this.distinctNotNullMenu$.pipe(
    map(menu => menu?.name)
  );

  public readonly emptyMarketingMenuHasNoContentText$ = combineLatest([
    this.isUrlPlaylistMenu$,
    this.menuName$
  ]).pipe(
    map(([isUrlPlaylistMenu, menuName]) => {
      return isUrlPlaylistMenu
        ? `Menu (${menuName}) does not have an associated URL.`
        : `Menu (${menuName}) does not have any content enabled.`;
    })
  );

  public readonly howToFixText$ = this.isUrlPlaylistMenu$.pipe(
    map(isUrlPlaylistMenu => {
      return isUrlPlaylistMenu
        ? `To add a URL to this menu, go to the menu's settings and add a URL.`
        : `Open the menu editor and add content or make sure that the enabled switches are on. `
          + `They should look like the one below.`;
    })
  );

}
