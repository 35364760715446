import { Component, EnvironmentInjector, Injector, Input, OnChanges, Type } from '@angular/core';
import { PrintMenu } from '../../../../../../../models/menu/print-menu';
import { ProductMenuFooterInflatorComponent } from './product-menu-footer-inflator.component';
import { ProductMenuFooterComponent } from '../../building-blocks/menu-footer/product-menu-footer.component';

@Component({
  selector: 'app-product-menu-empty-footer-replacer-inflator',
  templateUrl: './product-menu-footer-inflator.component.html',
})
export class PrintMenuEmptyFooterReplacerInflatorComponent extends ProductMenuFooterInflatorComponent
  implements OnChanges {

  @Input() menu: PrintMenu;

  constructor(
    injector: Injector,
    environmentInjector: EnvironmentInjector,
  ) {
    super(injector, environmentInjector);
  }

  protected override getFooterType(): Type<ProductMenuFooterComponent> {
    return this.menu?.getEmptyFooterReplacement();
  }

}
