/* src/app/modules/display/components/splash/splash.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.splash-container {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 999999;
}
.company-logo {
  max-height: 50%;
  width: 35%;
}
.software-version {
  font-family: poppins, sans-serif;
  font-weight: 400;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 1rem;
  font-size: 2rem;
}
.centered {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*# sourceMappingURL=splash.component.css.map */
