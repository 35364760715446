import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { MenuSectionComponent } from '../menu-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Section } from '../../../../../../../../models/menu/section/section';

@Component({
  selector: 'app-image-section',
  templateUrl: './asset-section.component.html',
  providers: [
    { provide: MenuSectionComponent, useExisting: forwardRef(() => AssetSectionComponent) }
  ]
})
export class AssetSectionComponent extends MenuSectionComponent {

  constructor(
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(sanitizer, element);
  }

  @Input() section: Section;

  setupBindings() {
    super.setupBindings();
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
