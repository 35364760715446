import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { SectionWithProducts } from '../../../models/menu/section/section-with-products';

@Pipe({
  name: 'getSectionBottomBorder'
})
export class GetSectionBottomBorderPipe implements PipeTransform {

  transform(menu: Menu, section: SectionWithProducts): string {
    return section?.getBottomBorder(menu);
  }

}
