import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
import { OverflowState } from '../../../../../../../../models/enum/shared/overflow-transition-state.enum';

const pagingSpeedInMilliSeconds = 500;
export const productSectionPagingAnimations = trigger(
  'productSectionPagingAnimations',
  [
    state(OverflowState.SECTION_PAGING, style({})),
    transition(
      `void => ${OverflowState.SECTION_PAGING}`,
      [
        style({ opacity: 0 }),
        sequence([
          animate(pagingSpeedInMilliSeconds, style({ opacity: 0 })),
          animate(pagingSpeedInMilliSeconds, style({ opacity: 1 }))
        ])
      ]
    ),
    transition(
      `${OverflowState.SECTION_PAGING} => void`,
      [
        style({ opacity: 1 }),
        animate(pagingSpeedInMilliSeconds, style({ opacity: 0 }))
      ]
    ),
    transition(
      `${OverflowState.SECTION_PAGING_HIDDEN} => ${OverflowState.SECTION_PAGING}`,
      [
        style({ opacity: 0 }),
        sequence([
          animate(pagingSpeedInMilliSeconds, style({ opacity: 0 })),
          animate(pagingSpeedInMilliSeconds, style({ opacity: 1 }))
        ])
      ]
    )
  ]
);
