import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../../../../models/menu/section/section';

@Pipe({
  name: 'assetSectionWidth'
})
export class AssetSectionWidthPipe implements PipeTransform {

  transform(section: Section): string {
    const width = section?.metadata?.width || '100';
    return width + '%';
  }

}
