import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../models/menu/menu';

@Pipe({
  name: 'getDefaultBackgroundImageUrl'
})
export class GetDefaultBackgroundImageUrlPipe implements PipeTransform {

  transform(menu: Menu): string | null {
    return menu?.getDefaultBackgroundImageUrl() || null;
  }

}
