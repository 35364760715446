import { Component, ElementRef, forwardRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SplitProductSectionViewModel } from './split-product-section-view-model';
import { ProductSectionComponent } from '../product-section.component';

@Component({
  selector: 'app-split-product-section',
  templateUrl: './split-product-section.component.html',
  styleUrls: ['./split-product-section.component.scss'],
  providers: [
    SplitProductSectionViewModel,
    { provide: ProductSectionComponent, useExisting: forwardRef(() => SplitProductSectionComponent) }
  ]
})
export class SplitProductSectionComponent extends ProductSectionComponent {

  constructor(
    public viewModel: SplitProductSectionViewModel,
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(viewModel, sanitizer, element);
  }

}
