/* src/app/modules/display/components/menus/marketing-menu/building-blocks/menu-cards/base-product-card/base-card.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.marketing-card-container {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5.17% 5.17% 3% 5.17%;
}
.marketing-card-container .product-label {
  text-shadow: none;
  font-family: poppins, sans-serif;
  font-weight: 600;
  align-self: end;
  margin-left: 0;
}
/*# sourceMappingURL=base-card.component.css.map */
