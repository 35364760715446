<div
  #sectionContainer
  class="empty-container sativa-hybrid-indica-section-container"
  [style.display]="'flex'"
  [style.height.px]="section?.heightInPx"
  [style.border-color]="section?.borderColor"
  [style.background-color]="section?.backgroundColor">
  <!-- Sativa Product Items -->
  <div class="sativa-products" [style.border-color]="section?.borderColor"></div>
  <!-- Hybrid Product Items -->
  <div class="hybrid-products"></div>
  <!-- Indica Product Items -->
  <div class="indica-products"></div>
  <div class="gap-filler" [style.background-color]="section?.backgroundColor"></div>
</div>
