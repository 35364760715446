import { Component, ComponentRef, EnvironmentInjector, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CompanyConfiguration } from '../../../../../../../models/company/dto/company-configuration';
import { SpotlightMenu } from '../../../../../../../models/menu/spotlight-menu';
import { SpotlightFooterComponent } from '../../building-blocks/menu-footer/spotlight-footer/spotlight-footer.component';
import { SpotlightFooterInflatorContainerDirective } from './spotlight-footer-inflator-container.directive';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import { Subscribable } from '../../../../../../../models/base/subscribable';

@Component({
  selector: 'app-spotlight-footer-inflator',
  templateUrl: './spotlight-footer-inflator.component.html'
})
export class SpotlightFooterInflatorComponent extends Subscribable implements OnInit, OnChanges {

  constructor(
    protected injector: Injector,
    protected environmentInjector: EnvironmentInjector
  ) {
    super();
  }

  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: SpotlightMenu;
  @Input() reset: boolean;
  @ViewChild(SpotlightFooterInflatorContainerDirective, { static: true })
  contentContainer: SpotlightFooterInflatorContainerDirective;
  private compRef: ComponentRef<SpotlightFooterComponent>;

  ngOnInit() {
    this.inflateFooter();
  }

  inflateFooter() {
    if (!!this.menu) {
      this.contentContainer.viewContainerRef.clear();
      this.compRef = this
        .contentContainer
        .viewContainerRef
        .createComponent(
          this.menu?.getFooterType(),
          { injector: this.injector, environmentInjector: this.environmentInjector }
        );
      this.compRef.setInput('companyConfig', this.companyConfig);
      this.compRef.setInput('locationConfig', this.locationConfig);
      this.compRef.setInput('locationId', this.locationId);
      this.compRef.setInput('menu', this.menu);
      this.compRef.setInput('reset', this.reset);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) {
      this.inflateFooter();
    } else {
      if (!!this.compRef) {
        if (changes.locationId) this.compRef.setInput('locationId', this.locationId);
        if (changes.companyConfig) this.compRef.setInput('companyConfig', this.companyConfig);
        if (changes.locationConfig) this.compRef.setInput('locationConfig', this.locationConfig);
        if (changes.reset) this.compRef.setInput('reset', this.reset);
        this.compRef.instance.ngOnChanges(changes);
      }
    }
  }

}
