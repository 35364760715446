import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../../../../models/menu/section/section';

@Pipe({
  name: 'assetSectionAlignment'
})
export class AssetSectionAlignmentPipe implements PipeTransform {

  transform(section: Section): string {
    switch (section?.metadata?.alignment) {
      case 'center': return 'center';
      case 'right':  return 'flex-end';
      default:       return 'flex-start';
    }
  }

}
