import { Component, Input } from '@angular/core';
import { EmptySectionComponent } from '../empty-section.component';
import { PlantlifeNonSmokableEmptySection } from '../../../../../../../../../models/menu/section/plantlife-non-smokable-empty-section';

@Component({
  selector: 'app-plantlife-non-smokable-empty-section',
  templateUrl: './plantlife-non-smokable-empty-section.component.html',
  styleUrls: ['./plantlife-non-smokable-empty-section.component.scss']
})
export class PlantlifeNonSmokableEmptySectionComponent extends EmptySectionComponent {

  @Input() override section: PlantlifeNonSmokableEmptySection;

}
