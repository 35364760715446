<div class="error-container move-to-top-of-z-stack">
  <div class="flex-grow"></div>
  <div class="error-margin flex-grow">
    <div class="error-title smaller">Oh no.</div>
    <div class="error-desc flex-grow smaller">
      <ng-container *ngIf="hasScheduledMenus; else empty">
        <p>No menus are scheduled to play at this time on your {{ isCollection ? 'collection' : 'display' }}.</p>
        <p>
          Login to the dashboard at app.mybudsense.com and review your
          {{ isCollection ? 'collection' : 'display' }} configuration.
        </p>
      </ng-container>
      <ng-template #empty>
        <p>This {{ isCollection ? 'collection' : 'display' }} doesn't have any content.</p>
        <p>
          Login to the dashboard at app.mybudsense.com to add content to your
          {{ isCollection ? 'collection' : 'display' }}.
        </p>
      </ng-template>
    </div>
  </div>
  <div class="error-logo-container flex-grow">
    <img class="error-logo smaller" src="/assets/logo/dark/logo-stroke.svg" alt="" />
  </div>
</div>
