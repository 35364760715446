<div
  #sectionContainer
  *ngIf="!section?.empty"
  appTypeturaContext
  class="section-container header-image-carousel-card-section {{ menu?.getSectionWrapperClass(index) }}"
  [style.background-color]="viewModel?.backgroundColor$ | async">
  <!--Non 2 Card Header-->
  <ng-container *ngIf="!(viewModel?.is2Card$ | async)">
    <div
      class="card-image-header-container"
      [style.align-items]="'center'"
      [style.justify-content]="getImageSectionAlignment()">
      <img
        appImage
        [asset]="section?.image"
        [reset]="reset"
        [style.height.%]="100"
        [style.width]="viewModel.imageSectionWidth$ | async"
        [style.float]="viewModel.imageSectionFloat$ | async"
        [style.object-fit]="section?.metadata?.objectFit"
        alt="section image"
        src="" />
    </div>
  </ng-container>
  <div *ngIf="!(viewModel?.is2Card$ | async)" [style.color]="viewModel.sectionHeaderTextColor$ | async">
    <div class="card-subtitle">{{ section?.subTitle }}</div>
    <div class="card-title" [ngClass]="section?.isHidingPrices() ? 'hide-price-font-size' : ''">
      {{ section?.title }}
    </div>
  </div>
  <!--Non 2 Card Header-->

  <!--2 Card Header-->
  <ng-container *ngIf="viewModel?.is2Card$ | async">
    <div
      class="card-image-header-container"
      [style.align-items]="'center'"
      [style.color]="viewModel.sectionHeaderTextColor$ | async">
      <div class="embedded-card-title-container" fxLayout="column" fxLayoutAlign="center start">
        <div class="card-subtitle">{{ section?.subTitle }}</div>
        <div class="card-title" [ngClass]="section?.isHidingPrices() ? 'hide-price-font-size' : ''">
          {{ section?.title }}
        </div>
      </div>
      <div
        [style.display]="'flex'"
        [style.height.%]="100"
        [style.justify-content]="getImageSectionAlignment()"
        [style.flex]="1">
        <img
          appImage
          [asset]="section?.image"
          [reset]="reset"
          [style.height.%]="100"
          [style.width]="viewModel.imageSectionWidth$ | async"
          [style.float]="viewModel.imageSectionFloat$ | async"
          [style.object-fit]="section?.metadata?.objectFit"
          alt="section image"
          src="" />
      </div>
    </div>
  </ng-container>
  <!--2 Card Header-->

  <div
    #productsContainer
    class="products-container"
    [style.background-color]="viewModel.productsInfoBackgroundColor$ | async"
    [style.color]="viewModel.sectionBodyTextColor$ | async">
    <ng-container *ngFor="let sectionRowViewModel of viewModel.featureCategorySectionRowViewModels$ | async">
      <app-label-inflator #calculateLabel hidden [columnLabel]="false" [labelComponentInterface]="sectionRowViewModel">
      </app-label-inflator>
      <div
        *ngIf="(viewModel.currentCarouselProductRowViewModel$ | async) === sectionRowViewModel"
        [style.color]="sectionRowViewModel?.style?.fontColor"
        [class.force-bold-style]="viewModel.forcedRowBoldStyling$ | async"
        [class.force-italic-style]="viewModel.forcedRowItalicStyling$ | async"
        class="carousel-container"
        [@featuredCategoryCardSectionAnimations]="viewModel.transitionType$ | async">
        <div #numberTitle fxLayout="row" [style.gap.rem]="1">
          <app-card-section-number
            *ngIf="viewModel.showCardNumber$ | async"
            class="card-image-header-number"
            [index]="index"
            [section]="section"
            [menu]="menu">
          </app-card-section-number>
          <div class="typetura" fxFlex fxLayout="column">
            <div class="product-name">{{ sectionRowViewModel.getRowTitle() }}</div>
            <div class="product-brand">{{ sectionRowViewModel | sectionRowViewModelBasicMethod: 'getBrand' }}</div>
          </div>
        </div>
        <div
          [style.height]="'calc(100% - ' + numberTitle.clientHeight + 'px)'"
          fxLayout="row"
          fxLayoutAlign=" center"
          [style.gap.rem]="1">
          <img
            *ngIf="!(sectionRowViewModel | getVariantAssetForCard | async)"
            [style.flex]="'1 1 100%'"
            [style.max-width.%]="50"
            [style.max-height.%]="100"
            [style.object-fit]="section?.metadata?.objectFit"
            [src]="'assets/placeholder/no-image.svg'"
            alt="" />
          <img
            *ngIf="sectionRowViewModel | getVariantAssetForCard | async as variantAsset"
            appImage
            [asset]="variantAsset"
            [reset]="reset"
            [style.flex]="'1 1 100%'"
            [style.max-width.%]="50"
            [style.max-height.%]="100"
            [style.object-fit]="section?.metadata?.objectFit"
            alt="product asset"
            src="" />
          <div fxFlex="50" fxLayout="column" class="clip-x-overflow" [style.gap.rem]="1">
            <div
              *ngIf="sectionRowViewModel.hasBadges() || !!(calculateLabel.currentLabelText$ | async)"
              fxLayout="row wrap"
              [style.gap.rem]="1.5">
              <app-label-inflator
                *ngIf="(viewModel.showLabel$ | async) && !!(calculateLabel.currentLabelText$ | async)"
                class="product-label-container"
                [columnLabel]="false"
                [labelComponentInterface]="sectionRowViewModel">
              </app-label-inflator>
              <img
                *ngFor="let b of sectionRowViewModel | sectionRowViewModelBasicMethod: 'getAllVariantBadges'"
                class="badge"
                appImage
                [asset]="b.image"
                [reset]="reset"
                alt="badge"
                src="" />
            </div>
            <div class="product-info">
              THC {{ sectionRowViewModel | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'THC' }}
            </div>
            <div class="product-info">
              CBD {{ sectionRowViewModel | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'CBD' }}
            </div>
            <div class="product-info">{{ sectionRowViewModel | sectionRowViewModelBasicMethod: 'getSize' }}</div>
            <div [style.color]="viewModel.sectionBodyTextColor$ | async">
              <app-header-image-carousel-card-price
                *ngIf="section?.metadata?.hidePrices === 'false'"
                [textColor]="sectionRowViewModel?.style?.fontColor"
                [originalPrice]="
                  menu
                    | getOriginalVariantPricing
                      : locationConfig
                      : locationId
                      : section
                      : sectionRowViewModel
                      : null
                      : null
                "
                [salePrice]="
                  (menu | isVariantPriceDiscounted: locationConfig : locationId : section : sectionRowViewModel : null)
                    ? menu.getVariantPrice(
                        locationConfig?.priceFormat,
                        locationId,
                        sectionRowViewModel?.rowVariants?.firstOrNull(),
                        menu.getDropDecimal(),
                        menu.getDropDollarSign()
                      )
                    : ''
                ">
              </app-header-image-carousel-card-price>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
