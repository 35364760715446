@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/building-blocks/card-sections/product-deal-carousel-card-section/product-deal-carousel-card-landscape-section/product-deal-carousel-card-landscape-section.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.product-deal-card-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}
.product-deal-card-container .carousel-container {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-deal-card-container .landscape-container {
  height: unset;
  width: unset;
  position: absolute;
  inset: 1rem;
  overflow-x: hidden;
  flex-direction: row;
  justify-content: space-between;
}
.product-deal-card-container .landscape-card {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-evenly;
  align-items: stretch;
  max-width: 75%;
  flex: 1 1 100%;
  min-width: 0;
}
.product-deal-card-container .product-deal-header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0;
}
.product-deal-card-container .product-deal-header.landscape-header {
  padding: unset;
}
.product-deal-card-container .product-deal-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}
@keyframes card-image-header-number {
  0% {
    font-size: 1.875rem;
  }
  100% {
    font-size: 2rem;
  }
}
.product-deal-card-container .card-image-header-number {
  --tt-key: card-image-header-number;
  --tt-max: 1020;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.product-deal-card-container .product-deal-primary-image {
  width: 4rem;
  height: 4rem;
}
.product-deal-card-container .product-header-info {
  flex: 1;
  overflow-x: hidden;
}
@keyframes discount-display {
  0% {
    font-size: 1.625rem;
  }
  100% {
    font-size: 1.75rem;
  }
}
.product-deal-card-container .discount-display {
  line-height: 1.1;
  --tt-key: discount-display;
  --tt-max: 900;
  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@keyframes product-brand {
  0% {
    font-size: 1.125rem;
  }
  100% {
    font-size: 1.25rem;
  }
}
.product-deal-card-container .product-brand {
  line-height: 1.1;
  --tt-key: product-brand;
  --tt-max: 900;
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
@keyframes brand-without-discount {
  0% {
    font-size: 1.625rem;
  }
  100% {
    font-size: 1.75rem;
  }
}
.product-deal-card-container .brand-without-discount {
  line-height: 1.1;
  --tt-key: brand-without-discount;
  --tt-max: 900;
  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.product-deal-card-container .product-deal-body-container {
  display: block;
}
.product-deal-card-container .product-deal-info-container.landscape {
  flex: 1 1 0;
  box-sizing: border-box;
  flex-direction: row;
  min-width: 0;
}
@keyframes product-name {
  0% {
    font-size: 1.125rem;
  }
  100% {
    font-size: 1.25rem;
  }
}
.product-deal-card-container .product-name {
  --tt-key: product-name;
  --tt-max: 400;
  font-family: poppins, sans-serif;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
  width: 100%;
  text-transform: uppercase;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  line-height: 1.5;
}
.product-deal-card-container .product-name.landscape {
  white-space: nowrap;
  text-overflow: ellipsis;
}
@keyframes product-deal-variant-asset {
  0% {
    width: 8rem;
    height: 8rem;
  }
  100% {
    width: 10rem;
    height: 10rem;
  }
}
.product-deal-card-container .product-deal-variant-asset {
  --tt-key: product-deal-variant-asset;
  --tt-max: 900;
  margin-right: 1.5rem;
  border-radius: 0.33rem;
}
.product-deal-card-container .product-deal-label-and-badge {
  display: flex;
  line-height: 1.5;
  padding-bottom: 0.25rem;
}
.product-deal-card-container .product-badge {
  height: 1.75rem;
  aspect-ratio: 1;
}
.product-deal-card-container .product-info-container {
  display: flex;
  justify-content: space-between;
}
@keyframes product-info {
  0% {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  100% {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.product-deal-card-container .product-info {
  font-family: poppins, sans-serif;
  font-weight: 600;
  line-height: 1;
  --tt-key: product-info;
  --tt-max: 900;
}
.product-deal-card-container .product-deal-footer {
  height: 100%;
  padding: 1rem 1rem 1rem 0;
}
.product-deal-card-container .product-deal-footer.landscape-footer {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-between;
  align-items: stretch;
  flex: 0 0 auto;
  padding: unset;
}
.product-deal-card-container .footer-image {
  height: 4rem;
  width: 4rem;
  border-radius: 0.5rem;
  float: right;
}
@keyframes product-deal-price {
  0% {
    font-size: 2.5rem;
  }
  100% {
    font-size: 3.125rem;
  }
}
.product-deal-card-container .product-deal-price {
  --tt-key: product-deal-price;
  font-family: poppins, sans-serif;
  font-weight: 600;
  line-height: 1;
  text-align: right;
}
@keyframes product-regular-price {
  0% {
    font-size: 1.375rem;
  }
  100% {
    font-size: 2rem;
  }
}
.product-deal-card-container .product-regular-price {
  --tt-key: product-regular-price;
  font-family: poppins, sans-serif;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
  line-height: 1;
  text-align: right;
  opacity: 0.6;
}
.product-deal-card-container .product-label-container {
  width: auto;
  margin-bottom: 0.5rem;
  font-family: poppins, sans-serif;
  font-weight: 500;
  --tt-key: product-label-container;
  --tt-max: 1020;
}
@keyframes product-label {
  0% {
    line-height: 1.5rem;
    padding: 0 0.3125rem;
  }
  100% {
    padding: 0 0.5rem;
    line-height: 2rem;
  }
}
.product-deal-card-container .product-label-container .product-label {
  margin-left: 0;
  font-size: 0.875rem;
  --tt-key: product-label;
  --tt-max: 1020;
}
.product-deal-card-container .flex-container .landscape {
  flex-direction: row;
  box-sizing: border-box;
  flex: 1 1 0;
}
.product-deal-card-container .flex-container {
  flex-direction: row;
  box-sizing: border-box;
  place-content: stretch space-evenly;
  align-items: stretch;
  flex: 1 1 0;
}
.product-deal-card-container .force-italic-style * {
  font-style: italic !important;
}
.product-deal-card-container .force-bold-style * {
  font-family: poppins, sans-serif;
  font-weight: 700;
}
/*# sourceMappingURL=product-deal-carousel-card-landscape-section.component.css.map */
