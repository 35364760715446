import { Injectable } from '@angular/core';
import { ProductSectionViewModel } from '../../../product-menu/building-blocks/menu-section/product-section/product-section-view-model';
import { map, switchMap } from 'rxjs/operators';
import { ColorUtils } from '../../../../../../../utils/color-utils';
import { combineLatest } from 'rxjs';

@Injectable()
export class StaticFeaturedCategoryCardViewModel extends ProductSectionViewModel {

  public cardColor$ = combineLatest([
    this.section$,
    this.menu$,
  ]).pipe(
    map(([section, menu]) => {
      // Card color should always default to white background, but we need to consider the opacity even if the
      // background color is not set. See below for the class that is always overwritten
      // .marketing-featured-category-theme .product-menu-container .section-container
      let [R, G, B] = [255, 255, 255];
      if (!!section?.metadata?.productsContainerBackgroundColor) {
        [R, G, B] = ColorUtils.hexToRgb(section?.metadata?.productsContainerBackgroundColor);
      } else if (!!menu?.menuOptions?.bodyBackgroundColor) {
        [R, G, B] = ColorUtils.hexToRgb(menu?.menuOptions?.bodyBackgroundColor);
      }
      const opacity = Number(section?.metadata?.cardOpacity || '1.0');
      return `rgba(${R}, ${G}, ${B}, ${opacity})`;
    })
  );

  public showCardNumber$ = this.menu$.pipe(
    map(menu => menu?.metadata?.hideCardNumber === 'false')
  );

  public sectionBodyTextColor$ = this.menu$.pipe(
    switchMap(menu => this.section$.pipe(map(section => menu?.getSectionBodyTextColor(section))))
  );

  public sectionHeaderTextColor$ = this.menu$.pipe(
    switchMap(menu => this.section$.pipe(map(section => menu?.getSectionHeaderTextColor(section))))
  );

}
