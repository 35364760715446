import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, Observable, Subscription, timer } from 'rxjs';
import { Asset } from '../../../../../models/image/dto/asset';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { DisplayDomainModel } from '../../../../../domain/display-domain-model';
import { CacheService } from '../../../../services/cache-service';
import { DisplayOptions } from '../../../../../models/shared/display-options';
import { SortUtils } from '../../../../../utils/sort-utils';
import { VariantFeature } from '../../../../../models/product/dto/variant-feature';
import { Variant } from '../../../../../models/product/dto/variant';
import { OptionScale } from '../../../../../models/enum/dto/option-scale.enum';
import { FeaturedProductMenuData } from '../../../../../models/menu/marketing/FeaturedProduct/featured-product-menu-data';
import { VariantAndAsset } from '../../../../../models/menu/marketing/FeaturedProduct/variant-and-asset';
import { OrderFeaturedVariants } from '../../../../../models/menu/marketing/FeaturedProduct/order-featured-variants';
import { RotationState } from '../../../../../models/enum/shared/rotation-state.enum';
import { DistinctUtils } from '../../../../../utils/distinct.utils';
import { DisplayMenuCoupling } from '../../../../../couplings/display-menu-coupling.service';
import { HoldOffMenuRotation } from '../../../../../models/shared/hold-off-menu-rotation';
import { IsMenuReadyService } from '../../../../services/is-menu-ready.service';
import { MarketingLoopingContentMenu } from '../../../../../models/menu/marketing/marketing-looping-content-menu';
import { ProductType } from '../../../../../models/enum/dto/product-type.enum';
import { BaseMenuViewModel } from '../menu/base-menu-view-model';
import { OrientationService } from '../../../../../services/orientation.service';
import { NumberUtils } from '../../../../../utils/number.utils';
import { CannabisUnitOfMeasure } from '../../../../../models/enum/dto/cannabis-unit-of-measure.enum';
import { DeprecatedMarketingMenu } from '../../../../../models/menu/deprecated-marketing-menu';

/**
 * @deprecated
 */
@Injectable()
export class DeprecatedMarketingMenuViewModel extends BaseMenuViewModel {

  constructor(
    protected isMenuReadyService: IsMenuReadyService,
    protected cache: CacheService,
    protected dm: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling,
    protected orientationService: OrientationService
  ) {
    super(orientationService);
  }

  public override _menu = new BehaviorSubject<DeprecatedMarketingMenu>(null);
  public distinctNotNullMenu$: Observable<DeprecatedMarketingMenu> = defer(() => this._menu).notNull().pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)
  );
  private asset = new BehaviorSubject<Asset>(null);
  public asset$ = this.asset.pipe(
    distinctUntilChanged(DistinctUtils.distinctAsset),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  private featuredVariant = new BehaviorSubject<Variant>(null);
  public distinctFeaturedVariant$ = this.featuredVariant.notNull().pipe(
    distinctUntilChanged(DistinctUtils.distinctVariant),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public distinctFeaturedVariantCUOM$ = this.distinctFeaturedVariant$.pipe(
    map(v => {
      if (v.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
        return '';
      } else {
        return v.cannabisUnitOfMeasure;
      }
    })
  );
  private _featuredDisplayData = new BehaviorSubject<FeaturedProductMenuData>(null);
  public featuredDisplayData$ = this._featuredDisplayData as Observable<FeaturedProductMenuData>;
  public showSize$ = this.featuredDisplayData$.pipe(map(data => !!data?.sizeText && data?.sizeText !== '-'));
  public showClassification$ = this.featuredDisplayData$.pipe(
    map(data => !!data?.classification && data?.classification !== '-')
  );
  public showTHC$ = this.featuredDisplayData$.pipe(map(data => data?.productType !== ProductType.Accessories));
  public showCBD$ = this.featuredDisplayData$.pipe(map(data => data?.productType !== ProductType.Accessories));
  public showProducer$ = this.featuredDisplayData$.pipe(map(data => !!data?.producer));
  private _labelText = new BehaviorSubject<string>(null);
  public labelText$ = this._labelText as Observable<string>;
  public showLabel$ = this.labelText$.pipe(map(labelText => !!labelText));
  public showFeaturedProductInfo$ = combineLatest([this.showSize$, this.showClassification$]).pipe(
    map(([showSize, showClassification]) => showSize || showClassification),
  );
  public showThcCbdProducerLabelRow$ = combineLatest([
    this.showTHC$,
    this.showCBD$,
    this.showProducer$,
    this.showLabel$
  ]).pipe(
    map(([showTHC, showCBD, showProducer, showLabel]) => showTHC || showCBD || showProducer || showLabel)
  );
  public showDesc$ = this.featuredDisplayData$.pipe(map(data => !!data?.description));
  private featuredVariantAndAsset = new BehaviorSubject<VariantAndAsset>(null);
  public distinctFeaturedVariantAndAsset$ = this.featuredVariantAndAsset.pipe(
    distinctUntilChanged(DistinctUtils.distinctFeaturedVariantAndAsset)
  );

  private rotationInstructions$ = this.distinctNotNullMenu$.pipe(map(it => it?.options));
  public scaleFit$ = this.rotationInstructions$.pipe(map(it => it?.scale === OptionScale.Fit));

  private _loopVideo = new BehaviorSubject<boolean>(false);
  public loopVideo$ = this._loopVideo.pipe(distinctUntilChanged());
  connectToLoopVideo = (loopVideo: boolean) => this._loopVideo.next(loopVideo);

  public playAudio$ = this._menu.pipe(map(menu => menu?.menuOptions?.playAudio));

  private _position = new BehaviorSubject<number>(0);
  public position$ = this._position as Observable<number>;
  connectToPosition = (position: number) => this._position.next(position);

  private _currentVideoTime = new BehaviorSubject<number>(0);
  public currentVideoTime$ = this._currentVideoTime as Observable<number>;
  connectToCurrentVideoTime = (currentVideoTime: number) => this._currentVideoTime.next(currentVideoTime);

  public videoDuration = new BehaviorSubject<number>(0);
  public showFeatureTitle$ = combineLatest([
    this.isMenuReadyService.showSplashScreen$.pipe(map(it => !it)),
    this.featuredDisplayData$.notNull()
  ]).pipe(map(([loaded, data]) => {
    return loaded && !!data?.featuredProductTitle;
  }));
  // Featured Variants
  private featuredVariants = new BehaviorSubject<VariantFeature>(null);
  public featuredVariants$ = this.featuredVariants.pipe(
    map(featured => {
      if (featured) {
        featured.variantIds = featured?.variantIds?.filter(id => {
          const variant = featured?.variants?.find(v => v.id === id);
          return NumberUtils.floatFirstGreaterThanSecond(variant?.inventory.quantityInStock, 0);
        });
      }
      return featured;
    })
  );
  public hasVariants$ = this.featuredVariants$.pipe(
    map(featured => featured?.variantIds?.length > 0),
    startWith(true)
  );
  public noVariants$ = this.featuredVariants$.pipe(map(featured => featured?.variantIds?.length === 0));
  private featureProductDataMechanism$ = combineLatest([
    this.locationId$.notNull(),
    this.distinctNotNullMenu$,
    this.distinctFeaturedVariant$.notNull(),
    this.dm.companyConfig$.notNull(),
    this.dm.locationConfig$.notNull(),
  ]);
  // Assets
  private assets$ = combineLatest([
    this._menu,
    this.dm.marketingMenuAssets$
  ]).pipe(
    map(([menu, assets]) => assets?.get(menu?.getOriginalMenuId()))
  );
  // Ordering assets
  private _orderedAssets = new BehaviorSubject<VariantAndAsset[]>([]);
  public orderedAssets$ = this._orderedAssets.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );
  connectToOrderedAssets = (assets: VariantAndAsset[]) => this._orderedAssets.next(assets);
  private orderMediaCircuit$ = combineLatest([
    this.distinctNotNullMenu$,
    this.featuredVariants$,
    this.rotationInstructions$.notNull(),
    this.assets$,
  ]).pipe(debounceTime(100));
  // Loop Video
  private loopVideoMechanism$ = combineLatest([
    this.displayMenuCoupling.menuToRotateCount.notNull(),
    this.orderedAssets$,
    this.rotationInstructions$.notNull(),
    this.distinctFeaturedVariantAndAsset$.notNull(),
    this.videoDuration.notNull(),
  ]).pipe(debounceTime(1));
  // Is current menu being displayed this menu?
  private isCurrentMenuThisOne$ = combineLatest([
    this.distinctNotNullMenu$,
    this.dm.menuToDisplay.notNull().pipe(
      map(it => it?.menu),
      distinctUntilChanged(DistinctUtils.distinctMenu),
    )
  ]).pipe(
    map(([a, b]) => {
      return a.id === b.id;
    })
  );
  // Rotation
  private rotationState = new BehaviorSubject<RotationState>(RotationState.START);
  private rotationMechanism$ = combineLatest([
    this.orderedAssets$,
    this.rotationState.pipe(distinctUntilChanged()),
    this.isCurrentMenuThisOne$.notNull().pipe(distinctUntilChanged()),
  ]).pipe(debounceTime(1));
  // comes from display rotation. Don't rotate content if display is about to rotate
  private holdOfLoopingMenuRotation$ = this.displayMenuCoupling.holdLoopingMenuRotation.pipe(distinctUntilChanged());
  // Timing
  private intervalSub: Subscription;
  public _videoEnded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public videoEnded$ = this._videoEnded as Observable<boolean>;
  connectToVideoEnded = (videoEnded: boolean) => this._videoEnded.next(videoEnded);

  private timerMechanism$ = combineLatest([
    this.distinctFeaturedVariantAndAsset$.notNull(),
    this.rotationInstructions$.notNull(),
    this.isCurrentMenuThisOne$,
    this.distinctNotNullMenu$,
  ]).pipe(debounceTime(1));
  // Position
  public evenPosition$ = this.position$.pipe(map(position => (position % 2 === 0)));
  public oddPosition$ = this.position$.pipe(map(position => (position % 2 !== 0)));

  bind() {
    this.bindToMenu();
    this.bindToFeaturedProductMechanism();
    this.subToOrderMedia();
    this.rotationSub();
    this.bindToReset();
    this.loopVideoSub();
    this.timerSub();
  }

  private bindToMenu() {
    this.distinctNotNullMenu$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(menu => this.featuredVariants.next(menu?.hydratedVariantFeature));
  }

  private bindToFeaturedProductMechanism() {
    const s = this.featureProductDataMechanism$
      .subscribe(([locationId, menu, variant, companyConfig, locationConfig]) => {
        const data = new FeaturedProductMenuData(locationId, menu, variant, companyConfig, locationConfig);
        this._featuredDisplayData.next(data);
      });
    this.pushSub(s);
  }

  private subToOrderMedia() {
    const moSub = this.orderMediaCircuit$.subscribe(
      ([menu, featured, order, media]) => {
        const positions: OrderFeaturedVariants[] = menu?.getOrderFeaturedVariants(featured, order, media);
        const ordered = positions.sort(SortUtils.menuAssetByAscendingPos).filterNulls();
        const orderedMedia = ordered.map(it => it?.variantAndAsset);
        this.connectToOrderedAssets(orderedMedia);
      }
    );
    this.pushSub(moSub);
  }

  private rotationSub() {
    const rotationMecSub = this.rotationMechanism$.subscribe(
      ([media, state, isCurrentMenuActive]) => {
        const hasMedia = !!media;
        // we only want to start asset rotation if current menu is active
        if (hasMedia && isCurrentMenuActive) {
          switch (state) {
            case RotationState.START: {
              this.setFeaturedVariant(media, false);
              break;
            }
            case RotationState.ROTATE: {
              this.setFeaturedVariant(media, true);
              this.rotationState.next(RotationState.HOLD);
              break;
            }
            case RotationState.RESET: {
              this.setFeaturedVariant(media, false, true);
              break;
            }
            case RotationState.HOLD: {
              break;
            }
          }
        } else if (hasMedia && !isCurrentMenuActive) {
          this.setFeaturedVariant(media, false, true);
        }
      }
    );
    this.pushSub(rotationMecSub);
  }

  private bindToReset() {
    this.reset$.pipe(filter(reset => reset)).subscribeWhileAlive({
      owner: this,
      next: () => this.rotationState.next(RotationState.RESET)
    });
  }

  private setFeaturedVariant(media: VariantAndAsset[], rotate: boolean = false, reset: boolean = false) {
    const hasMedia = media?.length > 0;
    switch (true) {
      case reset && hasMedia: {
        this.connectToPosition(0);
        this.setDataForFeaturedVariant(media?.firstOrNull());
        this.videoDuration.next(0);
        this.connectToLoopVideo(false);
        break;
      }
      case rotate && hasMedia: {
        this.position$.once(position => this.connectToPosition((position + 1) % media?.length));
        break;
      }
    }
    switch (true) {
      case media?.length > 1: {
        this.position$.once(position => this.setAssetAtPosition(position, media));
        break;
      }
      case media?.length === 1: {
        this.setDataForFeaturedVariant(media?.firstOrNull());
        break;
      }
    }
  }

  private setAssetAtPosition(position: number, media: VariantAndAsset[]) {
    let currentIndex = position;
    let currentVariantAsset = media?.[position];
    if (!currentVariantAsset) {
      currentIndex = 0;
      this.connectToPosition(currentIndex);
      currentVariantAsset = media?.[currentIndex];
    }
    if (currentVariantAsset) {
      this.setDataForFeaturedVariant(currentVariantAsset);
    }
  }

  private setDataForFeaturedVariant(variantAndAsset: VariantAndAsset) {
    this.asset.next(variantAndAsset?.asset);
    this.featuredVariant.next(variantAndAsset?.variant);
    this.featuredVariantAndAsset.next(variantAndAsset);
  }

  private loopVideoSub() {
    const s = this.loopVideoMechanism$.subscribe(([, , options, asset, duration]) => {
      const videoDisplayTime = ((options?.rotationInterval?.get(asset.getMediaIdentifier())) || 0);
      const loopContent = !((videoDisplayTime === -1) || (Math.trunc(videoDisplayTime) <= Math.trunc(duration)));
      if (loopContent) this.connectToLoopVideo(true);
    });
    this.pushSub(s);
  }

  private timerSub() {
    const s = this.timerMechanism$.subscribe(([asset, options, isBeingDisplayed, menu]) => {
      const loopingContentMenu = (menu instanceof MarketingLoopingContentMenu);
      if (!!menu && asset && options && isBeingDisplayed && loopingContentMenu) {
        this.setIntervalTimer(menu.id, asset, options);
      } else if (!isBeingDisplayed) {
        this.intervalSub?.unsubscribe();
      }
    });
    this.pushSub(s);
  }

  /**
   * Sets the time interval for current product variant.
   * If it's a video:
   *  Loop asset for designated time. If asset hasn't finished current play through,
   *  wait, then toggle rotation.
   */
  private setIntervalTimer(menuId: string, variantAsset: VariantAndAsset, options: DisplayOptions) {
    const displayTime = ((options?.rotationInterval?.get(variantAsset.getMediaIdentifier())) || 0) * 1000;
    if (variantAsset && displayTime > 0) {
      if (variantAsset.asset?.isImage()) {
        this.setIntervalForImage(menuId, displayTime);
      } else if (variantAsset.asset?.isVideo()) {
        this.setIntervalForVideo(menuId, displayTime);
      }
    } else {
      this.rotationState.next(RotationState.HOLD);
    }
  }

  private killInterval() {
    this.intervalSub?.unsubscribe();
  }

  private setIntervalForImage(menuId: string, displayTime: number) {
    this.killInterval();
    this.displayMenuCoupling.holdDisplayRotation.next(new HoldOffMenuRotation(menuId));
    this.intervalSub = combineLatest([
      timer(0, displayTime),
      // comes from display rotation. Don't rotate content if display is about to rotate
      this.holdOfLoopingMenuRotation$
    ]).subscribe(([timeToRotate, holdOfRotation]) => {
      if (timeToRotate > 0) {
        this.displayMenuCoupling.holdDisplayRotation.next(null);
        if (!holdOfRotation) {
          this.rotationState.next(RotationState.ROTATE);
        }
        this.killInterval();
      }
    });
  }

  private setIntervalForVideo(menuId: string, displayTime: number) {
    this.killInterval();
    this.displayMenuCoupling.holdDisplayRotation.next(new HoldOffMenuRotation(menuId));
    const menuRotationInterval$ = defer(() => this._menu).pipe(
      map(m => m?.rotationInterval),
      distinctUntilChanged()
    );
    const nMenusRotating$ = this.displayMenuCoupling.menuToRotateCount.notNull().pipe(distinctUntilChanged());
    const numberOfAssets$ = this.orderedAssets$.pipe(map(assets => assets?.length), distinctUntilChanged());
    const assetRanForAllottedDuration$ = timer(0, displayTime);
    this.intervalSub = combineLatest([
      menuRotationInterval$,
      nMenusRotating$,
      numberOfAssets$,
      assetRanForAllottedDuration$,
      this.holdOfLoopingMenuRotation$, // Don't rotate content if display is about to rotate
      this.videoEnded$
    ]).subscribe(([rotationInterval, nMenusRotating, nAssets, timerFired, holdOfRotation, videoEnded]) => {
      const singleMenuRotating = nMenusRotating <= 1;
      const singleAssetRotating = nAssets <= 1;
      const singleAssetAndMenuIntervalLongerThanVideo = singleAssetRotating
        && (Math.trunc(timerFired * displayTime) < Math.trunc(rotationInterval * 1000));

      switch (true) {
        case singleMenuRotating && singleAssetRotating: {
          this.connectToLoopVideo(true);
          break;
        }
        case (timerFired > 0) && videoEnded: {
          this.displayMenuCoupling.holdDisplayRotation.next(null);
          if (!holdOfRotation) this.rotationState.next(RotationState.ROTATE);
          this.killInterval();
          break;
        }
        case timerFired > 0: {
          this.connectToLoopVideo(singleAssetAndMenuIntervalLongerThanVideo);
          break;
        }
      }
    });
  }

  connectToLabelText = (labelText: string) => this._labelText.next(labelText);

}
