import { Component, ComponentRef, EnvironmentInjector, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ProductMenu } from '../../../../../../../models/menu/product-menu';
import { Section } from '../../../../../../../models/menu/section/section';
import { ProductMenuSectionHeaderInflatorContainerDirective } from './product-menu-section-header-inflator-container.directive';
import { MenuSectionHeaderComponent } from '../../building-blocks/menu-section-header/menu-section-header.component';
import { SectionRowViewModel } from '../../building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { PlantlifeSectionHeaderComponent } from '../../building-blocks/menu-section-header/plantlife-section-header/plantlife-section-header.component';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';

@Component({
  selector: 'app-product-menu-section-header-inflator',
  templateUrl: './product-menu-section-header-inflator.component.html',
})
export class ProductMenuSectionHeaderInflatorComponent implements OnInit, OnChanges {

  constructor(
    protected injector: Injector,
    protected environmentInjector: EnvironmentInjector
  ) {
  }

  @Input() calculationMode: boolean = false;
  @Input() locationConfig: LocationConfiguration;
  @Input() menu: ProductMenu;
  @Input() section: Section;
  @Input() sectionRowViewModels: SectionRowViewModel[] = [];
  @Input() index: number;

  @Input() reset: boolean;

  @ViewChild(ProductMenuSectionHeaderInflatorContainerDirective, { static: true })
  contentContainer: ProductMenuSectionHeaderInflatorContainerDirective;

  private compRef: ComponentRef<MenuSectionHeaderComponent>;

  ngOnInit(): void {
    this.compRef = this
      .contentContainer
      .viewContainerRef
      .createComponent(
        this.menu.getSectionsHeaderType(),
        { injector: this.injector, environmentInjector: this.environmentInjector }
      );
    this.compRef.setInput('calculationMode', this.calculationMode);
    this.compRef.setInput('locationConfig', this.locationConfig);
    this.compRef.setInput('menu', this.menu);
    this.compRef.setInput('section', this.section);
    this.compRef.setInput('sectionRowViewModels', this.sectionRowViewModels);
    this.compRef.setInput('index', this.index);
    this.compRef.setInput('reset', this.reset);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.calculationMode) this.compRef?.setInput('calculationMode', this.calculationMode);
    if (changes.locationConfig) this.compRef?.setInput('locationConfig', this.locationConfig);
    if (changes.menu) this.compRef?.setInput('menu', this.menu);
    if (changes.section) this.compRef?.setInput('section', this.section);
    if (changes.sectionRowViewModels) this.compRef?.setInput('sectionRowViewModels', this.sectionRowViewModels);
    if (changes.index) this.compRef?.setInput('index', this.index);
    if (changes.reset) this.compRef?.setInput('reset', this.reset);
    this.compRef?.instance?.ngOnChanges(changes);
  }

  getHeaderHeight(): number {
    return this.compRef.instance?.getHeaderHeight();
  }

  getExpandedHeaderHeight(): number {
    if (this.compRef.instance instanceof PlantlifeSectionHeaderComponent) {
      return this.compRef.instance?.getExpandedHeaderHeight() ?? 0;
    }
    return this.getHeaderHeight();
  }

  getCollapsedHeaderHeight(): number {
    if (this.compRef.instance instanceof PlantlifeSectionHeaderComponent) {
      return this.compRef.instance?.getCollapsedHeaderHeight() ?? 0;
    }
    return this.getHeaderHeight();
  }

}
