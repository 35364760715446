<!--
Headers and footers in print menus live within the sections-container's guide rails. This change happened when
we built the future to show headers and footers on every page.
- if the sections-container has margins on it, so will all calculated headers, sections, and footers
- the overflow calculator will get the wrong heights for headers, sections, and footers without this,
  because they will be calculated without the margins applied by devs on the sections-container class
------------------------------------------------------------------------------------------------------------------------
The following allows the overflow calculator to calculate without affecting the layout system of the actual
headers, sections, and footers that the user sees. If this wasn't here, then all the visible content would
be pushed down, and a bunch of white space would fill the first couple of pages in the pdf.
- [style.position]="calculating ? 'absolute' : null"
- [style.top]="calculating ? '0' : null"
- [style.left]="calculating ? '0' : null"
- [style.right]="calculating ? '0' : null"
-->
<div
  #sectionsContainer
  class="sections-container"
  [id]="!calculating ? 'sections-container-' + menu?.id : null"
  [style.position]="calculating ? 'absolute' : null"
  [style.top]="calculating ? '0' : null"
  [style.left]="calculating ? '0' : null"
  [style.right]="calculating ? '0' : null"
  [style.display]="(menu | printMenuBasicMethod : 'wrappingOverflowSwimlanesEnabled') ? 'block' : ''"
  [style.flex-wrap]="(menu | printMenuBasicMethod : 'getShouldSectionsContainerFlexWrap') ? 'wrap' : null"
  [style.background-color]="
    (menu | printMenuBasicMethod : 'getOpacityLocation') === Types.OpacityLocation.SECTION_CONTAINER
      ? (menu | printMenuBasicMethod : 'getBackgroundColorWithOpacity')
      : (menu | printMenuBasicMethod : 'getSectionsBackgroundColor')
  "
  [ngClass]="[
    menu | printMenuBasicMethod : 'getSectionsWrapperClass',
    menu | printMenuBasicMethod : 'getMenuScrollClass'
  ]">
  <ng-content></ng-content>
</div>
