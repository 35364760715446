@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/marketing-featured-product-menu/marketing-featured-product-menu.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.empty-state {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 5rem;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 20;
}
.empty-state-logo {
  max-height: 50%;
  width: 35%;
}
.empty-state-text {
  font-size: 2.5rem;
  font-family: "Montserrat";
  padding-right: 5rem;
}
.marketing-main-wrapper {
  width: 100%;
}
.marketing-featured-product-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.strip-white-background {
  background-color: unset;
}
.padding-container {
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  align-items: center;
  animation: fadeIn 0.5s ease-in-out forwards;
  -webkit-animation: fadeIn 0.5s ease-in-out forwards;
  -moz-animation: fadeIn 0.5s ease-in-out forwards;
  -o-animation: fadeIn 0.5s ease-in-out forwards;
}
.padding-container.reverse-flex {
  flex-flow: row-reverse;
}
.featured-product-info-container {
  width: 50%;
  text-align: center;
  align-content: flex-start;
  max-height: 95vh;
}
.featured-product-info-container .company-logo {
  object-fit: contain;
  height: 9rem;
  max-height: 9rem;
  max-width: 50%;
  padding-bottom: 1.25rem;
}
.featured-product-info-container.right-align .company-logo {
  float: right;
}
.featured-product-info-container.right-align .producer-label {
  text-align: right;
}
.featured-product-info-container.right-align .producer-value {
  text-align: right;
}
.featured-product-info-container.left-align .company-logo {
  float: left;
}
.featured-product-info-container.left-align .producer-label {
  text-align: left;
}
.featured-product-info-container.left-align .producer-value {
  text-align: left;
}
.featured-product-image-container {
  width: 50%;
  text-align: left;
  align-content: flex-start;
}
.featured-product-container {
  text-align: center;
}
.static-menu-title {
  position: absolute;
}
.menu-title {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  height: 6%;
  width: 100%;
  text-align: center;
  background-color: #222222;
}
.featured-product-title {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fefefe;
  font-family: "Montserrat";
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
}
.featured-product-price-container {
  padding: 1.25rem 0;
  text-align: center;
}
.single-price {
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 3px;
  color: #222222;
  font-family: "Montserrat";
}
.featured-product-media-wrapper {
  display: flex;
  margin: auto;
  max-width: 100%;
  vertical-align: top;
}
.featured-product-media {
  padding: 0.5rem;
  width: 100%;
}
.featured-product-scale-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}
.original-price {
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 3px;
  color: #222222;
  font-family: "Montserrat";
  text-decoration: line-through;
  margin: 0 15px 0 0;
}
.sale-price {
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 3px;
  color: #548c38;
  font-family: "Montserrat";
  margin: 0 0 0 15px;
}
.featured-product-name {
  text-align: center;
  width: 100%;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 3px;
  color: #222222;
  font-family: "Montserrat";
  padding: 1.25rem 0;
  height: fit-content;
  white-space: nowrap;
  overflow-x: hidden;
}
.right-align .featured-product-name {
  text-align: right;
}
.left-align .featured-product-name {
  text-align: left;
}
.featured-product-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  gap: 1.5rem;
}
.right-align .featured-product-info,
.right-align .featured-product-potency {
  justify-content: flex-end;
}
.left-align .featured-product-info,
.left-align .featured-product-potency {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.featured-product-info-child {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.featured-product-size,
.featured-product-strain-type {
  display: inline-block;
  background-color: #595758;
  color: #fefefe;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 1rem;
  border-radius: 0.625rem;
  min-width: 10rem;
  font-family: "Montserrat";
}
.info-value {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
  display: block;
  vertical-align: middle;
}
.featured-product-strain-type.hybrid {
  background-color: #9b3014;
}
.featured-product-strain-type.indica {
  background-color: #022d4b;
}
.featured-product-strain-type.sativa {
  background-color: #bf6721;
}
.featured-product-strain-type.blend {
  background-color: #1a1a1a;
}
.featured-product-strain-type.cbd {
  background-color: #37562d;
}
.info-label {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}
.featured-product-potency {
  width: 100%;
  margin: 2.5rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}
.featured-product-producer {
  display: inline-block;
  width: fit-content;
  max-width: 50%;
}
.featured-product-thc-cbd {
  display: inline-block;
}
.left-align .featured-product-potency .featured-product-thc-cbd {
  text-align: left;
}
.right-align .featured-product-potency .featured-product-thc-cbd {
  text-align: right;
}
.producer-label,
.thc-cbd-label,
.label-label {
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Montserrat";
  line-height: 3rem;
}
.featured-product-label {
  display: inline-block;
}
.left-align .featured-product-potency .featured-product-label {
  text-align: left;
}
.right-align .featured-product-potency .featured-product-label {
  text-align: right;
}
.thc-cbd-label {
  line-height: 3rem;
}
.producer-value,
.thc-cbd-value {
  display: block;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3rem;
  text-transform: uppercase;
  font-family: "Montserrat";
  color: #222222;
}
.thc-cbd-value {
  white-space: nowrap;
  overflow-x: hidden;
}
.low-thc {
  color: #d3d3d3;
}
.low-cbd {
  color: #d3d3d3;
}
.producer-value {
  font-size: 2rem;
}
.featured-product-description {
  display: block;
  width: 100%;
  color: #222222;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.5rem;
  margin: 40px 0;
  font-family: "Montserrat";
}
.right-align .featured-product-description {
  text-align: right;
}
.left-align .featured-product-description {
  text-align: left;
}
.product-label {
  font-family: poppins, sans-serif;
  font-weight: 400;
  height: 1.8rem;
  font-size: 0.8rem;
}
.screen-is-portrait .empty-state {
  flex-direction: column;
  padding: 0;
}
.screen-is-portrait .empty-state-text {
  font-size: 2rem;
  padding: 0 20%;
  text-align: center;
}
.screen-is-portrait .padding-container {
  padding-left: 11.65rem;
  padding-right: 11.65rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 4.5rem;
}
.screen-is-portrait .featured-product-media-wrapper {
  display: block;
  max-height: 50%;
}
.screen-is-portrait .left-align .featured-product-potency {
  text-align: left;
  justify-content: flex-start;
}
.screen-is-portrait .left-align .featured-product-name {
  text-align: start;
}
.screen-is-portrait .left-align .featured-product-info-container {
  text-align: start;
  align-content: flex-start;
}
.screen-is-portrait .left-align .featured-product-label {
  text-align: start;
}
.screen-is-portrait .left-align .thc-cbd-label {
  text-align: start;
}
.screen-is-portrait .left-align .featured-product-info {
  justify-content: flex-start;
}
.screen-is-portrait .left-align .featured-product-producer,
.screen-is-portrait .left-align .featured-product-info-child {
  text-align: start;
}
.screen-is-portrait .right-align .featured-product-potency {
  text-align: right;
  justify-content: end;
}
.screen-is-portrait .right-align .featured-product-name {
  text-align: right;
}
.screen-is-portrait .right-align .featured-product-info-container {
  text-align: right;
  align-content: end;
}
.screen-is-portrait .right-align .featured-product-label {
  text-align: right;
}
.screen-is-portrait .right-align .thc-cbd-label {
  text-align: right;
}
.screen-is-portrait .right-align .featured-product-info {
  justify-content: end;
}
.screen-is-portrait .right-align .featured-product-producer,
.screen-is-portrait .right-align .featured-product-info-child {
  text-align: right;
}
.screen-is-portrait .featured-product-info-container {
  width: 100%;
}
.screen-is-portrait .featured-product-size,
.screen-is-portrait .featured-product-strain-type {
  text-align: center;
}
.screen-is-portrait .featured-product-image-container {
  width: 100%;
}
.screen-is-portrait .featured-product-image-container {
  width: 100%;
  text-align: center;
  margin: auto;
}
.screen-is-portrait .featured-product-producer,
.screen-is-portrait .featured-product-info-child {
  display: inline-block;
  vertical-align: middle;
}
.screen-is-portrait .info-label,
.screen-is-portrait .info-value {
  text-align: center;
}
.screen-is-portrait .featured-product-producer {
  min-height: 68px;
}
/*# sourceMappingURL=marketing-featured-product-menu.component.css.map */
