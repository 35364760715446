import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, defer } from 'rxjs';
import type { Section } from '../../../../../../../../models/menu/section/section';
import type { Product } from '../../../../../../../../models/product/dto/product';
import type { SectionRowViewModel } from './section-row-view-models/SectionRowViewModel';
import { DisplayDomainModel } from '../../../../../../../../domain/display-domain-model';
import type { ProductMenu } from '../../../../../../../../models/menu/product-menu';
import { DistinctUtils } from '../../../../../../../../utils/distinct.utils';
import type { VariantInventory } from '../../../../../../../../models/product/dto/variant-inventory';
import type { SectionWithProducts } from '../../../../../../../../models/menu/section/section-with-products';
import { DisplayMenuCoupling } from '../../../../../../../../couplings/display-menu-coupling.service';
import { SectionRowViewModelUtils } from '../../../../../../../../utils/section-row-view-model-utils';
import { SectionUtils } from '../../../../../../../../utils/section-utils';

@Injectable()
export class ProductSectionViewModel extends BaseViewModel {

  constructor(
    protected domainModel: DisplayDomainModel,
    protected displayMenuCoupling: DisplayMenuCoupling
  ) {
    super();
  }

  private _backOfMenuFlipper = new BehaviorSubject<boolean>(undefined);
  public backOfMenuFlipper$ = this._backOfMenuFlipper.pipe(distinctUntilChanged());
  connectToBackOfMenuFlipper = (backOfMenuFlipper: boolean) => this._backOfMenuFlipper.next(backOfMenuFlipper);

  private _menu = new BehaviorSubject<ProductMenu>(null);
  public menu$ = defer(() => this._menu);

  private _section = new BehaviorSubject<SectionWithProducts>(null);
  public section$ = defer(() => this._section);

  private _calculationMode = new BehaviorSubject<boolean>(null);
  public calculationMode$ = this._calculationMode.pipe(distinctUntilChanged());

  enabledSortedProducts$ = this.section$.pipe(
    map(s => {
      // get enabled products
      const enabledProducts: Product[] = [];
      for (const product of s?.products || []) {
        const enabledVariants = product.variants.filter(v => s.enabledVariantIds.find(x => x === v.id));
        if (enabledVariants.length > 0) {
          enabledProducts.push(product);
        }
      }
      return enabledProducts;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  outOfStockVariantIds$ = this.enabledSortedProducts$.pipe(
    map(enabledProducts => {
      // Get out of stock variants
      const sectionInventory = enabledProducts
        .map(p => p.variants)
        .map(a => a.map(v => v.inventory))
        .flatten<VariantInventory[]>();
      const outOfStock: string[] = [];
      for (const i of sectionInventory) {
        if (!i.inStock()) {
          outOfStock.push(i.variantId);
        }
      }
      return outOfStock;
    })
  );

  companyConfig$ = this.domainModel.companyConfig$;
  locationConfig$ = this.domainModel.locationConfig$;

  sectionRowViewModels$ = combineLatest([
    this.section$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
    this.menu$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
    this.companyConfig$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
    this.locationConfig$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
    this.enabledSortedProducts$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctProducts)),
    this.outOfStockVariantIds$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctStrings))
  ]).pipe(
    debounceTime(1),
    map(([section, menu, companyConfig, locationConfig, enabledProducts, outOfStockVariantIds]) => {
      const buildRowVms = SectionRowViewModelUtils.generateRowViewModels;
      let rowVms = buildRowVms(section, menu, companyConfig, locationConfig, enabledProducts, outOfStockVariantIds);
      if (SectionUtils.isSectionWithProducts(section)) {
        // don't limit sectionRowViewModels$ if menu uses section level overflow, because we'll need to
        // transition through all of them
        if (section?.rowCount > 0 && !menu?.isSectionLevelOverflow()) rowVms = rowVms.take(section?.rowCount);
        const maxProductsPerSection = menu?.hydratedTheme?.themeFeatures?.sectionProductMaxCount;
        if (maxProductsPerSection > 0) rowVms = rowVms.take(maxProductsPerSection);
      }
      return rowVms;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  init(menu?: ProductMenu, section?: Section, calculationMode?: boolean) {
    this._menu.next(menu);
    this._calculationMode.next(calculationMode);
    if (SectionUtils.isSectionWithProducts(section)) this._section.next(section);
  }

  /**
   * Reuse DOM element if the index is equal to the previous index. This prevents product section flickering.
   */
  public trackListByIndex = (index: number) => index;

  /**
   * Reuse the same DOM element for updated row view models so line items don't flicker.
   */
  public trackByUniqueRowViewModelTransitionId = (index: number, rowViewModel: SectionRowViewModel): string => {
    return rowViewModel?.uniqueIdWithTransitionOffset();
  };

}
