/* src/app/modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-number/card-number.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.card-number-container {
  background-color: black;
  border-radius: 50%;
  height: 100%;
  aspect-ratio: 1;
}
.card-number {
  font-family: poppins, sans-serif;
  font-weight: 700;
  color: #222222;
}
.card-number-box-shadow {
  box-shadow: 1px 1px 2px #000000;
}
/*# sourceMappingURL=card-number.component.css.map */
