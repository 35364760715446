import { Component, Input } from '@angular/core';
import { EmptySectionComponent } from '../empty-section.component';
import { DoubleDutchEmptySection } from '../../../../../../../../../models/menu/section/double-dutch-empty-section';

@Component({
  selector: 'app-double-dutch-empty-section',
  templateUrl: './double-dutch-empty-section.component.html',
  styleUrls: ['./double-dutch-empty-section.component.scss']
})
export class DoubleDutchEmptySectionComponent extends EmptySectionComponent {

  @Input() override section: DoubleDutchEmptySection;

}
