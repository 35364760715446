import { Pipe, PipeTransform } from '@angular/core';
import { CardType } from '../../menu-cards/card-type.enum';
import { ComboCard } from '../../../../../../../../models/menu/product-menu-cards/combo-card';
import { Card } from '../../../../../../../../models/menu/product-menu-cards/card';

@Pipe({
  name: 'isHalfImageCard'
})
export class IsHalfImageCardPipe implements PipeTransform {

  transform(value: Card): boolean {
    return value instanceof ComboCard ? value?.type === CardType.Half : false;
  }

}
