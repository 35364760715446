<!-- hidden label to calculate if there is a label or not -->
<app-label-inflator #labelCalculator hidden [labelComponentInterface]="card"></app-label-inflator>

<div class="half-image-card-container" #productCard>
  <!-- Image -->
  <app-asset
    *ngIf="viewModel.hasAsset$ | async; else fallbackIcon"
    class="card-half-image-asset"
    [asset]="card.getAsset()"
    [style.bottom.px]="assetBottom$ | async">
  </app-asset>
  <ng-template #fallbackIcon>
    <img
      class="card-half-image-asset card-fallback-icon"
      [style.height.px]="iconHeight$ | async"
      [src]="viewModel.assetFallBackIconUrl$ | async"
      alt="" />
  </ng-template>
  <div fxLayout="column" fxLayoutAlign="flex-end " class="half-image-overlay" [style.bottom.px]="assetBottom$ | async">
    <app-label-inflator
      *ngIf="!!(labelCalculator.currentLabelText$ | async)"
      [style.display]="'block'"
      [style.align-self]="'flex-end'"
      [labelComponentInterface]="card"
      [fitTextParentContainer]="productCard"
      [fitTextPercentageOfParent]="getLabelPercentage()"
      [fitTextDisabled]="false">
    </app-label-inflator>
  </div>

  <!-- card content -->
  <div class="marketing-card-container top-half-of-card" [style.zIndex]="11">
    <div class="top-row" fxLayout="row" [style.height.%]="16">
      <app-card-number
        #num
        [style.width]="num.width | async"
        [card]="card"
        [changeBackgroundColor]="false"
        [index]="index">
      </app-card-number>
      <app-asset
        *ngFor="let badge of card.getBadges()"
        class="half-image-badge-asset"
        [style.width]="num.width | async"
        [asset]="badge.image"></app-asset>
      <app-card-price class="card-price" fxFlex [card]="card"></app-card-price>
    </div>
  </div>

  <div
    class="marketing-card-container bottom-half-of-card"
    fxLayout="column"
    fxLayoutAlign="end stretch"
    [ngStyle]="{
      color: card.getCardTextColor(),
      'background-color': card.getCardColor() || '#F1F2F3'
    }">
    <div [style.height.%]="12">
      <div
        class="marketing-card-product-name"
        #name
        appFitText
        [percentageOfParent]="getProductNameTextPercentage()"
        [parentContainer]="productCard">
        {{ card?.getProductName() }}
      </div>
    </div>
    <div
      [hidden]="!card?.getProductDesc()"
      class="marketing-card-product-desc"
      [ngStyle]="{ '-webkit-line-clamp': cardCount < 9 ? '4' : '3' }"
      appFitText
      [percentageOfParent]="getProductDescTextPercentage()"
      [parentContainer]="productCard">
      {{ card?.getProductDesc() }}
    </div>
    <div *ngIf="card.getIsThcAndCbdProduct()" class="thc-cbd-size" fxLayout="row" fxLayoutAlign="space-between">
      <div [style.display]="card.getIsCannabinoidRange() ? 'block' : 'contents'">
        <div
          class="marketing-card-product-thc"
          appFitText
          [percentageOfParent]="getProductCannabinoidTextPercentage()"
          [parentContainer]="productCard">
          {{ card?.getThcString() }}
        </div>
        <div
          class="marketing-card-product-cbd"
          appFitText
          [percentageOfParent]="getProductCannabinoidTextPercentage()"
          [parentContainer]="productCard">
          {{ card?.getCbdString() }}
        </div>
      </div>
      <div
        class="marketing-card-product-size"
        appFitText
        [percentageOfParent]="getProductCannabinoidTextPercentage()"
        [parentContainer]="productCard">
        {{ card?.getSizeString() }}
      </div>
    </div>
  </div>
</div>
