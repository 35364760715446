import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { MarketingFeaturedCategoryMenu } from '../../../../../../models/menu/marketing/featured-category/marketing-featured-category-menu';
import { combineLatest } from 'rxjs';
import { OverflowState } from '../../../../../../models/enum/shared/overflow-transition-state.enum';
import { MenuWithScrollableSectionsComponent } from '../../scrollable-sections/menu-with-scrollable-sections.component';
import { MarketingFeaturedCategoryMenuViewModel } from './marketing-featured-category-menu-view-model';
import { ProductMenuSectionInflatorComponent } from '../../product-menu/inflators/product-menu-section-inflator/product-menu-section-inflator.component';
import { RenderContextService } from '../../../../../services/render-context.service';

@Component({
  selector: 'app-marketing-featured-category-menu',
  templateUrl: './marketing-featured-category-menu.component.html',
  providers: [MarketingFeaturedCategoryMenuViewModel, RenderContextService]
})
export class MarketingFeaturedCategoryMenuComponent extends MenuWithScrollableSectionsComponent {

  constructor(
    public viewModel: MarketingFeaturedCategoryMenuViewModel
  ) {
    super(viewModel);
  }

  @Input() menu: MarketingFeaturedCategoryMenu;
  @ViewChildren(ProductMenuSectionInflatorComponent)
  override scrollableItems: QueryList<ProductMenuSectionInflatorComponent>;

  protected override bindToPaging() {
    const s = combineLatest([
      this.viewModel.reset$,
      this.viewModel.pagePosition$.notNull(),
      this.viewModel.scrollingInterface$,
      this.viewModel.activeOverflowState$.notNull(),
      this.viewModel.nSectionsPipe$,
    ]).subscribe(([reset, page, scrollingInterface, overflowState, [portrait]]) => {
      if (overflowState === OverflowState.PAGING || overflowState === OverflowState.SCROLL) {
        if (!reset && !!page.scrollableItemComponent) {
          const smooth = scrollingInterface?.isInScrollPageOverflowState();
          if (smooth) {
            const scrollConfig = portrait
              ? { behavior: 'smooth', block: 'end' }
              : { behavior: 'smooth', inline: 'start' };
            page.scrollableItemComponent.element.nativeElement.scrollIntoView(scrollConfig);
          } else {
            const scrollConfig = portrait ? { behavior: 'auto', block: 'end' } : { behavior: 'auto', inline: 'start' };
            page.scrollableItemComponent.element.nativeElement.scrollIntoView(scrollConfig);
          }
        }
      }
    });
    this.pushSub(s);
    this.viewModel.reset$.subscribeWhileAlive({
      owner: this,
      next: reset => {
        if (reset) this.sectionsContainer?.nativeElement?.scrollTo(0, 0);
      }
    });
  }

  hasHeader(): boolean {
    return this.menu?.getShowHeader();
  }

  hasFooter(): boolean {
    return this.menu?.getShowFooter();
  }

  hasHeaderAndFooter(): boolean {
    return (this.hasHeader() && this.hasFooter());
  }

  hasFooterXorHeader(): boolean {
    return (this.hasHeader() && !this.hasFooter()) || (!this.hasHeader() && this.hasFooter());
  }

  hasNoHeaderAndFooter(): boolean {
    return (!this.hasHeader() && !this.hasFooter());
  }

}
