<div class="error-container move-to-top-of-z-stack">
  <div class="flex-grow"></div>
  <div class="error-margin flex-grow">
    <img
      *ngIf="orientationError === Types.OrientationError.DisplayInPortraitModeButScreenIsLandscape"
      class="orientation-image"
      src="/assets/error/IncorrectOrientationLandscape.svg"
      alt="" />
    <img
      *ngIf="orientationError === Types.OrientationError.DisplayInLandscapeModeButScreenIsPortrait"
      class="orientation-image"
      src="/assets/error/IncorrectOrientationPortrait.svg"
      alt="" />
    <div class="error-title">Oh no.</div>
    <div class="error-desc flex-grow">
      <p>{{ viewModel.errorDesc1$ | async }}</p>
      <p>{{ viewModel.errorDesc2$ | async }}</p>
    </div>
  </div>
  <div class="error-logo-container flex-grow">
    <img class="error-logo" src="/assets/logo/dark/logo-stroke.svg" alt="" />
  </div>
</div>
