import { Pipe, PipeTransform } from '@angular/core';
import { ProductSectionWithTransitionsViewModel } from '../product-menu/building-blocks/menu-section/product-section/product-section-with-transitions/product-section-with-transitions-view-model';
import { Observable } from 'rxjs';

@Pipe({
  name: 'isProductPageHidden'
})
export class IsProductPageHiddenPipe implements PipeTransform {

  transform(viewModel: ProductSectionWithTransitionsViewModel, index: number, length: number): Observable<boolean> {
    return viewModel.isProductPageHidden$(index, length);
  }

}
