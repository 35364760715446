<div
  class="h100p"
  [ngClass]="[viewModel.themeClass$ | async, viewModel.menuWrapperClass$ | async]"
  [style.opacity]="viewModel.menuOpacity$ | async">
  <div class="product-menu-container print-product-menu-container">
    <!-- INFORMATION about why this was done the way that it is -->
    <!-- Notice how headers/footers live inside the sections-container's guide rails. This was a change that
         happened when we implemented the feature that allows headers and footers on all pages.
         The headers and footers have to live within these rails, because they act like regular sections.
         That was the only way to get this feature working with the overflow calculator. -->
    <!-- even though #sectionsContainer is not highlighted, it is still being used by the components parent class -->
    <div #sectionsContainer>
      <app-print-menu-sections-container [menu]="menu">
        <app-print-menu-section-overflow-calculator
          [menu]="menu"
          [companyConfig]="companyConfig"
          [locationConfig]="locationConfig"
          [locationId]="locationId"
          [reset]="reset"
          [pageHeightInPixels]="viewModel.pageLengthInPixels$ | async"
          (overflowedSections)="viewModel.connectToOverflowedSections($event)">
        </app-print-menu-section-overflow-calculator>
        <!-- vm.overflowedSections$ is an output that comes from
             app-menu-section-overflow-calculator. This will output either the recalculated overflowed sections
             or just the base menu.sections -->
        <ng-container
          *ngFor="
            let section of viewModel.pageLimitedOverflowedSections$ | async as sections;
            index as i;
            last as last
          ">
          <app-product-menu-header-inflator
            *ngIf="menu | printMenuBasicMethod : 'shouldInflateHeader' : section"
            [menu]="menu"
            [reset]="reset">
            Header Inflator
          </app-product-menu-header-inflator>
          <app-product-menu-empty-header-replacer-inflator
            *ngIf="
              (menu | printMenuBasicMethod : 'getEmptyHeaderReplacement') !== null &&
              (menu | printMenuBasicMethod : 'shouldInflateEmptyHeaderReplacement' : section)
            "
            [menu]="menu"
            [reset]="reset">
            Empty Header Replacement Inflator
          </app-product-menu-empty-header-replacer-inflator>
          <app-product-menu-section-inflator
            *ngIf="!(section | isPageBreakSection)"
            [reset]="reset"
            [style.width.%]="menu | printMenuBasicMethod : 'getSectionWidthPercentage'"
            [locationConfig]="locationConfig"
            [locationId]="locationId"
            [menu]="menu"
            [index]="i"
            [last]="last"
            [section]="section">
            Section
          </app-product-menu-section-inflator>
          <app-product-menu-footer-inflator
            *ngIf="menu | printMenuBasicMethod : 'shouldInflateFooter' : section"
            [menu]="menu"
            [lastProductSection]="
              viewModel?.lastProductSections$ | async | getLastKnownProductSectionForPage : section.pageIndex
            "
            [sectionsOnPage]="sections | sectionsOnPage : section?.pageIndex"
            [reset]="reset">
            Footer Inflator
          </app-product-menu-footer-inflator>
          <app-product-menu-empty-footer-replacer-inflator
            *ngIf="
              (menu | printMenuBasicMethod : 'getEmptyFooterReplacement') !== null &&
              (menu | printMenuBasicMethod : 'shouldInflateEmptyFooterReplacement' : section)
            "
            [menu]="menu"
            [lastProductSection]="
              viewModel?.lastProductSections$ | async | getLastKnownProductSectionForPage : section.pageIndex
            "
            [sectionsOnPage]="sections | sectionsOnPage : section?.pageIndex"
            [reset]="reset">
            Empty Footer Replacement Inflator
          </app-product-menu-empty-footer-replacer-inflator>
          <!-- forces content onto next page when footer is displayed -->
          <app-page-break-section
            *ngIf="(section | isPageBreakSection) || (section?.lastOnPage && !section?.lastSection)"
            [menu]="menu"
            [locationConfig]="locationConfig"
            [reset]="reset">
            Page Break
          </app-page-break-section>
        </ng-container>
      </app-print-menu-sections-container>
    </div>
  </div>
</div>
