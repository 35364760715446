<div
  #sectionContainer
  class="section-container {{ menu | sectionWrapperClass : index }}"
  [style.color]="menu | sectionBodyTextColor : section">
  <div class="image-section" [style.justify-content]="section | assetSectionAlignment">
    <ng-container *ngFor="let i of section | assetSectionRepetitions">
      <video
        *ngIf="section?.image | assetIsVideo as asset"
        appVideo
        [muted]="!menu?.menuOptions?.playAudio"
        [autoplay]="!calculationMode"
        [controls]="false"
        [loop]="!calculationMode"
        [style.width]="section | assetSectionWidth"
        [asset]="asset"
        [reset]="reset"
        src=""></video>
      <img
        *ngIf="section?.image | assetIsImage as asset"
        appImage
        [style.width]="section | assetSectionWidth"
        [asset]="asset"
        [reset]="reset"
        alt="section image"
        src="" />
    </ng-container>
  </div>
</div>
