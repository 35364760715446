import { Component, ElementRef, forwardRef } from '@angular/core';
import { MenuSectionComponent } from '../../../../product-menu/building-blocks/menu-section/menu-section.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-spotlight-section',
  templateUrl: './spotlight-section.component.html',
  providers: [
    { provide: MenuSectionComponent, useExisting: forwardRef(() => SpotlightSectionComponent) }
  ]
})
export class SpotlightSectionComponent extends MenuSectionComponent {

  constructor(
    public sanitizer: DomSanitizer,
    public element: ElementRef
  ) {
    super(sanitizer, element);
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
