import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseProductCardComponent } from '../base-product-card/base-product-card.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrientationService } from '../../../../../../../../services/orientation.service';
import { Orientation } from '../../../../../../../../models/enum/dto/orientation.enum';
import { HalfImageCardViewModel } from './half-image-card-view-model';

@Component({
  selector: 'app-half-image-card',
  templateUrl: './half-image-card.component.html',
  styleUrls: [
    './half-image-card.component.scss',
    '../base-product-card/base-card.component.scss'
  ],
  providers: [HalfImageCardViewModel],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HalfImageCardComponent extends BaseProductCardComponent implements OnDestroy {

  constructor(
    public viewModel: HalfImageCardViewModel,
    orientationService: OrientationService
  ) {
    super(viewModel, orientationService);
  }

  @ViewChild('name') name: ElementRef;
  public ro: ResizeObserver;
  private nameDomRec = new BehaviorSubject<DOMRectReadOnly | null>(null);
  public nameTop$ = combineLatest([
    this.orientationService.virtuallyRotated$,
    this.orientationService.virtualOrientation$,
    this.nameDomRec,
  ]).pipe(
    map(([virtuallyRotated, virtualOrientation, nameRec]) => {
      if (virtuallyRotated) {
        switch (virtualOrientation) {
          case Orientation.Portrait:
            return nameRec?.right;
          case Orientation.ReversePortrait:
            return nameRec?.left;
          default:
            return nameRec?.top;
        }
      } else {
        return nameRec?.top;
      }
    })
  );

  public assetBottom$ = combineLatest([
    this.orientationService.virtuallyRotated$,
    this.orientationService.virtualOrientation$,
    this.cardContainerClientHeight$,
    this.cardContainerBottom$,
    this.nameTop$
  ]).pipe(
    map(([virtuallyRotated, virtualOrientation, containerHeight, containerBottom, nameTop]) => {
      const buffer = (containerHeight * 0.02);
      if (virtuallyRotated) {
        switch (virtualOrientation) {
          case Orientation.Portrait:
            return nameTop - containerBottom + buffer;
          case Orientation.ReversePortrait:
            return containerBottom - nameTop + buffer;
          default:
            return containerBottom - nameTop + buffer;
        }
      } else {
        return containerBottom - nameTop + buffer;
      }
    })
  );

  public iconHeight$ = combineLatest([
    this.cardContainerClientHeight$,
    this.assetBottom$
  ]).pipe(
    map(([containerHeight, assetBottom]) => containerHeight - assetBottom)
  );

  setupBindings() {
    super.setupBindings();
    this.observeName();
  }

  observeName(): void {
    this.ro = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const nameRec = this.name?.nativeElement?.getBoundingClientRect();
        this.nameDomRec.next(nameRec as DOMRectReadOnly);
      }
    });
    // Element for which to observe height and width
    this.ro.observe(this.name.nativeElement);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.ro?.disconnect();
  }

}
