import { Component, Input } from '@angular/core';
import { EmptySectionComponent } from '../empty-section.component';
import { PlantlifeEmptySection } from '../../../../../../../../../models/menu/section/plantlife-empty-section';

@Component({
  selector: 'app-plantlife-empty-section',
  templateUrl: './plantlife-empty-section.component.html',
  styleUrls: ['./plantlife-empty-section.component.scss']
})
export class PlantlifeEmptySectionComponent extends EmptySectionComponent {

  @Input() override section: PlantlifeEmptySection;

}
