import { Component, Input } from '@angular/core';
import { SpotlightMenuSectionOverflowCalculatorComponentViewModel } from './spotlight-menu-section-overflow-calculator-component-view-model';
import { SpotlightMenu } from '../../../../../../models/menu/spotlight-menu';
import { MenuSectionOverflowCalculatorComponent } from '../../menu/menu-section-overflow-calculator/menu-section-overflow-calculator-component';

@Component({
  selector: 'app-spotlight-menu-section-overflow-calculator',
  templateUrl: './spotlight-menu-section-overflow-calculator.component.html',
  styleUrls: ['./spotlight-menu-section-overflow-calculator.component.scss'],
  providers: [SpotlightMenuSectionOverflowCalculatorComponentViewModel]
})
export class SpotlightMenuSectionOverflowCalculatorComponent extends MenuSectionOverflowCalculatorComponent {

  @Input() public menu: SpotlightMenu = null;

  constructor(
    public viewModel: SpotlightMenuSectionOverflowCalculatorComponentViewModel
  ) {
    super(viewModel);
  }

}
