import { Component, ElementRef, Input } from '@angular/core';
import { MenuSectionComponent } from '../menu-section.component';
import { DomSanitizer } from '@angular/platform-browser';
import { EmptySection } from '../../../../../../../../models/menu/section/empty-section';

@Component({
  selector: 'app-empty-section',
  templateUrl: './empty-section.component.html',
  styleUrls: ['./empty-section.component.scss']
})
export class EmptySectionComponent extends MenuSectionComponent {

  @Input() override section: EmptySection;

  constructor(
    sanitizer: DomSanitizer,
    element: ElementRef
  ) {
    super(sanitizer, element);
  }

  getUniqueIdentifier(): string {
    return this.section?.getUniqueIdentifier();
  }

}
