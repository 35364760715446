@charset "UTF-8";

/* src/app/modules/display/components/menus/marketing-menu/building-blocks/card-sections/header-image-carousel-card-section/header-image-carousel-card-section.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.section-container.header-image-carousel-card-section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.45rem;
}
.section-container.header-image-carousel-card-section .clip-x-overflow {
  overflow-x: clip;
}
.section-container.header-image-carousel-card-section .products-container {
  flex: 1 1 0;
  display: block;
  overflow: hidden;
  margin: 0.75rem;
  background-color: white;
  border-radius: 0.45rem;
}
.section-container.header-image-carousel-card-section .carousel-container {
  position: absolute;
  left: 1rem;
  top: 2rem;
  right: 1rem;
  bottom: 2rem;
}
.section-container.header-image-carousel-card-section .card-image-header-container {
  height: 30%;
  padding: 1rem 1rem 0 1rem;
  margin-bottom: 3%;
  position: relative;
  display: flex;
}
.section-container.header-image-carousel-card-section .embedded-card-title-container {
  width: 33%;
  height: 100%;
  margin-top: 1rem;
}
.section-container.header-image-carousel-card-section .embedded-card-title-container .card-title {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
@keyframes card-subtitle {
  0% {
    font-size: 1rem;
  }
  100% {
    font-size: 2rem;
  }
}
.section-container.header-image-carousel-card-section .card-subtitle {
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1;
  --tt-key: card-subtitle;
  --tt-max: 1020;
  padding: 0 1rem;
  max-width: 100%;
}
@keyframes card-title {
  0% {
    font-size: 2rem;
  }
  100% {
    font-size: 3.5rem;
  }
}
.section-container.header-image-carousel-card-section .card-title {
  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
  padding: 0 1rem;
  margin-bottom: 3%;
  line-height: 1;
  --tt-key: card-title;
  --tt-max: 1020;
}
.section-container.header-image-carousel-card-section .card-number-container {
  height: 4.625rem;
  width: 4.625rem;
}
.section-container.header-image-carousel-card-section .card-number-container .card-number {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 2.25rem;
}
@keyframes product-name {
  0% {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  100% {
    font-size: 2.5rem;
    line-height: 1.5;
  }
}
.section-container.header-image-carousel-card-section .product-name {
  font-family: poppins, sans-serif;
  font-weight: 700;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -moz-box;
  line-clamp: 1;
  --tt-key: product-name;
  --tt-max: 900;
}
@keyframes product-brand {
  0% {
    font-size: 1rem;
  }
  100% {
    font-size: 2rem;
  }
}
.section-container.header-image-carousel-card-section .product-brand {
  font-family: poppins, sans-serif;
  font-weight: 400;
  --tt-key: product-brand;
  --tt-max: 900;
}
@keyframes badge {
  0% {
    height: 2.75rem;
  }
  100% {
    height: 4rem;
  }
}
.section-container.header-image-carousel-card-section .badge {
  height: 3rem;
  width: auto;
  --tt-key: badge;
}
@keyframes product-label-container {
  0% {
    height: 2rem;
    font-size: 0.75rem;
  }
  100% {
    height: 3rem;
    font-size: 1.5rem;
  }
}
.section-container.header-image-carousel-card-section .product-label-container {
  overflow-x: hidden;
  width: auto;
  font-family: poppins, sans-serif;
  font-weight: 500;
  --tt-key: product-label-container;
  --tt-max: 1020;
}
@keyframes product-label {
  0% {
    line-height: 2rem;
    padding: 0 0.3125rem;
  }
  100% {
    padding: 0 0.5rem;
    line-height: 2.5rem;
  }
}
.section-container.header-image-carousel-card-section .product-label-container .product-label {
  display: block;
  margin-left: 0;
  --tt-key: product-label;
  --tt-max: 1020;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.section-container.header-image-carousel-card-section .product-label-container .flag-body {
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
@keyframes product-info {
  0% {
    font-size: 0.8rem;
  }
  100% {
    font-size: 2.75rem;
  }
}
.section-container.header-image-carousel-card-section .product-info {
  font-family: poppins, sans-serif;
  font-weight: 600;
  line-height: 1;
  --tt-key: product-info;
}
.section-container.header-image-carousel-card-section .force-italic-style * {
  font-style: italic !important;
}
.section-container.header-image-carousel-card-section .force-bold-style * {
  font-family: poppins, sans-serif;
  font-weight: 700;
}
/*# sourceMappingURL=header-image-carousel-card-section.component.css.map */
