import { Component, ElementRef, EnvironmentInjector, forwardRef, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { MenuItemComponent } from '../../building-blocks/menu-item/menu-item.component';
import { MenuItemInflatorComponent } from '../../../menu/inflators/menu-item-inflator-component';
import { Menu } from '../../../../../../../models/menu/menu';
import { exists } from '../../../../../../../functions/exists';

@Component({
  selector: 'app-product-menu-item-inflator',
  templateUrl: './product-menu-item-inflator.component.html',
  providers: [
    { provide: MenuItemInflatorComponent, useExisting: forwardRef(() => ProductMenuItemInflatorComponent) }
  ]
})
export class ProductMenuItemInflatorComponent extends MenuItemInflatorComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(elementRef, injector, environmentInjector);
  }

  @Input() menu: Menu;
  @Input() sectionDominantBackgroundColor: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rowViewModel) {
      this.connectToComponentType(this.getMenuItemType());
    } else {
      super.ngOnChanges(changes);
    }
  }

  protected override forwardInputs(): void {
    super.forwardInputs();
    if (exists(this.compRef)) this.compRef.instance.section = this.rowViewModel?.section;
  }

  protected override forwardInputChanges(changes: SimpleChanges): void {
    if (exists(this.compRef)) {
      Object.keys(changes)
        ?.filter(propName => propName !== 'section')
        ?.forEach(propertyName => this.compRef.setInput(propertyName, this[propertyName]));
      if (changes.rowViewModel) this.compRef.instance.section = this.rowViewModel.section;
      this.compRef?.instance?.ngOnChanges(changes);
    }
  }

  protected getMenuItemType(): Type<MenuItemComponent> {
    return this.rowViewModel?.section?.getMenuItemType();
  }

}
