import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DisplayViewModel } from './display-view-model';
import { ResizeObserver } from '@juggle/resize-observer';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Orientation } from '../../../../models/enum/dto/orientation.enum';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { OrientationError } from '../../../../models/enum/dto/orientation-error.enum';
import { LoggingService } from '../../../../services/logging-service';
import { environment } from '../../../../../environments/environment';
import { MenuType } from '../../../../models/enum/dto/menu-type.enum';
import { OrientationService } from '../../../../services/orientation.service';
import { BaseComponent } from '../../../../models/base/base-component';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DisplayViewModel],
})
export class DisplayComponent extends BaseComponent implements OnInit, AfterViewInit {

  constructor(
    public vm: DisplayViewModel,
    public logging: LoggingService,
    private orientationService: OrientationService
  ) {
    super();
  }

  @ViewChild('displayContainer')
  private displayContainer: ElementRef;
  private resizeObserver: ResizeObserver;
  // Display or menu?
  public isDisplayElseMenu$ = combineLatest([this.vm.displayId, this.vm.templateCollectionId]).pipe(
    map(([displayId, templateCollectionId]) => exists(displayId) || exists(templateCollectionId)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  // Orientation Error
  public calcOrientationError$ = combineLatest([
    this.orientationService.orientation$,
    this.vm.menuToDisplay$.pipe(map(it => it?.menu)),
    this.isDisplayElseMenu$,
    this.vm.canRotateWebView$
  ]).pipe(
    debounceTime(250),
    map(([orientation, menu, isDisplay, canRotateWebView]) => {
      let status: OrientationError;
      if (exists(isDisplay) && !canRotateWebView) {
        if (exists(menu) && menu?.isLandscape() && orientation === Orientation.Portrait) {
          status = OrientationError.DisplayInLandscapeModeButScreenIsPortrait;
        } else if (exists(menu) && menu.isPortrait() && orientation === Orientation.Landscape) {
          status = OrientationError.DisplayInPortraitModeButScreenIsLandscape;
        } else {
          status = OrientationError.NoError;
        }
      } else {
        status = OrientationError.NoError;
      }
      this.logging.logScreenOrientationError(status);
      return status;
    }),
    startWith(OrientationError.NoError),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public noOrientationError$ = this.calcOrientationError$.pipe(
    map(it => it === OrientationError.NoError),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public ignoreTouch = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    super.ngOnInit();
    this.vm.bind();
  }

  setupViews() {

  }

  setupBindings() {
    this.observeDisplayContainer();
    // Sub to active menu for touch gesture
    const touchSub = this.vm.menuToDisplay$.notNull().subscribe((m) => {
      this.ignoreTouch.next(environment.production && m.menu.type !== MenuType.WebMenu);
    });
    this.pushSub(touchSub);
  }

  private observeDisplayContainer() {
    this.resizeObserver?.disconnect();
    this.resizeObserver = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        this.orientationService.setOrientation(entry.contentRect as DOMRectReadOnly);
      }
    });
    // Element for which to observe height and width
    this.resizeObserver.observe(this.displayContainer.nativeElement);
  }

  destroy() {
    super.destroy();
    this.resizeObserver.disconnect();
  }

}
