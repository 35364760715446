import { Component, Input } from '@angular/core';
import { MarketingMenuComponent } from '../marketing-menu.component';
import { MarketingSmartPlaylistMenuViewModel } from './marketing-smart-playlist-menu-view-model.service';
import { MarketingSmartPlaylistMenu } from '../../../../../../models/menu/marketing/marketing-smart-playlist-menu';
import { RenderContextService } from '../../../../../services/render-context.service';

@Component({
  selector: 'app-marketing-smart-playlist-menu',
  templateUrl: './marketing-smart-playlist-menu.component.html',
  styleUrls: ['./marketing-smart-playlist-menu.component.scss'],
  providers: [MarketingSmartPlaylistMenuViewModel, RenderContextService]
})
export class MarketingSmartPlaylistMenuComponent extends MarketingMenuComponent {

  constructor(
    public vm: MarketingSmartPlaylistMenuViewModel
  ) {
    super(vm);
  }

  @Input() public override menu: MarketingSmartPlaylistMenu = null;

}
