import { Component, Input } from '@angular/core';
import { MarketingFeaturedCategoryStaticGridMenuViewModel } from './marketing-featured-category-static-grid-menu-view-model';
import { MenuWithMultipleScrollableSectionsComponent } from '../../scrollable-sections/menu-with-multiple-scrollable-sections.component';
import { MarketingFeaturedCategoryStaticGridMenu } from '../../../../../../models/menu/marketing/featured-category/marketing-featured-category-static-grid-menu';
import { RenderContextService } from '../../../../../services/render-context.service';

@Component({
  selector: 'app-marketing-featured-category-static-grid-menu',
  templateUrl: './marketing-featured-category-static-grid-menu.component.html',
  providers: [MarketingFeaturedCategoryStaticGridMenuViewModel, RenderContextService]
})
export class MarketingFeaturedCategoryStaticGridMenuComponent extends MenuWithMultipleScrollableSectionsComponent {

  @Input() menu: MarketingFeaturedCategoryStaticGridMenu;

  constructor(
    public viewModel: MarketingFeaturedCategoryStaticGridMenuViewModel
  ) {
    super(viewModel);
  }

}
