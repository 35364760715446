import { Pipe, PipeTransform } from '@angular/core';
import { ProductMenu } from '../../../../../../../../models/menu/product-menu';
import { Section } from '../../../../../../../../models/menu/section/section';

@Pipe({
  name: 'sectionBodyTextColor'
})
export class SectionBodyTextColorPipe implements PipeTransform {

  transform(menu: ProductMenu, section: Section): string | null | undefined {
    return menu?.getSectionBodyTextColor(section);
  }

}
