import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header-image-carousel-card-price',
  templateUrl: './header-image-carousel-card-price.component.html',
  styleUrls: ['./header-image-carousel-card-price.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderImageCarouselCardPriceComponent {

  @Input() textColor: string;
  @Input() originalPrice: string;
  @Input() salePrice: string;

  constructor() {
  }

}
