import { Inject, Injectable } from '@angular/core';
import { BaseService } from '../models/base/base-service';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScalingService extends BaseService {

  // Required for handling various screen sizes and different base rem values

  private remObserver: MutationObserver;
  private _fontSizeInPixels = new BehaviorSubject<number>(16); // 16px is the base value for 1rem
  public fontSizeInPixels$ = this._fontSizeInPixels.asObservable();

  constructor(
    @Inject(DOCUMENT) private doc: Document
  ) {
    super();
    this.observeFontSizeChanges();
  }

  observeFontSizeChanges() {
    this.remObserver = new MutationObserver((_) => {
      const fontSizeInPixels = window.getComputedStyle(this.doc.documentElement).getPropertyValue('font-size');
      this._fontSizeInPixels.next(parseInt(fontSizeInPixels, 10));
    });
    this.remObserver.observe(this.doc.documentElement, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['style', 'class'],
      childList: true,
    });
  }

  destroy() {
    super.destroy();
    this.remObserver.disconnect();
  }

}
