import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnComponent } from '../column.component';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { SharedModule } from '../../../shared.module';
import { exists } from '../../../../../functions/exists';
import type { SectionColumnConfigDataValue } from '../../../../../models/enum/shared/section-column-config-data-value';
import { SectionColumnCannabinoidType } from '../../../../../models/enum/shared/section-column-cannabinoid-type';

@Component({
  selector: 'app-column-cannabinoid',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgStyle,
    SharedModule,
  ],
  templateUrl: './column-cannabinoid.component.html',
  styleUrl: './column-cannabinoid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnCannabinoidComponent extends ColumnComponent implements AfterViewInit, OnChanges {

  constructor(protected renderer: Renderer2) {
    super();
  }

  @Input() useNgContent: boolean;
  @Input() cannabinoidColumnType: SectionColumnCannabinoidType;
  @Input() displayFormat: SectionColumnConfigDataValue;
  @ViewChild('cannabinoidColumn') cannabinoidColumn: ElementRef<HTMLDivElement>;
  private currentCannabinoidClass: string;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.updateClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cannabinoidColumnType) this.updateClass();
  }

  private updateClass(): void {
    const thcAndCbd = this.cannabinoidColumnType === SectionColumnCannabinoidType.THCAndCBD;
    if (exists(this.cannabinoidColumn?.nativeElement) && !thcAndCbd) {
      this.renderer.removeClass(this.cannabinoidColumn.nativeElement, this.currentCannabinoidClass);
      this.currentCannabinoidClass = `product-${this.cannabinoidColumnType?.toLowerCase()}`;
      this.renderer.addClass(this.cannabinoidColumn?.nativeElement, this.currentCannabinoidClass);
    }
  }

}

