import { Pipe, PipeTransform } from '@angular/core';
import { Themeable } from '../../../../../models/protocols/themeable';

@Pipe({
  name: 'getThemeClass'
})
export class GetThemeClassPipe implements PipeTransform {

  transform(menu: Themeable): string | null {
    return menu?.getThemeClass() || null;
  }

}
