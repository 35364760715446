/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/split-product-section/split-product-section.component.scss */
.split-product-section-header {
  display: flex;
  flex-direction: row;
}
.products-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.split-product-header-inflator {
  display: block;
  width: 50%;
}
.split-product-inflator {
  display: block;
  width: 50%;
}
/*# sourceMappingURL=split-product-section.component.css.map */
