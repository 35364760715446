import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { Menu } from '../../../../../models/menu/menu';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent {

  @Input() public menu: Menu;
  @Input() public hidePrices: boolean;
  @Input() public locationId: number;
  @Input() public companyConfig: CompanyConfiguration;
  @Input() public locationConfig: LocationConfiguration;
  @Input() public loading: boolean;
  @Input() public reset: boolean;
  @Input() public backOfMenuFlipper: boolean;

}
