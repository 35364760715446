<app-print-menu-sections-container [menu]="menu" [calculating]="true">
  <app-product-menu-header-inflator
    class="hide-print-header"
    [menu]="menu"
    [reset]="reset"
    (headerHeightInPx)="viewModel.connectToHeaderHeight($event)">
    Header Inflator
  </app-product-menu-header-inflator>

  <app-product-menu-empty-header-replacer-inflator
    *ngIf="(menu | printMenuBasicMethod : 'getEmptyHeaderReplacement') !== null"
    class="hide-print-header"
    [menu]="menu"
    [reset]="reset"
    (headerHeightInPx)="viewModel.connectToEmptyHeaderReplacementHeight($event)">
    Empty Header Replacement Inflator
  </app-product-menu-empty-header-replacer-inflator>

  <app-product-menu-section-inflator
    #emptySection
    id="empty-section-height-calculator"
    class="hide-empty-section-calculator"
    [calculationMode]="true"
    [reset]="reset"
    [locationId]="locationId"
    [menu]="menu"
    [section]="viewModel.emptySection$ | async"
    [ngStyle]="{ 'max-height': (viewModel.shouldOverflowHorizontallyElseVertically$ | async) ? '100%' : null }">
    Empty Container Height Inflator
  </app-product-menu-section-inflator>

  <div class="menu-section-overflow-calculator wrapping-swimlane-section-container">
    <div
      #sectionOverflowContainer
      [class.wrapping-swimlane]="viewModel.wrappingSwimlanesEnabled$ | async"
      [style.width.%]="viewModel.sectionWidthPercentage$ | async">
      <ng-container *ngFor="let s of menu?.sections; index as i; last as last">
        <app-product-menu-section-inflator
          *ngIf="(viewModel.headerAsTitleSectionIndex$ | async) === i"
          [calculationMode]="true"
          [reset]="reset"
          [hidden]="menu?.menuOptions?.hideTitle"
          [index]="i"
          [last]="menu?.sections?.length <= 0"
          [locationId]="locationId"
          [menu]="menu"
          [section]="viewModel.headerAsTitleSection$ | async"
          [ngStyle]="{ 'max-height': (viewModel.shouldOverflowHorizontallyElseVertically$ | async) ? '100%' : null }">
          Header As Title Section
        </app-product-menu-section-inflator>
        <app-product-menu-section-inflator
          [calculationMode]="true"
          [reset]="reset"
          [locationId]="locationId"
          [menu]="menu"
          [index]="i >= (viewModel.headerAsTitleSectionIndex$ | async) ? i + 1 : i"
          [last]="last"
          [section]="s"
          [ngStyle]="{ 'max-height': (viewModel.shouldOverflowHorizontallyElseVertically$ | async) ? '100%' : null }">
          Section
        </app-product-menu-section-inflator>
      </ng-container>
    </div>
    <div *ngFor="let i of viewModel.nSwimLanesToFake$ | async | range" class="wrapping-swimlane"></div>
  </div>

  <app-product-menu-footer-inflator
    class="hide-print-footer"
    [menu]="menu"
    [reset]="reset"
    (heightInPx)="viewModel.connectToFooterHeight($event)">
    Footer Inflator
  </app-product-menu-footer-inflator>

  <app-product-menu-empty-footer-replacer-inflator
    *ngIf="(menu | printMenuBasicMethod : 'getEmptyFooterReplacement') !== null"
    class="hide-print-footer"
    [menu]="menu"
    [reset]="reset"
    (heightInPx)="viewModel.connectToEmptyFooterReplacementHeight($event)">
    Empty Footer Replacement Inflator
  </app-product-menu-empty-footer-replacer-inflator>
</app-print-menu-sections-container>
