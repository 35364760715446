<div class="menu-default-white-background"></div>

<app-background-asset
  class="menu-background-asset"
  [defaultImageUrl]="menu | getDefaultBackgroundImageUrl"
  [defaultVideoUrl]="menu | getDefaultBackgroundVideoUrl"
  [menu]="menu"
  [scaleFit]="false"
  [reset]="reset"
  [loaded]="!loading">
  Background Asset
</app-background-asset>

<!-- A fixed wrapper to apply print styling to every page (ie: border) hidden by default -->
<div [hidden]="!menu" [ngClass]="menu | castToProductMenu | getThemeClass" class="fixed-print-wrapper"></div>

<div class="menu-container" [hidden]="!menu">
  <app-spotlight-menu-inflator
    *ngIf="menu | isSpotlightMenu"
    [menu]="menu | castToSpotlightMenu"
    [locationId]="locationId"
    [companyConfig]="companyConfig"
    [locationConfig]="locationConfig"
    [reset]="reset"
    [backOfMenuFlipper]="backOfMenuFlipper">
    Spotlight Menu
  </app-spotlight-menu-inflator>

  <app-print-menu-inflator
    *ngIf="menu | isPrintMenu"
    [menu]="menu | castToPrintMenu"
    [locationId]="locationId"
    [companyConfig]="companyConfig"
    [locationConfig]="locationConfig"
    [reset]="reset"
    [backOfMenuFlipper]="backOfMenuFlipper">
    Print Menu
  </app-print-menu-inflator>

  <app-product-menu-inflator
    *ngIf="menu | isBaseLevelProductMenu"
    [menu]="menu | castToProductMenu"
    [locationId]="locationId"
    [companyConfig]="companyConfig"
    [locationConfig]="locationConfig"
    [reset]="reset"
    [backOfMenuFlipper]="backOfMenuFlipper">
    Product Menu
  </app-product-menu-inflator>

  <app-marketing-menu-inflator
    *ngIf="menu | isMarketingMenu"
    [menu]="menu | castToMarketingMenu"
    [locationId]="locationId"
    [companyConfig]="companyConfig"
    [locationConfig]="locationConfig"
    [reset]="reset"
    [backOfMenuFlipper]="backOfMenuFlipper">
    Marketing Menu
  </app-marketing-menu-inflator>
</div>
