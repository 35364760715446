import { AfterViewInit, Component, ComponentRef, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MarketingMenu } from '../../../../../../../models/menu/marketing-menu';
import { MarketingHeaderComponent } from '../../building-blocks/menu-header/marketing-header.component';
import { MarketingMenuHeaderInflatorContainerDirective } from './marketing-menu-header-inflator-container.directive';
import { Card } from '../../../../../../../models/menu/product-menu-cards/card';
import { ComboHeaderComponent } from '../../building-blocks/menu-header/combo-header/combo-header.component';
import { exists } from '../../../../../../../functions/exists';

@Component({
  selector: 'app-marketing-menu-header-inflator',
  templateUrl: './marketing-menu-header-inflator.component.html',
})
export class MarketingMenuHeaderInflatorComponent implements AfterViewInit, OnChanges {

  constructor(
    protected injector: Injector,
    protected environmentInjector: EnvironmentInjector
  ) {
  }

  @Input() menu: MarketingMenu;
  @Input() cards: Card[] = [];
  @Input() reset: boolean;
  @ViewChild(MarketingMenuHeaderInflatorContainerDirective, { static: true })
  contentContainer: MarketingMenuHeaderInflatorContainerDirective;

  private compRef: ComponentRef<MarketingHeaderComponent>;

  ngAfterViewInit() {
    this.inflateHeader();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) {
      this.inflateHeader();
    }
    if (changes.menu) this.compRef?.setInput('menu', this.menu);
    if (changes.reset) this.compRef?.setInput('reset', this.reset);
    if (!!changes.cards && this.compRef?.instance instanceof ComboHeaderComponent) {
      this.compRef?.setInput('cards', this.cards);
    }
    this.compRef?.instance?.ngOnChanges(changes);
  }

  inflateHeader() {
    if (exists(this.menu)) {
      this.contentContainer.viewContainerRef.clear();
      this.compRef = this
        .contentContainer
        .viewContainerRef
        ?.createComponent(
          this.menu.getHeaderType(),
          { injector: this.injector, environmentInjector: this.environmentInjector }
        );
      this.compRef.setInput('menu', this.menu);
      if (this.menu.getHeaderType() === ComboHeaderComponent) {
        this.compRef.setInput('cards', this.cards);
      }
      this.compRef.setInput('reset', this.reset);
    }
  }

}
