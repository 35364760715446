<ng-container *ngFor="let col of sectionColumnViewModels">
  <div
    *ngIf="menu | getShouldInflateColumnHeaderForRow: section : col : sectionRowViewModels"
    class="section-column"
    [ngClass]="{ 'section-column-title': col.columnType === Types.SectionColumnProductInfoType.ProductTitle }"
    [style.width.%]="col.widthPercentage"
    [hidden]="menu | getShouldHideHeaderContent: section : col">
    <div
      *ngIf="col.columnType === Types.SectionColumnProductInfoType.StrainType"
      appScaleDownText
      [scaleEnabled]="!(menu | isPrintMenu)"
      [scaleDownLinkedKey]="this.section.id + Types.TextSizeLink.Classification"
      class="section-header-general section-header-size">
      {{ classification }}
    </div>
    <div
      *ngIf="col.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
      appScaleDownText
      [scaleEnabled]="!(menu | isPrintMenu)"
      [scaleDownLinkedKey]="this.section.id + Types.TextSizeLink.TypeCannabinoid"
      class="section-header-general section-header-thc">
      THC|CBD
    </div>
    <div
      *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
      appScaleDownText
      [scaleEnabled]="!(menu | isPrintMenu)"
      [scaleDownLinkedKey]="
        this.section.id +
        ((section | isInLineMode) ? Types.TextSizeLink.PricePreRollQuantitySize : Types.TextSizeLink.PriceLink)
      "
      class="section-header-general section-header-variant-price">
      $
    </div>
  </div>
</ng-container>
